import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';

import { getMemorialsApi, deleteMemorialApi, memorialTransApi } from 'apis/memorial';

import Pagination from 'react-js-pagination';

import noDeceasedPhoto from 'assets/images/no_img_memorial.jpg';

import SharedDialog from 'pages/pet-memorial-canvas/memorial/components/SharedDialog';
import ObituaryThanksDialog from 'pages/pet-memorial-canvas/mypage/components/ObituaryThanksDialog';

import 'css/pagination.css';

function MypageMemorialPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { loginUser } = useLogin();
  const { setGnbType } = useLayout();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const [searchMemorials, setSearchMemorials] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(6);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [searchWord, setSearchWord] = useState();

  const [openThanksDialog, setOpenThanksDialog] = useState(false);
  const [openSharedDialog, setOpenSharedDialog] = useState(false);

  const [selectedMemorialId, setSelectedMemorialId] = useState(0);

  const [currentMemorial, setCurrentMemorial] = useState({});

  const getSearchMemorialTotalCount = async () => {
    let params = {};
    params.status = 2;
    params.multy_type = 'N';
    params.own_member_id = loginUser.member_id;
    if (searchValue && searchValue !== '') params.deceased_name = searchValue;

    const result = await getMemorialsApi(params);
    setTotalCount(result.data.length);
  };

  const getSearchMemorials = async pageNum => {
    let params = {};
    params.status = 2;
    params.multy_type = 'N';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.own_member_id = loginUser.member_id;
    params.page = pageNum;
    params.itemInPage = listSize;
    // if (searchValue && searchValue !== '') params.deceased_name = searchValue;
    if (searchWord) params.searchWord = searchWord;

    const result = await getMemorialsApi(params);
    if (result.code === '0000') {
      setSearchMemorials(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getSearchMemorialsMore = async pageNum => {
    let params = {};
    params.status = 2;
    params.multy_type = 'N';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.own_member_id = loginUser.member_id;
    params.page = pageNum;
    params.itemInPage = listSize;
    // if (searchValue && searchValue !== '') params.deceased_name = searchValue;
    if (searchWord) params.searchWord = searchWord;

    const result = await getMemorialsApi(params);

    if (result.code === '0000') {
      setSearchMemorials([...searchMemorials, ...result.data]);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const deleteMemorial = async params => {
    const result = await deleteMemorialApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getSearchMemorials(1);
  };

  const memorialTrans = async params => {
    const result = await memorialTransApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getSearchMemorials(1);
  };

  const handleSearch = () => {
    getSearchMemorials(1);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getSearchMemorials(selectedPage);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleRegister = () => {
    navigate('/pet/memorial/register');
  };

  const handleModify = memorialId => {
    navigate(`/pet/memorial/modify/${memorialId}`);
  };

  const handleObituarys = memorialId => {
    navigate(`/pet/memorial/view/${memorialId}`, { state: { viewTab: 1 } });
  };

  const handleObituarysId = memorialId => {
    navigate(`/pet/memorial/view/${memorialId}`);
  };

  const handleDelete = memorialId => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(memorialId),
      () => hideConfirm(),
    );
  };

  const handleChangeStatus = memorialId => {
    showConfirm(
      '부고 전환 확인',
      '선택한 추모관을 부고로 정말 전환하시겠습니까?',
      () => confirmChangeStatus(memorialId),
      () => hideConfirm(),
    );
  };

  const confirmChangeStatus = memorialId => {
    hideConfirm();
    memorialTrans({ memorial_id: memorialId, status: '1' });
  };

  const handleConfirm = memorialId => {
    hideConfirm();
    deleteMemorial(memorialId);
  };

  const handleOpenThanksDialog = memorialId => {
    setSelectedMemorialId(memorialId);
    setOpenThanksDialog(true);
  };

  const handleSubmitMemorial = memorial => {
    setCurrentMemorial(() => memorial);
    setOpenSharedDialog(() => true);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getSearchMemorialsMore(nextPage);
    }
  };

  const handleTabClick = () => {
    // 부고 탭 클릭 시 페이지를 다시 로드합니다.
    //history.push('/memorial/mypage/obituary');
    window.location.reload();
    navigate('/pet/mypage/memorial');
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser && loginUser.member_id) {
      getSearchMemorialTotalCount();
      getSearchMemorials(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">마이페이지 </h2>
            <p>
              메모리얼 캔버스는
              <br />
              새로운 장례 문화를 선도하는 사회적 기업입니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          <div className="tabs tabs-default">
            <ul>
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/obituary">부고</Link>
                </a>
              </li>
              <li className="is-active">
                <a href={() => false}>
                  <Link className="on" to="/pet/mypage/memorial" onClick={handleTabClick}>
                    추모관
                  </Link>
                </a>
              </li>
              {/*<li>*/}
              {/*  <a href={() => false}>*/}
              {/*    <Link className="on" to="/pet/mypage/wreath">*/}
              {/*      구매내역*/}
              {/*    </Link>*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/member">회원설정</Link>
                </a>
              </li>
            </ul>
          </div>

          <div className="wrap-mypage">
            {/* S : 목록 상단 검색 + 버튼 */}
            <div className="list-head">
              {/* S : 목록 검색 */}
              <div className="box-search">
                <label for="ip-search" className="offscreen">
                  검색하기
                </label>
                <input
                  type="search"
                  id="ip-search"
                  placeholder="반려동물의 이름을 입력하세요"
                  value={searchWord}
                  onChange={e => setSearchWord(e.target.value)}
                  onKeyPress={onKeyPress}
                />
                <button type="button" ref={searchRef} onClick={() => handleSearch()}>
                  <i className="ic ic-search"></i>
                  <span className="offscreen">검색하기</span>
                </button>
              </div>
              {/* E : 목록 검색 */}
              {/* S : 버튼 */}
              <a href={() => false} className="btn btn-pos" onClick={handleRegister}>
                추모관 등록
              </a>
              {/* E : 버튼 */}
            </div>
            {/* E : 목록 상단 검색 + 버튼 */}

            {/* S : 작성한 추모관 글 목록 */}
            {searchMemorials && searchMemorials.length > 0 ? (
              <div className="list-obituary">
                {/* S : 추모관 글 목록 반복 시작 */}
                {searchMemorials.map(memorial => (
                  <div className="box-obituary" key={memorial.memorial_id}>
                    <div className="inner">
                      {/* S : 이미지 */}

                      <a
                        href={() => false}
                        className="img-link"
                        onClick={() => handleObituarysId(memorial.memorial_id)}
                      >
                        {/* 22-09-28 이미지 링크연결을 위한 마크업 수정(class추가) */}
                        <div className="img">
                          <span className="chk-size">
                            <img
                              alt=""
                              src={
                                memorial.deceased_photo ? memorial.deceased_photo : noDeceasedPhoto
                              }
                            />
                          </span>
                        </div>
                      </a>

                      {/* E : 이미지 */}
                      {/* S : 이름/정보 */}
                      <div className="content-obituary">
                        <div className="obituary-info">
                          <p className="name">
                            {`${memorial.deceased_name} ${memorial.deceased_job_title}`}

                            {memorial.display_yn === 'N' ? (
                              <span className="secret">
                                <i className="ic ic-secret2"></i>
                                <span className="offscreen">비밀글</span>
                              </span>
                            ) : (
                              ' '
                            )}
                          </p>
                          <ul className="btns">
                            <li>
                              <a
                                href={() => false}
                                onClick={() => handleModify(memorial.memorial_id)}
                              >
                                수정
                              </a>
                            </li>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleDelete(memorial.memorial_id)}
                              >
                                삭제
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div className="btn-wrap">
                          <ul className="btns">
                            <li className={`${memorial.digital_wreath_yn === 'Y' ? 'w-100' : ''}`}>
                              {/* <li> */}
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleSubmitMemorial(memorial)}
                              >
                                추모관 전송
                              </button>
                            </li>
                            {/* 디지털 화환 미노출 시 className="w-100" 삭제 */}
                            {/*<li>*/}
                            {/*  <button*/}
                            {/*    type="button"*/}
                            {/*    className="btn btn-sm-ln-blue"*/}
                            {/*    onClick={() => handleOpenThanksDialog(memorial.memorial_id)}*/}
                            {/*  >*/}
                            {/*    감사인사 전송*/}
                            {/*  </button>*/}
                            {/*</li>*/}
                            {/*{memorial.digital_wreath_yn === 'Y' ? (*/}
                            {/*  <li>*/}
                            {/*    <button*/}
                            {/*      type="button"*/}
                            {/*      className="btn btn-sm-ln-blue"*/}
                            {/*      // onClick={() => handleOpenWreathCustomDialog()}*/}
                            {/*      onClick={() =>*/}
                            {/*        navigate(`/pet/mypage/wreath/${memorial.memorial_id}`)*/}
                            {/*      }*/}
                            {/*    >*/}
                            {/*      디지털 화환*/}
                            {/*    </button>*/}
                            {/*  </li>*/}
                            {/*) : (*/}
                            {/*  ''*/}
                            {/*)}*/}
                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleChangeStatus(memorial.memorial_id)}
                              >
                                부고 전환
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleObituarys(memorial.memorial_id)}
                              >
                                추모의 글
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* E : 이름/정보 */}
                    </div>
                  </div>
                ))}
                {/* E : 추모관 글 목록 반복 시작 */}
              </div>
            ) : null}
            {/* E : 작성한 추모관 글 목록 */}

            {/* S : 페이징 */}
            <div className="btn-wrap">
              <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
                더보기 {page}/{totalPage}
              </button>
            </div>
            {/* E : 페이징 */}
          </div>

          <SharedDialog
            isOpen={openSharedDialog}
            onClose={() => setOpenSharedDialog(false)}
            memorial={currentMemorial}
          />
          <ObituaryThanksDialog
            isOpen={openThanksDialog}
            onClose={() => setOpenThanksDialog(false)}
            memorialId={selectedMemorialId}
          />
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default MypageMemorialPage;

import { useState, useEffect } from 'react';
import useCode from 'stores/code/hook';
import useDialog from 'stores/dialog/hook';

import { useFieldArray, Controller } from 'react-hook-form';

import KakaoPayGuideDialog from 'pages/memorial-canvas/obituary/components/KakaoPayGuideDialog';

function Condolatorys({ control, setValue, getValues, condolatoryYn, errors }) {
  const { showAlert, hideAlert } = useDialog();
  const { bankTypeCodes } = useCode();

  const [openKakaoPayGuideDialog, setOpenKakaoPayGuideDialog] = useState(false);
  const [condolatoryVisual, setCondolatoryVisual] = useState(false);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'condolatory_datas',
  });

  const handleAppend = () => {
    setCondolatoryVisual(true);
    if (fields.length > 9) {
      showAlert('알림', '조의금 정보는 최대 10개까지 등록 가능합니다.', () => hideAlert());
      return;
    }
    append({ applicant_name: '', bank_name: '', account_number: '' });
  };

  const handleRemove = index => {
    remove(index);
  };

  const handleOrderUp = index => {
    const targetIndex = index - 1;
    if (targetIndex > -1) move(index, index - 1);
  };

  const handleOrderDown = index => {
    const targetIndex = index + 1;
    if (fields.length > targetIndex) move(index, targetIndex);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  useEffect(() => {
    if (fields && fields.length === 0) handleAppend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {/*<div className="form-group">*/}
      {/*  <div className="box-stitle">*/}
      {/*    {condolatoryVisual === false ? <h5>대표계좌</h5> : <h5>계좌1</h5>}*/}
      {/*  </div>*/}
      {/*  <div className="ip-wrap">*/}
      {/*    <div className="ip-item">*/}
      {/*      <label for="ip-chief-mourner-name1" className="offscreen">*/}
      {/*        예금주 이름*/}
      {/*      </label>*/}
      {/*      <input type="text" id="ip-chief-mourner-name1" placeholder="예금주 이름" />*/}
      {/*    </div>*/}
      {/*    <div className="ip-item">*/}
      {/*      <label for="ip-chief-mourner-relation1" className="offscreen">*/}
      {/*        은행*/}
      {/*      </label>*/}
      {/*      <select id="ip-chief-mourner-relation1">*/}
      {/*        <option>은행</option>*/}
      {/*        <option>기업</option>*/}
      {/*        <option>국민</option>*/}
      {/*        <option>하나</option>*/}
      {/*      </select>*/}
      {/*    </div>*/}
      {/*    <div className="ip-item">*/}
      {/*      <label for="ip-chief-mourner-mobile1" className="offscreen">*/}
      {/*        계좌번호*/}
      {/*      </label>*/}
      {/*      <input*/}
      {/*        type="number"*/}
      {/*        id="ip-chief-mourner-mobile1"*/}
      {/*        placeholder="계좌번호를 작성하세요"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {fields.map((item, index) => (
        <div className="form-group">
          <div className="box-stitle">
            {index === 0 ? <h5>대표계좌</h5> : <h5>계좌 {index + 0}</h5>}
          </div>
          <div className="ip-wrap">
            <div className="ip-item">
              <label for="ip-chief-mourner-name1" className="offscreen">
                예금주 이름
              </label>
              <Controller
                control={control}
                name={`condolatory_datas.${index}.applicant_name`}
                render={({ field }) => (
                  <input
                    type="text"
                    id="ip-chief-mourner-name1"
                    placeholder="예금주 이름"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="ip-item">
              <label for="ip-chief-mourner-relation1" className="offscreen">
                은행
              </label>
              <select
                id="ip-chief-mourner-relation1"
                name={`condolatory_datas.${index}.bank_name`}
                onChange={handleChange}
              >
                {bankTypeCodes && bankTypeCodes.length > 0
                  ? bankTypeCodes.map(code => (
                      <option
                        value={code.code}
                        key={code.code}
                        selected={
                          getValues(`condolatory_datas.${index}.bank_name`) === code.code
                            ? 'true'
                            : ''
                        }
                      >
                        {code.value}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="ip-item">
              <label for="ip-chief-mourner-mobile1" className="offscreen">
                계좌번호
              </label>
              <Controller
                control={control}
                name={`condolatory_datas.${index}.account_number`}
                render={({ field }) => (
                  <input
                    type="number"
                    id="ip-chief-mourner-mobile1"
                    placeholder="계좌번호를 작성하세요"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          {index !== 0 ? (
            <div className="ip-btn">
              <button
                type="button"
                className="btn-contents-del"
                onClick={() => handleRemove(index)}
              >
                <span className="offscreen">삭제</span>
              </button>
              <button
                type="button"
                className="btn-contents-up"
                onClick={() => handleOrderUp(index)}
              >
                <span className="offscreen">순서 위로</span>
              </button>
              <button
                type="button"
                className="btn-contents-down"
                onClick={() => handleOrderDown(index)}
              >
                <span className="offscreen">순서 아래로</span>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      ))}
      <div className="btn-wrap">
        <button type="button" className="btn btn-md-white" onClick={handleAppend}>
          계좌 추가
        </button>
      </div>
    </>
  );
}

export default Condolatorys;

import { useEffect } from 'react';

import useCode from 'stores/code/hook';

import { useFieldArray, Controller } from 'react-hook-form';

function Relations({ control, setValue, getValues, errors, setVisual }) {
  const { relationPetDetailCodes } = useCode();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'relations_datas',
  });

  const handleAppend = () => {
    append({ relations_type: 'S', relations_detail: '', relations_name: '', mobile: '' });
  };

  const handleRemove = index => {
    if (index === 0) setVisual(false);
    remove(index);
  };

  const handleOrderUp = index => {
    const targetIndex = index - 1;
    if (targetIndex > -1) move(index, index - 1);
  };

  const handleOrderDown = index => {
    const targetIndex = index + 1;
    if (fields.length > targetIndex) move(index, targetIndex);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  useEffect(() => {
    if (fields && fields.length === 0) handleAppend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      {fields.map((item, index) => (
        <div className="form-group" key={item.id}>
          <div className="box-stitle">
            <h5>보호자 {index + 1}</h5>
          </div>
          <div className="ip-wrap">
            <div className="ip-item">
              <select
                id="ip-chief-mourner-relation1"
                name={`relations_datas.${index}.relations_detail`}
                aria-invalid={errors.relations_datas?.[index]?.relations_detail ? 'true' : 'false'}
                onChange={handleChange}
              >
                {relationPetDetailCodes && relationPetDetailCodes.length > 0
                  ? relationPetDetailCodes.map(code => (
                      <option
                        value={code.code}
                        key={code.code}
                        selected={
                          getValues(`relations_datas.${index}.relations_detail`) === code.code
                            ? 'true'
                            : ''
                        }
                      >
                        {code.value}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="ip-item">
              <label htmlFor="ip-chief-mourner-name1" className="offscreen">
                보호자 명
              </label>
              <Controller
                control={control}
                name={`relations_datas.${index}.relations_name`}
                render={({ field }) => (
                  <input
                    type="text"
                    id="ip-chief-mourner-name1"
                    placeholder="보호자 성함을 작성하세요"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="ip-btn">
            <button type="button" className="btn-contents-del" onClick={() => handleRemove(index)}>
              <span className="offscreen">삭제</span>
            </button>
            <button type="button" className="btn-contents-up" onClick={() => handleOrderUp(index)}>
              <span className="offscreen">순서 위로</span>
            </button>
            <button
              type="button"
              className="btn-contents-down"
              onClick={() => handleOrderDown(index)}
            >
              <span className="offscreen">순서 아래로</span>
            </button>
          </div>
        </div>
      ))}
      {/* E : 상주정보 */}
      <div className="btn-wrap">
        <button type="button" className="btn btn-md-white" onClick={handleAppend}>
          보호자 추가
        </button>
      </div>
    </>
  );
}

export default Relations;

import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import MemorialDialog from './components/MemorialDialog';
import OpenJoinDialog from './components/OpenJoinDialog';
import LinkCanvasDialog from './components/LinkCanvasDialog';
import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

import { useNavigate } from 'react-router-dom';
import { getCanvasApi, modifyCanvasApi, getCanvasJoinApi, modifyCanvasJoinApi } from 'apis/canvas';
//import login from 'stores/login';

function Canvas({ setMode }) {
  const { loginUser } = useLogin();
  const { showLifeAlert, hideLifeAlert } = useDialog();
  const [openMemorialDialog, setOpenMemorialDialog] = useState(false);
  const [openLinkCanvasDialog, setOpenLinkCanvasDialog] = useState(false);
  const [openJoinDialog, setOpenJoinDialog] = useState(false);
  const [joinLinkAccepts, setJoinLinkAccepts] = useState();
  const [joinLinkWaits, setJoinLinkWaits] = useState();
  const [loginId, setLoginId] = useState();
  const [anonymousYn, setAnonymousYn] = useState(false);
  const [canvas, setCanvas] = useState(null);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  const schema = Yup.object({
    display_status: Yup.string().required('* 필수 입력 항목입니다.'),
    comment_display: Yup.string().required('* 필수 입력 항목입니다.'),
    display_subscribe: Yup.string().required('* 필수 입력 항목입니다.'),
    display_subscribers: Yup.string().required('* 필수 입력 항목입니다.'),
    question_display: Yup.string().required('* 필수 입력 항목입니다.'),
    anonymous_yn: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    display_status: '0',
    comment_display: '0',
    anonymous_yn: 'N',
    display_subscribe: '0',
    display_subscribers: '0',
    question_display: '0',
  };

  const { register, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    //console.log(data);
    modifyCanvas(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const onChangeChecked = checked => {
    setChecked(!checked);
  };

  const modifyCanvas = async params => {
    const result = await modifyCanvasApi(params);
    //console.log('확인', result);
    if (result.code === '0000') {
      showLifeAlert('알림', result.message, () => successModifyCanvas());
      //successModifyCanvas();
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const successModifyCanvas = () => {
    hideLifeAlert();
  };

  const handleCanvasJoinModify = id => {
    showLifeAlert('연결 해제', '정말 연결을 해제하시겠습니까?', () => CanvasModifyJoin(id));
  };

  const handleCanvasJoinRefuse = id => {
    showLifeAlert('연결 거절', '정말 연결을 거절하시겠습니까?', () => CanvasModifyJoin(id));
  };

  const getCanvas = async () => {
    let params = {};
    params.canvas_id = loginUser.canvas_id;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasApi(params);
    setCanvas(result.data);
    if (result.code === '0000') {
      //alert(JSON.stringify(result.data.comment_display));
      //setCanvas(result.data);
      //showLifeAlert('알림', result.message, () => successSaveCanvasBlt());
      Object.entries(result.data).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const CanvasJoinModify = async id => {
    let params = {};
    params.join_id = id;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    params.status = 'R';

    const result = await modifyCanvasJoinApi(params);

    if (result.code === '0000') {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const CanvasJoinAccept = async id => {
    let params = {};
    params.join_id = id;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    params.status = 'A';

    const result = await modifyCanvasJoinApi(params);

    if (result.code === '0000') {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
      init();
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasJoin = async () => {
    let params = {};
    params.page = 1;
    params.itemInPage = 5;
    params.canvas_id_my = loginUser.canvas_id;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasJoinApi(params);

    if (result.code === '0000') {
      setJoinLinkAccepts(result.join_list.filter(it => it.status === 'A'));
      setJoinLinkWaits(result.join_list.filter(it => it.status === 'W'));
      setLoginId(loginUser.member_id);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleChangeAnonymousYn = value => {
    setValue('anonymous_yn', value ? 'Y' : 'N');
    setAnonymousYn(value);
  };

  const CanvasModifyJoin = id => {
    CanvasJoinModify(id);
    init();
  };

  useEffect(() => {
    if (canvas) {
      Object.entries(canvas).forEach(([key, value]) => {
        setValue(key, value);
      });
      //alert(canvas.anonymous_yn);
      setAnonymousYn(canvas.anonymous_yn === 'Y' ? true : false);
      if (canvas.anonymous_yn === 'Y' ? setChecked(true) : setChecked(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  const init = async () => {
    if (loginUser?.member_id) await getCanvas();
    if (loginUser?.member_id) await getCanvasJoin();
  };

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-setting">
          <div className="tabs tabs-setting">
            <ul>
              <li>
                <a href={() => false} onClick={() => setMode('setting')}>
                  회원설정
                </a>
              </li>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('canvas')}>
                  캔버스 관리
                </a>
              </li>
            </ul>
          </div>

          <h2 className="offscreen">캔버스 관리</h2>

          <div className="box-fix">
            <div className="sel-item">
              <label htmlFor="sel-open">내 캔버스 공개 범위</label>
              <select id="sel-open" {...register('display_status')}>
                <option value="0">전체 공개</option>
                <option value="2">비공개</option>
              </select>
            </div>

            <div className="sel-item">
              <label htmlFor="sel-reply">내 캔버스 댓글 허용 범위</label>
              <select id="sel-reply" {...register('comment_display')}>
                <option value="0">전체</option>
                <option value="1">구독자</option>
                <option value="2">허용하지 않음</option>
              </select>
            </div>
            {/* S : 22-08-18 구독 공개범위, 질문 허용 범위 추가 */}
            <div className="sel-item">
              <label htmlFor="sel-subscribe">내가 구독 공개범위</label>
              <select id="sel-subscribe" {...register('display_subscribe')}>
                <option value="0">전체</option>
                <option value="1">구독자</option>
                <option value="2">허용하지 않음</option>
              </select>
            </div>

            <div className="sel-item">
              <label htmlFor="sel-subscribe02">나를 구독 공개범위</label>
              <select id="sel-subscribe02" {...register('display_subscribers')}>
                <option value="0">전체</option>
                <option value="1">구독자</option>
                <option value="2">허용하지 않음</option>
              </select>
            </div>

            <div className="sel-item">
              <label htmlFor="sel-qna">질문 허용 범위</label>
              <select id="sel-qna" {...register('question_display')}>
                <option value="0">전체</option>
                <option value="1">구독자</option>
                <option value="2">허용하지 않음</option>
              </select>
            </div>

            <div className="rc-item rc-toggle">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => onChangeChecked(checked)}
                id="ip-public11"
              />
              <label
                htmlFor="ip-public11"
                onClick={() => handleChangeAnonymousYn(anonymousYn ? false : true)}
              >
                익명의 질문 받기 <span className="toggle"></span>
              </label>
            </div>
            {/* E : 22-08-18 구독 공개범위, 질문 허용 범위 추가 */}

            <div className="btn-wrap">
              <a
                href={() => false}
                // onClick={() => alert(JSON.stringify(canvas_id.member_id))}
                // onClick={() => getCanvas()}
                onClick={handleSubmit(onSubmit, onError)}
                className="btn btn-blue"
              >
                완료
              </a>
            </div>
          </div>

          {/* S : 22-08-18 연결 캔버스 목록 추가 */}
          <div className="connect-canvas">
            <h3>
              연결 캔버스 목록{' '}
              <button
                type="button"
                data-micromodal-trigger="modal-about-connect"
                onClick={() => setOpenLinkCanvasDialog(true)}
              >
                <em>?</em>
                <span className="offscreen">메모리얼 캔버스란?</span>
              </button>
            </h3>
            <ul className="connect-list">
              {joinLinkWaits &&
                joinLinkWaits.length > 0 &&
                joinLinkWaits.map(joinLinkWait => (
                  <li>
                    <div className="connect-content">
                      <p>
                        <a href={() => false}>{joinLinkWait.request_name}</a>님께서 연결을
                        요청하였습니다.
                      </p>
                      <span>
                        [{joinLinkWait.relation}] {joinLinkWait.createdtimeStr10}
                      </span>
                    </div>
                    <div className="btn-wrap">
                      <button
                        type="button"
                        className="btn btn-xs btn-lngray02"
                        data-micromodal-trigger="modal-connect-02"
                        onClick={() => handleCanvasJoinRefuse(joinLinkWait.join_id)}
                      >
                        거절
                      </button>
                      <button
                        type="button"
                        className="btn btn-xs btn-green"
                        data-micromodal-trigger="modal-connect-03"
                        onClick={() => CanvasJoinAccept(joinLinkWait.join_id)}
                      >
                        수락
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-green"
                data-micromodal-trigger="modal-connect-req-01"
                onClick={() => setOpenJoinDialog(true)}
              >
                연결 요청하기
              </button>
            </div>

            <ul className="connect-list">
              {joinLinkAccepts &&
                joinLinkAccepts.length > 0 &&
                joinLinkAccepts.map(joinLinkAccept => (
                  <li>
                    <div className="connect-content">
                      <p
                      // onClick={() => navigate(`/life/canvas/user/${joinLinkAccept.canvas_id_my}`)}
                      >
                        <strong>
                          {loginUser?.member_id === joinLinkAccept.request_id
                            ? `${joinLinkAccept.response_name}`
                            : `${joinLinkAccept.request_name}`}
                        </strong>
                        {`님과 [${joinLinkAccept.relation}] 연결`}
                      </p>
                      <span>{joinLinkAccept.createdtimeStr10}</span>
                    </div>
                    <div className="btn-wrap">
                      <button
                        type="button"
                        className="btn btn-xs btn-lngray02"
                        data-micromodal-trigger="modal-connect-01"
                        onClick={() => handleCanvasJoinModify(joinLinkAccept.join_id)}
                      >
                        연결 해제
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          {/* E : 22-08-18 연결 캔버스 목록 추가 */}

          <div className="box-memorial">
            <h3>
              메모리얼 캔버스{' '}
              <button
                type="button"
                data-micromodal-trigger="modal-about-memorial"
                onClick={() => setOpenMemorialDialog(true)}
              >
                <em>?</em>
                <span className="offscreen">메모리얼 캔버스란?</span>
              </button>
            </h3>
            <ul>
              <li>
                <a href={() => false} onClick={() => navigate('/life/settings/canvas/my')}>
                  내 자서전 메모리얼 캔버스에 등록하기
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => navigate('/life/settings/canvas/link')}>
                  연결 자서전 메모리얼 캔버스에 등록하기
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
      {openMemorialDialog && (
        <MemorialDialog isOpen={openMemorialDialog} onClose={() => setOpenMemorialDialog(false)} />
      )}
      {openLinkCanvasDialog && (
        <LinkCanvasDialog
          isOpen={openLinkCanvasDialog}
          onClose={() => setOpenLinkCanvasDialog(false)}
        />
      )}
      {openJoinDialog && (
        <OpenJoinDialog isOpen={openJoinDialog} onClose={() => setOpenJoinDialog(false)} />
      )}
    </section>
  );
}
export default Canvas;

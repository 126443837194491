import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  gnbType: '',
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setGnbTypeState: (state, action) => {
      state.gnbType = action.payload;
    },
  },
});

const { reducer, actions } = layoutSlice;

export const { setGnbTypeState } = actions;

export const layoutState = state => {
  return state.layout;
};

export const gnbTypeState = createSelector(layoutState, state => {
  return state.gnbType;
});

export default reducer;

import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getCanvasAlarmApi, modifyCanvasAlarmApi } from 'apis/canvas';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCommon from 'stores/common/hook';
import { getCanvasAlarmCountApi } from 'apis/canvas';

function Alarm() {
  const { loginUser } = useLogin();
  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { setAlarmCnt } = useCommon();
  const [alarms, setAlarms] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(3);
  const [totalPage, setTotalPage] = useState(0);
  // const [alarmType, setAlarmType] = useState();
  // const [bltId, setBltId] = useState();
  // const [requestId, setRequestId] = useState();

  const navigate = useNavigate();

  const getLinked = (alarm, canvas) => {
    if (alarm === 'answer' || alarm === 'joined' || alarm === 'link' || alarm === 'sub_reply') {
      navigate(`/life/canvas/user/${canvas}`);
    } else if (alarm === 'like' || alarm === 'question' || alarm === 'reply') {
      navigate('/life/canvas/my');
    } else if (alarm === 'join') {
      navigate('/life/settings/member');
    } else {
      navigate('/life');
    }
  };

  const getCanvas = (display, canvas, anonymous) => {
    if (display === '0') {
      navigate(`/life/canvas/user/${canvas}`);
    } else if (anonymous === 'Y') {
      showLifeAlert('알림', '익명의 경우 캔버스 공개가 되지 않습니다.', () => hideLifeAlert());
    } else {
      showLifeAlert('알림', '비공개 캔버스 입니다.', () => hideLifeAlert());
    }
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasAlarmMore(nextPage);
    }
  };

  const getCanvasAlarm = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'createdtime' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasAlarmApi(params);
    if (result.code === '0000') {
      setAlarms(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasAlarmMore = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'createdtime' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    const result = await getCanvasAlarmApi(params);
    if (result.code === '0000') {
      setAlarms([...alarms, ...result.data]);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const modifyCanvasAlarm = async (alarm, blt, request, canvas) => {
    let params = {};
    params.alarm_type = alarm;
    params.blt_id = blt;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    params.request_id = request;
    params.read_yn = 'Y';
    const result = await modifyCanvasAlarmApi(params);

    if (result.code === '0000') {
      const alarmCount = await getCanvasAlarmCountApi({
        read_yn: 'N',
        member_id: loginUser.member_id,
      });
      setAlarmCnt(alarmCount.data_cnt);
      getLinked(alarm, canvas);
    }
  };

  const handleWordLimit = word => {
    let result = word;
    const limitOver = '...';
    const limit = 10;
    if (word.length > limit) {
      const customWord = word.slice(0, 9);
      result = customWord + limitOver;
    }

    return result;
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  const init = async () => {
    if (loginUser?.member_id) await getCanvasAlarm(1);
  };

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-setting">
          <h2>알림</h2>

          {/* S : 알림 내용 */}
          {alarms &&
            alarms.length > 0 &&
            alarms.map(alarm => (
              <div className="alarm-wrap">
                {/* S : 알림 내용(반복 시작) */}
                <div className={`box-alarm ${alarm.read_yn === 'N' ? 'is-new' : ''}`}>
                  {/* 확인하지 않았거나/새로운 알람일 경우 className="is-new 추가 */}
                  <a href={() => false}>
                    <div
                      className="user-thumb"
                      onClick={() =>
                        getCanvas(
                          alarm.request_display_status,
                          alarm.request_canvas_id,
                          alarm.blt_anonymous_yn,
                        )
                      }
                    >
                      <span className="chk-size">
                        {alarm.blt_anonymous_yn === 'Y' ? (
                          <img alt="" src="" />
                        ) : (
                          <img
                            alt=""
                            src={alarm.request_profile_link ? alarm.request_profile_link : ''}
                          />
                        )}
                      </span>
                    </div>
                    <div
                      className="alarm-content"
                      onClick={() => {
                        modifyCanvasAlarm(
                          alarm.alarm_type,
                          alarm.blt_id,
                          alarm.request_id,
                          alarm.request_canvas_id,
                        );
                      }}
                    >
                      <p>
                        <strong>
                          {alarm.blt_anonymous_yn === 'Y' ? '익명' : alarm.request_title}
                        </strong>
                        {alarm.blt_type === '1' && (
                          <>
                            <span>님이 회원님의 일상</span>
                            <span>{`[${handleWordLimit(alarm?.blt_contents)}]`}</span>
                            <span>{alarm.default_ment}</span>
                          </>
                        )}
                        {alarm.blt_type === '2' && (
                          <>
                            <span>님이 회원님의 자서전</span>
                            <span>{`[${handleWordLimit(alarm?.blt_title)}]`}</span>
                            <span>{alarm.default_ment}</span>
                          </>
                        )}
                        {alarm.blt_type === '3' && (
                          <>
                            <span>님이 회원님의 문답</span>
                            <span>{`[${handleWordLimit(alarm?.blt_contents.split('"')[3])}]`}</span>
                            <span>{alarm.default_ment}</span>
                          </>
                        )}
                        {alarm.blt_type === '4' && (
                          <>
                            <span>님이 회원님의 버킷리스트</span>
                            <span>{`[${handleWordLimit(alarm?.blt_contents)}]`}</span>
                            <span>{alarm.default_ment}</span>
                          </>
                        )}

                        {!alarm.blt_type && alarm.default_ment}
                      </p>
                      <span className="date">{alarm.createdtimeStrFull}</span>
                    </div>
                  </a>
                </div>
                {/* E : 알림 내용(반복 종료) */}
              </div>
            ))}
          {/* E : 알림 내용 */}

          {/* S : 더보기 버튼 */}
          {alarms && alarms.length > 0 && (
            <div className="btn-wrap">
              <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
                더보기
              </button>
            </div>
          )}
          {/* E : 더보기 버튼 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default Alarm;

import { useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import useDialog from 'stores/dialog/hook';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { saveCanvasBltApi } from 'apis/canvasBlt';
import useLogin from 'stores/login/hook';
import Faqs from './components/Faqs';

function FaqRegister() {
  const navigate = useNavigate();

  const submitRef = useRef();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();

  const { loginUser } = useLogin();

  const schema = Yup.object({
    canvas_id: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_type: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_title: Yup.string().required('* 필수 입력 항목입니다.'),
    display_yn: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_contents: Yup.string()
      //.test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 1500000)
      .required('* 필수 입력 항목입니다.'),
    member_id: Yup.string().required('* 필수 입력 항목입니다.'),
    faqs: Yup.array().of(
      Yup.object().shape({
        questions: Yup.string().required('* 질문을 입력하세요.'),
        answer: Yup.string().required('* 답변을 입력하세요.'),
      }),
    ),
  }).required();

  const defaultValues = {
    canvas_id: '',
    blt_type: '3',
    blt_title: '문답',
    display_yn: 'Y',
    blt_contents: '',
    member_id: '',
  };

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const checkSubmit = () => {
    // const regex =
    //   /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    // if (regex.test(getValues('faqs') ? JSON.stringify(getValues('faqs')) : '')) {
    //   showLifeAlert('알림', '이모티콘은 사용할 수 없습니다.', () => hideLifeAlert());
    // } else {
    setValue('blt_contents', getValues('faqs') ? JSON.stringify(getValues('faqs')) : '');
    submitRef.current.click();
    // }
  };

  const onSubmit = data => {
    saveCanvasBlt(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const saveCanvasBlt = async params => {
    const result = await saveCanvasBltApi(params);
    if (result.code === '0000') {
      showLifeAlert('알림', '작성한 기록이 캔버스에 등록되었습니다.', () => successSaveCanvasBlt());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const successSaveCanvasBlt = () => {
    hideLifeAlert();
    navigate('/life/canvas/my', { state: { searchBltType: '3' } });
  };

  const handleChangeCategory = url => {
    const isContent = getValues('blt_contents');

    if (isContent !== '') {
      showLifeConfirm(
        '확인',
        '작성한 글이 모두 삭제됩니다.\n정말 변경하시겠습니까?',
        () => {
          navigate(url, { replace: true });
          hideLifeConfirm();
        },
        () => hideLifeConfirm(),
      );
      return;
    }
    navigate(url, { replace: true });
  };

  const handleSelect = e => {
    if (e.target.value === '1') {
      handleChangeCategory('/life/everyone/bucketlist/register');
    } else if (e.target.value === '2') {
      handleChangeCategory('/life/everyone/history/register');
    } else if (e.target.value === '3') {
      handleChangeCategory('/life/everyone/daily/register');
    }
  };

  const moveLogin = () => {
    hideLifeAlert();
    navigate('/life/login');
  };

  const handleClose = () => {
    showLifeConfirm(
      '확인',
      '작성한 글이 모두 삭제됩니다.\n정말 취소하시겠습니까?',
      () => confirmClose(),
      () => hideLifeConfirm(),
    );
  };

  const confirmClose = () => {
    hideLifeConfirm();
    navigate(-1);
  };

  // const handleMove = url => {
  //   showLifeConfirm(
  //     '확인',
  //     '작성한 글이 모두 삭제됩니다.\n정말 취소하시겠습니까?',
  //     () => confirmMove(url),
  //     () => hideLifeConfirm(),
  //   );
  // };

  // const confirmMove = url => {
  //   hideLifeConfirm();
  //   navigate(url);
  // };

  const changeDisplayYn = value => {
    setValue('display_yn', value);
  };

  const init = async () => {
    if (loginUser && !loginUser.member_id)
      showLifeAlert('알림', '로그인 후 이용가능합니다.', () => moveLogin());
    if (loginUser?.member_id) setValue('member_id', loginUser.member_id);
    if (loginUser?.member_name) setValue('member_name', loginUser.member_name);
    if (loginUser?.canvas_id) setValue('canvas_id', loginUser.canvas_id);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-write">
          {/* 글쓰기(자서전/문답/일상/버킷리스트) 일때 is-write 추가 */}
          {/* S : 22-08-18 타이틀(h2) class 추가, 기록 화면 버튼 SELECT BOX 형태로 수정 */}
          <h2 className="offscreen">문답</h2>
          <div className="sel-item sel-title-write">
            <label for="sel-write" className="offscreen">
              기록 유형 선택
            </label>
            <select id="sel-write" onChange={handleSelect} value="0">
              <option value="1">버킷리스트</option>
              <option selected value="0">
                문답
              </option>
              <option value="2">자서전</option>
              <option value="3">일상</option>
            </select>
          </div>
          {/* E : 22-08-18 타이틀(h2) class 추가, 기록 화면 버튼 SELECT BOX 형태로 수정 */}

          <Faqs control={control} errors={errors} changeDisplayYn={changeDisplayYn} />

          <div className="btn-wrap">
            <a href={() => false} className="btn btn-white" onClick={() => handleClose()}>
              취소
            </a>
            <button type="button" className="btn btn-green" onClick={() => checkSubmit()}>
              올리기
            </button>
            <button
              ref={submitRef}
              type="button"
              className="btn btn-green"
              onClick={handleSubmit(onSubmit, onError)}
              style={{ display: 'none' }}
            >
              Submit
            </button>
          </div>
        </div>

        {/* S : 글쓰기 하단 */}
        {/* <div className="write-bottom">
          <ul className="contents-width is-write">
            <li>
              <a
                href={() => false}
                onClick={() => handleMove('/life/everyone/bucketlist/register')}
              >
                <i className="ic ic-write-bucket"></i>버킷리스트
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => handleMove('/life/everyone/history/register')}>
                <i className="ic ic-write-history"></i>자서전
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => handleMove('/life/everyone/daily/register')}>
                <i className="ic ic-write-life"></i>일상
              </a>
            </li>
          </ul>
        </div> */}
        {/* E : 글쓰기 하단 */}
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default FaqRegister;

import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import parse from 'html-react-parser';

// import 'swiper/css';
// import 'swiper/css/navigation';

// import 'css/swiper.min.css';
// import 'css/swiper.custom.css';

import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';
import {
  toggleCanvasBltLikeApi,
  getCanvasBltCommentApi,
  saveCanvasBltCommentApi,
  deleteCanvasBltCommentApi,
} from 'apis/canvasBlt';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BucketlistGoaledDialog from '../BucketlistGoaledDialog';
import useCommon from 'stores/common/hook';
import { getCanvasAlarmCountApi } from 'apis/canvas';

import MediaViewSwiperDialog from '../MediaViewSwiperDialog';

function UserBucketlist({ canvasBlt, canvas }) {
  const navigate = useNavigate();
  const schema = Yup.object({
    com_contents: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_id: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    com_contents: '',
    blt_id: '',
  };

  const { register, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    saveCanvasBltComment(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();
  const { loginUser } = useLogin();
  const { setAlarmCnt } = useCommon();
  const targetRef = useRef();

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isLike, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [goalendData, setGoalendData] = useState();

  const [openBucketlistGoaledDialog, setOpenBucketlistGoaledDialog] = useState(false);

  const [comments, setComments] = useState();

  const [selectedComment, setSelectedComment] = useState();

  const [media, setMedia] = useState(null);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [itemIndex, setitemIndex] = useState(0);

  const toggleCanvasBltLike = async () => {
    if (!loginUser || !loginUser.member_id) {
      showLifeAlert('알림', '좋아요 기능은 로그인 후 이용가능합니다.', () => hideLifeAlert());
      return;
    }

    let params = {};
    params.blt_id = canvasBlt.blt_id;
    params.member_id = loginUser.member_id;

    const result = await toggleCanvasBltLikeApi(params);

    if (result.code === '0000') {
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
      setLikeCount(isLike ? likeCount - 1 : likeCount + 1);
      setLike(!isLike);
      const alarmCount = await getCanvasAlarmCountApi({
        read_yn: 'N',
        member_id: loginUser.member_id,
      });
      setAlarmCnt(alarmCount.data_cnt);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getGoalendData = data => {
    setGoalendData(data);
    setOpenBucketlistGoaledDialog(false);
  };

  const getCanvasBltComment = async () => {
    const params = {};
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.blt_id = canvasBlt.blt_id;

    const result = await getCanvasBltCommentApi(params);

    if (result.code === '0000') {
      setComments(result.data);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const saveCanvasBltComment = async data => {
    let params = {};

    params = data;

    params.blt_id = canvasBlt.blt_id;
    params.member_id = loginUser.member_id;
    params.member_name = loginUser.member_name;

    if (selectedComment) {
      params.up_blt_id = selectedComment.comment_id;
      params.comment_type = '2';
    }

    const result = await saveCanvasBltCommentApi(params);

    if (result.code === '0000') {
      setValue('com_contents', '');
      setSelectedComment();
      getCanvasBltComment();
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const deleteCanvasBltComment = async commentId => {
    const result = await deleteCanvasBltCommentApi(commentId);

    if (result.code === '0000') {
      getCanvasBltComment();
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleCommentDelete = (commentId, type) => {
    switch (type) {
      case 'comment': {
        const isComment = comments.find(comment => comment.comment_id === commentId);
        if (isComment.sub_comment_list.length > 0) {
          showLifeConfirm(
            '삭제 확인',
            '해당 댓글의 답글이 모두 삭제됩니다. 삭제하시겠습니까?',
            () => confirmDelete(commentId),
            () => hideLifeConfirm(),
          );
          return;
        }
        showLifeConfirm(
          '삭제 확인',
          '댓글을 삭제하시겠습니까?',
          () => confirmDelete(commentId),
          () => hideLifeConfirm(),
        );
        break;
      }
      case 'sub_comment': {
        showLifeConfirm(
          '삭제 확인',
          '댓글을 삭제하시겠습니까?',
          () => confirmDelete(commentId),
          () => hideLifeConfirm(),
        );
        break;
      }
      default: {
        showLifeConfirm(
          '삭제 확인',
          '댓글을 삭제하시겠습니까?',
          () => confirmDelete(commentId),
          () => hideLifeConfirm(),
        );
      }
    }
  };

  const confirmDelete = commentId => {
    hideLifeConfirm();
    deleteCanvasBltComment(commentId);
  };

  const handleMediaView = (media, index) => {
    setMedia(media);
    setitemIndex(index);
    setOpenMediaViewDialog(true);
  };

  useEffect(() => {
    setValue('blt_id', canvasBlt?.blt_id);

    setLike(canvasBlt.like_yn === 'Y' ? true : false);
    setLikeCount(canvasBlt.like_count);
    setGoalendData(canvasBlt);

    getCanvasBltComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasBlt]);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [targetRef.current]);

  return (
    <div className="canvas-post">
      <div className="canvas-inner">
        {/* S : 글 작성자 이름 */}
        <div className="canvas-head">
          <div className="user-info">
            <div className="user-thumb">
              <span className="chk-size">
                <img alt="" src={canvasBlt.profile_link ? canvasBlt.profile_link : ''} />
              </span>
              {/*<span className="chk-size"><img alt="" src="https://image.shutterstock.com/z/stock-photo-set-of-plumber-s-items-on-dark-background-2038101089.jpg" /></span>*/}
            </div>
            <span className="name">{canvasBlt.title}</span>

            <span className="date">{canvasBlt.modifydtime}</span>
          </div>
        </div>
        {/* E : 글 작성자 정보 */}

        {/* S : 글 내용 */}

        <div className="canvas-content">
          {/* S : 분류 */}
          <div className="category">
            <i className="ic ic-cate-bucketlist"></i>버킷리스트 {canvas.comment_display}
          </div>
          {/* E : 분류 */}

          {/* S : 이미지+글 */}

          {canvasBlt.media_files && canvasBlt.media_files.length > 0 && (
            <Swiper navigation={true} modules={[Navigation]} className="swiper canvas-swiper">
              <div className="swiper-wrapper">
                {canvasBlt.media_files.map((media, index) => (
                  <SwiperSlide
                    className={`swiper-slide chk-size ${
                      dimensions.width <= dimensions.height ? 'vertical' : ''
                    }`}
                    key={index}
                  >
                    {media.media_type === 'V' ? (
                      <ReactPlayer
                        className="react-player"
                        url={media.media_link} // 플레이어 url
                        width="100%" // 플레이어 크기 (가로)
                        height="100%" // 플레이어 크기 (세로)
                        playing={false} // 자동 재생 on
                        muted={false} // 자동 재생 on
                        controls={true} // 플레이어 컨트롤 노출 여부
                        light={false} // 플레이어 모드
                        pip={true} // pip 모드 설정 여부
                      />
                    ) : (
                      <img
                        ref={targetRef}
                        alt=""
                        src={media.media_link}
                        onClick={() => handleMediaView(canvasBlt.media_files, index)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          )}

          <p>{parse(canvasBlt.blt_contents.replace(/(\n|\r\n)/g, '<br />'))}</p>
          {/* E : 이미지+글 */}

          {/* S : 버킷리스트 달성 상태 */}
          <div className="bucket-status">
            {/* S : 버킷리스트 달성 전 */}
            {goalendData?.goalstatus !== 'Y' && (
              <>
                <div className="bucket-date">
                  <span className="circle-blue">
                    <i className="ic ic-arrow-check"></i>
                  </span>
                  목표날짜 : {goalendData?.goaldtime}
                  {loginUser?.member_id === goalendData?.member_id &&
                    goalendData?.goalstatus === 'N' && (
                      <button
                        type="button"
                        className="btn btn-xs btn-lightBlue"
                        onClick={() => setOpenBucketlistGoaledDialog(true)}
                      >
                        달성 완료하기
                      </button>
                    )}
                </div>
              </>
            )}
            {/* E : 버킷리스트 달성 전 */}
            {/* S : 버킷리스트 달성 후 */}
            {goalendData?.goalstatus === 'Y' && (
              <>
                <div className="bucket-date">
                  <span className="circle-blue">
                    <i className="ic ic-arrow-check"></i>
                  </span>
                  달성완료 : {goalendData?.goalenddtime}
                </div>
                <span className="btn btn-xs btn-lnblue">달성소감 한마디</span>
                <p>{goalendData?.goalcomment}</p>
              </>
            )}
            {/* E : 버킷리스트 달성 후 */}
          </div>
          {/* E : 버킷리스트 달성 상태 */}
        </div>
        {/* E : 글 내용 */}
        {/* S : 댓글 목록 */}
        <div className="canvas-cmt">
          {/* S : 좋아요 버튼 + 갯수 */}
          <div className="canvas-like">
            <button type="button" onClick={() => toggleCanvasBltLike()}>
              <i className={`ic ${isLike ? 'ic-canvas-like-active' : 'ic-canvas-like'}`}></i>
            </button>
            <span>{likeCount} </span>
          </div>
          {/* E : 좋아요 버튼 + 갯수 */}

          <div className="canvas-cmt-list">
            {/* S : 댓글 내용 */}
            {comments &&
              comments.length > 0 &&
              comments.map(comment => (
                <div className="box-cmt">
                  <div className="user-info">
                    <div
                      className="user-thumb"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/life/canvas/user/${comment.canvas_id}`)}
                    >
                      <span className="chk-size">
                        <img alt="" src={comment.profile_link ? comment.profile_link : ''} />
                      </span>
                      {/*<span className="chk-size"><img src="https://image.shutterstock.com/z/stock-photo-set-of-plumber-s-items-on-dark-background-2038101089.jpg"></span>*/}
                    </div>
                    <span
                      className="name"
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/life/canvas/user/${comment.canvas_id}`)}
                    >
                      {comment.title}
                    </span>
                    <span className="date">{comment.createdtime} </span>
                    {(loginUser?.member_id === comment.member_id ||
                      loginUser?.member_type === 'A') &&
                      comment.use_yn === 'Y' && (
                        <button
                          type="button"
                          className="btn-del-cmt"
                          onClick={() => handleCommentDelete(comment.comment_id, 'comment')}
                        >
                          <i className="ic ic-x"></i>
                          <span className="offscreen">삭제</span>
                        </button>
                      )}
                  </div>
                  <div className="cmt-content">
                    <p>{comment.com_contents}</p>
                    {comment.use_yn === 'Y' &&
                    (canvas.comment_display === '0' ||
                      (canvas.comment_display === '1' && canvas.link_yn === 'Y')) ? (
                      <button
                        type="button"
                        className="btn-reply"
                        onClick={() => setSelectedComment(comment)}
                      >
                        답글달기
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {/* S : 댓글 내용 */}
                  {comment.sub_comment_list &&
                    comment.sub_comment_list.length > 0 &&
                    comment.sub_comment_list.map(subComment => (
                      <div className="box-cmt">
                        <div className="user-info">
                          <div
                            className="user-thumb"
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/life/canvas/user/${subComment.canvas_id}`)}
                          >
                            <span className="chk-size">
                              <img
                                alt=""
                                src={subComment.profile_link ? subComment.profile_link : ''}
                              />
                            </span>
                            {/*<span className="chk-size"><img src="https://image.shutterstock.com/z/stock-photo-set-of-plumber-s-items-on-dark-background-2038101089.jpg"></span>*/}
                          </div>
                          <span
                            className="name"
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/life/canvas/user/${subComment.canvas_id}`)}
                          >
                            {subComment.title}
                          </span>
                          <span className="date">{subComment.createdtime} </span>
                          {(loginUser?.member_id === subComment.member_id ||
                            loginUser?.member_type === 'A') &&
                            subComment.use_yn === 'Y' && (
                              <button
                                type="button"
                                className="btn-del-cmt"
                                onClick={() =>
                                  handleCommentDelete(subComment.comment_id, 'sub_comment')
                                }
                              >
                                <i className="ic ic-x"></i>
                                <span className="offscreen">삭제</span>
                              </button>
                            )}
                        </div>
                        <div className="cmt-content">
                          <p>{subComment.com_contents}</p>
                          {/* <button type="button" className="btn-reply">
                            답글달기
                          </button> */}
                        </div>
                      </div>
                    ))}
                  {/* E : 댓글 내용 */}
                </div>
              ))}
            {/* E : 댓글 내용 */}
          </div>
        </div>
        {/* E : 댓글 목록 */}
        {/* S : 댓글 쓰기 */}
        {canvas.comment_display === '0' ||
        (canvas.comment_display === '1' && canvas.link_yn === 'Y') ? (
          <div className="ip-wrap ip-user">
            {selectedComment?.member_name && (
              // <div>
              //   <span style={{ cursor: 'pointer' }} onClick={() => setSelectedComment()}>
              //     X&nbsp;
              //   </span>
              // </div>
              <button type="button" className="btn-cancel" onClick={() => setSelectedComment()}>
                <i className="ic ic-x-gray"></i>
                <span className="offscreen">댓글 달기 취소</span>
              </button>
            )}

            <div className="ip-item">
              <label for="ip-cmt2" className="offscreen">
                댓글 입력
              </label>
              <input
                {...register('com_contents')}
                type="text"
                id="ip-cmt2"
                placeholder={
                  loginUser?.member_id
                    ? selectedComment?.member_name
                      ? `${selectedComment?.title}님에게 답글`
                      : '댓글을 입력하세요'
                    : '로그인 후 이용가능합니다.'
                }
                maxlength="500"
              />
            </div>

            {loginUser?.member_id && (
              <button
                type="button"
                className="btn btn-text"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록
              </button>
            )}
          </div>
        ) : (
          ''
        )}
        {/* E : 댓글 쓰기*/}
      </div>
      {openBucketlistGoaledDialog && (
        <BucketlistGoaledDialog
          isOpen={openBucketlistGoaledDialog}
          canvasBlt={canvasBlt}
          getGoalendData={getGoalendData}
          onClose={() => setOpenBucketlistGoaledDialog(false)}
        />
      )}
      {openMediaViewDialog && (
        <MediaViewSwiperDialog
          isOpen={openMediaViewDialog}
          onClose={() => setOpenMediaViewDialog(false)}
          media={media}
          itemIndex={itemIndex}
        />
      )}
    </div>
  );
}

export default UserBucketlist;

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import configs from '@configs/configs';
import { useRef, useEffect, useState } from 'react';
import { getPetBltCommunityApi, modifyPetBltCommunityApi } from 'apis/petBlt';
import useLayout from '../../../stores/layout/hook';
import useLogin from '../../../stores/login/hook';
import useDialog from '../../../stores/dialog/hook';
import copy from 'copy-to-clipboard';
import QuillEditor from '../../../components/editor/QuillEditor';

function DonationView() {
  const navigate = useNavigate();

  const { bltId } = useParams();
  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const [petBlt, setPetBlt] = useState('');

  const currentUrl = `${configs.BASEURL}/pet/community/view/${bltId}`;

  const getPetBlt = async params => {
    const result = await getPetBltCommunityApi(params);
    setPetBlt(result.data);
  };

  const handleClose = () => {
    navigate('/pet/community');
  };

  const handleModify = () => {
    navigate(`/pet/community/modify/${bltId}`);
  };

  const confirmDelete = () => {
    showConfirm(
      '확인',
      '작성한 글이 모두 삭제됩니다.\n정말 삭제하시겠습니까?',
      () => {
        handleDelete();
        hideConfirm();
      },
      () => hideConfirm(),
    );
  };

  const handleDelete = async () => {
    let params = {};
    params.blt_id = petBlt.blt_id;
    params.use_yn = 'N';
    const result = await modifyPetBltCommunityApi(params);
    if (result.type === 'success') {
      showAlert('알림', result.message, () => successDeletePet());
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const successDeletePet = () => {
    hideAlert();
    navigate('/pet/community');
  };

  const handleMenu = () => {
    navigate('/pet/community', { state: { viewTab: 'donation' } });
  };

  const shreadLink = async () => {
    try {
      copy(currentUrl);
      showAlert('알림', '주소 복사가 완료되었습니다.', () => hideAlert());
    } catch (error) {
      console.log('error > ', error);
      showAlert('알림', '주소 복사에 실패하였습니다.', () => hideAlert());
    }
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    getPetBlt(bltId);
  }, []);
  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">캔버스 스토리</h2>
            <p>
              사랑하는 사람의 부음을 문자 및 단체 알림, SNS 메시지로
              <br className="d-md-block d-none" /> 발송 대행하고{' '}
              <br className="d-block d-md-none" />
              장례 의식 공지 등의 서비스를 제공합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          <div className="tabs tabs-about type02">
            <ul>
              <li>
                <a href={() => false} onClick={() => navigate('/pet/community')}>
                  캔버스 스토리
                </a>
              </li>
              <li className="is-active">
                <a href={() => false} onClick={() => handleMenu()}>
                  기부소식
                </a>
              </li>
            </ul>
          </div>

          {petBlt ? (
            <div className="wrap-detail-news">
              {/* S : 소식 버튼, 제목, 첨부파일 */}
              <div className="box-detail-head">
                <div className="box-share">
                  <button type="button" onClick={() => shreadLink()}>
                    <i className="ic ic-share"></i>
                    <span className="offscreen">주소 복사하기</span>
                  </button>
                </div>
                <div className="box-category">캔버스 스토리</div>
                <div className="box-info">
                  <h3>{petBlt.blt_title}</h3>

                  <div className="box-user">
                    <span className="name">{petBlt.member_name}</span>
                    <span className="date">{petBlt.createdtimeStr10}</span>
                  </div>
                </div>
                <div className="box-file">
                  <ul>
                    {petBlt.media_files.map((media, index) => (
                      <li key={index}>
                        첨부파일 <span>{media.media_id}</span>
                        <a href={media.media_link} download={`image_${index + 1}.jpg`}>
                          다운로드
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* E : 소식 버튼, 제목, 첨부파일 */}

              {/* S : 소식 상세 내용 */}
              <div className="box-detail-content">
                <QuillEditor value={petBlt.blt_contents} viewerMode />
                {/*<div style={{ height: '300px' }}>{petBlt.blt_contents}</div>*/}

                <div className="admin-btn">
                  {loginUser && loginUser.member_id === petBlt.member_id ? (
                    <>
                      <a href={() => false} onClick={() => handleModify()}>
                        수정
                      </a>

                      <button type="button" onClick={() => confirmDelete()}>
                        삭제
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
              {/* E : 소식 상세 내용 */}

              {/* S : 페이지 하단 버튼 */}
              <div className="box-detail-footer">
                {petBlt.pre_blt_id ? (
                  <button
                    type="button"
                    disabled=""
                    //onClick={() => alert([petBlt.pre_blt_id])}
                    onClick={() => {
                      navigate(`/pet/community/view/${petBlt.pre_blt_id}`);
                      getPetBlt(petBlt.pre_blt_id);
                    }}
                  >
                    <span>
                      <i className="ic ic-arrow-prev"></i>
                    </span>
                    <em>이전글</em>
                  </button>
                ) : (
                  <button type="button" disabled>
                    <span>
                      <i className="ic ic-arrow-prev"></i>
                    </span>
                    <em>이전글</em>
                  </button>
                )}
                {/* 혹시 몰라서 추가한 비활성화 케이스 */}
                <a href={() => false} className="btn-list" onClick={handleClose}>
                  <i className="ic ic-list"></i>
                  <span className="offscreen">목록으로</span>
                </a>

                {petBlt.next_blt_id ? (
                  <button
                    type="button"
                    disabled=""
                    //onClick={() => alert([petBlt.pre_blt_id])}
                    onClick={() => {
                      navigate(`/pet/community/view/${petBlt.next_blt_id}`);
                      getPetBlt(petBlt.next_blt_id);
                    }}
                  >
                    <span>
                      <i className="ic ic-arrow-next"></i>
                    </span>
                    <em>다음글</em>
                  </button>
                ) : (
                  <button type="button" disabled>
                    <span>
                      <i className="ic ic-arrow-next"></i>
                    </span>
                    <em>다음글</em>
                  </button>
                )}
              </div>
              {/* E : 페이지 하단 버튼 */}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default DonationView;

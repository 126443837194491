import api from '@apis/api';
import configs from '@configs/configs';

// 구독 리스트
export async function getCanvasLinksApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_link`, params);
  return response.data;
}

// 구독 (설정/해제)
export async function toggleCanvasLinkApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_link/ins`, params);
  return response.data;
}

export async function checkCanvasByMemberIdApi(params) {
  const response = await api.get(
    `${configs.API_BASEURL}/canvas/chk_id?member_id=${params.member_id}`,
  );
  return response.data;
}

export async function getCanvasesApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas`, params);
  return response.data;
}

export async function getCanvasApi(params) {
  const response = await api.get(`${configs.API_BASEURL}/canvas`, { params: params });
  return response.data;
}

export async function modifyCanvasApi(params) {
  const response = await api.put(`${configs.API_BASEURL}/canvas`, params);
  return response.data;
}

// 연결 목록(대기, 수락)
export async function getCanvasJoinApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_join`, params);
  return response.data;
}

export async function modifyCanvasJoinApi(params) {
  const response = await api.put(`${configs.API_BASEURL}/canvas_join`, params);
  return response.data;
}
export async function getCanvasJoinListApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_join/list`, params);
  return response.data;
}

export async function saveCanvasJoinApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_join/ins`, params);
  return response.data;
}

//알림
export async function getCanvasAlarmApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_alarm`, params);
  return response.data;
}

export async function modifyCanvasAlarmApi(params) {
  const response = await api.put(`${configs.API_BASEURL}/canvas_alarm`, params);
  return response.data;
}
export async function getCanvasAlarmCountApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/canvas_alarm/cnt`, params);
  return response.data;
}

import { useEffect, useState, useRef } from 'react';

import { getHallsApi, saveHallsApi } from 'apis/hall';

import { Dialog } from '@mui/material';
import { LegendToggle } from '@mui/icons-material';

function SearchHallDialog({ isOpen, onClose, selectedHall, hallName }) {
  const searchRef = useRef();
  const searchTextRef = useRef();

  const [halls, setHalls] = useState(null);
  const [saveHalls, setSaveHalls] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchHalls, setSearchHalls] = useState([]);

  const [mode, setMode] = useState('0');
  const [message, setMessage] = useState(' ');

  const getHalls = async () => {
    const result = await getHallsApi({});
    setHalls(result.data);
  };

  const saveHall = async message => {
    let params = {};
    params.sido = '';
    params.sigungu = '';
    params.hall_name = message;

    const result = await saveHallsApi(params);
    if (result.code === '0000') {
      getSaveHalls();
    }
  };

  const getSaveHalls = async () => {
    const result = await getHallsApi({});
    setSaveHalls(result.data);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const onKeyConfirmPress = event => {
    if (event.key === 'Enter') {
      searchTextRef.current.click();
    }
  };

  const handleSearchValue = () => {
    setSearchHalls(
      halls && halls.length > 0
        ? halls.filter(hall => JSON.stringify(hall).indexOf(searchValue) > 0)
        : [],
    );
  };

  const handleChange = event => {
    const { value } = event.target;
    setMessage(value);
  };
  // useEffect(() => {
  //   setSearchHalls(
  //     halls && halls.length > 0
  //       ? halls.filter(hall => JSON.stringify(hall).indexOf(searchValue) > 0)
  //       : [],
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchValue]);
  useEffect(() => {
    saveHalls &&
      saveHalls.length > 0 &&
      saveHalls.map(hall => (hall.hall_name === message ? selectedHall(hall.hall_id) : ''));
  }, [saveHalls]);

  useEffect(() => {
    getHalls();
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-funeralhall-01" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">장례식장 정보</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="tabs">
                  <ul>
                    <li className={`${mode === '0' ? 'is-active' : ''}`}>
                      <button type="button" onClick={() => setMode('0')}>
                        장례식장 검색
                      </button>
                    </li>
                    <li className={`${mode === '1' ? 'is-active' : ''}`}>
                      <button type="button" onClick={() => setMode('1')}>
                        직접입력
                      </button>
                    </li>
                  </ul>
                </div>
                {mode === '0' ? (
                  <>
                    <div className="ip-wrap">
                      <div className="ip-item has-btn">
                        <label htmlFor="ip-deceased-name" className="offscreen">
                          빈소(장례식장)
                        </label>
                        <input
                          type="text"
                          id="ip-deceased-name"
                          placeholder="장례식장을 검색해주세요"
                          onChange={changeSearchValue}
                          onKeyPress={onKeyPress}
                        />
                        <button
                          type="button"
                          className="btn btn-navy"
                          ref={searchRef}
                          onClick={handleSearchValue}
                        >
                          검색
                        </button>
                      </div>
                    </div>
                    <div className="list-article is-scroll">
                      <ul>
                        {/* S : LOOP */}
                        {searchHalls && searchHalls.length > 0
                          ? searchHalls.map(hall => (
                              <li key={hall.hall_id}>
                                <div className="article-detail">
                                  <p className="title">{hall.hall_name}</p>
                                  <p className="text">{hall.hall_addr}</p>
                                </div>
                                <button
                                  type="button"
                                  className="btn-sm-ln-navy"
                                  onClick={() => selectedHall(hall.hall_id)}
                                >
                                  선택
                                </button>
                              </li>
                            ))
                          : null}
                        {/* E : LOOP */}
                      </ul>
                    </div>
                  </>
                ) : (
                  <div className="ip-wrap">
                    <div className="ip-item has-btn">
                      <label htmlFor="ip-funeralhall-name" className="offscreen">
                        빈소(장례식장)
                      </label>
                      <input
                        type="text"
                        id="ip-funeralhall-name"
                        placeholder="장례식장을 입력해주세요"
                        onChange={handleChange}
                        value={message}
                        onKeyPress={onKeyConfirmPress}
                      />
                      {/*<button type="button" className="btn btn-navy" onClick={() => hallName()}>*/}
                      <button
                        type="button"
                        className="btn btn-navy"
                        ref={searchTextRef}
                        onClick={() => saveHall(message)}
                      >
                        입력
                      </button>
                      <p className="message">직접 입력 시 지도 정보는 노출되지 않습니다.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SearchHallDialog;

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { saveConsultBoardApi } from 'apis/consultBoard';
import useDialog from 'stores/dialog/hook';

function ContactUs() {
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    req_name: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    req_phone: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    req_email: Yup.string()
      .required('필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    deceased_name: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    relation_desc: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    basic_contents: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(1000, '최대 입력은 1000자까지 가능합니다.'),
    agreement: Yup.boolean().oneOf([true], '필수 체크 항목입니다.'),
  }).required();

  const defaultValues = {
    req_name: '',
    req_phone: '',
    req_email: '',
    deceased_name: '',
    relation_desc: '',
    basic_contents: '',
    agreement: false,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    saveConsultBoard(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const saveConsultBoard = async params => {
    const result = await saveConsultBoardApi(params);

    result.code === '0000' && reset();
    showAlert('알림', result.message, () => hideAlert());
  };

  return (
    <>
      <section>
        <div className="service_content">
          <h2 className="title">
            05
            <br />
            서비스 신청
          </h2>
          <div className="service_register">
            <ul className="input_wrap">
              <li>
                <i>*</i>
                <input
                  type="text"
                  {...register('req_name')}
                  aria-invalid={errors.req_name ? 'true' : 'false'}
                  placeholder="이름을 입력하세요"
                />
                {errors.req_name && <div className="val_chk">{errors.req_name.message}</div>}
              </li>
              <li>
                <i>*</i>
                <input
                  type="text"
                  {...register('req_phone')}
                  aria-invalid={errors.req_phone ? 'true' : 'false'}
                  placeholder="전화번호를 입력하세요(하이픈없이)"
                />
                {errors.req_phone && <div className="val_chk">{errors.req_phone.message}</div>}
              </li>
              <li>
                <i>*</i>
                <input
                  type="text"
                  {...register('req_email')}
                  aria-invalid={errors.req_email ? 'true' : 'false'}
                  placeholder="이메일을 입력해주세요 (ex: sample@naver.com)"
                />
                {errors.req_email && <div className="val_chk">{errors.req_email.message}</div>}
              </li>
              <li>
                <i>*</i>
                <input
                  type="text"
                  {...register('deceased_name')}
                  aria-invalid={errors.deceased_name ? 'true' : 'false'}
                  placeholder="고인명을 입력하세요"
                />
                {errors.deceased_name && (
                  <div className="val_chk">{errors.deceased_name.message}</div>
                )}
              </li>
              <li>
                <i>*</i>
                <input
                  type="text"
                  {...register('relation_desc')}
                  aria-invalid={errors.relation_desc ? 'true' : 'false'}
                  placeholder="고인과의 관계를 입력하세요"
                />
                {errors.relation_desc && (
                  <div className="val_chk">{errors.relation_desc.message}</div>
                )}
              </li>
            </ul>
          </div>
          <div className="service_register">
            <h3 className="service_title">
              기초자료 입력
              <span>(예시 : 고향과 가족, 성장과정, 가족탄생, 사회생활, 고인의 삶 회상 등)</span>
            </h3>
            <textarea
              {...register('basic_contents')}
              aria-invalid={errors.basic_contents ? 'true' : 'false'}
              className="sv_textarea"
              placeholder="인터뷰 기초자료를 미리 입력해주세요 조문보 작성에 많은 도움이 됩니다. (최대 1000자)&#13;&#10;▶ 고향과 가족(태어난 곳, 부모님과 형제들)&#13;&#10;▶ 성장과정(학력, 어린시절, 청소년 시절)&#13;&#10;▶ 가족탄생(배우자를 만나게 된 배경, 자녀출생)&#13;&#10;▶ 사회생활(사회생활, 고인의 신조)&#13;&#10;▶ 고인의 삶 회상(지혜의 말씀, 일화)"
            ></textarea>
            {errors.basic_contents && (
              <div className="val_chk">{errors.basic_contents.message}</div>
            )}
          </div>
          <div className="service_register">
            <h3 className="service_title">개인정보 수집·이용에 대한 안내</h3>
            <p className="text">(필수) 개인정보 수집·이용에 대한 안내</p>
            <p className="text">
              (주)엠서클은 이용자 문의를 처리하기 위해 다음과 같이 개인정보를 수집 및 이용하며,
              이용자의 개인정보를 안전하게 취급하는데 최선을 다하고 있습니다.
            </p>
            <table className="term_table">
              <caption>개인정보 수집·이용에 대한 안내 표</caption>
              <thead>
                <tr>
                  <th scope="col">수집항목</th>
                  <th scope="col">이용목적</th>
                  <th scope="col">보유기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>이름, 휴대폰번호, 이메일주소, 고인명</td>
                  <td>
                    고객문의 및 상담요청에 대한
                    <span className="block">회신 상담을 위한 서비스 이용기록 조회</span>
                  </td>
                  <td>3년간 보관 후, 지체없이 파기</td>
                </tr>
              </tbody>
            </table>
            <div className="check_item">
              <input
                type="checkbox"
                {...register('agreement')}
                aria-invalid={errors.agreement ? 'true' : 'false'}
                className="check"
                id="txt_check"
              />
              <label htmlFor="txt_check">
                위 개인정보 수집과 이용목적, 보유기간에 대해 동의합니다.
              </label>
            </div>
            {errors.agreement && <div className="val_chk">{errors.agreement.message}</div>}
          </div>
        </div>
      </section>
      <div className="base_button_wrap">
        <button className="base_button" onClick={handleSubmit(onSubmit, onError)}>
          상담 신청
        </button>
      </div>
    </>
  );
}

export default ContactUs;

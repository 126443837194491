import { useState, useEffect } from 'react';

import useCodes from 'stores/code/hook';
import useLogin from 'stores/login/hook';

import configs from '@configs/configs';

import {
  getCommentApi,
  getAllCommentApi,
  getCommentsApi,
  saveCommentApi,
  deleteCommentApi,
  deleteCommentVerifyApi,
  getCommentCategoryApi,
  modifyCommentApi,
  getCommentsExceldownloadApi,
} from 'apis/comment';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Pagination from 'react-js-pagination';

import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import VerifyPasswordDialog from 'pages/memorial-canvas/obituary/components/VerifyPasswordDialog';
import MediaViewDialog from 'pages/memorial-canvas/obituary/components/MediaViewDialog';
import MediaViewSwiperDialog from 'pages/memorial-canvas/obituary/components/MediaViewSwiperDialog';
import ContentViewDialog from 'pages/memorial-canvas/obituary/components/ContentViewDialog';

import MediaUploader from 'pages/memorial-canvas/obituary/components/MediaUploader';

import SaveCommentCategoryDialog from 'pages/memorial-canvas/obituary/components/CommentCategoryDialog';
import ModifyCommentCategoryDialog from 'pages/memorial-canvas/obituary/components/CommentCategoryDialog/modify';
import DeleteCommentCategoryDialog from 'pages/memorial-canvas/obituary/components/CommentCategoryDialog/delete';
import useDialog from 'stores/dialog/hook';
import LoginDialog from 'pages/memorial-canvas/obituary/components/LoginDialog';
import VerifyCommentCategoryPasswordDialog from 'pages/memorial-canvas/obituary/components/VerifyCommentCategoryPassword';
import MediaAlbumDialog from 'pages/memorial-canvas/obituary/components/MediaAlbumDialog';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

//excel download
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DisabledByDefault } from '@mui/icons-material';

function TabCondolencesContent({ memorialId, ownMemberId }) {
  const { commentTemplateCodes } = useCodes();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { loginUser } = useLogin();
  // const userInfo = JSON.parse(localStorage.getItem('loginUser'));

  const schema = Yup.object({
    writer_name: Yup.string()
      .max(30, '* 작성자명은 최대 30글자까지 입력이 가능합니다.')
      .required('* 작성자명을 입력하세요.'),
    blt_password: Yup.string()
      .max(30, '* 비밀번호는 최대 30글자까지 입력이 가능합니다.')
      .required('* 비밀번호를 입력하세요.'),
    blt_contents: Yup.string()
      .max(3000, '* 추모글은 최대 3000글자까지 입력이 가능합니다.')
      .required('* 추모글을 입력하세요.'),
  }).required();

  const defaultValues = {
    memorial_id: memorialId,
    member_id: loginUser && loginUser.member_id ? loginUser.member_id : 'guest',
    writer_name: loginUser && loginUser.member_id ? loginUser.member_name : '',
    blt_password: loginUser && loginUser.member_id ? loginUser.member_id : '',
    blt_contents: '',
    display_yn: 'Y',
    medias: [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [page, setPage] = useState(1);
  const [listSize] = useState(6);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [openVerifyPasswordDialog, setOpenVerifyPasswordDialog] = useState(false);
  const [openModPasswordDialog, setOpenModPasswordDialog] = useState(false);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [media, setMedia] = useState(null);
  const [openMediaAlbumDialog, setOpenMediaAlbumDialog] = useState(false);
  const [displayYn, setDisplayYn] = useState(true);

  //페이지 state 1:글목록 0:글쓰기
  const [pageName, setPageName] = useState(1);
  //수정할 commentId
  const [modCommentId, setModCommentId] = useState(0);
  const [modCommentIdTemp, setModCommentIdTemp] = useState(0);
  //수정할 글
  const [contentMod, setContentMod] = useState(null);
  const [contentModTemp, setContentModTemp] = useState(null);
  const [contentPassword, setContentPassword] = useState('');
  //답글 commentId
  const [repCommentId, setRepCommentId] = useState('');
  //검색 타입 작성자:writer 내용:content
  const [searchType, setSearchType] = useState('writer');
  //검색 타입 작성자:writer 내용:content
  const [searchText, setSearchText] = useState('');
  //선택다운로드 체크박스
  const [checkedList, setCheckedList] = useState([]);
  //전체선택 체크박스
  const [allChecked, setAllChecked] = useState(false);
  const [itemIndex, setitemIndex] = useState(0);

  const [content, setContent] = useState(null);
  const [openContentViewDialog, setOpenContentViewDialog] = useState(false);

  const [contentValChk, setContentValChk] = useState('');

  const handleMediaView = (media, index) => {
    setMedia(media);
    setitemIndex(index);
    setOpenMediaViewDialog(true);
  };

  const handleViewCommentInfo = commentId => {
    getComment(commentId);
    setOpenContentViewDialog(true);
  };

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    saveComment(data);
  };

  const saveComment = async params => {
    if (pageName === 0) {
      params.parent_comment_id = '0';
      params.category_id = selectedCommentCategory.category_id;
    } else {
      params.category_id = selectedCommentCategory.category_id;
    }

    const result = await saveCommentApi(params);
    if (result.code === '0000') {
      showAlert('알림', '추모글 등록이 완료되었습니다', () => handleClose());
    } else {
      showAlert('알림', result.message, () => handleClose());
    }

    categoryCnt(1);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const [comments, setComments] = useState(null);
  const [commentsExcel, setCommentsExcel] = useState(null);
  const [medias, setMedias] = useState([]);

  const getComments = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    //params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.search_type = searchType;
    params.search_text = searchText;
    params.category_id = selectedCommentCategory.category_id;

    setOpenLoadingDialog(true);
    const result = await getCommentsApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setComments(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getCommentsExcel = async () => {
    if (checkedList.length === 0) {
      showAlert('알림', '다운로드할 글을 선택해 주세요.', () => hideAlert());
    } else {
      let params = {};
      params.memorial_id = memorialId;
      params.category_id = selectedCommentCategory.category_id;
      params.comment_ids = checkedList;
      params.search_type = searchType;
      params.search_text = searchText;

      setOpenLoadingDialog(true);
      await getCommentsExceldownloadApi(params, selectedCommentCategoryName);
      setOpenLoadingDialog(false);
    }
  };

  const getAllCommentsExcel = async () => {
    if (comments.length === 0) {
      showAlert('알림', '다운로드할 게시글이 없습니다.', () => hideAlert());
    } else {
      let params = {};
      params.memorial_id = memorialId;
      params.category_id = selectedCommentCategory.category_id;
      params.search_type = searchType;
      params.search_text = searchText;

      setOpenLoadingDialog(true);
      await getCommentsExceldownloadApi(params, selectedCommentCategoryName);
      setOpenLoadingDialog(false);
    }
  };

  const getCommentsMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    //params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.search_type = searchType;
    params.search_text = searchText;
    params.category_id = selectedCommentCategory.category_id;

    setOpenLoadingDialog(true);
    const result = await getCommentsApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setComments([...comments, ...result.data]);
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getComment = async comment_id => {
    let params = {};
    // 필수
    params.memorial_id = memorialId;
    params.comment_id = comment_id + '';
    params.category_id = selectedCommentCategory.category_id;
    // 검색
    params.search_type = searchType;
    params.search_text = searchText;

    const result = await getCommentApi(params);
    // showAlert('알림', result.message, () => hideAlert());
    setCommentInfo(result.data);
  };

  // 추모의 글 상세
  const [commentInfo, setCommentInfo] = useState({});

  // 추모 카테고리 state 홍순규
  const [commentCategory, setCommentCategory] = useState([]);
  const [catPage, setCatPage] = useState(1);
  const [catListSize] = useState(5);
  const [catTotalPage, setCatTotalPage] = useState(0);
  const [catTotalCount, setCatTotalCount] = useState(0);
  const [selectedCommentCategory, setSelectedCommentCategory] = useState({ category_id: '' });
  const [showModify, setShowModify] = useState(false);
  const [CategorySearchText, setCategorySearchText] = useState('');
  const [CategorySearchType, setCategorySearchType] = useState('category_name');
  const [currCategory, setCurrCategory] = useState({});

  // 추모 모임 팝업(저장,수정,삭제) + 로그인 체크 팝업 + 비밀방 비밀번호 체크 팝업
  const [selectedCommentCategoryName, setSelectedCommentCategoryName] = useState('추모의 글');
  const [selectedCommentCategoryDesc, setSelectedCommentCategoryDesc] = useState('');
  const [openSaveCommentCategoryDialog, setOpenSaveCommentCategoryDialog] = useState(false);
  const [openModifyCommentCategoryDialog, setOpenModifyCommentCategoryDialog] = useState(false);
  const [openDeleteCommentCategoryDialog, setOpenDeleteCommentCategoryDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openVerifyCommentCategoryDialog, setOpenVerifyCommentCategoryDialog] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [currentMedia, setCurrentMedia] = useState({});
  // 추모카테고리
  const getCommentCategory = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.search_text = CategorySearchText;
    params.search_type = CategorySearchType;

    if (isMobile) {
      //모바일은 전체 로드
      params.itemInPage = 1000;
    } else {
      params.itemInPage = catListSize;
    }

    if (loginUser.member_id) {
      params.create_member_id = loginUser.member_id;
    }
    params.member_id = loginUser.member_id;

    setOpenLoadingDialog(true);
    const result = await getCommentCategoryApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setCommentCategory(result.data);
      setCatPage(1);
      setCatTotalPage(parseInt(Math.ceil((result.data_cnt + 1) / catListSize)));

      setCatTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getCommentCategoryMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = catListSize;
    params.search_text = CategorySearchText;
    params.search_type = CategorySearchType;
    params.member_id = loginUser.member_id;

    setOpenLoadingDialog(true);
    const result = await getCommentCategoryApi(params);

    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setCommentCategory([...commentCategory, ...result.data]);
      setCatTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handleOpenSaveCommentCategory = () => {
    // 로그인 체크
    if (!loginUser.member_id) {
      handleOpenLoginDialog();
      return;
    }
    setOpenSaveCommentCategoryDialog(true);
  };

  const handleOpenModifyCommentCategory = () => {
    setOpenModifyCommentCategoryDialog(true);
  };

  const handleOpenDeleteCommentCategory = () => {
    setOpenDeleteCommentCategoryDialog(true);
  };

  const handleChangeSelectedCategory = category => {
    // 로그인 체크
    if (category.category_id != '' && !loginUser.member_id) {
      handleOpenLoginDialog();
      return;
    }
    //console.log(category);

    setSelectedCommentCategoryName(category.category_name);
    setSelectedCommentCategoryDesc(category.category_desc);

    // 비밀방 체크
    if (category.display_yn === 'N' && !(loginUser.member_id === ownMemberId)) {
      setCurrCategory(() => category);
      setOpenVerifyCommentCategoryDialog(true);
    } else {
      // active 처리
      setSelectedCommentCategory(category);
    }
  };

  const handleShowModify = () => {
    setShowModify(!showModify);
  };

  const handleMoreCommentCategory = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > catTotalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setCatPage(nextPage);
      getCommentCategoryMore(nextPage);
    }
  };

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  };

  const handleChangeCategorySearchText = event => {
    setCategorySearchText(event.target.value);
  };

  const handleCategorySearch = () => {
    getCommentCategory(1);
  };

  const handleKeyPressCategorySearch = event => {
    if (event.key === 'Enter') {
      getCommentCategory(1);
    }
  };

  const handleOpenAlbumDialog = (media, index) => {
    setCurrentMedia(media);
    setInitialSlide(index);
    setOpenMediaAlbumDialog(true);
  };

  useEffect(() => {
    setShowModify(() => false);
    // 댓글 호출 api
    getComments(1);

    setValue('category_id', selectedCommentCategory.category_id);

    setAllChecked(false);
  }, [selectedCommentCategory]);

  useEffect(() => {
    setCheckedList([]);
  }, [allChecked]);
  // end 홍순규
  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getComments(selectedPage);
  };

  const handleClose = () => {
    reset();
    setMedias([]);
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    setValue('blt_password', loginUser && loginUser.member_id ? loginUser.member_id : '');
    setDisplayYn(getValues('display_yn') === 'Y' ? true : false);
    getComments(1);

    hideAlert();

    //추모글 리스트 페이지로 이동
    movePage(1);
    //추모글 리스트 페이지로 수정 textarea 숨김
    setModCommentId(0);
    //추모글 답글 input 숨김
    setRepCommentId('');
  };

  const [selectedCommentId, setSelectedCommentId] = useState(null);

  const handleDelete = (commentId, depth, categoryId) => {
    if (depth === 1) {
      if (categoryId !== null && categoryId !== '') {
        showConfirm(
          '삭제 확인',
          '해당 추모의 글의 답글이 모두 삭제 됩니다.\n삭제 하시겠습니까?',
          () => handleConfirm(commentId),
          () => hideConfirm(),
        );
      } else {
        showConfirm(
          '삭제 확인',
          '해당 추모의 글을 삭제 하시겠습니까?',
          () => handleConfirm(commentId),
          () => hideConfirm(),
        );
      }
    } else {
      showConfirm(
        '삭제 확인',
        '정말 삭제 하시겠습니까?',
        () => handleConfirm(commentId),
        () => hideConfirm(),
      );
    }
  };

  // const handleChangeDiaplayYn_ = () => {
  //   setDisplayYn(!displayYn);
  // };

  const handleChangeDiaplayYn = event => {
    // const isChecked = event.target.checked ? 'Y' : 'N';
    //alert(event.target.checked);
    setDisplayYn(event.target.checked);
  };

  const handleChangeContentMod = event => {
    setContentMod(event.target.value);
    if (event.target.value === '' || event.target.value === null) {
      setContentValChk('* 추모글을 입력하세요.');
    } else {
      setContentValChk('');
    }
  };

  /**
   * 글수정 이벤트
   */
  const handleModifySubmit = () => {
    if (contentMod === '' || contentMod === null) {
      setContentValChk('* 추모글을 입력하세요.');
    } else {
      let data = {};
      data.comment_id = modCommentId;
      data.blt_contents = contentMod;
      modComment(data);
      setContentValChk('');
    }
  };

  /**
   * 글수정
   * @param {입력 param} params
   */
  const modComment = async params => {
    const result = await modifyCommentApi(params);
    showAlert('알림', result.message, () => handleClose());
  };

  /**
   * 답글 노출
   * @param {글번호} commentId
   */
  const handleReply = commentId => {
    if (repCommentId === commentId) {
      setRepCommentId('');
    } else {
      setRepCommentId(commentId);
    }
    setModCommentId(0);
  };

  const onCheckedElement = (checked, item) => {
    if (checked) {
      setCheckedList([...checkedList, item]);
    } else if (!checked) {
      setCheckedList(checkedList.filter(el => el !== item));
    }
  };

  const isChecked = commentId => {
    return checkedList.includes(commentId);
  };

  const onAllChecked = async checked => {
    setAllChecked(() => !allChecked);

    if (checked) {
      // 전체 추모의 글 요청
      let params = {};
      params.memorial_id = memorialId;
      params.category_id = selectedCommentCategory.category_id;
      params.search_type = searchType;
      params.search_text = searchText;

      setOpenLoadingDialog(true);
      const result = await getAllCommentApi(params);

      setOpenLoadingDialog(false);
      if (result.code === '0000') {
        setCheckedList(
          // 삭제된 글 제외
          // result.data.filter(comment => comment.use_yn === 'Y').map(comment => comment.comment_id)

          // 삭제된 글 포함
          result.comment_ids,
        );
      }

      // 전체체크
    } else {
      // 전체체크 해제
      setCheckedList(() => []);
    }
  };

  const handleConfirm = commentId => {
    hideConfirm();
    deleteComment(commentId);
  };

  const handleVerifyDelete = commentId => {
    setSelectedCommentId(commentId);
    setOpenVerifyPasswordDialog(true);
  };

  const deleteCommentVerify = async params => {
    setOpenVerifyPasswordDialog(false);
    const result = await deleteCommentVerifyApi({
      comment_id: selectedCommentId,
      blt_password: params.password,
    });

    getComments(1);
    showAlert('알림', result.message, () => hideAlert());

    categoryCnt(-1);
  };

  const deleteComment = async params => {
    const result = await deleteCommentApi(params);
    getComments(1);
    showAlert('알림', result.message, () => hideAlert());

    categoryCnt(-1);
  };

  /**
   * 글 추가/삭제 시 category 표기 숫자 변경
   * @param {계산 숫자} arg
   */
  const categoryCnt = arg => {
    let selectedCateIndex = commentCategory.findIndex(
      cate => cate.category_id === selectedCommentCategory.category_id,
    );
    commentCategory[selectedCateIndex].cnt = commentCategory[selectedCateIndex].cnt + arg;
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  const handleCommentTemplate = event => {
    const commentTemplate = event.target.value;
    setValue('blt_contents', commentTemplate);
  };

  const handleSearchType = event => {
    setSearchType(event.target.value);
  };

  const handleSearchText = event => {
    setSearchText(event.target.value);
  };

  const handleSearch = e => {
    if (e.key === 'Enter') getComments(1);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getCommentsMore(nextPage);
    }
  };

  /**
   * 글쓰기/목록 페이지 이동
   * @param {0:글쓰기 1:글목록} pageName
   */
  const movePage = pageName => {
    setPageName(pageName);
  };

  /**
   * 수정 버튼 이벤트
   */
  const handleModify = (commentId, contents, password, memberId) => {
    if (memberId) {
      setModCommentId(commentId);
      setContentMod(contents);
    } else {
      setOpenModPasswordDialog(true);
      setModCommentIdTemp(commentId);
      setContentModTemp(contents);
      setContentPassword(password);
      setRepCommentId('');
    }
  };
  const handleModifyView = params => {
    setOpenModPasswordDialog(false);
    if (contentPassword === params.password) {
      setModCommentId(modCommentIdTemp);
      setContentMod(contentMod);
    } else {
      showAlert('알림', '비밀번호가 일치하지않습니다. \n비밀번호를 확인해주시기 바랍니다.', () =>
        hideAlert(),
      );
    }
  };
  const handleModifyClose = () => {
    setModCommentIdTemp(0);
    setContentModTemp(null);
    setContentPassword('');
    setModCommentId(0);
    setContentMod(null);
    setRepCommentId('');
    setContentValChk('');
  };

  const handleChangeCategorySearchType = e => {
    setCategorySearchType(e.target.value);
  };

  const excelFileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const excelFileExtension = '.xlsx';
  /**
   * excel download
   */
  const excelDownload = excelData => {
    const ws = XLSX.utils.aoa_to_sheet([[selectedCommentCategoryName], [], ['작성자', '내용']]);
    excelData.map(data => {
      XLSX.utils.sheet_add_aoa(ws, [[data.writer_name, data.blt_contents]], {
        origin: -1,
      });
      ws['!cols'] = [
        //<-- 행 사이즈
        { wpx: 100 },
        { wpx: 500 },
      ];
      return false;
    });
    const wb = { Sheets: { sheet: ws }, SheetNames: ['sheet'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, selectedCommentCategoryName + excelFileExtension);
  };

  useEffect(() => {
    setValue('display_yn', displayYn ? 'Y' : 'N');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayYn]);

  useEffect(() => {
    setValue('medias', medias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medias]);

  useEffect(() => {
    setValue('parent_comment_id', repCommentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repCommentId]);

  useEffect(() => {
    reset();
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    setValue('blt_password', loginUser && loginUser.member_id ? loginUser.member_id : '');
    setDisplayYn(getValues('display_yn') === 'Y' ? true : false);
    setValue(repCommentId);
    setValue('category_id', selectedCommentCategory.category_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    if (memorialId) {
      getComments(1);
      getCommentCategory(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
  }, [pageName]);

  return (
    <div className="tab-contents">
      <div className="wrap-form">
        {/* S : 추모글 입력 폼 */}

        {pageName === 0 && (
          <div className="write-memorial">
            <div class="box-title">
              <h4 class="title">추모글 남기기</h4>
              <p class="desc">
                <strong>{selectedCommentCategoryName}</strong>
                {selectedCommentCategoryDesc}
              </p>
            </div>
            {/* S : 문구 선택 */}
            <div className="ip-item">
              <label for="sel-ment" className="offscreen">
                문구 선택
              </label>
              <select id="sel-ment" value="" onChange={handleCommentTemplate}>
                {commentTemplateCodes &&
                  commentTemplateCodes.length > 0 &&
                  commentTemplateCodes.map(commentTemplateCode => (
                    <option key={commentTemplateCode.code} value={commentTemplateCode.code}>
                      {commentTemplateCode.value}
                    </option>
                  ))}
              </select>
            </div>
            {/* E : 문구 선택 */}
            {/* S : 문구 입력 */}
            <div className="ip-item is-error">
              <label for="ip-cmt" className="offscreen">
                추모글 입력
              </label>
              <textarea
                {...register('blt_contents')}
                id="ip-cmt"
                cols="30"
                rows="10"
                placeholder="고인을 비방하는 댓글은 예고없이 삭제될 수 있습니다."
              ></textarea>
              {errors.blt_contents && <p className="message">{errors.blt_contents.message}</p>}
            </div>
            {/* E : 문구 입력 */}
            {/* S : 파일 첨부 */}
            <div className="file-wrap">
              <MediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
            </div>
            {/* E : 파일 첨부 */}
            {/* S : 작성자/비밀번호 입력 */}
            {loginUser && !loginUser.member_id ? (
              <div className="ip-wrap ip-group">
                <div className="ip-item is-error">
                  <label for="ip-name" className="offscreen">
                    작성자명 입력
                  </label>
                  <input
                    type="text"
                    aria-invalid={errors.writer_name ? 'true' : 'false'}
                    {...register('writer_name')}
                    id="ip-name"
                    placeholder="작성자명"
                  />
                  {errors.writer_name && <p className="message">{errors.writer_name.message}</p>}
                </div>
                <div className="ip-item is-error">
                  <label for="ip-password" className="offscreen">
                    비밀번호 입력
                  </label>
                  <input
                    type="password"
                    aria-invalid={errors.blt_password ? 'true' : 'false'}
                    {...register('blt_password')}
                    id="ip-password"
                    placeholder="비밀번호(삭제시 필요합니다)"
                  />
                  {errors.blt_password && <p className="message">{errors.blt_password.message}</p>}
                </div>
              </div>
            ) : null}
            {/* E : 작성자/비밀번호 입력 */}
            {/* S : 입력 버튼 */}
            <div className="btn-wrap">
              {/* S : 공개여부 */}
              <div className="rc-item rc-toggle">
                <input
                  type="checkbox"
                  id="ip-public01"
                  defaultChecked={true}
                  onChange={handleChangeDiaplayYn}
                />
                <label for="ip-public01">
                  <span className="toggle"></span> 공개여부
                </label>
              </div>
              {/* E : 공개여부 */}
              <button
                type="button"
                className="btn btn-pos"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록
              </button>
              &nbsp;
              <button type="button" class="btn btn-pos-white" onClick={() => movePage(1)}>
                취소
              </button>
            </div>
            {/* E : 입력 버튼 */}
          </div>
        )}
        {/* E : 추모글 입력 폼 */}

        {/* S : 단체방 */}
        {pageName === 1 && (
          <div className="group-memorial-writing">
            {/*  S : 단체 모임 목록 */}

            {/*  E : 단체 모임 목록 */}

            {/*  S : 단체 글 목록 */}
            <div className="mr-article-list" style={{ width: 'calc(100%)' }}>
              {/*  S : 목록 상단 검색 영역 */}
              <div className="list-head">
                {/*  S : 목록 검색 */}
                <div className="box-search">
                  <div className="ip-item">
                    <label for="sel-type" className="offscreen">
                      분류 선택
                    </label>
                    <select id="sel-type" onChange={handleSearchType}>
                      <option value="writer">작성자</option>
                      <option value="contents">내용</option>
                    </select>
                  </div>
                  <div className="ip-item">
                    <label for="ip-search" className="offscreen">
                      검색어 입력
                    </label>
                    <input
                      type="search"
                      id="ip-search"
                      placeholder="검색어를 입력해주세요"
                      onChange={handleSearchText}
                      onKeyPress={handleSearch}
                    />
                  </div>
                  <button type="button">
                    <i className="ic ic-search" onClick={() => getComments(1)}></i>
                    <span className="offscreen" onClick={() => getComments(1)}>
                      검색하기
                    </span>
                  </button>
                </div>
                {/*  E : 목록 검색 */}
                {/*  S : 버튼 */}
                <button type="button" className="btn btn-pos" onClick={() => movePage(0)}>
                  글쓰기
                </button>
                {/*  E : 버튼 */}
              </div>
              {/*  E : 목록 상단 검색 영역 */}

              {/*  S : 전체 선택/다운로드 버튼 (작성자/상주/관리자 일 경우에만 노출) */}
              {loginUser && loginUser.member_id === ownMemberId ? (
                <div className="list-head-admin">
                  {/*  S : 전체 선택 */}
                  <div className="rc-item">
                    <input
                      type="checkbox"
                      id="chk-sample-all"
                      onChange={e => {
                        onAllChecked(e.target.checked, e.target.value);
                      }}
                      checked={allChecked}
                    />
                    <label for="chk-sample-all">전체선택</label>
                  </div>
                  {/*  E : 전체 선택 */}
                  {/*  S : 버튼 */}
                  <button type="button" className="btn-download" onClick={() => getCommentsExcel()}>
                    <i className="ic ic-download"></i>선택 다운로드
                  </button>
                  {/*  E : 버튼 */}
                  {/*  S : 버튼 */}
                  <button
                    type="button"
                    className="btn-download"
                    onClick={() => getAllCommentsExcel()}
                  >
                    <i className="ic ic-download"></i>전체 다운로드
                  </button>
                  {/*  E : 버튼 */}
                </div>
              ) : (
                ''
              )}
              {/*  E : 전체 선택/다운로드 버튼 (작성자/상주/관리자 일 경우에만 노출) */}

              {/*  S : 추모글 목록 */}
              {/*  작성자/상주/관리자 일 경우 className="is-admin" 추가 */}
              {comments && comments.length > 0 ? (
                <div
                  className={
                    (loginUser && loginUser.member_id === memorialId) ||
                    loginUser.member_id === ownMemberId
                      ? 'list-memorial-writing is-admin'
                      : 'list-memorial-writing'
                  }
                >
                  {/* S : 댓글 내용 (반복되는 부분 시작) */}
                  {comments.map(comment => (
                    <div
                      className={comment.depth > 1 ? 'box-mr reply' : 'box-mr'}
                      key={comment.comment_id}
                    >
                      {/* S : 작성자/상주/관리자 일 경우 체크박스 노출 */}
                      {loginUser?.member_id === ownMemberId ? (
                        <div className="rc-item">
                          <input
                            type="checkbox"
                            id={'chk-sample-' + comment.comment_id}
                            value={comment.comment_id}
                            checked={isChecked(comment.comment_id)}
                            onClick={
                              // 삭제된 글 제외
                              // comment.use_yn === 'Y'
                              //   ? e => onCheckedElement(e.target.checked, comment.comment_id)
                              //   : null

                              // 삭제된 글 포함
                              e => onCheckedElement(e.target.checked, comment.comment_id)
                            }
                          />
                          <label for={'chk-sample-' + comment.comment_id} className="offscreen">
                            선택하기
                          </label>
                        </div>
                      ) : (
                        ''
                      )}
                      {/* E : 작성자/상주/관리자 일 경우 체크박스 노출 */}
                      <div className="user-info">
                        <span className="name">{comment.writer_name}</span>
                        <span className="date">{comment.createdtime}</span>
                        {comment.display_yn === 'N' ? (
                          <span className="secret">
                            <i className="ic ic-secret"></i>
                            <span className="offscreen">비밀글</span>
                          </span>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="mr-content">
                        {comment.comment_id === modCommentId ? (
                          <>
                            <p>
                              <textarea onChange={handleChangeContentMod}>
                                {comment.blt_contents}
                              </textarea>
                            </p>
                            {contentValChk && <p className="message">{contentValChk}</p>}
                            <ul className="btns">
                              <li>
                                <a onClick={() => handleModifyClose()}>취소</a>
                              </li>
                              <li>
                                <a onClick={() => handleModifySubmit()}>등록</a>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <>
                            {comment.display_yn === 'Y' ||
                            (loginUser.member_id && loginUser.member_id === comment.member_id) ||
                            (loginUser.member_id && loginUser.member_id === ownMemberId) ? (
                              <>
                                {loginUser.member_id === ownMemberId ? (
                                  <p
                                    style={{ wordBreak: 'break-all', cursor: 'pointer' }}
                                    onClick={() => handleViewCommentInfo(comment.comment_id)}
                                  >
                                    {comment.depth > 1 ? (
                                      <>
                                        <strong class="mention">@{comment.org_writer_name}</strong>
                                        {comment.blt_contents}
                                      </>
                                    ) : (
                                      comment.blt_contents
                                    )}
                                  </p>
                                ) : (
                                  <p style={{ wordBreak: 'break-all' }}>
                                    {comment.depth > 1 ? (
                                      <>
                                        <strong class="mention">@{comment.org_writer_name}</strong>
                                        {comment.blt_contents}
                                      </>
                                    ) : (
                                      comment.blt_contents
                                    )}
                                  </p>
                                )}

                                {/* S : 첨부파일 있을 경우 */}
                                {comment.medias && comment.medias.length > 0 ? (
                                  <div className="file-content">
                                    {comment.medias.map((media, index) => (
                                      <span
                                        key={media.media_id}
                                        className={media.media_type === 'I' ? 'img' : 'movie'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleOpenAlbumDialog(comment.medias, index)}
                                      >
                                        <img alt="" src={media.thumbnail_link} />
                                      </span>
                                    ))}
                                  </div>
                                ) : null}
                                {/* E : 첨부파일 있을 경우 */}
                              </>
                            ) : (
                              <p>비공개 게시글입니다.</p>
                            )}

                            {comment.use_yn === 'Y' &&
                              (loginUser && loginUser.member_id ? (
                                loginUser.member_id === comment.member_id ? (
                                  <ul className="btns">
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleModify(
                                            comment.comment_id,
                                            comment.blt_contents,
                                            '',
                                            loginUser.member_id,
                                          )
                                        }
                                      >
                                        수정
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleDelete(
                                            comment.comment_id,
                                            comment.depth,
                                            selectedCommentCategory.category_id,
                                          )
                                        }
                                      >
                                        삭제
                                      </a>
                                    </li>
                                  </ul>
                                ) : (
                                  loginUser.member_id === ownMemberId && (
                                    <ul className="btns">
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleDelete(
                                              comment.comment_id,
                                              comment.depth,
                                              selectedCommentCategory.category_id,
                                            )
                                          }
                                        >
                                          삭제
                                        </a>
                                      </li>
                                    </ul>
                                  )
                                )
                              ) : // 비회원
                              !loginUser.member_id && comment.member_id === 'guest' ? (
                                <ul className="btns">
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleModify(
                                          comment.comment_id,
                                          comment.blt_contents,
                                          comment.blt_password,
                                          '',
                                        )
                                      }
                                    >
                                      수정
                                    </a>
                                  </li>
                                  <li>
                                    <a onClick={() => handleVerifyDelete(comment.comment_id)}>
                                      삭제
                                    </a>
                                  </li>
                                </ul>
                              ) : null)}

                            {selectedCommentCategory.category_id != '' && comment.use_yn === 'Y' ? (
                              <button
                                type="button"
                                className="btn-reply"
                                onClick={() => handleReply(comment.comment_id)}
                                style={{ marginBottom: '10px' }}
                              >
                                답글달기
                              </button>
                            ) : (
                              ''
                            )}
                            {/*  S : 댓글 쓰기 */}
                            {comment.comment_id === repCommentId &&
                            selectedCommentCategory.category_id != '' ? (
                              <div
                                className="ip-item has-btn"
                                style={{ borderBottom: 'none', marginBottom: '-20px' }}
                              >
                                <label for="ip-deceased-name" className="offscreen">
                                  답글 달기
                                </label>
                                <input
                                  type="text"
                                  id="ip-deceased-name"
                                  {...register('blt_contents')}
                                />
                                <button
                                  type="button"
                                  className="btn-write-mr"
                                  onClick={handleSubmit(onSubmit, onError)}
                                >
                                  등록
                                </button>
                                {errors.blt_contents && (
                                  <p className="message">{errors.blt_contents.message}</p>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                            {/*  E : 댓글 쓰기*/}
                          </>
                        )}
                      </div>
                      {/* E : 댓글 내용 (반복되는 부분 종료) */}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no_data">등록된 추모글이 없습니다.</div>
              )}

              {/*  S : 페이징 */}
              <div className="btn-wrap">
                <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
                  더보기 {page}/{totalPage}
                </button>
              </div>
              {/*  E : 추모글 목록 */}
            </div>
            {/*  E : 단체 글 목록 */}
          </div>
        )}
        {/* S : 목록 상단 검색 영역 */}
        {/* <div className="list-head"> */}
        {/* S : 목록 검색 */}
        {/* <div className="box-search">
            <div className="ip-item">
              <label for="sel-type" className="offscreen">
                분류 선택
              </label>
              <select id="sel-type">
                <option>작성자</option>
              </select>
            </div>
            <div className="ip-item">
              <label for="ip-search" className="offscreen">
                검색어 입력
              </label>
              <input type="search" id="ip-search" placeholder="검색어를 입력해주세요" />
            </div>
            <button type="button">
              <i className="ic ic-search"></i>
              <span className="offscreen">검색하기</span>
            </button>
          </div> */}
        {/* E : 목록 검색 */}
        {/* S : 버튼 */}
        {/* <a href={() => false} className="btn btn-pos">
            글쓰기
          </a> */}
        {/* E : 버튼 */}
        {/* </div> */}
        {/* E : 목록 상단 검색 영역 */}

        {/* S : 전체 선택/다운로드 버튼 (작성자/상주/관리자 일 경우에만 노출) */}
        {/* <div className="list-head-admin"> */}
        {/* S : 전체 선택 */}
        {/* <div className="rc-item">
            <input type="checkbox" id="chk-sample-all" checked="" />
            <label for="chk-sample-all">전체선택</label>
          </div> */}
        {/* E : 전체 선택 */}
        {/* S : 버튼 */}
        {/* <button type="button" className="btn-download">
            <i className="ic ic-download"></i>선택 다운로드
          </button> */}
        {/* E : 버튼 */}
        {/* </div> */}
        {/* E : 전체 선택/다운로드 버튼 (작성자/상주/관리자 일 경우에만 노출) */}

        {/* S : 추모글 목록 */}

        {/* E : 추모글 목록 */}
      </div>
      <VerifyPasswordDialog
        isOpen={openVerifyPasswordDialog}
        onClose={() => setOpenVerifyPasswordDialog(false)}
        callBack={deleteCommentVerify}
      />
      <VerifyPasswordDialog
        isOpen={openModPasswordDialog}
        onClose={() => setOpenModPasswordDialog(false)}
        callBack={handleModifyView}
      />
      <MediaViewDialog
        isOpen={openMediaViewDialog}
        onClose={() => setOpenMediaViewDialog(false)}
        media={media}
      />
      {/* <MediaViewSwiperDialog
        isOpen={openMediaViewDialog}
        onClose={() => setOpenMediaViewDialog(false)}
        media={media}
        itemIndex={itemIndex}
      /> */}
      <ContentViewDialog
        isOpen={openContentViewDialog}
        onClose={() => setOpenContentViewDialog(false)}
        commentInfo={commentInfo}
        searchType={searchType}
        searchText={searchText}
        getComment={getComment}
        deleteComment={deleteComment}
        setCommentInfo={setCommentInfo}
      />
      <SaveCommentCategoryDialog
        isOpen={openSaveCommentCategoryDialog}
        onClose={() => setOpenSaveCommentCategoryDialog(false)}
        memorialId={memorialId}
        memberId={getValues('member_id')}
        callBack={getCommentCategory}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
      />

      <ModifyCommentCategoryDialog
        isOpen={openModifyCommentCategoryDialog}
        onClose={() => setOpenModifyCommentCategoryDialog(false)}
        memorialId={memorialId}
        memberId={getValues('member_id')}
        callBack={getCommentCategory}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
        category={selectedCommentCategory}
        commentCategory={commentCategory}
        setCommentCategory={setCommentCategory}
      />

      <DeleteCommentCategoryDialog
        isOpen={openDeleteCommentCategoryDialog}
        onClose={() => setOpenDeleteCommentCategoryDialog(false)}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
        callBack={getCommentCategory}
        categoryId={selectedCommentCategory.category_id}
      />

      <VerifyCommentCategoryPasswordDialog
        isOpen={openVerifyCommentCategoryDialog}
        onClose={() => setOpenVerifyCommentCategoryDialog(false)}
        callBack={setSelectedCommentCategory}
        category={selectedCommentCategory}
        currCategory={currCategory}
      />

      <LoginDialog isOpen={openLoginDialog} onClose={() => setOpenLoginDialog(false)} />

      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openLoadingDialog}
        //onClick={() => setOpenLoadingDialog(false)}
        backdrop="static"
      >
        <Stack gap={1} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography>Loading...</Typography>
        </Stack>
      </Backdrop>

      <MediaAlbumDialog
        isOpen={openMediaAlbumDialog}
        onClose={() => setOpenMediaAlbumDialog(false)}
        medias={currentMedia}
        initialSlide={initialSlide}
      />
    </div>
  );
}

export default TabCondolencesContent;

import Dialog from '@mui/material/Dialog';
import ReactPlayer from 'react-player';

import 'css/popup.css';

function MediaViewDialog({ isOpen, onClose, media }) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      {media ? (
        media.media_type === 'I' ? (
          <div className="wrap is-new" id="memorialCanvas">
            <div className="modal is-preview" id="modal-remembrance-01" aria-hidden="true">
              <div className="modal__overlay" tabindex="-1" data-micromodal-close>
                <div
                  className="modal__container"
                  role="dialog"
                  data-size="small"
                  aria-modal="true"
                  aria-labelledby="modal-agreement-title"
                >
                  <div className="modal__header">
                    <h2 className="modal__title">사진 미리보기</h2>
                    <button
                      className="modal__close js-modal-close"
                      aria-label="Close modal"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      <i class="ic ic-close"></i>
                    </button>
                  </div>
                  <div className="modal__content">
                    <div className="swiper preview-img">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide chk-size">
                          <img
                            src={media.media_link}
                            alt=""
                            className="swiper-slide-name"
                            //onClick={() => alert(JSON.stringify(media))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal__footer"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="wrap is-new" id="memorialCanvas">
            <div className="modal is-video" id="modal-remembrance-08" aria-hidden="true">
              <div className="modal__overlay" tabindex="-1" data-micromodal-close>
                <div
                  className="modal__container"
                  role="dialog"
                  data-size="small"
                  aria-modal="true"
                  aria-labelledby="modal-agreement-title"
                >
                  <div className="modal__header">
                    <h2 className="modal__title">영상 보기</h2>
                    <button
                      className="modal__close js-modal-close"
                      aria-label="Close modal"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      <i class="ic ic-close"></i>
                    </button>
                  </div>
                  <div className="modal__content">
                    <div className="wrap-media">
                      {/* <video src="https://d2y083oijopune.cloudfront.net/video/cfa935dd-3f69-4205-815d-c790d1bef776Islands - 2119.mp4" preload="auto" autoplay="" controls="" style="width: 100%; height: 100%;"></video>
                       */}
                      <ReactPlayer
                        className="react-player"
                        url={media.media_link} // 플레이어 url
                        width="100%" // 플레이어 크기 (가로)
                        // height="500px" // 플레이어 크기 (세로)
                        playing={true} // 자동 재생 on
                        muted={false} // 자동 재생 on
                        controls={true} // 플레이어 컨트롤 노출 여부
                        light={false} // 플레이어 모드
                        pip={true} // pip 모드 설정 여부
                      />
                    </div>
                  </div>
                  <div className="modal__footer">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      확인
                    </button>
                    <button
                      type="button"
                      className="btn btn-white"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : null}
    </Dialog>
  );
}

export default MediaViewDialog;

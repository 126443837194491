import MemorialSection from 'pages/pet-memorial-canvas/main/components/MemorialSection';

import useLayout from 'stores/layout/hook';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PetMemorialCanvas() {
  const { setGnbType } = useLayout();
  const navigate = useNavigate();
  const [isMobile, setisMobile] = useState(false);

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  // 우선 맨 처음 1024이하면 모바일 처리
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }

    window.addEventListener('resize', resizingHandler);

    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('resize', resizingHandler);
    };
  }, []);

  useEffect(() => {
    setGnbType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="container is-main" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          {/* S : 메인 비주얼 */}
          <div className="visual-wrap">
            <div className="contents-width">
              <h2>
                <em>PET</em> Memorial
                <br />
                Canvas
              </h2>
              <p className="title">펫 메모리얼 캔버스는</p>
              <p>
                너무나 소중했던 아이가
                <br />
                마지막까지 사랑 받을 수 있도록
                <br />
                진심어린 추모의 마음을 전달합니다.
              </p>
            </div>
          </div>
          {/* E : 메인 비주얼 */}
          <div className="contents-width">
            {/* S : 추모관 */}
            <div className="box-title">
              <h3 className="title">추모관</h3>
              <p className="desc">
                우리 곁에 남기고 간 온기를 기억하며, 함께했던 순간들을 영원히 간직합니다.
              </p>
              <a
                href={() => false}
                onClick={() => navigate('/pet/memorial')}
                className="btn-all-view"
              >
                전체 보기
              </a>
            </div>
            <MemorialSection />
            {/* S : Memorial Canvas */}
            <div className="box-title">
              <h3 className="title">
                <em>PET</em> Memorial Canvas
              </h3>
              <p className="desc">
                펫 캔버스에서는 반려동물에게 진심어린 추모의 마음을 전달하는 문화를 형성하여{' '}
                <br class="d-block d-md-none" />
                모든 아이들이 소중한 존재로 여겨진다는 사회적인 인식을 확산합니다.
              </p>
            </div>
            <div className="memorial-canvas">
              {/* S : 부고 */}
              <div className="box-canvas-content">
                <a
                  href={() => false}
                  onClick={() => navigate('/pet/service', { state: { viewTab: 'premium' } })}
                >
                  <i className="ic ic-main-memorial-01"></i>
                  <p className="title">장례식</p>
                  <p className="desc">
                    아이들의 마지막 순간을
                    <br />
                    소중히 동행합니다.
                  </p>
                </a>
              </div>
              {/* E : 부고 */}
              {/* S : 추모관 */}
              <div className="box-canvas-content">
                <a href={() => false} onClick={() => navigate('/pet/obituary')}>
                  <i className="ic ic-main-memorial-02"></i>
                  <p className="title">반려동물 부고</p>
                  <p className="desc">
                    아이들이 남기고 간 따스함을
                    <br />
                    ‘발자국’을 통해 알립니다.
                  </p>
                </a>
              </div>
              {/* E : 추모관 */}
              {/* S : 조문보 */}
              <div className="box-canvas-content">
                <a href={() => false} onClick={() => navigate('/pet/memorial')}>
                  <i className="ic ic-main-memorial-03"></i>
                  <p className="title">추모관</p>
                  <p className="desc">
                    그리운 아이들의 온기를
                    <br />
                    오롯이 추억할 수 있는 공간입니다.
                  </p>
                </a>
              </div>
              {/* E : 조문보 */}
              {/* S : FAQ */}
              <div className="box-canvas-content">
                <a href={() => false} onClick={() => navigate('/pet/community')}>
                  <i className="ic ic-main-memorial-04"></i>
                  <p className="title">캔버스소식</p>
                  <p className="desc">
                    장례식부터 추모까지
                    <br />
                    서비스에 대한 사항과
                    <br />
                    기부소식을 확인하실 수 있습니다.
                  </p>
                </a>
              </div>
              {/* E : FAQ */}
            </div>
            {/* E : Memorial Canvas */}
          </div>
        </div>
        {/* E : CONTENT */}
      </section>
    </>
  );
}

export default PetMemorialCanvas;

import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { getPetBltsAllCommunityApi } from 'apis/petBlt';
import useDialog from '../../../../../stores/dialog/hook';
import useLayout from '../../../../../stores/layout/hook';

import Pagination from 'react-js-pagination';
import useLogin from '../../../../../stores/login/hook';
import loading_test from '../../../../../css/static/media/loading.gif';
import { useInView } from 'react-intersection-observer';

function DonationContent({ setMode }) {
  const navigate = useNavigate();
  const searchRef = useRef();
  const { loginUser } = useLogin();

  const { showAlert, hideAlert } = useDialog();
  const { setGnbType } = useLayout();

  const [searchPetStory, setSearchPetStory] = useState(null);
  const [page, setPage] = useState(1);
  const [listSize] = useState(8);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [searchType, setSearchType] = useState(null);
  const [searchWord, setSearchWord] = useState(null);

  const [loading, setLoading] = useState(true);

  //페이징 처리
  const [ref, inView] = useInView();

  const memorialId = '1';

  const getSearchPetStory = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.blt_type = 2;
    params.page = pageNum;
    params.itemInPage = listSize;
    params.searchType = searchType;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    const result = await getPetBltsAllCommunityApi(params);
    if (result.code === '0000') {
      setSearchPetStory(result.data);
      //console.log('확인해보는 것 ' + JSON.stringify(result.data));
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert);
    }
  };

  const getSearchPetStoryMore = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.blt_type = 2;
    params.page = pageNum;
    params.itemInPage = listSize;
    params.searchType = searchType;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    setLoading(true);
    const result = await getPetBltsAllCommunityApi(params);
    if (result.code === '0000') {
      setSearchPetStory([...searchPetStory, ...result.data]);
    } else {
      showAlert('알림', result.message, () => hideAlert);
    }
    setLoading(false);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const changeSearch = value => {
    if (value === '') {
      setSearchWord('');
    }
    setSearchType(value);
  };

  const handleSearch = () => {
    getSearchPetStory(1);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      if (page === 1) {
        return;
      }
      // showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getSearchPetStoryMore(nextPage);
    }
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getSearchPetStory(selectedPage);
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    getSearchPetStory(1);

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && !loading) {
      handleMore(page);
    }
  }, [inView, loading]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">캔버스 스토리</h2>
            <p>
              사랑하는 사람의 부음을 문자 및 단체 알림, SNS 메시지로
              <br className="d-md-block d-none" /> 발송 대행하고{' '}
              <br className="d-block d-md-none" />
              장례 의식 공지 등의 서비스를 제공합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          <div className="tabs tabs-about type02">
            <ul>
              <li>
                <a href={() => false} onClick={() => setMode('story')}>
                  캔버스 스토리
                </a>
              </li>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('donation')}>
                  기부소식
                </a>
              </li>
            </ul>
          </div>

          {/* S : 목록 상단 */}
          <div className="list-head">
            {/* S : 검색 */}
            <div className="list-head-right">
              {/* S : 목록 검색 */}
              <div className="box-search type02">
                <label htmlFor="ip-search1" className="offscreen">
                  검색어 입력
                </label>
                <select id="ip-search1" onChange={e => changeSearch(e.target.value)}>
                  <option value="">전체</option>
                  <option value="TITLE">제목</option>
                  <option value="WRITER">작성자</option>
                </select>
                <label htmlFor="ip-search2" className="offscreen">
                  검색어 입력
                </label>
                <input
                  type="search"
                  id="ip-search2"
                  placeholder="검색어를 입력해주세요"
                  value={searchWord}
                  onChange={e => setSearchWord(e.target.value)}
                  onKeyPress={onKeyPress}
                />
                <button ref={searchRef} type="button" onClick={() => handleSearch()}>
                  <i className="ic ic-search"></i>
                  <span className="offscreen">검색하기</span>
                </button>
              </div>
              {/* E : 목록 검색 */}
              {/* S : 버튼 */}
              {/*{loginUser?.role === 'ADMIN' ? (*/}
              <a
                href={() => false}
                className="btn btn-pos"
                onClick={() => navigate('/pet/community/donation/register')}
              >
                글쓰기
              </a>
              {/*) : (*/}
              {/*  ''*/}
              {/*)}*/}
              {/* E : 버튼 */}
            </div>
            {/* E : 검색 */}
          </div>
          {/* E : 목록 상단 */}

          {/* S : 캔버스 소식 목록 */}
          <div className="wrap-list-news">
            {searchPetStory &&
              searchPetStory.length > 0 &&
              searchPetStory.map(search =>
                search.use_yn === 'Y' ? (
                  <div
                    className="box-news"
                    key={search.blt_id}
                    // onClick={() => navigate(`/pet/community/view/${search.blt_id}`)}
                  >
                    <Link to={`/pet/community/donation/view/${search.blt_id}`}>
                      <div className="img">
                        <a href={() => false}>
                          {search.thumbnail_link ? (
                            <img src={search.thumbnail_link} alt="" />
                          ) : (
                            <img src="https://picsum.photos/400" alt="" />
                          )}
                        </a>
                      </div>
                      <div className="text">
                        <a href={() => false}>
                          <p>{search.blt_title}</p>
                          <div className="box-news-item">
                            <span className="name">{search.member_name}</span>
                            <span className="date">{search.createdtimeStr10}</span>
                          </div>
                        </a>
                      </div>
                    </Link>
                  </div>
                ) : (
                  ''
                ),
              )}
          </div>
          {/* E : 캔버스 소식 목록 */}

          {/* S : 페이징 */}
          <div className="btn-wrap" ref={ref}>
            {/*<button type="button" className="btn btn-more" onClick={() => handleMore(page)}>*/}
            {/*  더보기 {page}/{totalPage}*/}
            {/*</button>*/}
            {loading === true ? <img src={loading_test} alt="" /> : ''}
          </div>
          {/*<Pagination*/}
          {/*  activePage={page}*/}
          {/*  itemsCountPerPage={listSize}*/}
          {/*  totalItemsCount={totalCount}*/}
          {/*  pageRangeDisplayed={5}*/}
          {/*  firstPageText={''}*/}
          {/*  prevPageText={''}*/}
          {/*  nextPageText={''}*/}
          {/*  lastPageText={''}*/}
          {/*  onChange={handlePageChange}*/}
          {/*  itemClassFirst={'ico-page-first'}*/}
          {/*  itemClassPrev={'ico-page-prev'}*/}
          {/*  itemClassNext={'ico-page-next'}*/}
          {/*  itemClassLast={'ico-page-end'}*/}
          {/*/>*/}
          {/* E : 페이진 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default DonationContent;

import React, { useState, useEffect, useRef } from 'react';
import { deleteCommentApi, getCommentsApi, getMediasApi, saveCommentApi } from 'apis/comment';
import Pagination from 'react-js-pagination';
import ExifOrientationImg from 'react-exif-orientation-img';

import MediaViewSwiperDialog from 'pages/pet-memorial-canvas/obituary/components/MediaViewSwiperDialog';
//import { useInView } from 'react-intersection-observer';
//import loading_test from 'css/static/media/loading.gif';
import useDialog from 'stores/dialog/hook';
import 'css/pagination.css';
import TabMediaUploader from '../TabMediaUploader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useLogin from '../../../../../stores/login/hook';
import * as Yup from 'yup';
import MediaViewDialog from '../../../obituary/components/MediaViewDialog';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import loading_test from '../../../../../css/static/media/loading.gif';
import { useInView } from 'react-intersection-observer';
import LoginDialog from '../../../obituary/components/LoginDialog';

function TabAlbumContent({ memorialId, deceasedName, ownMemberId, tabState }) {
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { loginUser } = useLogin();

  const schema = Yup.object({
    // writer_name: Yup.string()
    //   .max(30, '* 작성자명은 최대 30글자까지 입력이 가능합니다.')
    //   .required('* 작성자명을 입력하세요.'),
    // blt_password: Yup.string()
    //   .max(30, '* 비밀번호는 최대 30글자까지 입력이 가능합니다.')
    //   .required('* 비밀번호를 입력하세요.'),
    // blt_contents: Yup.string()
    //   .max(3000, '* 추모글은 최대 3000글자까지 입력이 가능합니다.')
    //   .required('* 추모글을 입력하세요.'),
    medias: Yup.array().of(
      Yup.object().shape({
        media_link: Yup.string().required('* 사진을 등록 하세요.'),
      }),
    ),
  }).required();

  const defaultValues = {
    memorial_id: memorialId,
    member_id: loginUser && loginUser.member_id ? loginUser.member_id : 'guest',
    writer_name: loginUser && loginUser.member_id ? loginUser.member_name : '',
    // blt_password: loginUser && loginUser.member_id ? loginUser.member_id : '',
    blt_contents: ' ',
    display_yn: 'Y',
    medias: [{ media_type: '', thumbnail_link: '', media_link: '' }],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    // resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const [ref, inView] = useInView();

  const [searchMedias, setSearchMedias] = useState(null);
  const [searchAllMedias, setSearchAllMedias] = useState(null);
  const [searchVideos, setSearchVideos] = useState(null);
  const [searchAllVideos, setSearchAllVideos] = useState(null);

  const [page, setPage] = useState(1);
  const [mediaPage, setMediaPage] = useState(1);
  const [videoPage, setVideoPage] = useState(1);
  const [listSize] = useState(8);
  const [totalMediaPage, setTotalMediaPage] = useState(0);
  const [totalVideoPage, setTotalVideoPage] = useState(0);
  const [totalMediaCount, setTotalMediaCount] = useState(0);
  const [totalVideoCount, setTotalVideoCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [openVideoViewDialog, setOpenVideoViewDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [media, setMedia] = useState(null);
  const [medias, setMedias] = useState([]);

  const [itemIndex, setitemIndex] = useState(0);

  const [mode, setMode] = useState(0);
  const [comments, setComments] = useState(null);

  const [loading, setLoading] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  const xy = useRef(null);

  const getSearchMediasMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'I';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    setLoading(true);
    const result = await getMediasApi(params);
    if (result.code === '0000') {
      setSearchMedias([...searchMedias, ...result.data]);
      setTotalMediaCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
    setLoading(false);
  };

  const getSearchMedias = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'I';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    setOpenLoadingDialog(true);
    const result = await getMediasApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setSearchMedias(result.data);
      console.log('미디어' + JSON.stringify(result.data));
      setMediaPage(1);
      setTotalMediaPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalMediaCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getSearchVideo = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'V';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    setOpenLoadingDialog(true);
    const result = await getMediasApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setSearchVideos(result.data);
      setVideoPage(1);
      setTotalVideoPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalVideoCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getSearchVideoMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'V';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;

    setLoading(true);
    const result = await getMediasApi(params);
    if (result.code === '0000') {
      setSearchVideos([...searchVideos, ...result.data]);
      setTotalVideoCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
    setLoading(false);
  };

  const getSearchAllMedias = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'I';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    //params.page = pageNum;
    //params.itemInPage = listSize;

    setOpenLoadingDialog(true);
    const result = await getMediasApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setSearchAllMedias(result.data);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  function findMatchingIDs(medialist, commentlist) {
    //alert(JSON.stringify(medialist));
    //alert(JSON.stringify(commentlist));
    const matchingItems = [];

    for (const mediaItem of medialist) {
      for (const commentItem of commentlist) {
        if (mediaItem.comment_id === commentItem.comment_id) {
          matchingItems.push({ ...mediaItem });
          //alert(JSON.stringify(matchingItems));
          break;
        }
      }
    }

    return matchingItems;
  }

  const getSearchAllVideos = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.media_type = 'V';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'comment_id' };
    //params.page = pageNum;
    //params.itemInPage = listSize;

    const result = await getMediasApi(params);
    if (result.code === '0000') {
      setSearchAllVideos(result.data);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getComments = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.comment_yn = 'N';
    //params.orderBy = { direction: 'desc', field: 'comment_id' };
    //params.page = pageNum;
    //params.itemInPage = listSize;
    //params.search_type = searchType;
    //params.search_text = searchText;
    //params.category_id = selectedCommentCategory.category_id;
    params.category_id = '';
    // setOpenLoadingDialog(true);
    const result = await getCommentsApi(params);
    // setOpenLoadingDialog(false);
    if (result.code === '0000') {
      // console.log('ghkr', JSON.stringify(result.data));
      setComments(result.data);
      // setPage(1);
      // setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      // setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const saveComment = async params => {
    params.parent_comment_id = '0';
    params.category_id = '';
    params.comment_yn = 'N';

    const result = await saveCommentApi(params);
    if (result.code === '0000') {
      showAlert('알림', '사진 등록이 완료되었습니다', () => handleClose());
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const deleteComment = async params => {
    const result = await deleteCommentApi(params);
    getSearchMedias(1);
    getComments(1);
    showAlert('알림', result.message, () => hideAlert());
  };

  const handleClose = () => {
    reset();
    setMedias([]);
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    getSearchMedias(1);
    getComments(1);
    hideAlert();

    setMode(0);
  };

  // 추억앨범 데이터 저장 로직 변경 0508
  const onSubmit = data => {
    //alert(JSON.stringify(data));
    if (medias && medias.length > 0) {
      saveComment(data);
    } else {
      showAlert('알림', '사진을 업로드 해주세요.', () => hideAlert());
    }
    // medias === []
    //   ? saveComment(data)
    //   : showAlert('알림', '업로드 파일이 없습니다.', () => handleClose());
    //saveComment(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getSearchMedias(selectedPage);
  };

  const handleMediaView = (media, index) => {
    setMedia(media);
    setitemIndex(index);
    setOpenMediaViewDialog(true);
  };

  const handleVideoView = media => {
    setMedia(media);
    setOpenVideoViewDialog(true);
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  // const handleMore = pageNum => {
  //   const nextPage = pageNum + 1;
  //   if (nextPage > totalPage) {
  //     showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
  //   } else {
  //     setPage(nextPage);
  //     getSearchMediasMore(nextPage);
  //   }
  // };

  const handleMediasMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalMediaPage) {
      if (page === 1) {
        return;
      }
      //showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
      //setFinish(1);
    } else {
      setMediaPage(nextPage);
      getSearchMediasMore(nextPage);
    }
  };

  const handleVideosMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalVideoPage) {
      if (page === 1) {
        return;
      }
      //showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
      //setFinish(1);
    } else {
      setVideoPage(nextPage);
      getSearchVideoMore(nextPage);
    }
  };

  const handleDelete = commentId => {
    showConfirm(
      '삭제 확인',
      '사진 또는 영상을 삭제 하시겠습니까?',
      () => handleConfirm(commentId),
      () => hideConfirm(),
    );
  };

  const handleConfirm = commentId => {
    hideConfirm();
    deleteComment(commentId);
  };

  const handleName = commentName => {
    if (commentName.length >= 5) {
      return commentName.substr(0, 5) + '...';
    } else if (commentName === '') {
      return 'guest';
    }
    return commentName;
  };

  const handleCheckLogin = () => {
    if (!loginUser.member_id) {
      setOpenLoginDialog(true);
      return;
    }
  };

  function findMatchingIDs(medialist, commentlist) {
    //alert(JSON.stringify(medialist));
    //alert(JSON.stringify(commentlist));
    const matchingItems = [];

    for (const mediaItem of medialist) {
      for (const commentItem of commentlist) {
        if (mediaItem.comment_id === commentItem.comment_id) {
          matchingItems.push({ ...mediaItem });

          break;
        }
      }
    }
    //alert(JSON.stringify(matchingItems));
    return matchingItems;
  }

  const handleUpload = () => {
    //업로드 전 로그인 상태 확인
    handleCheckLogin();
    setMode(2);
  };

  useEffect(() => {
    setValue('medias', medias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medias]);

  useEffect(() => {
    reset();
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    //alert(loginUser.member_id);
  }, [loginUser]);

  useEffect(() => {
    if (mode === 0) {
      getSearchMedias(1);
      getSearchAllMedias(1);
    } else if (mode == 1) {
      getSearchVideo(1);
      //getSearchAllVideos(1);
    }
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    reset();
  }, [mode]);

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    getSearchMedias(1);
    getSearchVideo(1);
    getComments(1);
    //alert(JSON.stringify(searchMedias));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
  }, [tabState]);

  useEffect(() => {
    if (inView && !loading) {
      if (mode === 0) {
        handleMediasMore(mediaPage);
      } else if (mode === 1) {
        handleVideosMore(videoPage);
      }
    }
  }, [inView, loading, mediaPage, videoPage]);
  function getElement(media, index) {
    return (
      <div
        className="box-list"
        // onClick={() => alert(ownMemberId + JSON.stringify(loginUser))}
      >
        <div
          className="img"
          key={media.media_id}
          onClick={() => handleMediaView(findMatchingIDs(searchMedias, comments), index)}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={media.thumbnail_link}
            alt=""
            style={{
              width: '100%',
              height: '100%;',
              imageOrientation: 'none',
            }}
          />
        </div>
        <div className="info">
          <p className="name">
            <span>by </span>
            {comments &&
              comments.map(comment =>
                comment.comment_id === media.comment_id ? handleName(comment.writer_name) : '',
              )}
          </p>

          {/*{loginUser.member_id === ownMemberId ? (*/}
          {/*  <button type="button">삭제</button>*/}
          {/*) : (*/}
          {/*  ''*/}
          {/*)}*/}

          {comments &&
            comments.map(comment =>
              comment.comment_id === media.comment_id &&
              (comment.member_id === loginUser.member_id || loginUser.member_id === ownMemberId) ? (
                <button type="button" onClick={() => handleDelete(comment.comment_id)}>
                  삭제
                </button>
              ) : (
                ''
              ),
            )}
        </div>
      </div>
    );
  }
  let idx = 0;
  return (
    <>
      {mode !== 2 ? (
        <div className="wrap-album is-list" ref={xy}>
          <div className="contents-width">
            <div className="box-title has-crown">
              <h4 className="title">추억앨범</h4>
            </div>
            {/* S : 목록 상단 영역 */}
            <div className="list-head">
              <div className="tabs tabs-line">
                <ul>
                  <li className={mode === 0 ? 'is-active' : ''}>
                    <button type="button" onClick={() => setMode(0)}>
                      사진
                    </button>
                  </li>
                  <li className={mode === 1 ? 'is-active' : ''}>
                    <button type="button" onClick={() => setMode(1)}>
                      영상
                    </button>
                  </li>
                </ul>
              </div>
              <div className="btns">
                <button type="button" className="btn btn-pos" onClick={() => handleUpload()}>
                  등록
                </button>
              </div>
            </div>
            {/* E : 목록 상단 영역 */}

            {/* S : 등록된 게시물이 있을 때 */}
            {/*사진*/}
            {mode === 0 ? (
              searchMedias && searchMedias.length > 0 ? (
                <div className="wrap-list is-album">
                  {/* S : 이미지/영상 */}
                  {searchMedias.map(
                    (media, index) =>
                      comments &&
                      comments.map(
                        comment =>
                          comment.comment_id === media.comment_id && getElement(media, idx++),
                      ),
                  )}
                  <MediaViewSwiperDialog
                    isOpen={openMediaViewDialog}
                    onClose={() => setOpenMediaViewDialog(false)}
                    media={media}
                    itemIndex={itemIndex}
                  />
                  {/* E : 이미지/영상 */}
                  {/* S : 로딩 */}
                  <div className="box-loading" ref={ref}>
                    {/* 아래 로딩 이미지는 디자인팀 통하여 gif 이미지 전달 받은 후 교체해주세요 */}
                    {loading === true ? <img src={loading_test} alt="" /> : ''}
                  </div>
                  {/* E : 로딩 */}
                </div>
              ) : (
                <div className="wrap-list is-album">
                  <div className="no-data">
                    <div className="box-text">
                      <p className="title">등록된 사진이 없습니다.</p>
                      <p className="desc">{deceasedName}님과의 소중한 추억 사진을 올려주세요.</p>
                    </div>
                  </div>
                </div>
              )
            ) : searchVideos && searchVideos.length > 0 ? (
              // 영상
              <div className="wrap-list is-album">
                {/* S : 이미지/영상 */}
                {searchVideos.map(
                  media =>
                    comments &&
                    comments.map(
                      comment =>
                        comment.comment_id === media.comment_id && (
                          <div className="box-list">
                            <div
                              className="img"
                              key={media.media_id}
                              onClick={() => handleVideoView(media)}
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={media.thumbnail_link} alt="" />
                            </div>
                            <div className="info">
                              <p className="name">
                                <span>by </span>
                                {comments &&
                                  comments.map(comment =>
                                    comment.comment_id === media.comment_id
                                      ? handleName(comment.writer_name)
                                      : '',
                                  )}
                              </p>

                              {comments &&
                                comments.map(comment =>
                                  comment.comment_id === media.comment_id &&
                                  (comment.member_id === loginUser.member_id ||
                                    loginUser.member_id === ownMemberId) ? (
                                    <button
                                      type="button"
                                      onClick={() => handleDelete(comment.comment_id)}
                                    >
                                      삭제
                                    </button>
                                  ) : (
                                    ''
                                  ),
                                )}
                            </div>
                          </div>
                        ),
                    ),
                )}
                <MediaViewDialog
                  isOpen={openVideoViewDialog}
                  onClose={() => setOpenVideoViewDialog(false)}
                  media={media}
                />
                {/* E : 이미지/영상 */}
                {/*/!* S : 로딩 *!/*/}
                <div className="box-loading" ref={ref}>
                  {/* 아래 로딩 이미지는 디자인팀 통하여 gif 이미지 전달 받은 후 교체해주세요 */}
                  {loading === true ? <img src={loading_test} alt="" /> : ''}
                </div>
                {/*/!* E : 로딩 *!/*/}
              </div>
            ) : (
              <div className="wrap-list is-album">
                <div className="no-data">
                  <div className="box-text">
                    <p className="title">등록된 영상이 없습니다.</p>
                    <p className="desc">{deceasedName}님과의 소중한 추억 영상을 올려주세요.</p>
                  </div>
                </div>
              </div>
            )}

            {/*/!* S : 로딩 *!/*/}
            {/*<div className="box-loading" ref={ref}>*/}
            {/*  /!* 아래 로딩 이미지는 디자인팀 통하여 gif 이미지 전달 받은 후 교체해주세요 *!/*/}
            {/*  {loading === true ? <img src={loading_test} alt="" /> : ''}*/}
            {/*</div>*/}
            {/*/!* E : 로딩 *!/*/}
          </div>
        </div>
      ) : (
        <div className="wrap-album is-upload">
          <div className="contents-width">
            <div className="box-title has-crown">
              <h4 className="title">추억앨범</h4>
            </div>

            {/* S : 사진/영상 등록 */}
            <div className="box-form">
              {/* S : 파일첨부 영역 */}
              <TabMediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
              {/* E : 첨부 이미지 등록 후 */}
              {/* S : 버튼 */}
              <div className="btn-wrap">
                <button type="button" className="btn btn-ln-gray" onClick={() => setMode(0)}>
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-pos"
                  onClick={handleSubmit(onSubmit, onError)}
                  aria-invalid={errors.medias?.[0]?.media_link ? 'true' : 'false'}
                >
                  올리기
                </button>
              </div>
              {/* E : 버튼 */}
            </div>
            {/* E : 사진/영상 등록 */}
          </div>
          <LoginDialog isOpen={openLoginDialog} onClose={() => setOpenLoginDialog(false)} />
        </div>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openLoadingDialog}
        //onClick={() => setOpenLoadingDialog(false)}
        backdrop="static"
      >
        <Stack gap={1} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography>Loading...</Typography>
        </Stack>
      </Backdrop>
    </>
  );
}

export default TabAlbumContent;

import api from '@apis/api';
import configs from '@configs/configs';

//PET 커뮤니티 목록
export async function getPetBltsCommunityApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/pet_blt`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

//PET 커뮤니티 목록 전체
export async function getPetBltsAllCommunityApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/pet_blt/all`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

//PET 커뮤니티 상세정보 확인
export async function getPetBltCommunityApi(bltId) {
  try {
    const response = await api.get(
      `${configs.API_BASEURL}/pet_blt?blt_id=${bltId}&path=${configs.PATH}`,
    );
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

//PET 커뮤니티 등록
export async function savePetBltCommunityApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/pet_blt/ins`, params);
    if (response.data.code === '0000')
      return { type: 'success', message: response.data.message, id: response.data.memorial_id };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

//PET 커뮤니티 수정
export async function modifyPetBltCommunityApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.put(`${configs.API_BASEURL}/pet_blt`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

import { Dialog } from '@mui/material';
import { useState, useEffect } from 'react';

function VerifyCommentCategoryPasswordDialog({ isOpen, onClose, callBack, currCategory }) {
  const [errorMsg, setErrorMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [inputPassword, setInputPassword] = useState('');

  const handleVerify = () => {
    if (!inputPassword) {
      setIsError(true);
      setErrorMsg('비밀번호를 입력해주세요.');
    } else {
      if (inputPassword != currCategory.blt_password) {
        setIsError(true);
        setErrorMsg('비밀번호가 일치하지 않습니다.');
      } else {
        callBack(currCategory);
        handleClose();
      }
    }
  };

  const handleChangePassword = event => {
    setInputPassword(event.target.value);
  };

  const handleClose = () => {
    onClose();
    setErrorMsg('');
    setIsError(false);
    setInputPassword('');
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-chk-password" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추모모임 입장 비밀번호 확인</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={handleClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label className="offscreen">비밀번호 입력</label>
                    <input
                      aria-invalid={isError ? 'true' : 'false'}
                      type="password"
                      placeholder="비밀번호를 입력하세요"
                      onChange={handleChangePassword}
                    />
                    {isError && errorMsg ? errorMsg : ''}
                  </div>
                </div>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={handleVerify}
                  data-micromodal-close
                >
                  확인
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  onClick={handleClose}
                  data-micromodal-close
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default VerifyCommentCategoryPasswordDialog;

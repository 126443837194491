import { useEffect, useState } from 'react';
import configs from '@configs/configs';
import Dialog from '@mui/material/Dialog';
import useLayout from 'stores/layout/hook';

import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { getMessageTemplateViewApi } from 'apis/messageTemplate';

import useDialog from 'stores/dialog/hook';
import copy from 'copy-to-clipboard';

function SharedSmsDialog({ isOpen, onClose, memorial }) {
  const { setGnbType } = useLayout();

  const { showAlert, hideAlert } = useDialog();

  const [contentSms, setContentSms] = useState('');
  const currentUrl = `${configs.BASEURL}/memorial/memorial/views/${memorial.memorial_id}`;

  const shreadSms = () => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = `sms:&body=${contentSms.replace(/(\n|\r\n)/g, '%0a')}`;
      } else if (isAndroid) {
        window.location.href = `sms:?body=${contentSms.replace(/(\n|\r\n)/g, '%0a')}`;
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };

  const getMessageTemplateView = async params => {
    const result = await getMessageTemplateViewApi(params);

    setContentSms(
      result.result[0].contents + '\n\n' + '- 1주기 온라인 추모관 바로가기 : bit.ly/3YGub27',
    );
  };

  const handleChangeContentMod = event => {
    setContentSms(event.target.value);
  };

  const shreadContentSms = async () => {
    try {
      copy(contentSms);
      showAlert(
        '부고 내용 복사가 완료되었습니다.',
        '공유하고 싶은 곳에 [붙여넣기] 하시면 \n 부고 내용을 공유할 수 있습니다.',
        () => hideAlert(),
      );
    } catch (error) {
      console.log('error > ', error);
      showAlert('알림', '부고 내용 복사에 실패하였습니다.', () => hideAlert());
    }
  };

  useEffect(() => {
    if (memorial.memorial_id) {
      getMessageTemplateView({
        memorial_id: memorial.memorial_id,
        message_type: 'R',
      });
    }
  }, [memorial]);

  useEffect(() => {
    setGnbType('sub_gnb');
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="false" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-share-sms" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">문자 전송</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close
                  onClick={onClose}
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="ip-wrap">
                  <div className="ip-item">
                    <label htmlFor="text-tribute" className="offscreen">
                      소개글 입력
                    </label>
                    <textarea id="text-tribute" onChange={handleChangeContentMod}>
                      {contentSms}
                    </textarea>
                    <div className="count-text">
                      <span>{contentSms.length}</span> / 299자
                    </div>
                    {/*<div className="btn-copy-wrap">*/}
                    {/*  <button*/}
                    {/*    type="button"*/}
                    {/*    className="btn btn-sm-text"*/}
                    {/*    onClick={() => shreadContentSms()}*/}
                    {/*  >*/}
                    {/*    <i className="ic ic-text-copy"></i>부고내용 복사*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <p className="text-guide">
                  299자 이상 넘어갈 경우 수신자가 [전체보기]버튼을 눌러야 메시지 전문을 확인할 수
                  있습니다.
                </p>
                <p className="text-guide">수신자가 아이폰의 경우 글자 수 제한 없습니다.</p>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => shreadSms()}
                  data-micromodal-close
                >
                  보내기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SharedSmsDialog;

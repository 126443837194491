import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { getConsultBoardsApi, deleteConsultBoardApi } from 'apis/consultBoard';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import IconModify from '@mui/icons-material/Edit';
import IconDelete from '@mui/icons-material/DeleteForever';

import Pagination from 'react-js-pagination';

function AdminConsultingPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { pathname } = useLocation();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { consultingTypeCodes } = useCode();

  const getConsultBoards = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getConsultBoardsApi(params);
    //console.log(JSON.stringify(result));
    setConsultBoards(result.data);
    setTotalCount(result.data_cnt);
  };

  const deleteConsultBoard = async params => {
    const result = await deleteConsultBoardApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getConsultBoards(1);
  };

  const [consultBoards, setConsultBoards] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const handleModify = id => {
    navigate(`${pathname}/modify/${id}`);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleRegister = () => {
    navigate(`${pathname}/register`);
  };

  const handleDelete = id => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(id),
      () => hideConfirm(),
    );
  };

  const handleConfirm = id => {
    hideConfirm();
    deleteConsultBoard(id);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getConsultBoards(selectedPage);
  };

  const handleSearchMembers = () => {
    getConsultBoards(1);
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id && loginUser.role !== 'ADMIN') {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getConsultBoards(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>상담 관리</h2>
      </div>

      {/* S : 목록 검색 박스 */}
      <div className="content-box has-bdr-top">
        <table className="tbl-search">
          <caption></caption>
          <colgroup>
            <col width="180px" />
            <col />
            <col width="155px" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="selCategory02">검색입력</label>
              </th>
              <td className="input">
                <div className="ip-item sel-ip-box">
                  <select id="selCategory02">
                    <option>전체</option>
                    {/* <option>부고 대상</option>
              <option>소유자</option> */}
                  </select>
                  <input
                    type="text"
                    id="ipSearchText2"
                    value={searchValue}
                    onChange={changeSearchValue}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-search"
                  ref={searchRef}
                  onClick={handleSearchMembers}
                >
                  검색
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* E : 목록 검색 박스 */}

      {/* S : 테이블 상단 게시물 갯수+버튼 */}

      <div className="box-tbl-head">
        <div className="total">
          Total :{' '}
          <span>
            <em>{totalCount}</em>
          </span>
        </div>
        <div className="head-content">
          <div className="ip-item">
            <label for="selSample01" className="offscreen">
              보기 갯수 선택
            </label>
            <select id="selSample01">
              <option>10개씩 보기</option>
              <option>30개씩 보기</option>
              <option>50개씩 보기</option>
            </select>
          </div>
          <div className="btn-wrap">
            <button
              type="button"
              className="btn btn-download"
              onClick={() => alert('엑셀 다운로드 준비 중')}
            >
              엑셀 다운로드
            </button>
            <button type="button" className="btn btn-pos" onClick={handleRegister}>
              상담 등록
            </button>
          </div>
        </div>
      </div>
      {/* E : 테이블 상단 게시물 갯수+버튼 */}

      {/* S : 목록 */}
      <div className="content-box">
        <div className="tbl-wrap">
          <table className="tbl-col">
            <caption className="offscreen">상담 관리</caption>
            <colgroup>
              <col width="10%" />
              <col width="15%" />
              <col width="15%" />
              {/* <col width="15%" /> */}
              <col width="15%" />
              {/* <col width="10%" /> */}
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              {/* <col width="10%" /> */}
              <col width="10%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">구분</th>
                <th scope="col">종류</th>
                <th scope="col">신청자</th>
                {/* <th scope="col">전화번호</th> */}
                <th scope="col">신청일자</th>
                {/* <th scope="col">주인공 성함</th> */}

                <th scope="col">이름</th>
                <th scope="col">전화번호</th>
                <th scope="col">이메일</th>
                <th scope="col">상태</th>
                {/* <th scope="col">등록</th> */}
                <th scope="col">기능</th>
              </tr>
            </thead>
            <tbody>
              {consultBoards && consultBoards.length > 0
                ? consultBoards.map(row => (
                    <tr key={row.id}>
                      <td>
                        {row.type === '001'
                          ? '조문보'
                          : row.type === '002'
                          ? '자서전'
                          : row.type === '003'
                          ? '펫 장례'
                          : row.type === '004'
                          ? '펫 자서전'
                          : ''}
                      </td>
                      <td>{row.item_type_name}</td>
                      <td>{`${row.relation_desc} ${row.req_name}`}</td>
                      {/* <td>{row.req_phone}</td> */}
                      <td>{row.createddatetime}</td>
                      {/* <td>{row.deceased_name}</td> */}
                      <td>{row.req_name ? row.req_name : ''}</td>
                      <td>{row.req_phone ? row.req_phone : ''}</td>
                      <td>{row.req_email ? row.req_email : ''}</td>
                      <td>
                        {row.status === '1' ? (
                          <span className="tc-red">상담대기</span>
                        ) : (
                          <span className="tc-green">상담완료</span>
                        )}
                      </td>
                      {/* <td>
                            <div className="btn-wrap">
                              {row.type === '001' ? (
                                <button type="button" className="btn">
                                  조문보 등록
                                </button>
                              ) : row.type === '002' ? (
                                <button type="button" className="btn">
                                  자서전 등록
                                </button>
                              ) : (
                                ' '
                              )}{' '}
                            </div>
                          </td> */}
                      <td>
                        <div className="btn-wrap">
                          <Tooltip title="수정" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="수정"
                              onClick={() => handleModify(row.id)}
                            >
                              <i className="ic ic-modify"></i>
                            </button>
                          </Tooltip>
                          <Tooltip title="삭제" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="삭제"
                              onClick={() => handleDelete(row.id)}
                            >
                              <i className="ic ic-delete"></i>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* E : 목록 */}

      {/* S : 페이징 */}
      <Pagination
        activePage={page}
        itemsCountPerPage={listSize}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        firstPageText={''}
        prevPageText={''}
        nextPageText={''}
        lastPageText={''}
        onChange={handlePageChange}
        itemClassFirst={'ico-page-first'}
        itemClassPrev={'ico-page-prev'}
        itemClassNext={'ico-page-next'}
        itemClassLast={'ico-page-end'}
      />
      {/* E : 페이징 */}
    </div>
  );
}

export default AdminConsultingPage;

import useLogin from '../../../../../stores/login/hook';
import useDialog from 'stores/dialog/hook';

import Dialog from '@mui/material/Dialog';
import { notiAuthSendApi, notiAuthVerifyApi } from '../../../../../apis/openapi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import WithdrawalResultDialog from 'pages/pet-memorial-canvas/mypage/components/WithdrawalResultDialog';
import { modifyMemberApi } from '../../../../../apis/member';

function WithdrawalConfirmDialog({ isOpen, onClose }) {
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const [minutes, setMinutes] = useState(parseInt(0));
  const [seconds, setSeconds] = useState(parseInt(0));

  const [openWithdrawalResultDialog, setOpenWithdrawalResultDialog] = useState(false);

  const [status, setStatus] = useState(0);
  // const [phone, setPhone] = useState('');

  const schema = Yup.object({
    mobile: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    code: Yup.string()
      .required('* 필수입력 항목입니다.')
      .min(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.')
      .max(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.'),
  }).required();

  const defaultValues = {
    mobile: '',
    id: 0,
    code: '',
    verified: 'N',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onError = error => {
    console.log('error > ', error);
  };

  const handleNotiAuthSend = () => {
    // if (!getValues('mobile') || getValues('mobile') === '' || errors.mobile) {
    //   showAlert('알림', '휴대폰 번호를 확인하세요.', () => hideAlert());
    // } else {
    //   notiAuthSend();
    // }
    notiAuthSend();
  };

  const notiAuthSend = async () => {
    const params = { send_type: 'S', phone_num: getValues('mobile') };
    const result = await notiAuthSendApi(params);
    setValue('id', result.id);
    showAlert('알림', '문자로 발송된 인증코드를 입력하세요.', () => hideAlert());
  };

  const handleNotiAuthVerify = () => {
    if (!getValues('code') || getValues('code') === '' || errors.code) {
      showAlert('알림', '인증번호를 확인하세요.', () => hideAlert());
    } else {
      notiAuthVerify();
    }
  };

  const deleteMemorial = async memberId => {
    let params = {};
    params.member_id = memberId;
    params.status = '0';
    const result = await modifyMemberApi(params);
    setOpenWithdrawalResultDialog(true);
    //onClose();
    // showAlert('알림', result.message, () => hideAlert());
    // handleLogout();
  };

  const notiAuthVerify = async () => {
    const params = { id: getValues('id'), code: getValues('code') };
    const result = await notiAuthVerifyApi(params);
    if (result.code === '0000') {
      setValue('verified', 'Y');
      // setPhone(getValues('mobile'));
      //alert(getValues('mobile'));
      setStatus(1);
    }

    //showAlert('알림', result.message, () => hideAlert());
  };

  const handleDelete = () => {
    if (status === 0) {
      showAlert('알림', '본인인증 완료해주세요.', () => hideAlert());
    } else {
      deleteMemorial(loginUser.member_id);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const resetTimer = () => {
    setMinutes(3);
    setSeconds(0);
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <div className="wrap" id="memorialCanvas">
        <div className="modal" id="modal-mobile-change" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">본인인증</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={handleClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="popup-form">
                  <div className="ip-item has-btn">
                    <label htmlFor="ip-text1" className="offscreen">
                      휴대폰 번호 입력
                    </label>
                    <input
                      type="text"
                      id="ip-text1"
                      placeholder="휴대폰 번호"
                      {...register('mobile')}
                      aria-invalid={errors.mobile ? 'true' : 'false'}
                    />
                    <button type="button" className="btn btn-pos" onClick={handleNotiAuthSend}>
                      인증요청
                    </button>
                  </div>
                  <div className="ip-item has-btn is-error">
                    <label htmlFor="ip-text2" className="offscreen">
                      인증번호 입력
                    </label>
                    <input
                      type="text"
                      id="ip-text2"
                      placeholder="인증번호 입력"
                      {...register('code')}
                      aria-invalid={errors.code ? 'true' : 'false'}
                    />
                    <span className="timer">
                      {minutes}분 {seconds < 10 ? `0${seconds}` : seconds}초
                    </span>
                    <button type="button" className="btn btn-pos" onClick={handleNotiAuthVerify}>
                      인증확인
                    </button>
                    {errors.code && <p class="message">{errors.code.message}</p>}
                  </div>
                </div>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={handleDelete}
                  data-micromodal-close
                >
                  확인
                </button>
                <button
                  type="button"
                  className="btn btn-white"
                  data-micromodal-close
                  onClick={handleClose}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
        <WithdrawalResultDialog
          isOpen={openWithdrawalResultDialog}
          onClose={() => setOpenWithdrawalResultDialog(false)}
        />
      </div>
    </Dialog>
  );
}

export default WithdrawalConfirmDialog;

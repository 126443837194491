/* global kakao */
import React, { useEffect } from 'react';

const KakaoMap = ({ location }) => {
  useEffect(() => {
    const mapContainer = document.getElementById('map'); // 지도를 표시할 div
    const mapOption = {
      center: new kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표
      level: 3, // 지도의 확대 레벨
    };

    // 지도를 생성합니다
    const map = new kakao.maps.Map(mapContainer, mapOption);

    // 주소-좌표 변환 객체를 생성합니다
    const geocoder = new kakao.maps.services.Geocoder();

    // 주소로 좌표를 검색합니다
    geocoder.addressSearch(location, (result, status) => {
      // 정상적으로 검색이 완료됐으면
      if (status === kakao.maps.services.Status.OK) {
        const coords = new kakao.maps.LatLng(result[0].y, result[0].x);

        map.setCenter(coords);

        const marker = new kakao.maps.Marker({
          position: coords,
        });
        marker.setMap(map);
      }
    });
  }, [location]);

  return <div id="map" style={{ width: '100%', height: '100%' }} />;
};

export default KakaoMap;

import api from '@apis/api';
import configs from '@configs/configs';

export async function getWreathsApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/wreath`, params);
  return response.data;
}

export async function getWreathApi(id) {
  const response = await api.get(`${configs.API_BASEURL}/wreath?id=${id}`);
  return response.data;
}

export async function saveWreathApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/wreath/ins`, params);
  return response.data;
}

export async function modifyWreathApi(params) {
  try {
    const response = await api.put(`${configs.API_BASEURL}/wreath`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function deleteWreathApi(id) {
  const response = await api.delete(`${configs.API_BASEURL}/wreath?id=${id}`);
  return response.data;
}

export async function saveWreathExt1Api(params) {
  const response = await api.post(`${configs.API_BASEURL}/wreath/ins/ext1`, params);
  return response.data;
}

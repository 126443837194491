import { useState } from 'react';

import Memorial from './components/memorial';
import Premium from './components/premium';

function ServicePage() {
  const [mode, setMode] = useState('memorial');

  return (
    <>{mode === 'memorial' ? <Memorial setMode={setMode} /> : <Premium setMode={setMode} />}</>
  );
}

export default ServicePage;

import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';
import { getCanvasBltsAllApi } from 'apis/canvasBlt';

import imgNoThumb01 from 'assets/life/images/contents/img_no_thumb_01.jpg';
import imgNoThumb02 from 'assets/life/images/contents/img_no_thumb_02.jpg';

function LifeCanvas() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { loginUser } = useLogin();

  const [canvasBlts, setCanvasBlts] = useState();
  const [searchWord, setSearchWord] = useState();

  const [page, setPage] = useState(1);
  const [listSize] = useState(8);
  const [totalPage, setTotalPage] = useState(0);

  const [isActive, setActive] = useState(false);

  const getCanvasBltsAll = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    const result = await getCanvasBltsAllApi(params);
    if (result.code === '0000') {
      setCanvasBlts(result.data);
      setPage(1);

      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasBltsAllMore = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    // 일상글만 노출 (1 : 일상, 2 : 자서전, 3 : 문답, 4 : 버킷리스트)
    params.itemInPage = listSize;

    if (searchWord) params.searchWord = searchWord;
    if (loginUser?.member_id) params.member_id = loginUser.member_id;

    const result = await getCanvasBltsAllApi(params);
    if (result.code === '0000') {
      setCanvasBlts([...canvasBlts, ...result.data]);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleSearch = () => {
    getCanvasBltsAll();
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasBltsAllMore(nextPage);
    }
  };

  const moveRegister = register => {
    setActive(false);
    if (loginUser && !loginUser.member_id) {
      showLifeAlert('알림', '로그인 후 이용가능합니다.', () => moveLogin());
    } else if (register === 1) {
      navigate('/life/everyone/daily/register');
    } else if (register === 2) {
      navigate('/life/everyone/bucketlist/register');
    } else if (register === 3) {
      navigate('/life/everyone/faq/register');
    } else if (register === 4) {
      navigate('/life/everyone/history/register');
    }
  };

  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const moveLogin = () => {
    hideLifeAlert();
    navigate('/life/login');
  };

  const init = async () => {
    getCanvasBltsAll(1);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <section className="container" id="containerWrap">
      <div className={`${isActive ? 'bg-overlay' : ''}`}></div>
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 라이프 캔버스 비주얼 */}
        <div className="lifecanvas-visual">
          <div className="contents-width">
            <h2>
              <span>나를 위한 기록</span> <strong>라이프 캔버스</strong>
            </h2>
          </div>
        </div>
        {/* E : 라이프 캔버스 비주얼 */}

        {/* S : 라이프 캔버스 리스트 */}
        <div className="contents-width pos-r">
          {/* S : 타이틀 + 검색 */}
          <div className="lifecanvas-head">
            <h3>모두의 캔버스</h3>
            <div className="box-search">
              <label htmlFor="ip-search" className="offscreen">
                검색하기
              </label>
              <input
                type="search"
                id="ip-search"
                placeholder="이름을 검색하세요"
                value={searchWord}
                onChange={e => setSearchWord(e.target.value)}
                onKeyPress={onKeyPress}
              />
              <button ref={searchRef} type="button" onClick={() => handleSearch()}>
                <i className="ic ic-search"></i>
                <span className="offscreen">검색하기</span>
              </button>
            </div>
          </div>
          {/* E : 타이틀 + 검색 */}

          {/* S : 모두의 캔버스 목록 */}
          <div className="list-card">
            {/* S : CARD */}
            {canvasBlts &&
              canvasBlts.length > 0 &&
              canvasBlts.map(canvasBlt => (
                <div className="box-card">
                  <div
                    className="card-thumb chk-size"
                    onClick={() =>
                      Number(loginUser?.canvas_id) !== Number(canvasBlt.canvas_id)
                        ? navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)
                        : navigate('/life/canvas/my')
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      alt=""
                      // src={
                      //   canvasBlt.background_link
                      //     ? canvasBlt.background_link
                      //     : Math.round(Math.random()) > 0
                      //     ? imgNoThumb01
                      //     : imgNoThumb02
                      // }
                      src={
                        canvasBlt?.background_link
                          ? canvasBlt.background_link
                          : canvasBlt?.canvas_id % 2 === 0
                          ? imgNoThumb01
                          : imgNoThumb02
                      }
                    />
                  </div>
                  <div className="card-info">
                    <span
                      className="user-img chk-size"
                      onClick={() =>
                        Number(loginUser?.canvas_id) !== Number(canvasBlt.canvas_id)
                          ? navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)
                          : navigate('/life/canvas/my')
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <img alt="" src={canvasBlt.profile_link ? canvasBlt.profile_link : ''} />
                    </span>
                    <span
                      className="user-name"
                      onClick={() =>
                        Number(loginUser?.canvas_id) !== Number(canvasBlt.canvas_id)
                          ? navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)
                          : navigate('/life/canvas/my')
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      {canvasBlt.title}
                    </span>
                    {Number(loginUser?.canvas_id) !== Number(canvasBlt.canvas_id) ? (
                      canvasBlt.link_yn === 'Y' ? (
                        <button
                          type="button"
                          class="btn btn-sm btn-lngray"
                          onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
                        >
                          구독 중
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-lngreen"
                          onClick={() => navigate(`/life/canvas/user/${canvasBlt.canvas_id}`)}
                        >
                          + 구독
                        </button>
                      )
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sm btn-lngreen"
                        onClick={() => navigate('/life/canvas/my')}
                      >
                        본인
                      </button>
                    )}
                  </div>
                  <div className="card-content">
                    <a
                      href={() => false}
                      onClick={() =>
                        Number(loginUser?.canvas_id) !== Number(canvasBlt.canvas_id)
                          ? navigate(`/life/canvas/user/${canvasBlt.canvas_id}`, {
                              state: { searchBltType: '1' },
                            })
                          : navigate('/life/canvas/my', {
                              state: { searchBltType: '1' },
                            })
                      }
                    >
                      {/* <p className="motto">{canvas.blt_title}</p> */}
                      <p className="article">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0',
                            fontSize: '1rem',
                            color: '#1bc0c6',
                            fontWeight: '700',
                          }}
                        >
                          {canvasBlt?.blt_type === '1' && (
                            <div>
                              <i
                                className="ic ic-cate-life"
                                style={{ width: '1.2rem', height: '1.2rem', marginRight: '0.3rem' }}
                              ></i>
                              일상
                            </div>
                          )}
                          {canvasBlt?.blt_type === '2' && (
                            <div>
                              <i
                                className="ic ic-cate-history"
                                style={{ width: '1.2rem', height: '1.2rem', marginRight: '0.3rem' }}
                              ></i>
                              자서전
                            </div>
                          )}
                          {canvasBlt?.blt_type === '3' && (
                            <div>
                              <i
                                className="ic ic-cate-faq"
                                style={{ width: '1.2rem', height: '1.2rem', marginRight: '0.3rem' }}
                              ></i>
                              문답
                            </div>
                          )}
                          {canvasBlt?.blt_type === '4' && (
                            <div>
                              <i
                                className="ic ic-cate-bucketlist"
                                style={{ width: '1.2rem', height: '1.2rem', marginRight: '0.3rem' }}
                              ></i>
                              버킷리스트
                            </div>
                          )}
                        </div>
                        {canvasBlt?.blt_type === '1' && canvasBlt.blt_contents}
                        {canvasBlt?.blt_type === '2' && canvasBlt.blt_title}
                        {canvasBlt?.blt_type === '3' && (
                          <>
                            <p
                              style={{
                                fontWeight: '700',
                                color: '#00237e',
                                lineHeight: '1.36',
                              }}
                            >
                              Q. {canvasBlt.blt_contents.split('"')[3]}
                            </p>
                          </>
                        )}
                        {canvasBlt?.blt_type === '4' && canvasBlt.blt_contents}
                      </p>
                      <span className="date">{canvasBlt.createdtime}</span>
                    </a>
                  </div>
                </div>
              ))}

            {/* E : CARD */}
          </div>
          {/* E : 모두의 캔버스 목록 */}

          {/* S : 더보기 버튼 */}
          <div className="btn-wrap">
            <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
              더보기 {page}/{totalPage}
            </button>
          </div>
          {/* E : 더보기 버튼 */}

          {/* S : (고정) 기록 버튼 */}
          <div className="quick-btn-wrap">
            <button
              className={`btn btn-fixed-list ${isActive ? 'is-active' : ''}`}
              onClick={() => setActive(!isActive)}
            >
              <span>기록</span>
            </button>
            <ul className={`quick-write-list ${isActive ? 'is-active' : ''}`}>
              <li>
                <a href={() => false} onClick={() => moveRegister(1)}>
                  <i className="ic ic-write-life"></i> 일상
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => moveRegister(2)}>
                  <i className="ic ic-write-bucket"></i> 버킷리스트
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => moveRegister(3)}>
                  <i className="ic ic-write-faq"></i> 문답
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => moveRegister(4)}>
                  <i className="ic ic-write-history"></i> 자서전
                </a>
              </li>
            </ul>
            {/* S : 22-12-14 모바일 top 버튼 추가 */}
            <button type="button" className="btn-go-top" onClick={() => scrollTo()}>
              <span className="offscreen">위로가기</span>
              <i className="ic ic-arrow-up"></i>
            </button>
            {/* E : 22-12-14 모바일 top 버튼 추가 */}
          </div>
          {/* <a href={() => false} className="btn btn-fixed-list" onClick={() => moveRegister()}>
            기록
          </a> */}
          {/* E : (고정) 기록 버튼 */}
        </div>
        {/* E : 라이프 캔버스 리스트*/}
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default LifeCanvas;

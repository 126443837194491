import api from '@apis/api';
import configs from '@configs/configs';

export async function getMessageTemplatesApi(params) {
  /*
[필수]
message_type > O : 부고알림, T : 감사인사, A : 계좌알림
  */
  // params.path = configs.PATH;
  const response = await api.post(`${configs.API_BASEURL}/member/resetPwd`, params);
  return response.data;
}

export async function getMessageTemplateViewApi(params) {
  /*
[필수]
memorial_id > 추모관 아이디
message_type > O : 부고알림, M : 추모관 전송, T : 감사인사, A : 계좌알림
  */
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/messageTemplate/view`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }

  // const response = await api.post(`${configs.API_BASEURL}/messageTemplate/view`, params);
  // return response.data;
}

import { useState, useEffect, useRef } from 'react';
import useLayout from 'stores/layout/hook';
import useCode from 'stores/code/hook';

import useDialog from 'stores/dialog/hook';

import { getFaqBoardsApi } from 'apis/faqBoard';

import Faq from 'pages/life-canvas/faq/components/Faq';

function FaqPage() {
  const searchRef = useRef();

  const { myfaqBltTypeCodes } = useCode();
  const { setGnbType } = useLayout();
  const [faqs, setFaqs] = useState([]);

  const { showLifeAlert, hideLifeAlert } = useDialog();

  const [totalCount, setTotalCount] = useState(0);
  const [selectedFaqBltTypeCode, setSelectedFaqBltTypeCode] = useState('');
  const [code, setCode] = useState([]);

  const [page, setPage] = useState(1);
  const [listSize] = useState(4);
  const [totalPage, setTotalPage] = useState(0);

  const [searchWord, setSearchWord] = useState();

  // const getFaqBoards = async params => {
  //   const result = await getFaqBoardsApi(params);
  //   setFaqs(result.data);
  //   setTotalCount(result.data_cnt);
  //   //alert(totalCount);
  //   //alert(JSON.stringify(faqs));
  // };

  const changeFaqBltTypeCode = async (faqBltTypeCode, pageNum) => {
    let params = {};
    params.canvas_type = 'L';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'asc', field: 'order_num' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (faqBltTypeCode && faqBltTypeCode !== '') params.blt_type = faqBltTypeCode;
    if (searchWord) params.searchWord = searchWord;

    setSelectedFaqBltTypeCode(faqBltTypeCode);

    const result = await getFaqBoardsApi(params);
    if (result.code === '0000') {
      setFaqs(result.data);
      setTotalCount(result.data_cnt);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const changeFaqBltTypeCodeMore = async (faqBltTypeCode, pageNum) => {
    let params = {};
    params.canvas_type = 'L';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'asc', field: 'order_num' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (faqBltTypeCode && faqBltTypeCode !== '') params.blt_type = faqBltTypeCode;
    if (searchWord) params.searchWord = searchWord;

    setSelectedFaqBltTypeCode(faqBltTypeCode);

    const result = await getFaqBoardsApi(params);
    if (result.code === '0000') {
      setFaqs([...faqs, ...result.data]);
      setTotalCount(result.data_cnt);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleSearch = code => {
    changeFaqBltTypeCode(code, 1);
  };

  const handleMore = (pageNum, code) => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      changeFaqBltTypeCodeMore(code, nextPage);
    }
  };

  const init = async () => {
    changeFaqBltTypeCode('', 1);
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    init();
    // getFaqBoards({
    //   canvas_type: 'L',
    //   display_yn: 'Y',
    //   orderBy: { direction: 'asc', field: 'order_num' },
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-faq">
          <h2>FAQ</h2>

          <div className="tabs tabs-faq">
            <ul>
              {myfaqBltTypeCodes &&
                myfaqBltTypeCodes.length > 0 &&
                myfaqBltTypeCodes.map(faqBltTypeCode => (
                  <li
                    className={` ${
                      faqBltTypeCode.code === selectedFaqBltTypeCode ? 'is-active' : ''
                    } `}
                  >
                    <a
                      href={() => false}
                      style={{ cursor: 'pointer' }}
                      key={faqBltTypeCode.code}
                      onClick={() => {
                        changeFaqBltTypeCode(faqBltTypeCode.code, 1);
                        setCode(faqBltTypeCode);
                      }}
                    >
                      {faqBltTypeCode.value}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="faq-list-head">
            <p>
              총 <strong>{totalCount}건</strong>
            </p>
            {/* S : 검색 */}
            <div className="box-search">
              <label for="ip-search" className="offscreen">
                검색하기
              </label>
              <input
                type="search"
                id="ip-search"
                placeholder="검색어를 검색해주세요"
                value={searchWord}
                onChange={e => setSearchWord(e.target.value)}
                onKeyPress={onKeyPress}
              />
              <button ref={searchRef} type="button" onClick={() => handleSearch(code.code)}>
                <i className="ic ic-search"></i>
                <span className="offscreen">검색하기</span>
              </button>
            </div>
            {/* E : 검색 */}
          </div>
          <Faq faqs={faqs} code={code} />
          {/* S : 더보기 버튼 */}
          <div className="btn-wrap">
            <button
              type="button"
              className="btn btn-more"
              onClick={() => handleMore(page, code.code)}
            >
              더보기 {page}/{totalPage}
            </button>
          </div>
          {/* E : 더보기 버튼 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default FaqPage;

//import { useState, useEffect } from 'react';

// function Faq() {
//   const [isActive, setActive] = useState(false);
//   const [isNumber, setNumber] = useState('');
//   const [selectedIndex, setSelectedIndex] = useState('');
//   const [count, setCount] = useState('13');

//   const move = num => {
//     setNumber(num);
//     setActive(true);
//   };

//   useEffect(() => {
//     setSelectedIndex('1');
//   }, []);

//   return (
//     <section className="container" id="containerWrap">
//       {/* S : CONTENT */}
//       <div className="content">
//         <div className="contents-width is-faq">
//           <h2>FAQ</h2>
//           <div className="tabs tabs-faq">
//             <ul>
//               <li className={`${selectedIndex === '1' ? 'is-active' : ''}`}>
//                 <a
//                   href={() => false}
//                   onClick={() => {
//                     setSelectedIndex('1');
//                     setCount('13');
//                   }}
//                 >
//                   전체
//                 </a>
//               </li>
//               <li className={`${selectedIndex === '2' ? 'is-active' : ''}`}>
//                 <a
//                   href={() => false}
//                   onClick={() => {
//                     setSelectedIndex('2');
//                     setCount('2');
//                   }}
//                 >
//                   글작성
//                 </a>
//               </li>
//               <li className={`${selectedIndex === '3' ? 'is-active' : ''}`}>
//                 <a
//                   href={() => false}
//                   onClick={() => {
//                     setSelectedIndex('3');
//                     setCount('7');
//                   }}
//                 >
//                   캔버스
//                 </a>
//               </li>
//               <li className={`${selectedIndex === '4' ? 'is-active' : ''}`}>
//                 <a
//                   href={() => false}
//                   onClick={() => {
//                     setSelectedIndex('4');
//                     setCount('3');
//                   }}
//                 >
//                   구독 및 연결
//                 </a>
//               </li>
//               <li className={`${selectedIndex === '5' ? 'is-active' : ''}`}>
//                 <a
//                   href={() => false}
//                   onClick={() => {
//                     setSelectedIndex('5');
//                     setCount('1');
//                   }}
//                 >
//                   메모리얼캔버스 연동
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div className="faq-list-head">
//             <p>
//               총 <strong>{count}건</strong>
//             </p>
//             {/* S : 검색 */}
//             <div className="box-search">
//               <label for="ip-search" className="offscreen">
//                 검색하기
//               </label>
//               <input type="search" id="ip-search" placeholder="검색어를 검색해주세요" />
//               <button type="button">
//                 <i className="ic ic-search"></i>
//                 <span className="offscreen">검색하기</span>
//               </button>
//             </div>
//             {/* E : 검색 */}
//           </div>
//           <div className="faq-list">
//             <ul>
//               {selectedIndex === '1' || selectedIndex === '2' ? (
//                 <>
//                   <li
//                     className={`${isNumber === '1' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('1')}
//                   >
//                     <div className="question">
//                       <p className="title">
//                         마이라이프캔버스 모든 단말 기기 에서 글을 쓸 수 있나요?
//                       </p>
//                       <span className="category">글작성</span>
//                     </div>
//                     <div className="answer">
//                       <p>네 가능합니다.</p>
//                     </div>
//                   </li>
//                   {/* <li
//                     className={`${isNumber === '2' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('2')}
//                   >
//                     <div className="question">
//                       <p className="title">자판이 익숙하지 않아 타이핑이 어려워요?</p>
//                       <span className="category">글작성</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         마이라이프캔버스에서 말하면 바로 받아써지는 받아쓰기 기능을 이용해 보세요.{' '}
//                         <br />
//                         직접 타이핑하지 않아도 됩니다.
//                       </p>
//                     </div>
//                   </li> */}
//                   <li
//                     className={`${isNumber === '3' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('3')}
//                   >
//                     <div className="question">
//                       <p className="title">글을 어떻게 시작해야할지 어려워요</p>
//                       <span className="category">글작성</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         고민하지 마세요. 제시한 주제에 답하기만 하면 글들이 모여 이야기가 됩니다.
//                       </p>
//                     </div>
//                   </li>
//                 </>
//               ) : (
//                 ''
//               )}
//               {selectedIndex === '1' || selectedIndex === '3' ? (
//                 <>
//                   <li
//                     className={`${isNumber === '4' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('4')}
//                   >
//                     <div className="question">
//                       <p className="title">마이라이프캔버스를 공개 또는 비공개로 할 수 있나요?</p>
//                       <span className="category">캔버스</span>
//                     </div>
//                     <div className="answer">
//                       <p>네 공개, 비공개로 할 수 있습니다.</p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '5' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('5')}
//                   >
//                     <div className="question">
//                       <p className="title">게시된 글 수정 어떻게 하나요?</p>
//                       <span className="category">캔버스</span>
//                     </div>
//                     <div className="answer">
//                       <p>각 글에 수정 버튼이 있습니다. 수정버튼을 누르고 수정하시면 됩니다.</p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '6' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('6')}
//                   >
//                     <div className="question">
//                       <p className="title">게시된 글을 관리하려면 어떻게 해야 하나요?</p>
//                       <span className="category">캔버스</span>
//                     </div>
//                     <div className="answer">
//                       <p>마이 캔버스에서 글을 관리하면 됩니다.</p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '7' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('7')}
//                   >
//                     <div className="question">
//                       <p className="title">게시된 글은 얼마 동안 보관이 되나요?</p>
//                       <span className="category">캔버스</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         작성한 글은 마이라이프캔버스 서비스가 운영되는 동안 영구적으로 보관됩니다.
//                       </p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '8' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('8')}
//                   >
//                     <div className="question">
//                       <p className="title">개인 정보는 어떻게 보호가 되나요?</p>
//                       <span className="category">캔버스</span>
//                     </div>
//                     <div className="answer">
//                       <p>제공된 개인정보는 관계 법령과 본사 규칙에 따라 철저히 보호 됩니다.</p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '9' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('9')}
//                   >
//                     <div className="question">
//                       <p className="title">
//                         라이프 캔버스에서 여러 계정을 만들어서 사용 할 수 있나요?
//                       </p>
//                       <span className="category">캔버스</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         회원가입 시 휴대폰 번호를 통해 인증하고 가입할 수 있습니다. <br />
//                         휴대폰 번호가 여러 개 있다면 여러 개의 계정을 생성할 수 있으나, 그렇지
//                         않으면 어렵습니다.{' '}
//                       </p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '10' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('10')}
//                   >
//                     <div className="question">
//                       <p className="title">사진 및 동영상 파일도 올릴 수 있나요?</p>
//                       <span className="category">캔버스</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         자서전, 일상, 버킷리스트에 자유롭게 사진 파일을 업로드 할 수 있습니다.{' '}
//                         <br />
//                         동영상은 지원하지 않고 있습니다.
//                       </p>
//                     </div>
//                   </li>
//                 </>
//               ) : (
//                 ''
//               )}
//               {selectedIndex === '1' || selectedIndex === '4' ? (
//                 <>
//                   <li
//                     className={`${isNumber === '11' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('11')}
//                   >
//                     <div className="question">
//                       <p className="title">제3자의 콘텐츠를 내 게시글에 추가 할 수 있나요?</p>
//                       <span className="category">구독 및 연결</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         마이라이프캔버스는 본인이 작성한 글로 채우며 인생을 그려 나가는 것이
//                         목적입니다. 마이 캔버스에는 본인의 글만 등록이 가능합니다.
//                       </p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '12' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('12')}
//                   >
//                     <div className="question">
//                       <p className="title">가족, 친구들과 공유를 어떻게 하나요?</p>
//                       <span className="category">구독 및 연결</span>
//                     </div>
//                     <div className="answer">
//                       <p>가족, 친구의 계정을 구독하고 계정에서 올라오는 글을 확인할 수 있습니다.</p>
//                     </div>
//                   </li>
//                   <li
//                     className={`${isNumber === '13' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('13')}
//                   >
//                     <div className="question">
//                       <p className="title">연결된 사람의 비공개 글도 모두 볼 수 있나요?</p>
//                       <span className="category">구독 및 연결</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         마이라이프캔버스에서 ‘연결’의 개념은 부고가 발생했을 시, 부고 대상의
//                         자서전을 조문보로 활용할 수 있게 관리 가능한 대상을 지정하는 것입니다.{' '}
//                         <br />
//                         예를들어 친구와 연결하고 친구에게 부고가 발생했을 시, 친구가 라이프캔버스에
//                         남긴 자서전을 활용하여 친구를 추모할 수 있는 글을 메모리얼 캔버스에 구성할
//                         수 있습니다.
//                       </p>{' '}
//                       <br />
//                       <p>
//                         일상 글, 문답 글, 버킷리스트 글을 비공개로 등록할 시에는 연결된 캔버스도
//                         글을 확인할 수 없으나 자서전의 글은 비공개로 등록하여도 연결된 사람이
//                         메모리얼 캔버스에 등록할 시 확인할 수 있습니다.
//                       </p>
//                       <p>
//                         연결된 캔버스도 확인하지 못할 글을 올리고자 한다면 일상글이나 버킷리스트
//                         문답을 활용하여 비공개 글로 등록하시면 됩니다. <br />
//                         비공개 설정된 일상글, 문답글, 버킷리스트 글은 나만 확인할 수 있습니다.
//                       </p>
//                     </div>
//                   </li>
//                 </>
//               ) : (
//                 ''
//               )}
//               {selectedIndex === '1' || selectedIndex === '5' ? (
//                 <>
//                   <li
//                     className={`${isNumber === '14' ? (isActive ? 'is-active' : '') : ''}`}
//                     onClick={() => move('14')}
//                   >
//                     <div className="question">
//                       <p className="title">
//                         라이프 캔버스에 글을 올리면 메모리얼 캔버스에 자동으로 등록 되나요?
//                       </p>
//                       <span className="category">메모리얼캔버스 연동</span>
//                     </div>
//                     <div className="answer">
//                       <p>
//                         아닙니다.
//                         <br />
//                         라이프캔버스와 메모리얼 캔버스는 별개입니다. <br />
//                         메모리얼 캔버스에 글을 등록하려고 할 시에만 라이프캔버스에 올린 자서전을
//                         불러와서 등록할 수 있습니다.
//                       </p>
//                     </div>
//                   </li>
//                 </>
//               ) : (
//                 ''
//               )}
//             </ul>
//           </div>
//           {/* S : 더보기 버튼 */}
//           <div className="btn-wrap">
//             <button type="button" className="btn btn-more">
//               더보기 1/1
//             </button>
//           </div>
//           {/* E : 더보기 버튼 */}
//         </div>
//       </div>
//       {/* E : CONTENT */}
//     </section>
//   );
// }

// export default Faq;

import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useLogin from 'stores/login/hook';
import KakaoLogin from 'components/KakaoLogin';
import NaverLogin from 'components/NaverLogin';
import useDialog from 'stores/dialog/hook';

function LoginUserPage({ onChange, closeDialog }) {
  const { showAlert, hideAlert } = useDialog();
  const { error, emailLogin } = useLogin();
  const submitRef = useRef();

  const [savedId, setSavedId] = useState(false);

  const schema = Yup.object({
    email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    member_pwd: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    email: '',
    member_pwd: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    if (savedId) window.localStorage.setItem('savedId', data.email);
    else window.localStorage.removeItem('savedId');

    emailLogin(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      submitRef.current.click();
    }
  };

  useEffect(() => {
    if (error) {
      showAlert('알림', error.message, () => hideAlert());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const savedId = localStorage.getItem('savedId');
    if (savedId) {
      setSavedId(true);
      setValue('email', savedId);
    } else {
      setSavedId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="layer_pop pop_login">
      <div className="pop_inner">
        <div className="layer_cont_wrap">
          <div className="layer_cont">
            <div className="layer_cont_inner">
              <div className="remember_login">
                <h2 className="title">Login</h2>
                <div className="sub_title">
                  사랑하는 고인의 가족, 친지분들에게
                  <br />
                  부고를 알리며 온라인 장례를 통해
                  <br />
                  조문을 하실 수 있습니다
                </div>
                <div className="login_form">
                  <form>
                    <fieldset>
                      <legend>로그인</legend>
                      <ul>
                        <li>
                          <label htmlFor="email">
                            <input
                              {...register('email')}
                              type="text"
                              className="login_input"
                              id="email"
                              placeholder="이메일주소"
                              aria-invalid={errors.email ? 'true' : 'false'}
                            />
                            {errors.email && <div className="val_chk">{errors.email.message}</div>}
                          </label>
                        </li>
                        <li className="mar_type">
                          <label htmlFor="password">
                            <input
                              {...register('member_pwd')}
                              type="password"
                              className="login_input"
                              id="password"
                              placeholder="비밀번호"
                              onKeyPress={onKeyPress}
                              aria-invalid={errors.member_pwd ? 'true' : 'false'}
                            />
                            {errors.member_pwd && (
                              <div className="val_chk">{errors.member_pwd.message}</div>
                            )}
                          </label>
                          <div className="check_item">
                            <input
                              type="checkbox"
                              name=""
                              className="check"
                              id="id_chk"
                              checked={savedId}
                              onClick={() => setSavedId(!savedId)}
                            />
                            <label htmlFor="id_chk">아이디 저장</label>
                          </div>
                        </li>
                      </ul>
                    </fieldset>
                  </form>
                </div>
                <div className="login_btn">
                  <a
                    href={() => false}
                    style={{ cursor: 'pointer' }}
                    className="login"
                    ref={submitRef}
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    Login
                  </a>
                  {/* <KakaoLogin />
                  <NaverLogin /> */}
                </div>
                <div className="member_link">
                  <a
                    href={() => false}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onChange('register')}
                  >
                    회원가입
                  </a>
                  <a
                    href={() => false}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onChange('findId')}
                  >
                    아이디 찾기
                  </a>
                  <a
                    href={() => false}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onChange('findPw')}
                  >
                    비밀번호 초기화
                  </a>
                  <a
                    href={() => false}
                    style={{ cursor: 'pointer' }}
                    class="close"
                    onClick={() => closeDialog()}
                  >
                    닫기
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginUserPage;

import { useEffect } from 'react';
import configs from '@configs/configs';

function NaverLogin() {
  const initializeNaverLogin = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: 'qpvjxPimoZNc26VcDxJG',
      callbackUrl: `${configs.BASEURL}/life/login/naver`,
      isPopup: false, // popup 형식으로 띄울것인지 설정
      loginButton: { color: 'green', type: 3, height: '45' }, //버튼의 스타일, 타입, 크기를 지정
    });
    naverLogin.init();
  };

  useEffect(() => {
    initializeNaverLogin();
  }, []);

  return (
    <li>
      <div id="naverIdLogin" style={{ display: 'none' }} />
      <button
        type="button"
        className="btn-naver"
        onClick={() => document.getElementById('naverIdLogin').firstChild.click()}
      >
        네이버 아이디로 로그인
      </button>
    </li>
  );
}

export default NaverLogin;

import { useState, useEffect, useRef } from 'react';

import useLayout from 'stores/layout/hook';

import AboutBlt from 'pages/pet-memorial-canvas/blt/components/AboutBlt';
import WritingProcess from 'pages/pet-memorial-canvas/blt/components/WritingProcess';
import PremiumService from 'pages/pet-memorial-canvas/blt/components/PremiumService';
import Step from 'pages/pet-memorial-canvas/blt/components/Step';
import ContactUs from 'pages/pet-memorial-canvas/blt/components/ContactUs';

function BltPage() {
  const { setGnbType } = useLayout();

  const scrollRef = useRef([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [gnbHeight, setGnbHeight] = useState(0);

  useEffect(() => {
    scrollRef.current[tabIndex].scrollIntoView({ behavior: 'smooth' });
  }, [tabIndex]);

  useEffect(() => {
    setGnbType('sub_gnb');
    setGnbHeight(
      document.getElementById('remember_gnb')
        ? document.getElementById('remember_gnb').clientHeight
        : 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content_wrap bg_grey bg_type">
        <div className="content_inner">
          <h2 className="content_title">조문보</h2>
          <div className="premium_service">
            <div className="tab_area">
              <a
                href={() => false}
                style={{ cursor: 'pointer' }}
                onClick={() => setTabIndex(0)}
                className={`${tabIndex === 0 && 'on'}`}
              >
                조문보란
              </a>
              <a
                href={() => false}
                style={{ cursor: 'pointer' }}
                onClick={() => setTabIndex(1)}
                className={`${tabIndex === 1 && 'on'}`}
              >
                작성과정
              </a>
              <a
                href={() => false}
                style={{ cursor: 'pointer' }}
                onClick={() => setTabIndex(2)}
                className={`${tabIndex === 2 && 'on'}`}
              >
                프리미엄서비스
              </a>
              <a
                href={() => false}
                style={{ cursor: 'pointer' }}
                onClick={() => setTabIndex(3)}
                className={`${tabIndex === 3 && 'on'}`}
              >
                절차
              </a>
              <a
                href={() => false}
                style={{ cursor: 'pointer' }}
                onClick={() => setTabIndex(4)}
                className={`${tabIndex === 4 && 'on'}`}
              >
                신청
              </a>
            </div>
            <div className="section_wrap">
              <div
                ref={el => (scrollRef.current[0] = el)}
                style={{ position: 'absolute', top: 0 }}
              ></div>
              <AboutBlt />
              <div
                ref={el => (scrollRef.current[1] = el)}
                style={{ position: 'absolute', marginTop: gnbHeight * -1 }}
              ></div>
              <WritingProcess />
              <div
                ref={el => (scrollRef.current[2] = el)}
                style={{ position: 'absolute', marginTop: gnbHeight * -1 }}
              ></div>
              <PremiumService />
              <div
                ref={el => (scrollRef.current[3] = el)}
                style={{ position: 'absolute', marginTop: gnbHeight * -1 }}
              ></div>
              <Step />
              <div
                ref={el => (scrollRef.current[4] = el)}
                style={{ position: 'absolute', marginTop: gnbHeight * -1 }}
              ></div>
              <ContactUs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BltPage;

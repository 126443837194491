import { useEffect, forwardRef } from 'react';

import useLayout from 'stores/layout/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import { getTemplateBoardsApi } from 'apis/templateBoard';
import useDialog from 'stores/dialog/hook';
import { useState } from 'react';
let selectedHeader = null;

function FaqTemplateDialog({ isOpen, getSelectedQna, onClose }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const [templates, setTemplates] = useState();
  const [templateHeader, setTemplateHeader] = useState();

  const getTemplateBoards = async () => {
    let params = {};
    params.canvas_type = 'L';
    params.blt_type = '3';
    params.display_yn = 'Y';
    const result = await getTemplateBoardsApi(params);

    if (result.code === '0000') {
      setTemplates(result.data.sort((a, b) => a.order_num - b.order_num));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleHeader = header => {
    selectedHeader = header;
  };

  const handleSelected = () => {
    if (selectedHeader) {
      console.log('selectedHeader > ', selectedHeader);

      getSelectedQna(templates.filter(template => template.qna_type === selectedHeader));
    } else {
      alert('질문꾸러미를 선택하세요.');
    }
  };

  useEffect(() => {
    if (templates) {
      let headers = [];
      templates.map(template => headers.push(template.qna_type));
      headers = [...new Set(headers)];
      setTemplateHeader(headers);
    }
  }, [templates]);

  useEffect(() => {
    setGnbType('sub_gnb');
    getTemplateBoards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="false"
      fullWidth
    >
      <>
        <div className="wrap" id="lifeCanvas">
          <div className="modal" id="modal-template-01" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">질문꾸러미 선택하기</h2>
                  <p className="modal__desc">원하는 주제를 선택하세요.</p>
                  <button
                    className="modal__close"
                    aria-label="Close modal"
                    data-micromodal-close
                    onClick={onClose}
                  >
                    <i className="ic ic-close"></i>
                  </button>
                </div>
                <div className="modal__content">
                  {/* S : 질문꾸러미 리스트 */}
                  <ul className="template-list">
                    {templateHeader &&
                      templateHeader.length > 0 &&
                      templateHeader.map(header => (
                        <li key={header}>
                          <button type="button" onClick={() => handleHeader(header)}>
                            {header}
                          </button>
                        </li>
                      ))}
                  </ul>
                  {/* E : 질문꾸러미 리스트 */}
                </div>

                <div className="modal__footer">
                  <button
                    type="button"
                    className="btn btn-blue"
                    data-micromodal-close
                    onClick={() => handleSelected()}
                  >
                    선택
                  </button>
                  <button
                    type="button"
                    className="btn btn-white"
                    data-micromodal-close
                    onClick={onClose}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default FaqTemplateDialog;

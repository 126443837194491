import { useEffect, useState } from 'react';
import configs from '@configs/configs';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import useLayout from 'stores/layout/hook';

import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import copy from 'copy-to-clipboard';

import useDialog from 'stores/dialog/hook';

import SharedKakaoDialog from 'pages/pet-memorial-canvas/memorial/components/SharedKakaoDialog';
import SharedSmsDialog from 'pages/pet-memorial-canvas/memorial/components/SharedSmsDialog';
import useScript from '../../../../../utils/useScript';
import { getMessageTemplateViewApi } from '../../../../../apis/messageTemplate';

function SharedDialog({ isOpen, onClose, memorial }) {
  const { setGnbType } = useLayout();

  const { showAlert, hideAlert } = useDialog();
  const currentUrl = `${configs.BASEURL}/pet/memorial/view/${memorial.memorial_id}`;
  const [messageTemplateView, setMessageTemplateView] = useState([]);

  const [openKakaoDialog, setOpenKakaoDialog] = useState(false);
  const [openSmsDialog, setOpenSmsDialog] = useState(false);

  const [content, setContent] = useState('');

  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');

  const shreadSms = () => {
    if (isMobile) {
      if (isAndroid || isIOS) {
        setOpenSmsDialog(true);
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };

  const shreadLink = async () => {
    try {
      copy(currentUrl);
      showAlert(
        '링크복사가 완료되었습니다.',
        '공유하고 싶은 곳에 [붙여넣기] 하시면 \n 추모 공간을 공유할 수 있습니다.',
        () => hideAlert(),
      );
    } catch (error) {
      console.log('error > ', error);
      showAlert('알림', '링크복사에 실패하였습니다.', () => hideAlert());
    }
  };

  const shreadLineLink = () => {
    const sns_title = messageTemplateView[0].title;
    const sns_summary = content;
    const sns_br = '\n';
    const sns_link = currentUrl;
    const sns_img = memorial.deceased_photo ? memorial.deceased_photo : '';
    const url = 'http://line.me/R/msg/text/?' + encodeURIComponent(sns_summary + sns_br + sns_link);
    window.location.href = url;
  };

  const getMessageTemplateView = async params => {
    const result = await getMessageTemplateViewApi(params);

    setMessageTemplateView(result.result);
    setContent(result.result[0].contents.split('\r\n\r\n추모관 바로가기 : ', 1)[0]);
  };

  useEffect(() => {
    if (memorial.memorial_id) {
      getMessageTemplateView({ memorial_id: memorial.memorial_id, message_type: 'R' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorial]);

  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init('c38ef9667188c5012eda6562f4403007');
      }
    }
  }, [status]);

  useEffect(() => {
    setGnbType('sub_gnb');
  }, []);

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="false" fullWidth>
        <div className="wrap is-new" id="memorialCanvas">
          <div className="modal" id="modal-obituary-share" aria-hidden="true">
            <div className="modal__overlay" tabindex="-1" data-micromodal-close>
              <div
                className="modal__container"
                role="dialog"
                data-size="small"
                aria-modal="true"
                aria-labelledby="modal-agreement-title"
              >
                <div className="modal__header">
                  <h2 className="modal__title">추모관 전송</h2>
                  {/* 22-09-28 수정 : 팝업 설명 텍스트 위치 아래로 이동 */}
                  <button
                    className="modal__close js-modal-close"
                    aria-label="Close modal"
                    onClick={onClose}
                    data-micromodal-close
                  >
                    <i className="ic ic-close"></i>
                  </button>
                </div>
                <div className="modal__content">
                  {/* 22-09-28 수정 : 팝업 설명 텍스트 위치 수정 */}
                  <ul className="list-obituary-share">
                    {/* S : 22-09-28 아이콘 클래스 추가(ic-kakaotalk, ic-sms, ic-link) */}
                    <li>
                      <a href={() => false} onClick={() => shreadSms()}>
                        <span className="share-sms">
                          <i className="ic ic-share-sms"></i>
                        </span>
                        문자
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => setOpenKakaoDialog(true)}>
                        <span className="share-kakao">
                          <i className="ic ic-share-kakao"></i>
                        </span>
                        카카오톡
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => shreadLineLink()}>
                        <span className="share-line">
                          <i className="ic ic-share-line"></i>
                        </span>
                        라인
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => shreadLink()}>
                        <span className="share-link">
                          <i className="ic ic-share-link"></i>
                        </span>
                        링크 복사
                      </a>
                    </li>
                    {/* E : 22-09-28 아이콘 클래스 추가(ic-kakaotalk, ic-sms, ic-link) */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <SharedKakaoDialog
        isOpen={openKakaoDialog}
        onClose={() => setOpenKakaoDialog(false)}
        memorial={memorial}
      />
      <SharedSmsDialog
        isOpen={openSmsDialog}
        onClose={() => setOpenSmsDialog(false)}
        memorial={memorial}
      />
    </>
  );
}

export default SharedDialog;

import { Outlet } from 'react-router-dom';

import Gnb from 'layouts/main/Gnb/index';
import Footer from 'layouts/main/Footer';

// import 'css/life/common.css';

function MainLayout() {
  return (
    <>
      {/* S : WRAP */}
      <section className="wrap" id="landing">
        <div className="skip-nav-wrap">
          <a href="#gnbMenuWrap">메뉴 바로가기</a>
          <a href="#containerWrap">본문 바로가기</a>
        </div>
        {/* S: HEADER */}
        <Gnb />
        {/* E: HEADER */}

        {/* S: CONTENTS */}
        <Outlet />
        {/* E: CONTENTS */}

        {/* S: FOOTER */}
        <Footer />
        {/* E: FOOTER */}
      </section>
      {/* E : WRAP */}
    </>
  );
}

export default MainLayout;

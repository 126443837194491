import { useEffect, useState } from 'react';
import configs from '@configs/configs';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import useLayout from 'stores/layout/hook';

import useScript from 'utils/useScript';
import { getMessageTemplateViewApi } from 'apis/messageTemplate';

import useDialog from 'stores/dialog/hook';

import noDeceasedPhoto from 'css/static/media/user_no_portrait_obituary.png';
import copy from 'copy-to-clipboard';

function SharedKakaoDialog({ isOpen, onClose, memorial }) {
  const { setGnbType } = useLayout();

  const { showAlert, hideAlert } = useDialog();
  const { pathname } = useLocation();
  const currentUrl = `${configs.BASEURL}${pathname.replace('/board', '')}`;
  const [messageTemplateView, setMessageTemplateView] = useState([]);

  const [obType, setObType] = useState(1);

  const [content, setContent] = useState('');
  const [length, setLength] = useState(0);

  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');

  const shreadKakaoLink = () => {
    //0:이미지 부고, 1:글 부고
    if (obType === 0) {
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: messageTemplateView[0].title,
          // description: messageTemplateView[0]?.contents.split('\r\n임종일:', 1)[0],
          description: messageTemplateView[0]?.contents.substring(
            0,
            messageTemplateView[0].contents.indexOf('▶') - 1,
          ),
          //description: content,
          imageUrl: memorial.deceased_photo
            ? memorial.deceased_photo
            : 'https://www.remembered.co.kr/' + noDeceasedPhoto,
          link: {
            webUrl: currentUrl,
            mobileWebUrl: currentUrl,
          },
        },
        buttons: [
          {
            title: '부고 확인',
            link: {
              webUrl: currentUrl,
              mobileWebUrl: currentUrl,
            },
          },
        ],
      });
    } else if (obType === 1) {
      if (content.length > 200) {
        showAlert('알림', '200자 이상 입력할 수 없습니다.', () => hideAlert());
      } else {
        window.Kakao.Share.sendDefault({
          objectType: 'text',
          text: content,
          link: {
            webUrl: currentUrl,
            mobileWebUrl: currentUrl,
          },
          buttons: [
            {
              title: '부고 확인',
              link: {
                webUrl: currentUrl,
                mobileWebUrl: currentUrl,
              },
            },
          ],
        });
      }
    }
  };

  //운영 반영할땐 하드코딩 빼기
  const getMessageTemplateView = async params => {
    const result = await getMessageTemplateViewApi(params);

    setMessageTemplateView(result.result);
    setContent(
      result.result[0].title +
        '\n' +
        result.result[0].contents.substring(0, result.result[0].contents.indexOf('▶') - 1),
    );
    setLength(
      result.result[0].contents.substring(0, result.result[0].contents.indexOf('▶') - 1).length,
    );
    // // if (memorial.memorial_id === 'M00000003167') {
    // setContent(result.result[0].contents.substring(0, result.result[0].contents.indexOf('▶') - 1));
    // setLength(
    //   result.result[0].contents.substring(0, result.result[0].contents.indexOf('▶') - 1).length,
    // );
    // // } else {
    //    setContent(result.result[0].contents.split('\r\n부고 바로가기 :', 1)[0]);
    //    setLength(result.result[0].contents.length);
    // // }
  };

  const handleChangeContentMod = event => {
    if (event.target.value.length > 200) {
      showAlert('알림', '200자 이상 입력할 수 없습니다.', () => hideAlert());
      setLength(event.target.value.length);
    } else {
      setContent(event.target.value);
      setLength(event.target.value.length);
    }
  };

  const shreadContent = async () => {
    try {
      copy(content);
      showAlert(
        '부고 내용 복사가 완료되었습니다.',
        '공유하고 싶은 곳에 [붙여넣기] 하시면 \n 부고 내용을 공유할 수 있습니다.',
        () => hideAlert(),
      );
    } catch (error) {
      console.log('error > ', error);
      showAlert('알림', '부고 내용 복사에 실패하였습니다.', () => hideAlert());
    }
  };

  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init('c38ef9667188c5012eda6562f4403007');
      }
    }
  }, [status]);

  useEffect(() => {
    if (memorial.memorial_id) {
      getMessageTemplateView({ memorial_id: memorial.memorial_id, message_type: 'O' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorial]);

  useEffect(() => {
    if (!isOpen) {
      setObType(1);
    }
  }, [isOpen]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="false" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-share-kakao-text" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">카카오톡 전송</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <ul className="rc-list row">
                  <li>
                    <div className="rc-item">
                      <input
                        type="radio"
                        id="rdo-sample-22"
                        name="rdo-sample-2"
                        onClick={() => setObType(1)}
                        checked={obType === 1 ? true : false}
                      />
                      <label htmlFor="rdo-sample-22">글 부고</label>
                    </div>
                  </li>
                  <li>
                    <div className="rc-item">
                      <input
                        type="radio"
                        id="rdo-sample-21"
                        name="rdo-sample-2"
                        onClick={() => setObType(0)}
                        checked={obType === 0 ? true : false}
                      />
                      <label htmlFor="rdo-sample-21">이미지 부고</label>
                    </div>
                  </li>
                </ul>
                {obType === 1 && (
                  <>
                    <div className="ip-wrap">
                      <div className="ip-item">
                        <label htmlFor="text-tribute" className="offscreen">
                          소개글 입력
                        </label>
                        <textarea id="text-tribute" onChange={handleChangeContentMod}>
                          {content}
                        </textarea>
                        <div className="count-text">
                          <span>{length}</span> / 200자
                        </div>
                        <div className="btn-copy-wrap">
                          <button
                            type="button"
                            className="btn btn-sm-text"
                            onClick={() => shreadContent()}
                          >
                            <i className="ic ic-text-copy"></i>부고내용 복사
                          </button>
                        </div>
                      </div>
                    </div>
                    <p className="text-guide">최대 200자 까지 입력할 수 있습니다.</p>
                  </>
                )}
                {obType === 0 && (
                  <div className="shard-img">
                    <div className="inner-scroll">
                      <div className="box-preview">
                        <div className="img">
                          <img
                            src={
                              memorial.deceased_photo ? memorial.deceased_photo : noDeceasedPhoto
                            }
                            alt="카카오톡 미리보기 이미지"
                          />
                        </div>
                        <p className="title">{messageTemplateView[0]?.title}</p>
                        {/*운영 반영할땐 하드코딩 빼기*/}
                        <p className="text">
                          {' '}
                          {memorial.memorial_id === 'M00000001025'
                            ? '장봉애(張鳳愛)께서 영면하셨기에 삼가 알려드립니다.\n'
                            : messageTemplateView[0]?.contents.split('\r\n임종일:', 1)[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => shreadKakaoLink()}
                  data-micromodal-close
                >
                  보내기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SharedKakaoDialog;

import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';

import 'css/popup.css';

function WreathResultDialog({ isOpen, onClose, memorialId }) {
  const navigate = useNavigate();

  const handleClose = () => {
    // navigate(`/memorial/mypage/wreath/${memorialId}`);
    window.location.reload();
    onClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <div className="modal is-alert" id="modal-digital-wreath-01" aria-hidden="false">
        <div className="modal__overlay" tabindex="-1" data-micromodal-close>
          <div
            className="modal__container"
            role="dialog"
            data-size="small"
            aria-modal="true"
            aria-labelledby="modal-agreement-title"
          >
            <div className="modal__header">
              <h2 className="modal__title"></h2>
              <button
                className="modal__close js-modal-close"
                aria-label="Close modal"
                data-micromodal-close="true"
                onClick={onClose}
              >
                <i className="ic ic-close"></i>
              </button>
            </div>
            <div className="modal__content">
              <p>
                금액 정산 신청이 <br />
                완료되었습니다.
              </p>
            </div>
            <div className="modal__footer">
              <button
                type="button"
                className="btn btn-blue"
                data-micromodal-close="true"
                onClick={() => handleClose()}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default WreathResultDialog;

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import configs from '@configs/configs';

function KiccPayment() {
  const BASEURL = configs.BASEURL;
  const { id } = useParams();

  useEffect(() => {
    window.parent.location.href = `${BASEURL}/kicc/payment/result/${id}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default KiccPayment;

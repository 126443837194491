import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alarmCntState, setAlarmState } from './reducer';

export default function useCommon() {
  const alarmCnt = useSelector(alarmCntState);

  const dispatch = useDispatch();

  const setAlarmCnt = useCallback(
    alarmCnt => {
      dispatch(setAlarmState(alarmCnt));
    },
    [dispatch],
  );

  return {
    alarmCnt,
    setAlarmCnt,
  };
}

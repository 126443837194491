import { useRef, useEffect, forwardRef } from 'react';

import { useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useLayout from 'stores/layout/hook';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useDialog from 'stores/dialog/hook';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { getCanvasBltApi, modifyCanvasBltApi } from 'apis/canvasBlt';
import { useState } from 'react';
import AdminFaqModifyPage from 'pages/memorial-canvas/admin/faq/memorialModify';

function MyQnaDialog({ canvasId, isOpen, onClose, reflash }) {
  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();

  const [qna, setQna] = useState('');
  const [question, setQuestion] = useState();
  const submitRef = useRef();
  const navigate = useNavigate();

  const schema = Yup.object({
    canvas_id: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_type: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_title: Yup.string().required('* 필수 입력 항목입니다.'),
    display_yn: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_contents: Yup.string().required('* 필수 입력 항목입니다.'),
    member_id: Yup.string().required('* 필수 입력 항목입니다.'),
    faqs: Yup.array().of(
      Yup.object().shape({
        questions: Yup.string().required('* 질문을 입력하세요.'),
        answer: Yup.string().required('* 답변을 입력하세요.'),
      }),
    ),
  }).required();

  const defaultValues = {
    canvas_id: '',
    blt_type: '3',
    blt_title: '문답',
    display_yn: '',
    blt_contents: '',
    member_id: '',
  };

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'faqs',
  });

  const checkSubmit = () => {
    setValue('blt_contents', getValues('faqs') ? JSON.stringify(getValues('faqs')) : '');
    setValue('goalstatus', 'N');
    submitRef.current.click();
  };

  const onSubmit = data => {
    //alert('데이터 확인 한다 ' + JSON.stringify(data));
    modifyCanvasBlt(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const changeDisplayYn = value => {
    setValue('display_yn', value);
  };

  const successSaveCanvasBlt = () => {
    //hideLifeAlert();
    reflash();
    navigate('/life/canvas/my', { state: { searchBltType: '3' } });
  };

  const getCanvasBlt = async () => {
    let params = {};
    params.blt_id = canvasId;

    const result = await getCanvasBltApi(params);

    //alert('데이터' + JSON.stringify(result.data));
    //alert('qna확인' + JSON.stringify(result.data.blt_contents));
    if (result.code === '0000') {
      setQna(result.data);
      Object.entries(result.data).forEach(([key, value]) => {
        setValue(key, value);
        if (key === 'blt_contents') {
          setValue('faqs', JSON.parse(value));
        }
      });
      if (result.data?.blt_contents) {
        const obj = JSON.parse(result.data.blt_contents.replace(/(\n|\r\n)/g, '<br />'));
        setQuestion(obj);
      }
      //alert(JSON.stringify(question.questions));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const modifyCanvasBlt = async params => {
    const result = await modifyCanvasBltApi(params);

    if (result.code === '0000') {
      successSaveCanvasBlt();
      //showLifeAlert('알림', result.message, () => successSaveCanvasBlt());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  useEffect(() => {
    getCanvasBlt();
  }, []);

  useEffect(() => {
    setGnbType('sub_gnb');
    //alert('dd' + canvasId);
    //getCanvasBlt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   init();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loginUser]);

  return (
    <div className="modal" id="modal-qna-reply" aria-hidden="true">
      <div className="modal__overlay" tabindex="-1" data-micromodal-close>
        <div
          className="modal__container"
          role="dialog"
          data-size="small"
          aria-modal="true"
          aria-labelledby="modal-agreement-title"
        >
          <div className="modal__header">
            <h2 className="modal__title">문답</h2>
            <button
              className="modal__close js-modal-close"
              aria-label="Close modal"
              onClick={onClose}
              data-micromodal-close
            >
              <i className="ic ic-close"></i>
            </button>
          </div>
          {fields &&
            fields.length > 0 &&
            fields.map((content, index) => (
              <div className="modal__content" key={index}>
                {qna.blt_anonymous_yn === 'N' ? (
                  <p className="qna-questioner">{qna.member_name}님 질문에 대한 답변</p>
                ) : (
                  <p className="qna-questioner">{qna.goalcomment} 익명 질문에 대한 답변</p>
                )}
                {/* {question &&
              question.length > 0 &&
              question.map((qna, index) => <p className="qna-title">{qna.questions}</p>)}
               */}
                <Controller
                  control={control}
                  name={`faqs.${index}.questions`}
                  render={({ field }) => <p className="qna-title">{field.value}</p>}
                />
                <div className="write-form">
                  <div className="box-editor">
                    <label for="textarea-01" className="offscreen">
                      답변 내용 입력
                    </label>
                    <Controller
                      control={control}
                      name={`faqs.${index}.answer`}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          id="textarea-01"
                          rows="5"
                          cols="3"
                          placeholder="답변을 입력해주세요"
                        />
                      )}
                    />
                  </div>
                  <div className="editor-util">
                    <div className="select-public-type">
                      <label for="sel-public" className="offscreen">
                        공개 범위 선택
                      </label>
                      <select id="sel-public" onChange={e => changeDisplayYn(e.target.value)}>
                        <option value="Y">전체 공개</option>
                        <option value="S">구독자 공개</option>
                        <option value="N">비공개</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <div className="modal__footer">
            <button type="button" className="btn btn-white" onClick={onClose} data-micromodal-close>
              취소
            </button>
            <button
              type="button"
              className="btn btn-blue btn-qna-reply-confirm"
              onClick={() => checkSubmit()}
            >
              올리기
            </button>
            <button
              ref={submitRef}
              type="button"
              className="btn btn-green"
              onClick={handleSubmit(onSubmit, onError)}
              style={{ display: 'none' }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyQnaDialog;

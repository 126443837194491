import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { getMemorialsApi } from 'apis/memorial';

import useLayout from 'stores/layout/hook';
import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

import ContentLoader from 'react-content-loader';
import Pagination from 'react-js-pagination';

import { useInView } from 'react-intersection-observer';

import noDeceasedPhoto from 'css/static/media/user_no_portrait_obituary.png';
import noDeceasedPhoto1 from 'css/static/media/user_no_portrait_rememberance.png';

import 'css/pagination.css';
import loading_test from '../../../css/static/media/loading.gif';

function ObituaryPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { showAlert, hideAlert } = useDialog();
  const { setGnbType } = useLayout();
  const { loginUser, setOpenLogin } = useLogin();

  const [loading, setLoading] = useState(true);

  const [searchObituarys, setSearchObituarys] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(8);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  //페이징 처리
  const [ref, inView] = useInView();

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleSearchObituarys = () => {
    getSearchObituarys(1);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getSearchObituarys(selectedPage);
  };

  // const handleMore = pageNum => {
  //   const nextPage = pageNum + 1;
  //   if (nextPage > totalPage) {
  //     showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
  //   } else {
  //     setPage(nextPage);
  //     getSearchObituarysMore(nextPage);
  //   }
  // };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      if (page === 1) {
        return;
      }
      // showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getSearchObituarysMore(nextPage);
    }
  };

  const getSearchObituarys = async pageNum => {
    let params = {};
    params.status = 1;
    params.multy_type = 'Y';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getMemorialsApi(params);
    if (result.code === '0000') {
      setSearchObituarys(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert);
    }
  };

  const getSearchObituarysMore = async pageNum => {
    let params = {};
    params.status = 1;
    params.multy_type = 'Y';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    setLoading(true);
    const result = await getMemorialsApi(params);
    if (result.code === '0000') {
      setSearchObituarys([...searchObituarys, ...result.data]);
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert);
    }
    setLoading(false);
  };

  const handleRegister = () => {
    //alert('확인하려구 ' + JSON.stringify(loginUser));
    if (loginUser && loginUser.member_id) {
      console.log(JSON.stringify(loginUser));
      navigate('/pet/obituary/register');
    } else {
      navigate('/pet/login');
    }
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    getSearchObituarys(1);
    setSearchValue('');

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && !loading) {
      handleMore(page);
    }
  }, [inView, loading]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">부고</h2>
            <p>
              사랑하는 사람의 부음을 문자 및 단체 알림, SNS 메시지로
              <br class="d-md-block d-none" /> 발송 대행하고 <br class="d-block d-md-none" />
              장례 의식 공지 등의 서비스를 제공합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          {/* S : 목록 상단 */}
          {/* S : 23-04-21 상단 검색영역 수정 */}
          {/* S : 목록 상단 */}
          <div className="list-head is-between">
            {/* S : 글 갯수 */}
            <div className="list-head-left">
              펫 캔버스에 아이들이 모여
              <br />
              <em>{totalCount}개의 별</em>을 만들었습니다.
            </div>
            {/* E : 글 갯수 */}
            {/* S : 검색 */}
            <div className="list-head-right">
              {/* S : 목록 검색 */}
              <div className="box-search">
                <label for="ip-search" className="offscreen">
                  검색하기
                </label>
                <input
                  type="search"
                  id="ip-search"
                  placeholder="반려동물의 이름을 입력하세요"
                  value={searchValue}
                  onChange={changeSearchValue}
                  onKeyPress={onKeyPress}
                />
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  ref={searchRef}
                  onClick={handleSearchObituarys}
                >
                  <i className="ic ic-search"></i>
                  <span className="offscreen">검색하기</span>
                </button>
              </div>
              {/* E : 목록 검색 */}
              {/* S : 버튼 */}
              <a href={() => false} className="btn btn-pos" onClick={handleRegister}>
                부고 등록
              </a>
              {/* E : 버튼 */}
            </div>
            {/* E : 검색 */}
          </div>
          {/* E : 목록 상단 */}
          {/* E : 23-04-21 상단 검색영역 수정 */}
          {/* E : 목록 상단 */}

          {/* S : 부고 목록 */}

          <div className="wrap-memorial">
            <div className="list-memorial">
              {searchObituarys &&
                searchObituarys.length > 0 &&
                searchObituarys.map(obituary => (
                  <div className="box-memorial" key={obituary.memorial_id}>
                    <Link to={`/pet/obituary/view/${obituary.memorial_id}`}>
                      <div className="img">
                        <span className="chk-size">
                          <img
                            alt=""
                            src={
                              obituary.deceased_photo
                                ? obituary.deceased_photo
                                : obituary.memorial_id.substring(
                                    obituary.memorial_id.length - 1,
                                    obituary.memorial_id.length,
                                  ) %
                                    2 ===
                                  0
                                ? noDeceasedPhoto
                                : noDeceasedPhoto1
                            }
                          />
                        </span>
                      </div>
                      <div className="info">
                        <p className="name">{`${obituary.deceased_name} ${obituary.deceased_job_title}`}</p>
                        {obituary.deceased_birthday === '' || obituary.deceased_birthday === ' ' ? (
                          <span className="date"> &nbsp; </span>
                        ) : (
                          <span className="date">{`${obituary.deceased_birthday} ~ ${obituary.deceased_date}`}</span>
                        )}
                      </div>
                      <div className="btn-wrap">
                        <a href={() => false} className="btn btn-orange">
                          조문하기
                        </a>
                      </div>
                      <div className="info obituary">
                        <dl>
                          {/* {obituary.chief_mourner !== '' || obituary.chief_mourner_desc !== ' ' ? (
                            <>
                              <dt>상주</dt>
                              <dd>{`${
                                obituary.chief_mourner_desc ? obituary.chief_mourner_desc : ''
                              } ${obituary.chief_mourner ? obituary.chief_mourner : ''}`}</dd>
                            </>
                          ) : (
                            ''
                          )} */}
                          {obituary.chief_mourner !== '' || obituary.chief_mourner_desc !== '' ? (
                            <>
                              <dt>보호자</dt>
                              <dd>
                                {obituary.chief_mourner_desc
                                  ? `${obituary.chief_mourner_desc}`
                                  : ''}{' '}
                                {obituary.chief_mourner ? `${obituary.chief_mourner} ` : ''}
                              </dd>
                            </>
                          ) : (
                            ''
                          )}
                          {/*발인 비공개 목록 수정 0508*/}
                          {/*{obituary.birth_yn === 'Y' ? (*/}
                          {/*  <>*/}
                          {/*    <dt>발인</dt>*/}
                          {/*    <dd>{`${*/}
                          {/*      obituary.birth_date || obituary.birth_date !== ''*/}
                          {/*        ? obituary.birth_date*/}
                          {/*        : ''*/}
                          {/*    } ${*/}
                          {/*      obituary.birth_hour || obituary.birth_hour !== ''*/}
                          {/*        ? obituary.birth_hour*/}
                          {/*        : ''*/}
                          {/*    }`}</dd>*/}
                          {/*  </>*/}
                          {/*) : obituary.birth_yn === 'N' ? (*/}
                          {/*  <>*/}
                          {/*    <dt>발인</dt>*/}
                          {/*    <dd>비공개</dd>*/}
                          {/*  </>*/}
                          {/*) : (*/}
                          {/*  ''*/}
                          {/*)}*/}
                          {/*{obituary.funeral_addr_yn === 'Y' ? (*/}
                          {/*  <>*/}
                          {/*    <dt>빈소 </dt>*/}
                          {/*    <dd>*/}
                          {/*      {obituary.funeral_addr_view ? obituary.funeral_addr_view : '비공개'}*/}
                          {/*    </dd>*/}
                          {/*  </>*/}
                          {/*) : (*/}
                          {/*  ''*/}
                          {/*)}*/}
                        </dl>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>

          <div className="btn-wrap" ref={ref}>
            {/*<button type="button" className="btn btn-more" onClick={() => handleMore(page)}>*/}
            {/*  더보기 {page}/{totalPage}*/}
            {/*</button>*/}
            {loading === true ? <img src={loading_test} alt="" /> : ''}
          </div>

          {/* E : 부고 목록 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default ObituaryPage;

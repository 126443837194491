import { useNavigate, useParams } from 'react-router-dom';
import useDialog from '../../../stores/dialog/hook';
import useLogin from '../../../stores/login/hook';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import {
  getPetBltCommunityApi,
  modifyPetBltCommunityApi,
  savePetBltCommunityApi,
} from '../../../apis/petBlt';
import { uploadApi, uploadImageApi } from '../../../apis/openapi';
import { useDropzone } from 'react-dropzone';
import Progress from '../../../components/mui/Progress';
import QuillEditor from '../../../components/editor/QuillEditor';

let imageCount = 0;

function StoryModify() {
  const navigate = useNavigate();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { loginUser } = useLogin();
  const { bltId } = useParams();

  const schema = Yup.object({
    blt_title: Yup.string().required('* 제목을 입력하세요.'),
    // .max(10, '* 최대 입력은 10자까지 가능합니다.'),
  }).required();

  const defaultValues = {
    blt_title: '',
    member_name: '',
    blt_contents: '',
    media_files: [],
  };
  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const imageMaxCount = 5;
  const imageMaxSize = 5 * 1024 * 1024;
  const imageTypeCode = 'I';
  const [thumbnail, setThumbnail] = useState('');
  const [displayProgressBar, setdisplayProgressBar] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [media, setMedia] = useState([]);

  //데이터 불러오기
  const [bltTitle, setBltTitle] = useState(null);
  const [name, setName] = useState(null);
  const [bltContent, setBltContent] = useState(null);
  const [thumbnailLink, setThumbnailLink] = useState(null);

  const [petBlt, setPetBlt] = useState('');
  const [contents, setContents] = useState(null);

  const getPetBlt = async params => {
    const result = await getPetBltCommunityApi(params);
    setPetBlt(result.data);
  };

  const modifyPetBlt = async params => {
    params.blt_type = '1';
    params.member_id = loginUser.member_id;
    const result = await modifyPetBltCommunityApi(params);
    if (result.type === 'success') {
      showAlert('알림', result.message, () => successSavePet());
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const successSavePet = petId => {
    hideAlert();
    navigate('/pet/community');
  };

  // const addMedia = media => {
  //   setMedias(prevState => [...prevState, media]);
  // };
  const changeImageFile = event => {
    const { files } = event.target;
    uploadImageFile(files[0]);
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);

    setThumbnailLink(result.uploadUrl);
    setValue('thumbnail_link', result.uploadUrl);
  };

  const removeImageFile = () => {
    hideConfirm();
    setThumbnailLink(null);
    setValue('thumbnail_link', '');
  };

  const onDrop = async acceptedFiles => {
    for (const file of acceptedFiles) {
      await uploadMediaFile(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/*'],
  });

  const uploadMediaFile = async file => {
    // if (!loginUser.member_id) {
    //   handleOpenLoginDialog();
    //   return;
    // }
    if (file.type.indexOf('image/') > -1) {
      if (file.size > imageMaxSize) {
        showAlert('알림', '사진 최대 용량은 5MB를 초과할 수 없습니다.', () => hideAlert());

        return;
      } else if (imageCount >= imageMaxCount) {
        showAlert('알림', '사진은 최대 5개까지만 등록 가능합니다.', () => hideAlert());

        return;
      }
      await uploadImageAttachFile(file);
      return;
    }
  };

  const uploadPercentage = value => {
    console.log('uploadPercentage > ', value);
    setProgressBarPercentage(value);
  };

  const uploadImageAttachFile = async file => {
    setdisplayProgressBar(true);
    const result = await uploadImageApi(file, uploadPercentage);

    console.log('result > ', result);

    if (result.code === '0000') {
      setValue('media_files', [
        ...getValues('media_files'),
        {
          media_type: 'I',
          thumbnail_link: result.thumnailUrl,
          media_link: result.uploadUrl,
        },
      ]);

      // imageCount = imageCount + 1;
    } else {
      showAlert('알림', '파일 업로드 중 오류가 발생하였습니다.', () => hideAlert());
    }
    setProgressBarPercentage(0);
    setdisplayProgressBar(false);
  };

  // const handleRemoveMedia = (index, mediaType) => {
  //   imageCount--;
  //   removeMedia(index);
  // };

  // const removeMedia = index => {
  //   medias.splice(index, 1);
  //   setMedias([...medias]);
  // };

  const changeMediaFile = async event => {
    const { files } = event.target;

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const size = getValues('media_files').length;
        console.log('size > ', size);
        if (size > 5) {
          showAlert('알림', '이미지는 5개까지 등록 가능합니다.', () => hideAlert());
          break;
        }
        await uploadMediaFile(files[i]);
      }
      setMediaFiles(getValues('media_files'));
    }
  };

  const removeMediaFile = index => {
    mediaFiles.splice(index, 1);
    setValue('media_files', [...mediaFiles]);
    setMediaFiles([...mediaFiles]);
  };

  const handleClose = () => {
    navigate('/pet/community');
  };

  const changeContents = value => {
    setContents(value);
    setValue('blt_contents', value);
  };

  const onSubmit = data => {
    modifyPetBlt(data);
  };

  const onError = error => {
    showAlert('알림', '잘못된 입력된 정보가 존재합니다. 입력값을 확인하세요.', () => hideAlert());
    console.log('error > ', error);
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }

    if (loginUser && loginUser.member_id) {
      getPetBlt(bltId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    if (petBlt) {
      Object.entries(petBlt).forEach(([key, value]) => {
        setValue(key, value);
        if (key === 'media_files') setMediaFiles(value);
      });

      setBltTitle(petBlt.blt_title);
      setName(petBlt.member_name);
      setContents(petBlt.blt_contents);
      setThumbnailLink(petBlt.thumbnail_link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petBlt]);

  // useEffect(() => {
  //   if (medias.length === 0) {
  //     imageCount = 0;
  //   }
  //   setValue('media_files', medias);
  // }, [medias]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">캔버스 스토리</h2>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          {/* S : 추모관 등록 */}
          <div className="wrap-form">
            {/* S : 고인정보 */}
            <div className="contents-form">
              {/* S : 제목 */}
              <div className="form-group">
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label for="ip-deceased-name">
                      제목{' '}
                      <span className="required">
                        *<span className="offscreen">필수입력</span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id="ip-deceased-name"
                      placeholder="제목을 작성하세요"
                      aria-invalid={errors.blt_title ? 'true' : 'false'}
                      {...register('blt_title')}
                    />
                    {errors.blt_title && <p className="message">{errors.blt_title.message}</p>}
                  </div>
                </div>
              </div>
              {/* E : 제목 */}
              {/* S : 작성자 */}
              <div className="form-group">
                <div className="ip-wrap">
                  <div className="ip-item">
                    <label for="ip-deceased-name">작성자</label>
                    <input
                      type="text"
                      id="ip-deceased-name"
                      placeholder="작성자"
                      {...register('member_name')}
                    />
                  </div>
                </div>
              </div>
              {/* E : 작성자 */}

              {/* S : 내용 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>내용</h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label for="text-tribute" className="offscreen">
                      내용 입력
                    </label>
                    <QuillEditor
                      // {...register('blt_contents')}
                      aria-invalid={errors.blt_contents ? 'true' : 'false'}
                      type="text"
                      id="contents"
                      className="input"
                      value={contents}
                      onChange={changeContents}
                      style={{ width: '100%' }}
                    />
                    {/*<textarea*/}
                    {/*  id="text-tribute"*/}
                    {/*  placeholder="내용입력"*/}
                    {/*  {...register('blt_contents')}*/}
                    {/*></textarea>*/}
                  </div>
                </div>
              </div>
              {/* E : 내용 */}

              {/* S : 대표사진 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>대표사진</h5>
                </div>

                {/* S : 파일첨부 영역 */}
                <div className="ip-wrap ip-file-wrap">
                  <div className="ip-item">
                    <label className="file" for="imageFile">
                      {/* for 값과 관련하여 css 설정되어 있으니 변경시 반드시 공지해주세요 */}
                      <span
                        className="offscreen"
                        onClick={() => document.getElementById('imageFile').click()}
                      >
                        파일선택
                      </span>
                      여기를 클릭하여 사진을 등록할 수 있습니다.
                      <br />* 이미지 파일만 등록할 수 있습니다.
                    </label>
                    <input
                      type="file"
                      className="offscreen"
                      id="imageFile"
                      onChange={changeImageFile}
                    />
                  </div>
                  {/* S : 첨부 이미지 등록 후 */}
                  {thumbnailLink ? (
                    <div className="file-output-wrap">
                      <div className="file-output">
                        <div className="file-area is-image">
                          <img alt="" src={thumbnailLink} />
                        </div>
                        <button className="file-del" onClick={removeImageFile}>
                          <span className="offscreen">파일 삭제</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="file_output default" onChange={changeImageFile}></div>
                  )}
                  {/* E : 첨부 이미지 등록 후 */}
                </div>
                {/* E : 파일첨부 영역 */}
              </div>
              {/* E : 대표사진 */}
              {/* S : 첨부파일 */}

              <div className="form-group">
                <div className="box-stitle">
                  <h5>첨부파일</h5>
                </div>

                {/* S : 파일첨부 영역 */}
                <div className="ip-wrap ip-file-wrap">
                  <div className="ip-item">
                    <label className="file" for="imageFile2">
                      {/* for 값과 관련하여 css 설정되어 있으니 변경시 반드시 공지해주세요 */}
                      <span className="offscreen">파일선택</span>
                      여기를 클릭하여 파일을 등록할 수 있습니다.
                      <br />* 첨부파일은 5개까지 등록 할 수 있으며 최대 20MB까지 등록 가능합니다.
                    </label>
                    <input
                      type="file"
                      accept="image/jpg, image/jpeg, image/png"
                      className="offscreen"
                      id="imageFile2"
                      onChange={changeMediaFile}
                    />
                  </div>
                  {displayProgressBar ? <Progress value={progressBarPercentage} /> : null}
                  {/* S : 첨부파일 등록 후 */}
                  {mediaFiles && mediaFiles.length > 0 ? (
                    <div className="file-output-wrap">
                      {mediaFiles.map((media, index) => (
                        <ul>
                          <li key={media.thumbnail_link}>
                            이미지 {index}
                            <button type="button" onClick={() => removeMediaFile(index)}>
                              <span className="offscreen">삭제</span>
                            </button>
                          </li>
                        </ul>
                      ))}
                    </div>
                  ) : null}
                  {/* E : 첨부파일 등록 후 */}
                </div>
                {/* E : 파일첨부 영역 */}
              </div>
              {/* E : 첨부파일 */}
            </div>
            {/* E : 고인정보 */}
            {/* S : 버튼 */}
            <div className="btn-wrap">
              <button type="button" className="btn btn-ln-gray" onClick={handleClose}>
                취소
              </button>
              <button
                type="button"
                className="btn btn-navy"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록
              </button>
            </div>
            {/* E : 버튼 */}
            {/* E : 23-04-03 메모리얼 캔버스 개편에 따른 마크업 수정 */}
          </div>
          {/* E : 추모관 등록 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default StoryModify;

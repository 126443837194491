import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useLogin from 'stores/login/hook';

function LoginKakao() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { kakaoLogin } = useLogin();

  const loginKakao = async token => {
    const result = await kakaoLogin(token);
    console.log('result > ', result);
    navigate('/');
  };

  const getKakaoToken = () => {
    if (!search) return;
    const token = search.split('=')[1].split('&')[0];
    loginKakao(token);
  };

  useEffect(() => {
    getKakaoToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default LoginKakao;

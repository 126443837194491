import { useNavigate } from 'react-router-dom';

import imgIntro01 from 'css/static/media/img_about_footprint_01.png';

function ServiceAuthorPage({ setMode }) {
  const navigate = useNavigate();

  return (
    <section className="container is-about" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        <div className="contents-width">
          <h3>펫 메모리얼 캔버스 소개</h3>

          <div className="tabs tabs-about">
            <ul>
              <li>
                <a href={() => false} onClick={() => setMode('memorial')}>
                  펫 메모리얼 캔버스란?
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => setMode('premium')}>
                  장례 안내
                </a>
              </li>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('author')}>
                  작가 안내
                </a>
              </li>
            </ul>
          </div>

          <div className="box-title">
            <h4 className="title">작가 서비스는</h4>
            <p className="desc lg">
              함께했던 순간을 아름답게 간직하기 위해 <br className="d-md-block d-none" />
              <em>행복했던 추억을 정리한 글입니다.</em>
            </p>
          </div>

          <div className="wrap-service">
            <div className="box-service-info">
              <div className="box-img">
                <img src={imgIntro01} />
              </div>
              <div className="box-text">
                <p className="title">프리미엄 작가 서비스는</p>
                <p className="desc">
                  우리의 추억을 더 깊이 있게, 의미 있게 담아내고자 할 경우
                  <br />
                  에버영피플의 전문작가의 도움을 받아 제작하는 서비스입니다.
                </p>
                <p className="desc">
                  그반려동물은 우리에게 향기로운 추억을 선물하고,
                  <br />
                  우리 삶에 따뜻함을 불어넣었습니다.
                  <br />
                  그 기억을 하나하나 풀어내며 우리의 아름다운 이야기를 다시 쓰고
                  <br />
                  소중하게 간직할 수 있습니다.
                </p>
                <div className="btn-wrap">
                  <a
                    href={() => false}
                    className="btn btn-pos"
                    onClick={() => navigate('/pet/service/premium')}
                  >
                    신청하기
                  </a>
                </div>
              </div>
            </div>

            <div className="wrap-process">
              <h5>제작방법</h5>
              <p className="desc">인터뷰와 질문지를 기반으로 전문 작가가 제작합니다.</p>

              <div className="contents-process">
                <div className="box-process">
                  <div className="row order-1">
                    <div className="box-circle">
                      <div className="inner">고객상담</div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">작가 배정</div>
                    </div>
                  </div>
                  <div className="row order-2 has-circle">
                    <div className="box-circle">
                      <div className="inner">작가 인터뷰</div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">발자국 제작</div>
                    </div>
                    <div className="box-circle">
                      <div className="inner">결과물 완성</div>
                    </div>
                  </div>
                </div>

                <div className="list-process">
                  <div className="row order-1">
                    <div className="box-process-guide">
                      <p>1. 고객 상담</p>
                      <ul>
                        <li>- 신청 버튼을 클릭하여 상담 신청 양식을 제출합니다.</li>
                        <li>- 입력하신 E-mail로 발자국 작성 준비물을 안내 받습니다.</li>
                      </ul>
                    </div>
                    <div className="box-process-guide">
                      <p>2. 작가 배정</p>
                      <ul>
                        <li>- 발자국 전문 작가를 배정받습니다.</li>
                        <li>- 발자국 작성에 필요한 질문 답변지와 사진을 전달합니다.</li>
                      </ul>
                    </div>
                  </div>
                  <div className="row order-2">
                    <div className="box-process-guide">
                      <p>3. 작가 인터뷰</p>
                      <ul>
                        <li>- 전화/줌/대면인터뷰를 진행하며 발자국 내용을 구체화합니다.</li>
                      </ul>
                    </div>
                    <div className="box-process-guide is-footprint">
                      <p>4. 발자국 제작</p>
                      <ul>
                        <li>- 발자국 주제, 핵심어를 고객과 협의하고 사진과 글을 구성합니다.</li>
                        <li>- 초안 검토 후 2회 수정 가능합니다.</li>
                      </ul>
                    </div>
                    <div className="box-process-guide">
                      <p>5. 결과물 완성</p>
                      <ul>
                        <li>- 메모리얼캔버스에 완성된 발자국이 게시됩니다.</li>
                        <li>- 소책자 3부가 컬러 인쇄로 제공됩니다.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrap-service-info">
              <dl>
                <dt>회사명</dt>
                <dd>(주)에버영피플</dd>
                <dt>상담 시간</dt>
                <dd>월요일 ~ 금요일 08:00 ~ 15:00</dd>
                <dt>빠른 상담</dt>
                <dd>02-2138-8381. 010-5483-1965</dd>
                <dt>이메일</dt>
                <dd>kyunghyukchang@everyoungpeople.com</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default ServiceAuthorPage;

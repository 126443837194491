import { useState, useEffect, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';

import { getWreathsApi } from 'apis/wreath';

import { timestampToDate } from 'utils/date';

import imgSlider01 from 'assets/images/wreath_basic.jpg';
import imgSlider02 from 'assets/images/wreath_highend.jpg';
import imgSlider03 from 'assets/images/wreath_premium.jpg';

import 'swiper/css';
import 'swiper/css/pagination';
import 'css/swiper.min.css';

function WreathContent({ memorialId, message }) {
  const [wreaths, setWreaths] = useState(null);
  const wreathElement = useRef(null);

  const getWreaths = async params => {
    const result = await getWreathsApi(params);
    setWreaths(result.data);
  };

  useEffect(() => {
    getWreaths({
      memorial_id: memorialId,
      status: 1,
      orderBy: { direction: 'desc', field: 'id' },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message === 'wreath-complete') {
      wreathElement.current?.scrollIntoView({ block: 'center' });
    }
  });

  return (
    <>
      {wreaths && wreaths.length > 0 ? (
        <div className="wrap-digital-wreath contents-width" ref={wreathElement}>
          <div className="box-title">
            <h3 className="title">디지털 화환 해주신 분</h3>
            <p className="desc">소중한 마음들이 모여 아이가 떠나는 길에 위로의 마음을 전합니다.</p>
          </div>
          <div className="wreath-swiper">
            <div className="swiper-asset">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={16}
              slidesPerView={3}
              centeredSlides={false}
              //loop={wreaths.length < 3 ? false : true}
              // autoHeight={true}
              simulateTouch={true}
              autoplay={{ delay: 4000 }}
              navigation={{
                nextEl: '.wreath-swiper .swiper-asset .swiper-button-next',
                prevEl: '.wreath-swiper .swiper-asset .swiper-button-prev',
              }}
              // pagination={{ el: '.wreath-swiper .swiper-asset', clickable: true }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 11,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 22,
                },
                1440: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
            >
              <div className="swiper-wrapper ">
                {wreaths.map(wreath => (
                  <SwiperSlide className="wreath-item" key={wreath.id}>
                    {wreath.type === 'N' && (
                      <>
                        <div
                          className={
                            wreath.id % 2 === 0 ? 'box-wreath type-01' : 'box-wreath type-03'
                          }
                        >
                          {/* 국화 1송이 */}
                          <p>{wreath.message}</p>
                          {/* 1송이
                            <br />
                            삼가 고인의
                            <br />
                            명복을 빕니다. */}

                          <p className="info">{wreath.title}</p>
                          <p className="info">{timestampToDate(wreath.reqdtime)}</p>
                        </div>
                      </>
                    )}
                    {wreath.type === 'R' && (
                      <>
                        <div className="box-wreath type-02">
                          {/* 국화 3송이 */}
                          <p> {wreath.message}</p>
                          {/* 3송이
                            <br />
                            삼가 고인의
                            <br />
                            명복을 빕니다. */}

                          <p className="info">{wreath.title}</p>
                          <p className="info">{timestampToDate(wreath.reqdtime)}</p>
                        </div>
                      </>
                    )}
                    {wreath.type === 'P' && (
                      <>
                        <div
                          className={
                            wreath.id % 2 === 0 ? 'box-wreath type-01' : 'box-wreath type-03'
                          }
                        >
                          {/* 근조화환 */}
                          <p>{wreath.message}</p>
                          {/* 근조 화환
                            <br />
                            삼가 고인의
                            <br />
                            명복을 빕니다. */}

                          <p className="info">{wreath.title}</p>
                          <p className="info">{timestampToDate(wreath.reqdtime)}</p>
                        </div>
                      </>
                    )}
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      ) : (
        ' '
      )}
    </>
  );
}

export default WreathContent;

import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getMemorialsApi } from 'apis/memorial';

import ContentLoader from 'react-content-loader';

import noDeceasedPhoto from 'css/static/media/user_no_portrait_obituary.png';

import 'swiper/css';
import 'css/swiper.min.css';
import 'css/swiper.custom.css';
import 'css/custom.css';

function IntroSection() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [memorials, setMemorials] = useState(null);

  const getMemorials = async params => {
    const result = await getMemorialsApi(params);
    if (result.code === '0000') setMemorials(result.data.length > 0 ? result.data : null);
    else setMemorials(null);

    setLoading(false);
  };

  const handleApply = () => {
    navigate('/memorial/blt');
  };

  const handleMore = () => {
    navigate('/memorial/memorial');
  };

  useEffect(() => {
    getMemorials({
      status: 2,
      multy_type: 'Y',
      display_yn: 'Y',
      orderBy: { direction: 'desc', field: 'memorial_id' },
      page: 1,
      itemInPage: 10,
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <ContentLoader />
      ) : memorials && memorials.length > 0 ? (
        <div className="intro_section">
          <h2 className="title">추모관</h2>
          <p className="txt">
            고인의 추모관을 방문해 고인에 대한 그리운 마음과
            <br />
            생전에 하지 못한 이야기를 나누세요.
          </p>
          <a
            href={() => false}
            style={{ cursor: 'pointer' }}
            className="main_btn"
            onClick={handleApply}
          >
            신청하기
          </a>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Autoplay]}
            slidesPerView={1.2}
            spaceBetween={20}
            centeredSlides={false}
            loop={true}
            autoplay={{ delay: 2000 }}
            autoHeight={true}
            simulateTouch={true}
            navigation={{
              nextEl: ' .swiper-button-next',
              prevEl: ' .swiper-button-prev',
            }}
            breakpoints={{
              760: {
                slidesPerView: 3,
                spaceBetween: 50,
                centeredSlides: true,
                simulateTouch: false,
              },
            }}
            className="intro_slide"
          >
            <div className="swiper-wrapper">
              {memorials.map(memorial => (
                <SwiperSlide className="swiper-slide" key={memorial.memorial_id}>
                  <Link to={`/memorial/memorial/view/${memorial.memorial_id}`}>
                    <span className="img_area">
                      <img
                        alt=""
                        src={memorial.deceased_photo ? memorial.deceased_photo : noDeceasedPhoto}
                      />
                    </span>
                    <p className="text">{memorial.symbol_comment}</p>
                    <div className="info">
                      <em>{`${memorial.deceased_name} ${memorial.deceased_job_title}`}</em>
                      {`${memorial.deceased_birthday} ~  ${memorial.deceased_date}`}
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
          <div className="more_btn_area">
            <button className="more_btn" onClick={handleMore}>
              더보기
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default IntroSection;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogin from 'stores/login/hook';
import useCommon from 'stores/common/hook';

function Gnb() {
  const navigate = useNavigate();
  const { loginUser } = useLogin();
  const { alarmCnt } = useCommon();
  const userInfo = JSON.parse(localStorage.getItem('loginUser'));

  const breakPoint = window.matchMedia('(max-width: 1024px)');

  const [isActive, setActive] = useState(false);

  const moveMenu = url => {
    setActive(false);
    navigate(url);
  };

  return (
    <>
      <header
        className={`is-lifecanvas ${loginUser?.member_id ? 'is-member' : ''} ${
          isActive ? (breakPoint.matches ? 'is-open' : 'is-open is-active') : ''
        }`}
      >
        <div className="contents-width">
          <h1>
            <a
              href={() => false}
              onClick={() => {
                moveMenu('/life');
              }}
            >
              <span>LIFE CANVAS</span>
            </a>
          </h1>

          {loginUser?.member_id ? (
            <>
              {/* S : 로그인 후 메뉴 */}
              {/* 로그인 하지 않았을 때엔 이 영역 전체가 보이지 않아야 합니다 */}

              <div className="header-util">
                <div class="box-user">
                  <div class="user-profile">
                    <p>
                      {`${userInfo?.member_name} 님`}
                      <a
                        href={() => false}
                        onClick={() => {
                          moveMenu('/life/settings/member');
                        }}
                      >
                        <i class="ic ic-setting"></i>
                        <span class="offscreen">설정</span>
                      </a>
                    </p>
                    {/* <div class="user-name">{loginUser.canvas_title}님</div> */}
                    <p class="desc">
                      오늘은 어떤 기록을
                      <br />
                      남기실 건가요?
                    </p>
                    <div
                      class="user-thumb"
                      onClick={() => moveMenu('/life/canvas/my')}
                      style={{ cursor: 'pointer' }}
                    >
                      <span class="chk-size">
                        <img
                          alt=""
                          src={loginUser.canvas_profile ? loginUser.canvas_profile : ''}
                        />
                        <span class="user-img"></span>
                      </span>
                    </div>
                    <ul>
                      <li>
                        <a
                          href={() => false}
                          onClick={() => {
                            moveMenu('/life/canvas/my');
                          }}
                        >
                          내 캔버스
                        </a>
                      </li>
                      <li>
                        <a
                          href={() => false}
                          onClick={() => {
                            moveMenu('/life/everyone/bucketlist/register');
                          }}
                        >
                          기록하기
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* S : 버튼 영역(마이페이지 버튼 외 모바일에서 노출 안함) */}
                  <ul>
                    <li class="d-block d-md-none">
                      <a
                        href={() => false}
                        onClick={() => {
                          moveMenu('/life/everyone/bucketlist/register');
                        }}
                      >
                        <i class="ic ic-write"></i>
                        <span>기록하기</span>
                      </a>
                    </li>
                    {/* S : 알림 없을 때 */}
                    {alarmCnt === 0 ? (
                      <li>
                        <a
                          href={() => false}
                          onClick={() => {
                            moveMenu('/life/settings/alarm');
                          }}
                        >
                          <i class="ic ic-notice"></i>
                          <span class="offscreen">알림</span>
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          href={() => false}
                          onClick={() => {
                            moveMenu('/life/settings/alarm');
                          }}
                        >
                          <i class="ic ic-notice"></i>
                          <span class="offscreen">알림</span>
                          <i class="ic-new">
                            <span class="offscreen">새로운 알림</span>
                          </i>
                        </a>
                      </li>
                    )}
                    {/* E : 알림 있을 때 */}
                    <li class="mypage">
                      <a
                        href={() => false}
                        onClick={() => {
                          moveMenu('/life/canvas/my');
                        }}
                      >
                        <i class="ic ic-profile"></i>
                        <span class="offscreen">마이페이지</span>
                      </a>
                    </li>
                    <li class="d-block d-md-none">
                      <a
                        href={() => false}
                        onClick={() => {
                          moveMenu('/life/settings/member');
                        }}
                      >
                        <i class="ic ic-setting"></i>
                        <span class="offscreen">설정</span>
                      </a>
                    </li>
                    {/* S : 22-09-23 관리자 링크 추가 */}
                    {loginUser.role === 'ADMIN' ? (
                      <li class="d-block d-md-none">
                        <a href="/admin/obituary">관리자</a>
                      </li>
                    ) : null}
                    {/* E : 22-09-23 관리자 링크 추가 */}
                  </ul>
                  {/* E : 버튼 영역(마이페이지 버튼 외 모바일에서 노출 안함) */}
                  {/* <ul>
                  <li>
                    <a
                      href={() => false}
                      onClick={() => moveMenu('/life/everyone/bucketlist/register')}
                    >
                      <i className="ic ic-write"></i>
                      <span>기록</span>
                    </a>
                  </li>
                  <li>
                    <a href={() => false}>
                      <i className="ic ic-notice"></i>
                      <span>알림</span>
                    </a>
                  </li>
                  <li className="mypage">
                    <a href={() => false} onClick={() => moveMenu('/life/canvas/my')}>
                      <i className="ic ic-profile"></i>
                      <span>MY</span>
                    </a>
                  </li>
                  <li>
                    <a href={() => false} onClick={() => moveMenu('/life/settings/member')}>
                      <i className="ic ic-setting"></i>
                      <span>설정</span>
                    </a>
                  </li>
                </ul> */}
                </div>
              </div>
              {/* E : 로그인 후 메뉴  */}
            </>
          ) : (
            <>
              {/* S : 회원가입/로그인/로그아웃 */}
              {/* 로그인 했을 때엔 이 영역 전체가 보이지 않아야 합니다 */}

              {/* S : 기록하기/알림/마이페이지/설정/로그인/회원가입 버튼 */}
              <div class="header-util">
                <div class="box-user">
                  {/* S : (로그인 전) 멘트  */}
                  <div class="user-profile">
                    <p>LIFE CANVAS</p>
                    <p class="desc">기록으로 삶을 디자인합니다.</p>
                    <ul>
                      <li>
                        <a href={() => false} onClick={() => moveMenu('/life/login')}>
                          로그인
                        </a>
                      </li>
                      <li>
                        <a href={() => false} onClick={() => moveMenu('/life/login/register')}>
                          회원가입
                        </a>
                      </li>
                    </ul>
                  </div>

                  <ul>
                    <li class="d-block d-md-none">
                      <a href={() => false} onClick={() => moveMenu('/life/login/register')}>
                        회원가입
                      </a>
                    </li>
                    <li class="d-block d-md-none">
                      <a href={() => false} onClick={() => moveMenu('/life/login')}>
                        로그인
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* E : 회원가입/로그인/로그아웃 */}
            </>
          )}
          <nav id="gnbMenuWrap">
            <ul>
              {/* <li>
                <a href={() => false} onClick={() => moveMenu('/life/service')}>
                  서비스 소개
                </a>
              </li> */}
              <li>
                <a
                  href={() => false}
                  onClick={() => {
                    moveMenu('/life/everyone');
                  }}
                >
                  모두의 캔버스
                </a>
              </li>
              <li>
                <a
                  href={() => false}
                  onClick={() => {
                    moveMenu('/life/canvas/story');
                  }}
                >
                  우리 이야기
                </a>
              </li>
              {/* <li>
                <a href={() => false} onClick={() => moveMenu('/life/faq')}>
                  FAQ
                </a>
              </li> */}
            </ul>
            <ul class="extra-menu">
              <li>
                <a
                  href={() => false}
                  onClick={() => {
                    moveMenu('/life/service');
                  }}
                >
                  라이프캔버스 소개
                </a>
              </li>
              <li>
                <a
                  href={() => false}
                  onClick={() => {
                    moveMenu('/life/faq');
                  }}
                >
                  자주 묻는 질문
                </a>
              </li>
              <li class="go-mylifecanvas">
                <a href="/memorial">메모리얼 캔버스로 이동</a>
              </li>
            </ul>
            {/* <div className="sub-menu-link">
              <ul>
                <li>
                  <a href={() => false} onClick={() => moveMenu('/')}>
                    마이라이프캔버스로 이동
                  </a>
                </li>
              </ul>
            </div> */}
          </nav>

          <button className="toggle-gnb btn-toggle-gnb" onClick={() => setActive(!isActive)}>
            <span className="offscreen">전체메뉴</span>
          </button>
          <span className="nav-bg"></span>
          <span className="overlay"></span>
        </div>
      </header>
      {/* E: 220715 헤더 기획 변경에 따른 마크업 전체 수정  */}
    </>
  );
}

export default Gnb;

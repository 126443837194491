import api from '@apis/api';
import configs from '@configs/configs';

export async function emailLogin(params) {
  const response = await api.post(`${configs.API_BASEURL}/member/login`, params);
  return response.data;
}

export async function kakaoLogin(accessToken) {
  const response = await api.get(
    `${configs.API_BASEURL}/openapi/kakao/oauth?access_token=${accessToken}&path=${configs.PATH}`,
  );
  return response.data;
}

export async function naverLogin(accessToken) {
  const response = await api.get(
    `${configs.API_BASEURL}/openapi/naver/login?access_token=${accessToken}`,
  );
  return response.data;
}

export async function findEmailApi(params) {
  try {
    const response = await api.post(`${configs.API_BASEURL}/member/find/email`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
  // const response = await api.post(`${configs.API_BASEURL}/member/find/email`, params);
  // return response.data;
}

export async function resetPwdApi(params) {
  try {
    const response = await api.post(`${configs.API_BASEURL}/member/resetPwd`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
  // const response = await api.post(`${configs.API_BASEURL}/member/resetPwd`, params);
  // return response.data;
}

export async function getMembersApi(params) {
  // 혅재 내가 지금 쓰고있는 url 정보를 가지고 와서
  // "http://125.209.206.229/memorial/login"
  // params.push('type', path정보);
  const response = await api.post(`${configs.API_BASEURL}/member`, params);
  return response.data;
}

export async function getMemberApi(memberId) {
  // const response = await api.get(
  //   `${configs.API_BASEURL}/member?member_id=${memberId}&type=${path}`,
  // );
  const response = await api.get(`${configs.API_BASEURL}/member?member_id=${memberId}`);
  return response.data;
}

export async function saveMemberApi(params) {
  if (configs.PATH !== 'admin') {
    params.path = configs.PATH;
  }
  const response = await api.post(`${configs.API_BASEURL}/member/ins`, params);
  return response.data;
}

export async function modifyMemberApi(params) {
  try {
    const response = await api.put(`${configs.API_BASEURL}/member`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function deleteMemberApi(memberId) {
  const response = await api.delete(`${configs.API_BASEURL}/member?member_id=${memberId}`);
  return response.data;
}

export async function changePasswordApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/member/change/password`, params);
  return response.data;
}

export async function checkPasswordApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/member/verify`, params);
  return response.data;
}

export async function getMemberHistoryApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/member/hist`, params);
  return response.data;
}

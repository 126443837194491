import { useState, useEffect } from 'react';

import { useFieldArray, Controller } from 'react-hook-form';
import FaqTemplateDialog from '../FaqTemplateDialog';

import useDialog from 'stores/dialog/hook';

function Faqs({ control, errors, changeDisplayYn }) {
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();
  const [openFaqTemplateDialog, setOpenFaqTemplateDialog] = useState(false);

  const [contents, setContents] = useState('');

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'faqs',
  });

  const handleAppend = () => {
    append({ questions: '', answer: '' });
  };

  const handleOrderUp = index => {
    const targetIndex = index - 1;
    if (targetIndex > -1) move(index, index - 1);
  };

  const handleOrderDown = index => {
    const targetIndex = index + 1;
    if (fields.length > targetIndex) move(index, targetIndex);
  };

  const handleRemove = index => {
    remove(index);
  };

  const getSelectedQna = qnas => {
    setOpenFaqTemplateDialog(false);

    showLifeConfirm(
      '확인',
      '기존 작성중인 질문이 삭제됩니다.\n질문 꾸러미를 적용하시겠습니까?',
      () => confirmSelectedQna(qnas),
      () => hideLifeConfirm(),
    );
  };

  //이모지 사용시 알람 처리
  const changeContents = e => {
    let contents = removeEmojis(e.target.value);
    // if (e.target.value !== replacedValue) {
    //   setContents(replacedValue);
    // }
    //setValue('blt_contents', replacedValue);
  };

  const removeEmojis = inputValue => {
    // const regex =
    //   /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    // if (regex.test(inputValue)) {
    //   let strVal = inputValue.replace(regex, ' ');
    //   showLifeAlert('알림', '이모티콘은 사용할 수 없습니다.', () => hideLifeAlert());
    //   console.log(strVal);
    //   return strVal;
    // }
    // return inputValue;
  };

  const confirmSelectedQna = qnas => {
    hideLifeConfirm();
    remove();
    for (let i = 0; qnas.length > i; i++) {
      append({ questions: qnas[i].blt_title, answer: '' });
    }
  };

  useEffect(() => {
    if (fields && fields.length === 0) handleAppend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      <ul className="write-util">
        <li>
          <button
            type="button"
            className="btn btn-white"
            data-micromodal-trigger="modal-template-01"
            onClick={() => setOpenFaqTemplateDialog(true)}
          >
            질문 꾸러미
          </button>
        </li>
        {/* <li>
              <button
                type="button"
                data-micromodal-trigger="modal-sample-01"
                onClick={() => setOpenFaqSampleDialog(true)}
              >
                <i className="ic ic-sample"></i>샘플보기
              </button>
            </li> */}
      </ul>

      {fields &&
        fields.length > 0 &&
        fields.map((content, index) => (
          <div className="write-form" key={index}>
            <div className="box-editor is-faq">
              {/* 문답일 경우 is-faq 추가 */}
              {/* S : 문답일 경우 추가 */}
              <div className="ip-item">
                <label for="textarea-subject" className="offscreen">
                  제목 입력
                </label>

                <Controller
                  control={control}
                  name={`faqs.${index}.questions`}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      id="textarea-subject"
                      rows="5"
                      cols="3"
                      placeholder="질문을 직접 입력하시거나 질문꾸러미에서 선택해보세요"
                    />
                  )}
                />
              </div>
              {/* E : 문답일 경우 추가 */}
              <label for="textarea-01" className="offscreen">
                글 내용 입력
              </label>
              <Controller
                control={control}
                name={`faqs.${index}.answer`}
                render={({ field }) => (
                  <textarea
                    {...field}
                    id="textarea-01"
                    rows="5"
                    cols="3"
                    // onKeyDown={changeContents}
                    //onChange={changeContents}
                    placeholder="질문에 대한 답을 작성해주세요"
                  />
                )}
              />
            </div>
            <div class="editor-util">
              <p class="message">
                {errors.faqs?.[index]?.questions?.message}
                {errors.faqs?.[index]?.answer?.message}
              </p>
              <div class="editor-faq-util">
                <button
                  type="button"
                  class="btn-sort btn-sort-up"
                  onClick={() => handleOrderUp(index)}
                >
                  <i class="ic ic-up"></i>
                  <span class="offscreen">위로정렬</span>
                </button>
                <button
                  type="button"
                  class="btn-sort btn-sort-down"
                  onClick={() => handleOrderDown(index)}
                >
                  <i class="ic ic-down"></i>
                  <span class="offscreen">아래로정렬</span>
                </button>
                <button
                  type="button"
                  class="btn-sort btn-sort-delete"
                  onClick={() => handleRemove(index)}
                >
                  <i class="ic ic-delete"></i>
                  <span class="offscreen">삭제</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      <div class="editor-util">
        <div class="select-public-type">
          <label for="sel-public" class="offscreen">
            공개 여부 선택
          </label>
          <select id="sel-public" onChange={e => changeDisplayYn(e.target.value)}>
            <option value="Y">전체 공개</option>
            <option value="S">구독자 공개</option>
            <option value="N">비공개</option>
          </select>
        </div>
        <button type="button" class="btn-add" onClick={() => handleAppend()}>
          + 질문추가
        </button>
        {openFaqTemplateDialog && (
          <FaqTemplateDialog
            isOpen={openFaqTemplateDialog}
            getSelectedQna={getSelectedQna}
            onClose={() => setOpenFaqTemplateDialog(false)}
          />
        )}
      </div>
    </>
  );
}

export default Faqs;

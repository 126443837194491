import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useLogin from 'stores/login/hook';

import BucketlistTemplateDialog from './components/BucketlistTemplateDialog';
// import BucketlistSampleDialog from './components/BucketlistSampleDialog';

import useDialog from 'stores/dialog/hook';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { modifyCanvasBltApi } from 'apis/canvasBlt';
import { uploadImageApi } from 'apis/openapi';
import { getDate } from 'utils/date';

import imgProgressBar from 'assets/life/images/contents/img_loadingbar.gif';
import { getCanvasBltApi } from 'apis/canvasBlt';

function BucketlistModify() {
  const { id } = useParams();
  const [mediaFiles, setMediaFiles] = useState([]);
  const { loginUser } = useLogin();
  const [showProgressBar, setShowProgressBar] = useState(false);

  const [openBuckitlistTemplateDialog, setOpenBuckitlistTemplateDialog] = useState(false);

  const navigate = useNavigate();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();

  const schema = Yup.object({
    blt_type: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_title: Yup.string().required('* 필수 입력 항목입니다.'),
    display_yn: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_contents: Yup.string().required('* 필수 입력 항목입니다.'),
    goaldtime: Yup.string().required('* 필수 입력 항목입니다.'),
    member_id: Yup.string().required('* 필수 입력 항목입니다.'),
    // media_files: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    blt_type: '4',
    blt_title: '버킷리스트',
    display_yn: '',
    blt_contents: '',
    goaldtime: getDate(0),
    member_id: '',
    canvas_id: '',
    media_files: [],
  };

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    modifyCanvasBlt(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const modifyCanvasBlt = async params => {
    const result = await modifyCanvasBltApi(params);

    if (result.code === '0000') {
      showLifeAlert('알림', '작성한 기록이 캔버스에 등록되었습니다.', () => successSaveCanvasBlt());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const successSaveCanvasBlt = () => {
    hideLifeAlert();
    navigate('/life/canvas/my', { state: { searchBltType: '4' } });
  };

  const changeMediaFile = async event => {
    const { files } = event.target;

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const size = getValues('media_files').length;
        console.log('size > ', size);
        if (size > 9) {
          showLifeAlert('알림', '이미지는 10개까지 등록 가능합니다.', () => hideLifeAlert());
          break;
        }
        await uploadMediaFile(files[i]);
      }
      setMediaFiles(getValues('media_files'));
    }
  };
  const uploadPercentage = value => {
    console.log('pregress > ', value);
    // setProgressBarPercentage(value);
  };

  const uploadMediaFile = async file => {
    setShowProgressBar(true);
    const result = await uploadImageApi(file, uploadPercentage);

    if (result.code === '0000') {
      setValue('media_files', [
        ...getValues('media_files'),
        {
          media_type: 'I',
          thumbnail_link: result.thumnailUrl,
          media_link: result.uploadUrl,
        },
      ]);
    }

    setShowProgressBar(false);
  };

  const removeMediaFile = index => {
    mediaFiles.splice(index, 1);
    setValue('media_files', [...mediaFiles]);
    setMediaFiles([...mediaFiles]);
  };

  const handleSelect = e => {
    if (e.target.value === '1') {
      navigate('/life/everyone/faq/register');
    } else if (e.target.value === '2') {
      navigate('/life/everyone/history/register');
    } else if (e.target.value === '3') {
      navigate('/life/everyone/daily/register');
    }
  };

  const moveLogin = () => {
    hideLifeAlert();
    navigate('/life/login');
  };

  const handleClose = () => {
    showLifeConfirm(
      '확인',
      '작성한 글이 모두 삭제됩니다.\n정말 취소하시겠습니까?',
      () => confirmClose(),
      () => hideLifeConfirm(),
    );
  };

  const confirmClose = () => {
    hideLifeConfirm();
    navigate(-1);
  };

  // const handleMove = url => {
  //   showLifeConfirm(
  //     '확인',
  //     '작성한 글이 모두 삭제됩니다.\n정말 취소하시겠습니까?',
  //     () => confirmMove(url),
  //     () => hideLifeConfirm(),
  //   );
  // };

  // const confirmMove = url => {
  //   hideLifeConfirm();
  //   navigate(url);
  // };

  const getSelectedValue = value => {
    setOpenBuckitlistTemplateDialog(false);
    setValue('blt_contents', value);
  };

  const getCanvasBlt = async () => {
    let params = {};
    params.blt_id = id;

    const result = await getCanvasBltApi(params);

    if (result.code === '0000') {
      Object.entries(result.data).forEach(([key, value]) => {
        setValue(key, value);
        if (key === 'media_files') setMediaFiles(value);
      });
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  // 이모지 사용시 알람 처리
  const changeContents = e => {
    let replacedValue = removeEmojis(e.target.value);
    setValue('blt_contents', replacedValue);
  };

  const removeEmojis = inputValue => {
    // const regex =
    //   /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    // if (regex.test(inputValue)) {
    //   showLifeAlert('알림', '이모티콘은 사용할 수 없습니다.', () => hideLifeAlert());
    //   let strVal = inputValue.replace(regex, ' ');
    //   return strVal;
    // }
    return inputValue;
  };

  const handleGoogle = () => {
    window.gtag('event', '버킷리스트등록', {
      event_category: '버튼클릭',
      event_label: '버킷리스트',
    });
  };

  const init = async () => {
    if (loginUser && !loginUser.member_id)
      showLifeAlert('알림', '로그인 후 이용가능합니다.', () => moveLogin());
    if (loginUser?.member_id) setValue('member_id', loginUser.member_id);
    if (loginUser?.member_name) setValue('member_name', loginUser.member_name);
    if (loginUser?.canvas_id) setValue('canvas_id', loginUser.canvas_id);
  };

  useEffect(() => {
    if (id) getCanvasBlt();
  }, [id]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-write">
          {/* 글쓰기(자서전/문답/일상/버킷리스트) 일때 is-write 추가 */}
          {/* S : 22-08-18 타이틀(h2) class 추가, 기록 화면 버튼 SELECT BOX 형태로 수정 */}
          <h2 className="offscreen">버킷리스트</h2>
          <div className="sel-item sel-title-write">
            <label for="sel-write" className="offscreen">
              기록 유형 선택
            </label>
            <select id="sel-write">
              <option selected>버킷리스트</option>
              {/* <option value="1">문답</option>
              <option value="2">자서전</option>
              <option value="3">일상</option> */}
            </select>
          </div>
          {/* E : 22-08-18 타이틀(h2) class 추가, 기록 화면 버튼 SELECT BOX 형태로 수정 */}
          <ul className="write-util">
            <li>
              <button
                type="button"
                className="btn btn-white"
                data-micromodal-trigger="modal-template-01"
                onClick={() => setOpenBuckitlistTemplateDialog(true)}
              >
                버킷리스트 템플릿
              </button>
            </li>
            {/* <li>
              <button
                type="button"
                data-micromodal-trigger="modal-sample-01"
                onClick={() => setOpenBuckitlistSampleDialog(true)}
              >
                <i className="ic ic-sample"></i>샘플보기
              </button>
            </li> */}
          </ul>
          <div className="write-form">
            <div className="box-editor">
              <label htmlFor="textarea-01" className="offscreen">
                글 내용 입력
              </label>

              <textarea
                {...register('blt_contents')}
                id="textarea-01"
                rows="5"
                cols="3"
                //onKeyDown={changeContents}
                placeholder="이루고 싶은 것을 기록해 보세요."
              ></textarea>
            </div>

            <div className="editor-util">
              <div className="select-public-type">
                <select id="sel-public" {...register('display_yn')}>
                  <option value="Y">전체 공개</option>
                  <option value="S">구독자 공개</option>
                  <option value="N">비공개</option>
                </select>
              </div>
              <div className="ip-file">
                <input
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  multiple
                  className="input-hidden"
                  id="ip-file-upload"
                  onChange={changeMediaFile}
                />
                <label htmlFor="ip-file-upload">
                  <i className="ic ic-file-add"></i>
                  <span className="offscreen">파일첨부</span>
                </label>
              </div>
            </div>

            {/* S : 첨부 이미지 목록 (최대 10개) */}
            <div className="upload-file-wrap">
              {mediaFiles && mediaFiles.length > 0 && (
                <>
                  <ul>
                    {mediaFiles.map((mediaFile, index) => (
                      <li className="chk-size" key={mediaFile.thumbnail_link}>
                        <img alt="" src={mediaFile.thumbnail_link} />
                        <button type="button" onClick={() => removeMediaFile(index)}>
                          <i className="ic ic-x"></i>
                          <span className="offscreen">파일삭제</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                  <div className="file-count">
                    <strong>{mediaFiles.length}</strong> / 10
                  </div>
                </>
              )}
            </div>
            {showProgressBar && (
              <div class="loading">
                <img src={imgProgressBar} alt="" />
              </div>
            )}
            {/* E : 첨부 이미지 목록 (최대 10개) */}
          </div>
          {/* S : 버킷 달성 날짜 */}
          <div className="bucket-date form-date">
            <label htmlFor="ip-bucket-date">버킷 목표 날짜</label>
            <input
              {...register('goaldtime')}
              className="ip-flatpickr flatpickr"
              id="ip-bucket-date"
              type="date"
              placeholder="2022.07.01"
            />
          </div>
          {errors?.goaldtime?.message}
          {/* E : 버킷 달성 날짜 */}
          <div className="btn-wrap">
            <a href={() => false} className="btn btn-white" onClick={() => handleClose()}>
              취소
            </a>
            <button
              type="button"
              className="btn btn-green"
              // onClick={() => {
              //   handleSubmit(onSubmit, onError);
              //   handleGoogle();
              // }}
              onClick={handleSubmit(onSubmit, onError)}
            >
              올리기
            </button>
          </div>
        </div>

        {/* S : 글쓰기 하단 */}
        {/* <div className="write-bottom">
          <ul className="contents-width is-write">
            <li>
              <a href={() => false} onClick={() => handleMove('/life/everyone/faq/register')}>
                <i className="ic ic-write-faq"></i>문답
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => handleMove('/life/everyone/history/register')}>
                <i className="ic ic-write-history"></i>자서전
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => handleMove('/life/everyone/daily/register')}>
                <i className="ic ic-write-life"></i>일상
              </a>
            </li>
          </ul>
        </div> */}
        {/* E : 글쓰기 하단 */}
      </div>
      {/* E : CONTENT */}
      {openBuckitlistTemplateDialog && (
        <BucketlistTemplateDialog
          isOpen={openBuckitlistTemplateDialog}
          getSelectedValue={getSelectedValue}
          onClose={() => setOpenBuckitlistTemplateDialog(false)}
        />
      )}
    </section>
  );
}

export default BucketlistModify;

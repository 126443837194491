import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

function IndexPage() {
  const navigate = useNavigate();
  const heroSwiper = useRef(null);
  const progressBarFillRef = useRef(null);

  const [state, setState] = useState(true);
  const [isMobile, setisMobile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [progressIndex, setProgressIndex] = useState(0);

  const breakPoint = window.matchMedia('(max-width: 1024px)');

  const [swiper, setSwiper] = useState(null);
  // swiper instance
  const [play, setPlay] = useState(true); // auto play 기준
  const [progressBarStep, setProgressBarStep] = useState(1);

  const toggleAutoplay = () => {
    if (heroSwiper.current && heroSwiper.current.swiper.autoplay.running) {
      heroSwiper.current.swiper.autoplay.stop();
      setPlay(false);
    } else if (heroSwiper.current) {
      heroSwiper.current.swiper.autoplay.start();
      setPlay(true);
    }
  };

  const handlePaginationClick = index => {
    if (heroSwiper.current && heroSwiper.current.swiper) {
      heroSwiper.current.swiper.slideTo(index);
    }
  };
  // useEffect(() => {
  //   if (progressBarFillRef.current) {
  //     progressBarFillRef.current.classList.remove('step-1', 'step-2', 'step-3', 'step-4');
  //     progressBarFillRef.current.classList.add(`step-${progressBarStep + 1}`);
  //   }
  // }, [progressBarStep]);

  const handleSlideChange = swiper => {
    const newIndex = swiper.realIndex;
    //alert(activeIndex);
    setActiveIndex(newIndex);

    // if (swiper.activeIndex === '5') {
    //   setProgressIndex(swiper.activeIndex - 3);
    // } else {
    //   setProgressIndex(swiper.activeIndex + 1);
    // }
    setProgressIndex(swiper.activeIndex + 1);
    // span 엘리먼트의 클래스를 동적으로 변경
    // const progressBarFill = progressBarFillRef.current;
    // if (progressBarFill) {
    //   progressBarFill.className = `swiper-pagination-progressbar-fill step-${activeIndex + 1}`;
    // }
  };

  useEffect(() => {
    const progressBarFill = document.querySelector('.swiper-pagination-progressbar-fill');
    if (progressBarFill) {
      progressBarFill.className = `swiper-pagination-progressbar-fill step-${
        progressIndex === 6 ? 2 : progressIndex
      }`;
    }
  }, [activeIndex]);

  useEffect(() => {
    // 슬라이드 변경 시 프로그레스 바 업데이트
    // const progressBarFill = progressBarFillRef.current;
    const swiperSwitch = document.querySelectorAll('.swiper-switch');
    //
    // switch (activeIndex) {
    //   case 0:
    //     progressBarFill.classList.remove('step-2', 'step-3', 'step-4');
    //     progressBarFill.classList.add('step-1');
    //     break;
    //   case 1:
    //     progressBarFill.classList.remove('step-1', 'step-3', 'step-4');
    //     progressBarFill.classList.add('step-2');
    //     break;
    //   case 2:
    //     progressBarFill.classList.remove('step-1', 'step-2', 'step-4');
    //     progressBarFill.classList.add('step-3');
    //     break;
    //   case 3:
    //     progressBarFill.classList.remove('step-1', 'step-2', 'step-3');
    //     progressBarFill.classList.add('step-4');
    //     break;
    //   default:
    //     progressBarFill.classList.remove('step-2', 'step-3', 'step-4');
    //     progressBarFill.classList.add('step-1');
    // }

    // 스위치 업데이트
    swiperSwitch.forEach((el, index) => {
      el.classList.remove('is-active', 'is-on');
      if (index <= activeIndex) {
        el.classList.add('is-active', 'is-on');
      }
    });
  }, [activeIndex]);

  // const handleSlideChange = swiper => {
  //   const newIndex = swiper.realIndex;
  //   //alert(activeIndex);
  //   setActiveIndex(newIndex);
  //
  //   // span 엘리먼트의 클래스를 동적으로 변경
  //   // const progressBarFill = progressBarFillRef.current;
  //   // if (progressBarFill) {
  //   //   progressBarFill.className = `swiper-pagination-progressbar-fill step-${activeIndex + 1}`;
  //   // }
  // };

  useEffect(() => {
    // const progressBarFill = progressBarFillRef.current;
    // if (progressBarFill) {
    //   const stepClass = `step-${activeIndex + 1}`;
    //   console.log('stepClass ? :' + stepClass);
    //   progressBarFill.className = `swiper-pagination-progressbar-fill ${stepClass}`;
    //const progressBarFill = progressBarFillRef.current;
    if (progressBarFillRef.current) {
      // 모든 step 클래스를 삭제합니다.
      for (let i = 1; i <= 4; i++) {
        progressBarFillRef.current.classList.remove(`step-${i}`);
      }
      // 현재 활성 슬라이드에 해당하는 step 클래스를 추가합니다.
      progressBarFillRef.current.classList.add(`step-${activeIndex + 1}`);
    }
  }, [activeIndex]);

  useEffect(() => {
    // Work with the updated ref value here
    //console.log(progressBarFillRef.current);
  }, [progressBarFillRef]);

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  // 우선 맨 처음 1024이하면 모바일 처리
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }

    window.addEventListener('resize', resizingHandler);

    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('resize', resizingHandler);
    };
  }, []);

  return (
    <>
      {/* S: CONTENTS */}
      <section className="container" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          <div className="wrap-info">
            <div className="contents-width">
              <h2>
                함께 했던 소중한 삶의
                <br />
                이야기들을 캔버스<span className="mark green"></span> 위에{' '}
                <br className="d-block d-lg-none" />
                담아냅니다.
              </h2>

              <div className="list-service">
                <div className="box-service" onClick={() => navigate('/life')}>
                  <a href={() => false}>
                    <h3>
                      LIFE
                      <br />
                      CANVAS<span className="mark yellow"></span>
                    </h3>
                    <span className="text-sm">라이프 캔버스</span>
                    <div className="desc">
                      일상, 버킷리스트, 문답, 자서전을 작성하여
                      <br />
                      나만의 기록으로 채워갑니다.
                    </div>
                  </a>
                </div>
                <div className="box-service">
                  <a href="/memorial">
                    <h3>
                      MEMORIAL
                      <br />
                      CANVAS<span className="mark yellow"></span>
                    </h3>
                    <span className="text-sm">메모리얼 캔버스</span>
                    <div className="desc">
                      사랑하는 사람이 남기고간 메시지와
                      <br />
                      삶에 초점을 맞추어 오롯이 고인을
                      <br />
                      추모하는 공간입니다
                    </div>
                  </a>
                </div>
                <div className="box-service">
                  <a href="/pet">
                    <h3>
                      PET MEMORIAL
                      <br />
                      CANVAS<span className="mark yellow"></span>
                    </h3>
                    <span className="text-sm">펫 메모리얼 캔버스</span>
                    <div className="desc">
                      사랑하는 반려동물이 떠났을 때<br />
                      진심어린 추모의 마음을
                      <br />
                      전달하는 공간입니다.
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="wrap-canvas">
            <Swiper
              ref={heroSwiper}
              className="swiperCanvas"
              modules={[Pagination, Autoplay, EffectFade]}
              effect={'fade'}
              centeredSlides={true}
              loop={true}
              simulateTouch={true}
              autoplayDisableOnInteraction={true}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              pagination={{
                el: '.swiper-pagination',
                clickable: true,
                type: 'progressbar',
              }}
              onSlideChangeTransitionStart={handleSlideChange}
            >
              <div className="swiper-wrapper">
                <SwiperSlide className="slide-01">
                  <>
                    <div className="contents-width">
                      <div className="logo-wrap">
                        <div className="logo">
                          MY LIFE CANVAS<span className="mark yellow"></span>
                        </div>
                      </div>
                      <p className="summary">
                        일상의 순간부터 이별의 순간,
                        <br />그 이후의 추억까지
                        <br />
                        모든 이야기를 캔버스로 연결합니다.
                      </p>
                    </div>
                  </>
                </SwiperSlide>
                <SwiperSlide className="slide-02">
                  <>
                    <div className="contents-width">
                      <div className="logo-wrap">
                        <div className="logo">
                          LIFE CANVAS<span className="mark yellow"></span>
                        </div>
                      </div>
                      <h3>일대기</h3>
                      <p className="desc">소중한 삶의 순간들을 기록하는 일대기를 작성합니다.</p>
                    </div>
                  </>
                </SwiperSlide>

                <SwiperSlide className="slide-03">
                  <>
                    <div className="contents-width">
                      <div className="logo-wrap">
                        <div className="logo">
                          MEMORIAL CANVAS<span className="mark yellow"></span>
                        </div>
                        <div className="logo">
                          PET MEMORIAL CANVAS<span className="mark yellow"></span>
                        </div>
                      </div>
                      <h3>부고</h3>
                      <p className="desc">
                        일대기를 공유하여 생전 삶을 기릴 수 있는{' '}
                        <br className="d-block d-lg-none" />
                        온라인 장례를 진행합니다.
                      </p>
                    </div>
                  </>
                </SwiperSlide>

                <SwiperSlide className="slide-04">
                  <>
                    <div className="contents-width">
                      <div className="logo-wrap">
                        <div className="logo">
                          MEMORIAL CANVAS<span className="mark yellow"></span>
                        </div>
                        <div className="logo">
                          PET MEMORIAL CANVAS<span className="mark yellow"></span>
                        </div>
                      </div>
                      <h3>추모관</h3>
                      <p className="desc">
                        그리운 마음을 모아 메모리얼 캔버스 추모관에 보관합니다.
                      </p>
                    </div>
                  </>
                </SwiperSlide>
              </div>

              <div className="contents-width">
                {/* Timeline */}
                <ul className="swiper-pagination-custom">
                  <li
                    className={`swiper-pagination-switch ${
                      activeIndex === '0' ? 'is-active is-on' : 'is-on'
                    }`}
                  >
                    <span className="bullet" onClick={() => handlePaginationClick(1)}></span>
                    <span className="offscreen">MY LIFE CANVAS</span>
                  </li>
                  <li
                    className={`swiper-pagination-switch ${
                      activeIndex === '1' ? 'is-active is-on' : activeIndex >= 1 ? 'is-on' : ''
                    }`}
                  >
                    <span className="bullet" onClick={() => handlePaginationClick(2)}></span>
                    <span className="offscreen">LIFE CANVAS</span>
                  </li>
                  <li
                    className={`swiper-pagination-switch ${
                      activeIndex === '2' ? 'is-active is-on' : activeIndex >= 2 ? 'is-on' : ''
                    }`}
                  >
                    <span className="bullet" onClick={() => handlePaginationClick(3)}></span>
                    <span className="offscreen">MEMORIAL CANVAS</span>
                  </li>
                  <li
                    className={`swiper-pagination-switch ${
                      activeIndex === '3' ? 'is-active is-on' : activeIndex >= 3 ? 'is-on' : ''
                    }`}
                  >
                    <span className="bullet" onClick={() => handlePaginationClick(4)}></span>
                    <span className="offscreen">PET MEMORIAL CANVAS</span>
                  </li>
                </ul>

                <div className="swiper-pagination swiper-pagination-progressbar swiper-pagination-horizontal"></div>

                <div className="swiper-btn-wrap">
                  <button
                    type="button"
                    className={`btn-swiper-play ${play ? 'offscreen' : ''}`}
                    onClick={() => toggleAutoplay()}
                  >
                    <span className="offscreen">play</span>
                  </button>
                  <button
                    type="button"
                    className={`btn-swiper-stop ${play ? '' : 'offscreen'}`}
                    onClick={() => toggleAutoplay()}
                  >
                    <span className="offscreen">stop</span>
                  </button>
                </div>
              </div>
            </Swiper>
          </div>

          <div className="wrap-story is-slider-fade">
            <div className="contents-width">
              <div className="box-title">
                <h3>일대기</h3>
                <p className="desc">
                  사랑하는 대상에 대한 일대기를 기록합니다.
                  <br />
                  일상, 문답, 버킷리스트, 일대기를 작성하면서
                  <br />
                  사랑하는 대상에 대해 깊게 생각해보는 시간을 갖습니다.
                  <br />
                  라이프캔버스 위에는 사랑하는 기억과 추억들로 가득 채워집니다.
                </p>
                <div className="btn-wrap">
                  <a href={() => false} className="btn-go" onClick={() => navigate('/life')}>
                    라이프 캔버스 바로가기
                  </a>
                </div>
              </div>

              <div className="box-slider">
                <Swiper
                  className="swiperStory"
                  modules={[Pagination, Autoplay, EffectFade]}
                  effect={'fade'}
                  centeredSlides={true}
                  loop={true}
                  simulateTouch={true}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  pagination={{
                    el: '.swiper-pagination',
                    clickable: true,
                  }}
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide>
                      <>
                        <div className="inner">
                          <div className="img img01"></div>
                          <p>소중한 일상 기록</p>
                        </div>
                      </>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="inner">
                        <div className="img img02"></div>
                        <p>의미있는 기록 버킷리스트</p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="inner">
                        <div className="img img03"></div>
                        <p>문답으로 알아가는 소중한 것들</p>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="inner">
                        <div className="img img04"></div>
                        <p>일대기로 기록하는 기억하고 싶은 이야기</p>
                      </div>
                    </SwiperSlide>
                  </div>
                  <div className="swiper-pagination"></div>
                </Swiper>
              </div>
            </div>
          </div>

          <div className="wrap-obituary is-slider-fade">
            <div className="contents-width">
              <div className="box-title">
                <h3>부고</h3>
                <p className="desc">
                  라이프캔버스에 등록된 일대기를 연결하여
                  <br />
                  아름다운 추억과 함께 작별 인사를 건넬 수 있습니다.
                  <br />
                  일대기가 담긴 부고를 공유하여
                  <br />
                  추모의 마음을 전하는 모든 사람들에게 떠나간 이의 삶이 닿습니다.
                </p>
                <div className="btn-wrap">
                  <a href={() => false} className="btn-go" onClick={() => navigate('/memorial')}>
                    메모리얼 캔버스 바로가기
                  </a>
                  <a href={() => false} className="btn-go is-line" onClick={() => navigate('/pet')}>
                    펫 메모리얼 캔버스 바로가기
                  </a>
                </div>
              </div>

              <div className="box-slider">
                <Swiper
                  className="swiperObituary"
                  modules={[Pagination, Autoplay, EffectFade]}
                  effect={'fade'}
                  centeredSlides={true}
                  loop={true}
                  simulateTouch={true}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  pagination={{
                    el: '.swiper-pagination',
                    clickable: true,
                  }}
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide>
                      <>
                        <div className="inner">
                          <div className="img img01"></div>
                          <p>일대기가 담긴 온라인 부고 전송</p>
                        </div>
                      </>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="inner">
                        <div className="img img02"></div>
                        <p>모의 글, 디지털화환 등으로 마음 전달</p>
                      </div>
                    </SwiperSlide>
                  </div>
                  <div className="swiper-pagination"></div>
                </Swiper>
              </div>
            </div>
          </div>

          <div className="wrap-memorial is-slider-fade">
            <div className="contents-width">
              <div className="box-title">
                <h3>추모관</h3>
                <p className="desc">
                  그리울 때 마다 방문하여 추억을 들여다보고,
                  <br />
                  하고 싶은 말, 사진, 영상을 남길 수 있습니다.
                </p>
                <div className="btn-wrap">
                  <a
                    href={() => false}
                    className="btn-go is-white"
                    onClick={() => navigate('/memorial')}
                  >
                    메모리얼 캔버스 바로가기
                  </a>
                  <a
                    href={() => false}
                    className="btn-go is-white-line"
                    onClick={() => navigate('/pet')}
                  >
                    펫 메모리얼 캔버스 바로가기
                  </a>
                </div>
              </div>

              <div className="box-slider">
                <Swiper
                  className="swiperMemorial"
                  modules={[Pagination, Autoplay, EffectFade]}
                  effect={'fade'}
                  centeredSlides={true}
                  loop={true}
                  simulateTouch={true}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  pagination={{
                    el: '.swiper-pagination',
                    clickable: true,
                  }}
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide>
                      <>
                        <div className="inner">
                          <div className="img img01"></div>
                          <p>온라인 추모 공간</p>
                        </div>
                      </>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="inner">
                        <div className="img img02"></div>
                        <p>사랑으로 가득한 추억 앨범, 영상</p>
                      </div>
                    </SwiperSlide>
                  </div>
                  <div className="swiper-pagination"></div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        {/* E : CONTENT */}
      </section>
      {/* E: CONTENTS */}
    </>
  );
}

export default IndexPage;

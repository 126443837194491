import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useLogin from '../../../../stores/login/hook';
import useDialog from '../../../../stores/dialog/hook';
import useCode from '../../../../stores/code/hook';
import { deleteMemberApi, getMembersApi } from '../../../../apis/member';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Pagination from 'react-js-pagination';

function AdminMemberDetailPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { pathname } = useLocation();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { memberTypeCodes, loginTypeCodes } = useCode();
  const [searchType, setSearchType] = useState('NAME');

  // status 2 : 계정상태 비활성화 휴면상태
  const getMembers = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'member_id' };
    params.member_type = 'N';
    params.page = pageNum;
    params.status = 2;
    params.searchType = searchType;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getMembersApi(params);
    setMembers(result.data);
    setTotalCount(result.data_cnt);
  };

  const deleteMemorial = async params => {
    const result = await deleteMemberApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getMembers(1);
  };

  const [members, setMembers] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const handleModify = memberId => {
    navigate(`${pathname.replace('/detail', '')}/modify/${memberId}`);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleRegister = () => {
    navigate(`${pathname.replace('/detail', '')}/register`);
  };

  const handleDelete = memberId => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(memberId),
      () => hideConfirm(),
    );
  };

  const handleConfirm = memberId => {
    hideConfirm();
    deleteMemorial(memberId);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getMembers(selectedPage);
  };

  const handleSearchMembers = () => {
    getMembers(1);
  };

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getMembers(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>휴면 회원 관리</h2>
      </div>

      {/* S : 목록 검색 박스 */}
      <div className="content-box has-bdr-top">
        <table className="tbl-search">
          <caption></caption>
          <colgroup>
            <col width="180px" />
            <col />
            <col width="155px" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="selCategory02">검색입력</label>
              </th>
              <td className="input">
                <div className="ip-item sel-ip-box">
                  <select id="selCategory02" onChange={e => setSearchType(e.target.value)}>
                    <option value={'NAME'}>이름</option>
                    <option value={'EMAIL'}>이메일</option>
                    <option value={'PHONE'}>전화번호</option>
                  </select>
                  <input
                    type="text"
                    id="ipSearchText2"
                    value={searchValue}
                    onChange={changeSearchValue}
                    onKeyPress={onKeyPress}
                  />
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn-search"
                  ref={searchRef}
                  onClick={handleSearchMembers}
                >
                  검색
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* E : 목록 검색 박스 */}

      {/* S : 테이블 상단 게시물 갯수+버튼 */}

      <div className="box-tbl-head">
        <div className="total">
          Total :{' '}
          <span>
            <em>{totalCount}</em>
          </span>
        </div>
        <div className="head-content">
          <div className="ip-item">
            <label for="selSample01" className="offscreen">
              보기 갯수 선택
            </label>
            <select id="selSample01">
              <option>10개씩 보기</option>
              <option>30개씩 보기</option>
              <option>50개씩 보기</option>
            </select>
          </div>
          <div className="btn-wrap">
            <button type="button" className="btn btn-download">
              엑셀 다운로드
            </button>
            <button type="button" className="btn btn-pos" onClick={handleRegister}>
              회원 등록
            </button>
          </div>
        </div>
      </div>
      {/* E : 테이블 상단 게시물 갯수+버튼 */}

      {/* S : 목록 */}
      <div className="content-box">
        <div className="tbl-wrap">
          <table className="tbl-col">
            <caption className="offscreen">회원 조회 및 변경</caption>
            <colgroup>
              <col width="15%" />
              <col width="20%" />
              <col width="15%" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">이름</th>
                <th scope="col">이메일</th>
                <th scope="col">전화번호</th>
                <th scope="col">사용자타입</th>
                <th scope="col">로그인타입</th>
                <th scope="col">기능</th>
              </tr>
            </thead>
            <tbody>
              {members && members.length > 0
                ? members.map(row => (
                    <tr key={row.member_id}>
                      <td>{row.member_name}</td>
                      <td>{row.email}</td>
                      <td>{row.mobile}</td>
                      <td>
                        {memberTypeCodes &&
                          memberTypeCodes.length > 0 &&
                          memberTypeCodes
                            .filter(code => code.code === row.member_type)
                            .map(code => (
                              <Chip label={code.value} color={code.color} key={code.code} />
                            ))}
                      </td>
                      <td>
                        <span className="tc-gray">
                          {loginTypeCodes &&
                            loginTypeCodes.length > 0 &&
                            loginTypeCodes
                              .filter(code => code.code === row.login_type)
                              .map(code => (
                                <Chip label={code.value} color={code.color} key={code.code} />
                              ))}
                        </span>
                      </td>
                      <td>
                        <div className="btn-wrap">
                          <Tooltip title="수정" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="수정"
                              onClick={() => handleModify(row.member_id)}
                            >
                              <i className="ic ic-modify"></i>
                            </button>
                          </Tooltip>
                          <Tooltip title="삭제" arrow>
                            <button
                              className=""
                              type="button"
                              aria-label="삭제"
                              onClick={() => handleDelete(row.member_id)}
                            >
                              <i className="ic ic-delete"></i>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* E : 목록 */}

      {/* S : 페이징 */}
      <Pagination
        activePage={page}
        itemsCountPerPage={listSize}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        firstPageText={''}
        prevPageText={''}
        nextPageText={''}
        lastPageText={''}
        onChange={handlePageChange}
        itemClassFirst={'ico-page-first'}
        itemClassPrev={'ico-page-prev'}
        itemClassNext={'ico-page-next'}
        itemClassLast={'ico-page-end'}
      />
      {/* E : 페이징 */}
    </div>
  );
}
export default AdminMemberDetailPage;

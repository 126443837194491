import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useLayout from 'stores/layout/hook';
import useCode from 'stores/code/hook';

import { getHallApi } from 'apis/hall';
import { uploadApi } from 'apis/openapi';
import { getMemorialApi, modifyMemorialApi } from 'apis/memorial';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDate } from 'utils/date';

import ContentLoader from 'react-content-loader';
import ChudosaSampleDialog from 'pages/pet-memorial-canvas/obituary/components/ChudosaTemplateDialog';
import SearchHallDialog from 'pages/pet-memorial-canvas/obituary/components/SearchHallDailog';
import Relations from 'pages/pet-memorial-canvas/obituary/components/Relations';
import Condolatorys from 'pages/pet-memorial-canvas/obituary/components/Condolatorys';
import Blts from 'pages/pet-memorial-canvas/obituary/components/Blts';
import PreviewDialog from 'pages/pet-memorial-canvas/obituary/components/PreviewDialog';
import MediaUploader from 'pages/pet-memorial-canvas/obituary/components/MediaUploader';
import Chudosas from '../../../pet-memorial-canvas/obituary/components/Chudosas';

function AdminObituaryRegisterPage() {
  const navigate = useNavigate();
  const textRef = useRef();

  const { memorialId } = useParams();

  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();
  const { relationPetDetailCodes } = useCode();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const schema = Yup.object({
    // chief_mourner_desc: Yup.string().required('* 고인과의 관계를 선택하세요.'),
    // chief_mourner: Yup.string().required('* 상주명을 입력하세요.'),
    // chief_mourner_phone: Yup.string().required('* 휴대폰 번호를 입력하세요.'),
    deceased_name: Yup.string()
      .required('* 이름을 입력하세요.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    // deceased_birthday: Yup.string().required('* 생년월일을 입력하세요.'),
    // deceased_date: Yup.string().required('* 임종일자, 시간을 입력하세요.'),
    // deceased_hour: Yup.string().required('* 임종일자, 시간을 입력하세요.'),
    // birth_date: Yup.string().required('* 발인일자, 시간을 입력하세요.'),
    // birth_hour: Yup.string().required('* 발인일자, 시간을 입력하세요.'),
    // funeral_place: Yup.string().required('* 장지를 입력하세요.'),
    // funeral_hall: Yup.string().required('* 등록된 장례식장 정보가 없습니다.'),
    // relations_datas: Yup.array().of(
    //   Yup.object().shape({
    //     relations_name: Yup.string().required('* 상주정보를 입력하세요.'),
    //     relations_detail: Yup.string().required('* 고인관계를 입력하세요.'),
    //   }),
    // ),
    // condolatory_datas: Yup.array().of(
    //   Yup.object().shape({
    //     applicant_name: Yup.string().required('* 이름을 입력하세요.'),
    //     account_number: Yup.string().required('* 계좌번호를 입력하세요.'),
    //   }),
    // ),
    blt_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 발자국 주제를 등록 하세요.')
          .max(20, '* 발자국 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string()
          // .test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 3000000)
          .required('* 제목과 내용을 모두 작성하세요.'),
      }),
    ),
    chudosa_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 떠나보내는 글 주제를 등록 하세요.')
          .max(20, '* 떠나보내는 글 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string()
          // .test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 3000000)
          .required('* 제목과 내용을 모두 작성하세요.'),
      }),
    ),
    deceased_introduction: Yup.string().required('* 알림글을 입력하세요.'),
    // chief_funeral_nm: Yup.string().required('* 장례위원장명을 입력하세요.'),
  }).required();

  const defaultValues = {
    chief_mourner: '',
    chief_mourner_desc: '',
    chief_mourner_phone: '',
    digital_wreath_yn: '',
    chief_funeral_yn: 'N',
    birth_yn: 'N',
    funeral_yn: 'N',
    funeral_addr_yn: 'N',
    deceased_video_yn: 'N',
    delivery_yn: 'Y',
    condolatory_yn: 'Y',
    chief_funeral_nm: '',
    deceased_name: '',
    //deceased_birthday: getDate(-30000),
    deceased_job_title: '',
    // deceased_date: getDate(0),
    // deceased_hour: '00:00',
    // birth_date: getDate(3),
    // birth_hour: '00:00',
    //funeral_addr: '',
    funeral_room: '',
    funeral_place: '',
    funeral_hall: '',
    deceased_photo: '',
    deceased_introduction: '',
    chudosa: '',
    own_member_id: '',
    create_member_id: '',
    display_yn: 'Y',
    mylty_yn: 'N',
    donation_yn: 'N',
    status: '1',
    relations_datas: [],
    condolatory_datas: [],
    blt_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    chudosa_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    medias: [],
    deceased_video_link: '',
  };

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [loading, setLoading] = useState(true);
  const [openSearchHallDialog, setOpenSearchHallDialog] = useState(false);
  const [openChudosaSampleDialog, setOpenChudosaSampleDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [deceasedPhoto, setDeceasedPhoto] = useState(null);
  const [displayYn, setDisplayYn] = useState(true);
  const [birthYn, setBirthYn] = useState(false);
  const [funeralYn, setFuneralYn] = useState(false);
  const [funeralAddrYn, setFuneralAddrYn] = useState(false);
  const [videoYn, setVideoYn] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [videoPhoto, setVideoPhoto] = useState(null);
  const [deliveryYn, setDeliveryYn] = useState(false);
  const [chiefFuneralYn, setChiefFuneralYn] = useState(false);
  const [condolatoryYn, setCondolatoryYn] = useState(false);
  const [wreathYn, setWreathYn] = useState(false);

  const [multyYn, setMultyYn] = useState(false);
  const [hall, setHall] = useState(null);

  const [memorial, setMemorial] = useState(null);
  const [relations, setRelations] = useState(null);
  const [condolatorys, setCondolatorys] = useState(null);
  const [blts, setBlts] = useState(null);
  const [chudosas, setChudosas] = useState(null);

  const [medias, setMedias] = useState([]);

  const [displayChecked, setDisplayChecked] = useState(false);
  const [visual, setVisual] = useState(false);
  const [message, setMessage] = useState('');

  const getHall = async hallId => {
    const result = await getHallApi(hallId);
    setHall(result.data);
  };

  const getMemorial = async memorialId => {
    const result = await getMemorialApi(memorialId);
    setMemorial(result.data);
    setRelations(result.relations_datas);
    if (result.relations_datas.length === 0) {
      setVisual(false);
    } else {
      setVisual(true);
    }
    setCondolatorys(result.condolatory_datas);
    setBlts(result.blt_datas);
    setChudosas(result.chudosa_datas);
    setLoading(false);
  };

  const modifyMemorial = async params => {
    params.path = 'pet';
    const result = await modifyMemorialApi(params);
    if (result.type === 'success') {
      showAlert('알림', result.message, () => successModifyMemorial(memorialId));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);

    setDeceasedPhoto(result.uploadUrl);
    setValue('deceased_photo', result.uploadUrl);
  };

  const selectedHall = hallId => {
    setValue('funeral_addr', hallId);
    getHall(hallId);
    setOpenSearchHallDialog(false);
  };

  const handleChangeDisplayYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('display_yn', isChecked);
    setDisplayYn(event.target.checked);
  };

  const handleBirthYn = value => {
    setValue('birth_yn', value ? 'Y' : 'N');
    setBirthYn(value);
  };

  const handleFuneralAddrYn = value => {
    setValue('funeral_addr_yn', value ? 'Y' : 'N');
    setFuneralAddrYn(value);
  };

  const handleFuneralYn = value => {
    setValue('funeral_yn', value ? 'Y' : 'N');
    setFuneralYn(value);
  };

  const handleVideoYn = value => {
    setValue('deceased_video_yn', value ? 'Y' : 'N');
    setVideoYn(value);
  };

  const handleDeliveryYn = value => {
    setValue('delivery_yn', value ? 'Y' : 'N');
    setDeliveryYn(value);
  };

  const handleChiefFuneralYn = value => {
    setValue('chief_funeral_yn', value ? 'Y' : 'N');
    setChiefFuneralYn(value);
  };

  const handleCondolatoryYn = value => {
    setValue('condolatory_yn', value ? 'Y' : 'N');
    setCondolatoryYn(value);
  };

  const handleChangeMultyYn = event => {
    // setValue('multy_yn', value ? 'Y' : 'N');
    // setMultyYn(value);
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('multy_yn', isChecked);
    setMultyYn(event.target.checked);
  };

  const changeImageFile = event => {
    const { files } = event.target;
    uploadImageFile(files[0]);
  };

  const removeImageFile = () => {
    setDeceasedPhoto(null);
    setValue('deceased_photo', '');
  };

  const changeVideoImageFile = event => {
    const { files } = event.target;
    // 다시 작성해야 하는 부분(API전달받아서 작성)
    uploadVideoImageFile(files[0]);
  };

  const uploadVideoImageFile = async params => {
    const result = await uploadApi(params);

    setVideoPhoto(result.uploadUrl);
    setValue('video_thumbnail', result.uploadUrl);
  };

  const removeVideoImageFile = () => {
    hideConfirm();
    setVideoPhoto(null);
    // 다시 작성해야 하는 부분(API전달받아서 작성)
    setValue('video_thumbnail', '');
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
    //console.log(media);
    //console.log(media.media_link);
    setValue('deceased_video_link', media.media_link);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  const handlePreview = () => {
    setOpenPreviewDialog(true);
  };

  const handleClose = () => {
    navigate('/admin/obituary/pet');
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  // const handleDigitalWreathYn = event => {
  //   const isChecked = event.target.checked ? 'Y' : 'N';
  //   setValue('digital_wreath_yn', isChecked);
  // };

  const handleMessageChange = event => {
    const { value } = event.target;
    setMessage(value);
  };

  const handleDigitalWreathYn = value => {
    setValue('digital_wreath_yn', value ? 'Y' : 'N');
    setWreathYn(value);
  };

  const onSubmit = data => {
    modifyMemorial(data);
  };

  const imageDelete = () => {
    showConfirm(
      '삭제 확인',
      '첨부한 파일을 삭제하시겠습니까?',
      () => removeImageFile(),
      () => hideConfirm(),
    );
  };

  const onError = error => {
    console.log('error > ', error.deceased_name);
  };

  const successModifyMemorial = memorialId => {
    hideAlert();
    // navigate(`/memorial/obituary/view/${memorialId}`);
    window.location.href = `/pet/obituary/view/${memorialId}`;
  };

  useEffect(() => {
    if (relations) setValue('relations_datas', relations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relations]);

  useEffect(() => {
    if (condolatorys) setValue('condolatory_datas', condolatorys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condolatorys]);

  useEffect(() => {
    if (blts) setValue('blt_datas', blts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blts]);

  useEffect(() => {
    if (chudosas) setValue('chudosa_datas', chudosas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chudosas]);

  useEffect(() => {
    if (memorial) {
      Object.entries(memorial).forEach(([key, value]) => {
        setValue(key, value);
      });
      setVideoLink(memorial.deceased_video_link);
      setMessage(memorial.deceased_introduction);
      setDeceasedPhoto(memorial.deceased_photo);
      selectedHall(memorial.funeral_addr);
      setDisplayYn(memorial.display_yn === 'Y' ? true : false);
      setChiefFuneralYn(memorial.chief_funeral_yn === 'Y' ? true : false);
      setBirthYn(memorial.birth_yn === 'Y' ? true : false);
      setFuneralYn(memorial.funeral_yn === 'Y' ? true : false);
      setFuneralAddrYn(memorial.funeral_addr_yn === 'Y' ? true : false);
      setVideoYn(memorial.deceased_video_yn === 'Y' ? true : false);
      setDeliveryYn(memorial.delivery_yn === 'Y' ? true : false);
      setCondolatoryYn(memorial.condolatory_yn === 'Y' ? true : false);
      setMultyYn(memorial.multy_yn === 'Y' ? true : false);
      setWreathYn(memorial.digital_wreath_yn === 'Y' ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorial]);

  useEffect(() => {
    if (loginUser && !loginUser.member_id && loginUser.role !== 'ADMIN') {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      window.location.href = '/';
    }
    if (loginUser && loginUser.member_id) {
      getMemorial(memorialId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">부고 등록</h2>
            <p>
              사랑하는 사람의 부음을 문자 및 단체 알림, SNS 메시지로
              <br className="d-md-block d-none" /> 발송 대행하고
              <br className="d-block d-md-none" />
              장례 의식 공지 등의 서비스를 제공합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          {/* S : 부고 등록 */}
          <div className="wrap-form">
            {/* S : 23-04-03 메모리얼 캔버스 개편에 따른 마크업 수정 */}
            {/* S : 고인정보 */}
            <div className="contents-form">
              <div className="box-title">
                <h3>반려동물 정보</h3>
                <p className="required">* 표시는 필수값입니다.</p>
              </div>
              {/* S : 고인 성함, 직위 */}
              <div className="form-group">
                <div className="ip-wrap gap-10">
                  <div className="ip-item is-error">
                    <label htmlFor="ip-deceased-name">
                      이름{' '}
                      <span className="required">
                        *<span className="offscreen">필수입력</span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id="ip-deceased-name"
                      placeholder="이름을 작성하세요"
                      aria-invalid={errors.deceased_name ? 'true' : 'false'}
                      {...register('deceased_name')}
                    />
                    {errors.deceased_name && (
                      <p className="message">{errors.deceased_name.message}</p>
                    )}
                  </div>
                </div>
              </div>
              {/* E : 고인 성함, 직위 */}
              {/* S : 생년월일 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>태어난 날</h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item">
                    <label htmlFor="ip-deceased-birth" className="offscreen">
                      태어난 날
                    </label>
                    <input
                      type="date"
                      id="ip-deceased-birth"
                      placeholder="연도-월-일"
                      defaultValue=""
                      {...register('deceased_birthday')}
                    />
                  </div>
                </div>
              </div>

              {/* E : 생년월일 */}
              {/* S : 임종일자 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>하늘의 별이된 날</h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item">
                    <label htmlFor="ip-deceased-dying-day" className="offscreen">
                      하늘의 별이된 날
                    </label>
                    <input
                      type="date"
                      id="ip-deceased-dying-day"
                      placeholder="연도-월-일"
                      {...register('deceased_date')}
                    />
                  </div>
                </div>
              </div>
              {/* E : 임종일자 */}
              {/* S : 영정 사진 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>사진</h5>
                </div>

                {/* S : 파일첨부 영역 */}
                <div className="ip-wrap ip-filebox">
                  <span className="label">고인사진</span>
                  <label className="file" htmlFor="imageFile">
                    <span
                      className="offscreen"
                      onClick={() => document.getElementById('imageFile').click()}
                    >
                      파일선택
                    </span>
                  </label>
                  <input
                    type="file"
                    className="input-hidden"
                    id="imageFile"
                    onChange={changeImageFile}
                  />

                  {/* 첨부 이미지 등록 전 */}
                  {/*<div className="file_output default"></div>*/}

                  {/* 첨부 이미지 등록 후 */}
                  {deceasedPhoto ? (
                    <div className="file-output" onChange={changeImageFile}>
                      <span className="img-area">
                        <img
                          alt=""
                          src={deceasedPhoto}
                          //src="https://d3lzegcz38cz56.cloudfront.net/photo/41092f61-c8a8-4b57-8a39-e505942cb9953d9a47a286411ddbb2060b7d2ecef49e4bef53c9.jpg"
                        />
                      </span>
                      <button className="file-del" onClick={() => imageDelete()}>
                        <span className="offscreen">사진 삭제</span>
                      </button>
                    </div>
                  ) : (
                    <div className="file_output default" onChange={changeImageFile}></div>
                  )}
                </div>
                {/* E : 파일첨부 영역 */}
              </div>
              {/* E : 영정 사진 */}
            </div>
            {/* E : 고인정보 */}
            {/* S : 장례정보 : 상주 정보 */}
            <div className="contents-form">
              {/* S : 장례정보 타이틀 */}
              <div className="box-title">
                <h3>보호자 정보</h3>
                <div className="rc-item is-reverse">
                  <input
                    type="checkbox"
                    id="chk-agree1"
                    defaultChecked={true}
                    onChange={handleDeliveryYn}
                  />
                  <label htmlFor="chk-agree1">부고 공유하기를 사용합니다.</label>
                </div>
              </div>
              {/*<div className="box-title">*/}
              {/*  <h4>보호자</h4>*/}
              {/*</div>*/}
              {/* E : 장례정보 타이틀 */}
              {/* S : 대표상주 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>보호자</h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item">
                    <select
                      id="ip-chief-mourner-relation"
                      name={'chief_mourner_desc'}
                      aria-invalid={errors.chief_mourner_desc ? 'true' : 'false'}
                      onChange={handleChange}
                    >
                      {relationPetDetailCodes && relationPetDetailCodes.length > 0
                        ? relationPetDetailCodes.map(code => (
                            <option value={code.code} key={code.code}>
                              {code.value}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="ip-item">
                    <label htmlFor="ip-chief-mourner-name" className="offscreen">
                      보호자
                    </label>
                    <input
                      type="text"
                      id="ip-chief-mourner-name"
                      placeholder="보호자 성함을 작성하세요"
                      {...register('chief_mourner')}
                    />
                  </div>
                </div>
              </div>
              {/* E : 대표상주 */}
              {/* S : 추가상주 */}
              {visual === true ? (
                <Relations
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  setVisual={setVisual}
                  errors={errors}
                />
              ) : (
                ''
              )}
              {/* E : 추가상주 */}

              {/* S : 추가 버튼 */}
              {visual === false ? (
                <div className="btn-wrap">
                  <button
                    type="button"
                    className="btn btn-md-white"
                    onClick={() => setVisual(true)}
                  >
                    보호자 추가
                  </button>
                </div>
              ) : (
                ''
              )}
              {/* E : 추가 버튼 */}
            </div>
            {/* E : 장례정보 : 상주 정보 */}
            {/* S : 장례정보 : 장례식장 정보 */}
            <div className="contents-form">
              {/* E : 장례위원장 */}
              {/* S : 알림글 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>
                    알림글{' '}
                    <span className="required">
                      *<span className="offscreen">필수입력항목</span>
                    </span>
                  </h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label htmlFor="text-tribute" className="offscreen">
                      알림글 입력
                    </label>
                    <textarea
                      id="text-tribute"
                      placeholder="아이와 생에 인연이 있던 지인에게 부고 소식을 알리는 짧은 메시지를 작성해 주세요."
                      {...register('deceased_introduction')}
                      aria-invalid={errors.deceased_introduction ? 'true' : 'false'}
                      onChange={handleMessageChange}
                      value={message}
                      maxLength={500}
                    ></textarea>
                    <div className="count-text">
                      <span>{message.length}</span> / 500자
                    </div>
                    {errors.deceased_introduction && (
                      <p className="message">{errors.deceased_introduction.message}</p>
                    )}
                  </div>
                </div>
              </div>
              {/* E : 알림글 */}
            </div>
            {/* E : 장례정보 : 장례식장 정보 */}
            {/* S : 조의금계좌 등록 */}
            <div className="contents-form">
              <div className="box-title">
                <h3>조의금 계좌 등록 </h3>
                <div className="rc-item is-reverse">
                  <input
                    type="checkbox"
                    id="chk-agree2"
                    defaultChecked={true}
                    onChange={handleCondolatoryYn}
                  />
                  <label htmlFor="chk-agree2">조의금계좌를 공개합니다</label>
                </div>
              </div>

              <Condolatorys
                control={control}
                setValue={setValue}
                getValues={getValues}
                condolatoryYn={condolatoryYn}
                errors={errors}
                ref={textRef}
              />
            </div>
            {/* E : 조의금계좌 등록 */}
            {/* S : 디지털 화환 */}
            <div className="contents-form">
              <div className="box-title">
                <h3>디지털화환</h3>
                <div className="rc-group">
                  <div className="rc-item is-reverse">
                    <input
                      type="checkbox"
                      id="chk-agree3"
                      defaultChecked={false}
                      onChange={handleDigitalWreathYn}
                    />
                    <label htmlFor="chk-agree3">디지털화환을 받습니다</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <p className="desc">
                  디지털 화환을 받으시면 방문자들이 추모관에 디지털 화환으로 추모 메시지를 남길 수
                  있습니다.
                </p>
              </div>
            </div>
            {/* E : 디지털 화환 */}
            {/* S : 추모 영상 */}
            <div className="contents-form">
              <div className="box-title">
                <h3>추모 영상</h3>
              </div>
              <div className="form-group">
                {/* S : 파일첨부 영역 */}
                <div className="ip-wrap ip-file-wrap">
                  <MediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
                  {/* E : 첨부 이미지 등록 후 */}

                  <div className="file-output-wrap">
                    <div className="ip-item is-type-btn">
                      <label className="file" htmlFor="thumbFile">
                        <span className="btn btn-sm-blue">영상 미리보기 사진 첨부</span>
                        <span
                          className="desc"
                          onClick={() => document.getElementById('imageFile').click()}
                        >
                          클릭하여 영상 미리보기 사진을 첨부할 수 있습니다.
                          <br />* 이미지 파일만 첨부 가능하며 사진을 첨부하지 않으시면 기본 사진으로
                          설정됩니다.
                        </span>
                      </label>
                      <input
                        type="file"
                        className="offscreen"
                        id="thumbFile"
                        onChange={changeVideoImageFile}
                      />
                    </div>
                  </div>

                  {/* S : 첨부 이미지 등록 후 */}
                  {videoPhoto ? (
                    <div className="file-output-wrap">
                      <div className="file-output">
                        <div className="file-area is-video">
                          <img alt="" src={videoPhoto} />
                        </div>
                        <button className="file-del" onClick={removeVideoImageFile}>
                          <span className="offscreen">파일 삭제</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="file_output default" onChange={changeVideoImageFile}></div>
                  )}
                  {/* E : 첨부 이미지 등록 후 */}
                </div>
                {/* E : 파일첨부 영역 */}
              </div>
            </div>
            {/* E : 추모 영상 */}
            {/* S : 추도사 작성*/}
            <Chudosas control={control} setValue={setValue} getValues={getValues} errors={errors} />
            {/* E : 추도사 작성 */}
            {/* S : 일대기 작성 */}
            <Blts control={control} setValue={setValue} getValues={getValues} errors={errors} />
            {/* E : 일대기 작성  */}

            {/* S : 부고 공개 여부  */}
            <ul className="rc-list">
              <li>
                <div className="rc-item">
                  <input
                    type="checkbox"
                    id="chk-sample-3"
                    defaultChecked={true}
                    onChange={handleChangeDisplayYn}
                  />
                  <label htmlFor="chk-sample-3">입력한 부고를 공개하시겠습니까?</label>
                </div>
              </li>
              <li>
                {/* E : 부고 공개 여부 확인 */}
                <div className="rc-item">
                  <input
                    type="checkbox"
                    id="chk-sample-4"
                    defaultChecked={false}
                    onChange={handleChangeMultyYn}
                  />
                  <label htmlFor="chk-sample-4">입력한 추모관을 공개하시겠습니까?</label>
                </div>
              </li>
            </ul>
            {/* E : 부고 공개 여부  */}

            {/* S : 버튼 */}
            <div className="btn-wrap">
              <button type="button" className="btn btn-ln-gray " onClick={handleClose}>
                취소
              </button>
              <button
                type="button"
                className="btn btn-navy"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록
              </button>
              <button type="button" className="btn btn-ln-blue" onClick={() => handlePreview()}>
                미리보기
              </button>
            </div>
            {/* E : 버튼 */}
            {/* E : 23-04-03 메모리얼 캔버스 개편에 따른 마크업 수정 */}
          </div>
          {/* E : 부고 등록 */}
        </div>
      </div>
      <ChudosaSampleDialog
        isOpen={openChudosaSampleDialog}
        onClose={() => setOpenChudosaSampleDialog(false)}
      />
      <SearchHallDialog
        isOpen={openSearchHallDialog}
        onClose={() => setOpenSearchHallDialog(false)}
        selectedHall={selectedHall}
      />
      {openPreviewDialog && (
        <PreviewDialog
          isOpen={openPreviewDialog}
          onClose={() => setOpenPreviewDialog(false)}
          getValues={getValues}
        />
      )}
    </section>
  );
}

export default AdminObituaryRegisterPage;

import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { uploadImageApi, uploadVideoApi } from 'apis/openapi';
import MediaViewDialog from 'pages/pet-memorial-canvas/obituary/components/MediaViewDialog';
import Progress from 'components/mui/Progress';
import useDialog from 'stores/dialog/hook';
import useLogin from '../../../../../stores/login/hook';
import LoginDialog from '../../../obituary/components/LoginDialog';
import loadImage from 'blueimp-load-image';

let imageCount = 0;
let videoCount = 0;

function TabMediaUploader({ medias, addMedia, removeMedia }) {
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);

  const [media, setMedia] = useState(null);

  const handleMediaView = media => {
    setMedia(media);
    setOpenMediaViewDialog(true);
  };

  const imageMaxCount = 5;
  const videoMaxCount = 1;

  const imageMaxSize = 5 * 1024 * 1024;
  //  const videoMaxSize = 50 * 1024 * 1024;
  const videoMaxSize = 1 * 1024 * 1024 * 1024;

  const imageTypeCode = 'I';
  const videoTypeCode = 'V';

  const [displayProgressBar, setdisplayProgressBar] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  const uploadPercentage = value => {
    console.log('uploadPercentage > ', value);
    setProgressBarPercentage(value);
  };

  const uploadImageFile = async file => {
    setdisplayProgressBar(true);
    const result = await uploadImageApi(file, uploadPercentage);

    console.log('result > ', result);

    if (result.code === '0000') {
      addMedia({
        media_type: imageTypeCode,
        thumbnail_link: result.thumnailUrl,
        media_link: result.uploadUrl,
      });

      imageCount = imageCount + 1;
    } else {
      showAlert('알림', '파일 업로드 중 오류가 발생하였습니다.', () => hideAlert());
    }
    setProgressBarPercentage(0);
    setdisplayProgressBar(false);
  };

  const uploadVideoFile = async file => {
    setdisplayProgressBar(true);

    const result = await uploadVideoApi(file, uploadPercentage);
    console.log('video result > ', result);
    if (result.code === '0000') {
      addMedia({
        media_type: videoTypeCode,
        thumbnail_link: result.thumnailUrl,
        media_link: result.uploadUrl,
      });

      videoCount = videoCount + 1;
    } else {
      showAlert('알림', '파일 업로드 중 오류가 발생하였습니다.', () => hideAlert());
    }
    setProgressBarPercentage(0);
    setdisplayProgressBar(false);
  };

  const uploadMediaFile = async file => {
    if (!loginUser.member_id) {
      handleOpenLoginDialog();
      return;
    }
    if (file.type.indexOf('image/') > -1) {
      if (file.size > imageMaxSize) {
        showAlert('알림', '사진 최대 용량은 5MB를 초과할 수 없습니다.', () => hideAlert());

        return;
      } else if (imageCount >= imageMaxCount) {
        showAlert('알림', '사진은 최대 5개까지만 등록 가능합니다.', () => hideAlert());

        return;
      }
      await uploadImageFile(file);
      return;
    }

    if (file.type.indexOf('video/') > -1) {
      if (file.size > videoMaxSize) {
        showAlert('알림', '동영상 최대 용량은 50MB를 초과할 수 없습니다.', () => hideAlert());

        return;
      } else if (videoCount >= videoMaxCount) {
        showAlert('알림', '동영상은 최대 1개까지만 등록 가능합니다.', () => hideAlert());

        return;
      }
      await uploadVideoFile(file);
    }
  };

  const handleRemoveMedia = (index, mediaType) => {
    mediaType === 'I' ? imageCount-- : videoCount--;
    removeMedia(index);
  };

  const onDrop = async acceptedFiles => {
    for (const file of acceptedFiles) {
      await uploadMediaFile(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/*', 'video/*'],
  });

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  };

  useEffect(() => {
    if (medias.length === 0) {
      imageCount = 0;
      videoCount = 0;
    }
  }, [medias]);

  return (
    <>
      <div className="ip-wrap ip-file-wrap">
        <div className="ip-item">
          <label className="file" htmlFor="imageFile">
            {/* for 값과 관련하여 css 설정되어 있으니 변경시 반드시 공지해주세요 */}
            <span className="offscreen">파일선택</span>
            사진 및 동영상을 등록할 수 있습니다.
            <br />
            *동영상은 MP4형식의 파일만 등록할 수 있습니다.
          </label>
          <input
            type="file"
            className="offscreen"
            id="imageFile"
            {...getRootProps()}
            {...getInputProps()}
          />
        </div>
        {displayProgressBar ? <Progress value={progressBarPercentage} /> : null}
      </div>
      {/*<Progress value={progressBarPercentage} />*/}
      {/* S : 첨부 이미지 등록 후 */}
      {medias && medias.length > 0 ? (
        <div className="file-output-wrap">
          {medias.map((media, index) => (
            <div className="file-output">
              <div
                className="file-area"
                key={media.thumbnail_link}
                onClick={() => {
                  handleMediaView(media);
                  //alert(JSON.stringify(media));
                }}
                style={{ cursor: 'pointer' }}
              >
                {media.media_type === 'V' ? (
                  <video src={media.media_link} controls></video>
                ) : (
                  <img src={media.media_link} controls alt="" />
                )}
              </div>
              <button
                className="file-del"
                onClick={() => handleRemoveMedia(index, media.media_type)}
              >
                <span className="offscreen">파일 삭제</span>
              </button>
            </div>
          ))}
        </div>
      ) : null}

      <MediaViewDialog
        isOpen={openMediaViewDialog}
        onClose={() => setOpenMediaViewDialog(false)}
        media={media}
      />
      <LoginDialog isOpen={openLoginDialog} onClose={() => setOpenLoginDialog(false)} />
    </>
  );
}
export default TabMediaUploader;

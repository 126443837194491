import { useEffect } from 'react';

import useScript from 'utils/useScript';
import useLogin from 'stores/login/hook';
//import { kakaoLogin } from '../../../../../apis/member';

const KakaoLogin = path => {
  //console.log('memorial..' + path.path);
  const { kakaoLogin } = useLogin();

  const loginKakao = () => {
    // window.Kakao.Auth.authorize({
    //   redirectUri: 'http://localhost:3000/login/kakao',
    // });
    window.Kakao.Auth.login({
      success: function (authObj) {
        //kakaoLogin(authObj.access_token, path.path);
        kakaoLogin(authObj.access_token);
      },
      fail: function (err) {
        console.log(err);
        alert('kakao login failed');
      },
    });
  };

  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');

  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init('c38ef9667188c5012eda6562f4403007');
      }
    }

    //alert('확인1' + petValue);
    //alert('확인' + JSON.stringify(path));
  }, [status]);

  return (
    <li>
      <button type="button" className="btn-kakao" onClick={loginKakao}>
        카카오 로그인
      </button>
    </li>
  );
};

export default KakaoLogin;

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { modifyMemberApi, getMemberApi, resetPwdApi, getMemberHistoryApi } from 'apis/member';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function AdminMemberModifyPage() {
  const navigate = useNavigate();
  const { memberId } = useParams();

  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { memberTypeCodes, loginTypeCodes } = useCode();

  const [member, setMember] = useState(null);
  const [memberHistory, setMemberHistory] = useState(null);

  const schema = Yup.object({
    email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    member_name: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    mobile: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    birth: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
        '올바른 생년월일을 입력해주세요. 예) 19900101',
      ),
  }).required();

  const defaultValues = {
    member_id: '',
    member_name: '',
    member_type: 'N',
    passwd: '',
    email: '',
    mobile: '',
    addr: '',
    birth: '',
    use_yn: 'Y',
    login_type: 'E',
    nation_number: 82,
    nation_name: '대한민국',
    del_yn: 'N',
    status: 1,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const modifyMember = async params => {
    const result = await modifyMemberApi(params);
    result.type === 'success'
      ? showAlert('알림', result.message, () => handleClose())
      : showAlert('알림', result.message, () => hideAlert());
  };

  const getMember = async params => {
    const result = await getMemberApi(params);
    setMember(result.data);
    //console.log(JSON.stringify(result.data));
  };

  const getMemberHistory = async params => {
    const result = await getMemberHistoryApi(params);
    setMemberHistory(result.data);
  };

  const resetPassword = async parmas => {
    const result = await resetPwdApi(parmas);
    showAlert('알림', result.message, () => hideAlert());
  };

  const onSubmit = data => {
    modifyMember(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleClose = () => {
    hideAlert();
    navigate('/admin/member');
  };

  const handleResetPassword = async () => {
    showConfirm(
      '비밀번호 초기화 확인',
      '비밀번호를 정말 초기화 하시겠습니까?',
      () => confirmResetPassword(),
      () => hideConfirm(),
    );
  };

  // 확인 눌렀을때 휴면해제, 처리완료되었다고 팝업 띄우기
  const handleSleeperAccount = async () => {
    showConfirm(
      '알림',
      '해당 회원을 휴면 해제 하시겠습니까?',
      () => handleModifySleeperAccount(),
      () => hideConfirm(),
    );
  };

  const handleModifySleeperAccount = () => {
    hideConfirm();
    modifyMember({ member_id: member.member_id, status: '1' });
  };

  // 확인 눌렀을때 회원탈퇴, 처리완료되었다고 팝업 띄우기
  const handledWithDrawal = async () => {
    showConfirm(
      '알림',
      '해당 회원을 탈퇴 처리하시겠습니까?',
      () => handleModifyWithDrawalAccount(),
      () => hideConfirm(),
    );
  };

  const handleModifyWithDrawalAccount = () => {
    hideConfirm();
    modifyMember({ member_id: member.member_id, status: '0' });
  };

  const confirmResetPassword = () => {
    hideConfirm();
    resetPassword({ email: member.email, mobile: member.mobile, send_type: 'S' });
  };

  const time = epochTime => {
    const date = new Date(epochTime);

    // Date 객체를 사용하여 원하는 날짜 및 시간 형식으로 변환
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (member) {
      Object.entries(member).forEach(([key, value]) => {
        setValue(key, value);
      });
      getMemberHistory({ member_id: member.member_id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) getMember(memberId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>회원수정</h2>
      </div>

      <div className="content-box wrap-detail">
        {/* S : 정산정보 입력 */}
        <div className="content-detail">
          <h3>기본정보</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <colgroup>
                <col width="25%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label htmlFor="ipSample2">이메일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input
                        type="text"
                        id="ipSample2"
                        {...register('email')}
                        aria-invalid={errors.email ? 'true' : 'false'}
                        disabled
                      />
                      {errors.email && <p className="message">{errors.email.message}</p>}
                      {/*<button*/}
                      {/*  type="button"*/}
                      {/*  className="btn btn-sm"*/}
                      {/*  onClick={() => alert('본인인증 완료')}*/}
                      {/*>*/}
                      {/*  본인인증 완료*/}
                      {/*</button>*/}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">이름</label>
                  </th>
                  <td className="input">
                    {/* S : 에러 케이스 */}
                    <div className="ip-item is-error">
                      <input
                        type="text"
                        id="ipSample1"
                        {...register('member_name')}
                        aria-invalid={errors.member_name ? 'true' : 'false'}
                      />
                      {errors.member_name && (
                        <p className="message">{errors.member_name.message}</p>
                      )}
                    </div>
                    {/* E : 에러 케이스 */}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample4">비밀번호</label>
                  </th>
                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-sm"
                      onClick={() => handleResetPassword()}
                    >
                      비밀번호 초기화
                    </button>
                  </div>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample3">전화번호</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input
                        type="text"
                        id="ipSample3"
                        {...register('mobile')}
                        aria-invalid={errors.mobile ? 'true' : 'false'}
                      />
                      {errors.mobile && <p className="message">{errors.mobile.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label htmlFor="ipSample3">생년월일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      <input
                        type="text"
                        id="ipSample6"
                        {...register('birth')}
                        aria-invalid={errors.birth ? 'true' : 'false'}
                      />
                      {errors.birth && <p className="message">{errors.birth.message}</p>}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 정산정보 입력 */}

        {/* S : 디지털 화환 총 모금액 */}
        <div className="content-detail">
          <h3>추가정보</h3>
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="ipSample7">사용자타입</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <select
                        title="선택"
                        id="selSample7"
                        {...register('member_type')}
                        aria-invalid={errors.member_type ? 'true' : 'false'}
                      >
                        {memberTypeCodes && memberTypeCodes.length > 0
                          ? memberTypeCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                      {errors.member_type && (
                        <p className="message">{errors.member_type.message}</p>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample8">로그인타입</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <select
                        title="선택"
                        id="selSample8"
                        {...register('login_type')}
                        aria-invalid={errors.login_type ? 'true' : 'false'}
                        disabled
                      >
                        {loginTypeCodes && loginTypeCodes.length > 0
                          ? loginTypeCodes.map(code => (
                              <option value={code.code} key={code.code}>
                                {code.value}
                              </option>
                            ))
                          : null}
                      </select>
                      {errors.login_type && <p className="message">{errors.login_type.message}</p>}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label htmlFor="ipSample9">가입일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item is-error">
                      {/*<input type="text" id="ipSample2" {...register('createdtime')} disabled />*/}
                      <input type="text" id="ipSample2" value={time(member.createdtime)} disabled />
                      {member?.status === '0' ? (
                        <p className="message">* 탈퇴한 회원입니다.</p>
                      ) : (
                        ''
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* S : 회원 상태 기록 */}
          <div className="list-calculate">
            <p>회원 상태</p>
            <div className="tbl-wrap over-scroll">
              <table className="tbl-col tbl-sm">
                <caption className="offscreen">상태 기록 내역</caption>
                <colgroup>
                  <col width="30%" />
                  <col width="35%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">상태</th>
                    <th scope="col">일자</th>
                  </tr>
                </thead>
                <tbody>
                  {memberHistory && memberHistory.length > 0
                    ? memberHistory.map(row => (
                        <tr key={row.member_id}>
                          <td>{row.status}</td>
                          <td>{row.modifydtime}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="btn-wrap center">
            {member?.status === '2' ? (
              <button
                type="button"
                className="btn btn-lg btn-white"
                onClick={() => handleSleeperAccount()}
              >
                휴면해제
              </button>
            ) : (
              ''
            )}
            {member?.status !== '0' ? (
              <button
                type="button"
                className="btn btn-lg btn-white"
                onClick={() => handledWithDrawal()}
              >
                회원탈퇴
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* E : 디지털 화환 총 모금액 */}
      </div>

      {/* S : 버튼 */}
      <div className="btn-wrap center">
        <button
          type="button"
          className="btn btn-lg btn-pos"
          onClick={handleSubmit(onSubmit, onError)}
        >
          저장
        </button>
        <button type="button" className="btn btn-lg btn-white" onClick={handleClose}>
          취소
        </button>
      </div>
      {/* E : 버튼 */}
    </div>
  );
}

export default AdminMemberModifyPage;

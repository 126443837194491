import { useState } from 'react';

import imgFooterLogo from 'assets/images/footer_logo.png';
// import imgFooterIco1 from 'assets/images/footer_ico1.png';
// import imgFooterIco2 from 'assets/images/footer_ico2.png';

import ServiceDialog from '../Login/ServiceDialog';
import PrivacyDialog from '../Login/PrivacyDialog';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [openServiceDialog, setOpenServiceDialog] = useState(false);

  const [isActive, setActive] = useState(false);

  const moveMenu = url => {
    setActive(false);
    // navigate(url);
    window.location.replace(url);
  };

  return (
    <>
      <footer>
        <div className="footer-content">
          <ul>
            <li>
              <a href={() => false} onClick={() => setOpenServiceDialog(true)}>
                이용약관
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => setOpenPrivacyDialog(true)}>
                개인정보처리방침
              </a>
            </li>
          </ul>
          <div className="address">
            <p>
              <span className="d-md-block">대표자 : 서종원</span>
              <span className="bar d-md-none"></span>사업자등록번호 : 120-86-10499
              <span className="bar d-md-none"></span>
              <span className="d-md-block">통신판매업신고 2009-서울강남-00290</span>
            </p>
            <p>
              <span className="d-md-block">사업장 주소 : 서울시 강남구 봉은사로 114길 12</span>
              <span className="bar d-md-none"></span>대표번호 : 02-550-8700
              <span className="bar"></span>팩스 : 02-563-8399
            </p>
            <p>
              <span className="d-md-block">서비스 문의 : mylifecanvas@idstrust.com</span>
              <span className="bar d-md-none"></span>상담가능시간 : 오전 9:00 - 오후 6:00
            </p>
          </div>
          {/* S : family site */}
          <div className={`family-site js-f-site ${isActive ? 'is-active' : ''}`}>
            <a href={() => false} className="btn-select" onClick={() => setActive(!isActive)}>
              Family Site
            </a>
            <div className="site-list">
              <ul>
                <li>
                  <a href={() => false} target="_blank" onClick={() => moveMenu('/pet')}>
                    <i class="ic ic-sm-pet"></i>펫 메모리얼캔버스
                  </a>
                </li>
                <li>
                  <a href={() => false} target="_blank" onClick={() => moveMenu('/memorial')}>
                    <i class="ic ic-sm-memorial"></i>메모리얼캔버스
                  </a>
                </li>
                <li>
                  <a href={() => false} target="_blank" onClick={() => moveMenu('/life')}>
                    <i class="ic ic-sm-life"></i>라이프캔버스
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* E : family site */}

          <div className="copyright">
            <span className="offscreen">© Mcircle Corp.</span>
          </div>
        </div>
        {openPrivacyDialog && (
          <PrivacyDialog isOpen={openPrivacyDialog} onClose={() => setOpenPrivacyDialog(false)} />
        )}
        {openServiceDialog && (
          <ServiceDialog isOpen={openServiceDialog} onClose={() => setOpenServiceDialog(false)} />
        )}
      </footer>
    </>
  );
}

export default Footer;

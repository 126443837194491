import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';

import { getMemorialsApi, deleteMemorialApi, memorialTransApi } from 'apis/memorial';

import Pagination from 'react-js-pagination';
import ObituaryThanksDialog from 'pages/pet-memorial-canvas/mypage/components/ObituaryThanksDialog';
import SharedDialog from 'pages/pet-memorial-canvas/obituary/components/SharedDialog';
import WreathCustomDialog from 'pages/pet-memorial-canvas/obituary/components/WreathCustomDialog';

import noDeceasedPhoto from 'assets/images/no_img_obituary.jpg';

import 'css/pagination.css';

function MypageObituaryPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { loginUser, setOpenLogin } = useLogin();
  const { setGnbType } = useLayout();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const [searchObituarys, setSearchObituarys] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(6);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [searchWord, setSearchWord] = useState();

  const [openThanksDialog, setOpenThanksDialog] = useState(false);
  const [openSharedDialog, setOpenSharedDialog] = useState(false);
  const [openWreathCustomDialog, setOpenWreathCustomDialog] = useState(false);

  const [selectedMemorialId, setSelectedMemorialId] = useState(0);

  const [currentObituary, setCurrentObituary] = useState({});

  const getSearchObituaryTotalCount = async () => {
    let params = {};
    params.status = 1;
    params.multy_type = 'N';
    params.own_member_id = loginUser.member_id;
    if (searchValue && searchValue !== '') params.deceased_name = searchValue;

    const result = await getMemorialsApi(params);
    setTotalCount(result.data.length);
  };

  const getSearchObituarys = async pageNum => {
    let params = {};
    params.status = 1;
    params.multy_type = 'N';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.own_member_id = loginUser.member_id;
    params.page = pageNum;
    params.itemInPage = listSize;
    // if (searchValue && searchValue !== '') params.deceased_name = searchValue;
    if (searchWord) params.searchWord = searchWord;

    const result = await getMemorialsApi(params);
    if (result.code === '0000') {
      setSearchObituarys(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getSearchObituarysMore = async pageNum => {
    let params = {};
    params.status = 1;
    params.multy_type = 'N';
    params.orderBy = { direction: 'desc', field: 'memorial_id' };
    params.own_member_id = loginUser.member_id;
    params.page = pageNum;
    params.itemInPage = listSize;
    // if (searchValue && searchValue !== '') params.deceased_name = searchValue;
    if (searchWord) params.searchWord = searchWord;

    const result = await getMemorialsApi(params);
    if (result.code === '0000') {
      setSearchObituarys([...searchObituarys, ...result.data]);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getSearchObituarysMore(nextPage);
    }
  };

  const deleteMemorial = async params => {
    const result = await deleteMemorialApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getSearchObituarys(1);
  };

  const memorialTrans = async params => {
    const result = await memorialTransApi(params);
    showAlert('알림', result.message, () => hideAlert());
    getSearchObituarys(1);
  };

  const handleSearch = () => {
    getSearchObituarys(1);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getSearchObituarys(selectedPage);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleRegister = () => {
    navigate('/pet/obituary/register');
  };

  const handleModify = memorialId => {
    navigate(`/pet/obituary/modify/${memorialId}`);
  };

  const handleObituarys = memorialId => {
    navigate(`/pet/obituary/view/${memorialId}`, { state: { viewTab: 4 } });
  };

  const handleObituarysId = memorialId => {
    navigate(`/pet/obituary/view/${memorialId}`);
  };

  const handleDelete = memorialId => {
    showConfirm(
      '삭제 확인',
      '정말 삭제 하시겠습니까?',
      () => handleConfirm(memorialId),
      () => hideConfirm(),
    );
  };

  const handleChangeStatus = memorialId => {
    showConfirm(
      '추모관 전환 확인',
      '선택한 부고를 추모관으로 정말 전환하시겠습니까?',
      () => confirmChangeStatus(memorialId),
      () => hideConfirm(),
    );
  };

  const confirmChangeStatus = memorialId => {
    hideConfirm();
    memorialTrans({ memorial_id: memorialId, status: '2' });
  };

  const handleConfirm = memorialId => {
    hideConfirm();
    deleteMemorial(memorialId);
  };

  const handleOpenThanksDialog = memorialId => {
    setSelectedMemorialId(memorialId);
    setOpenThanksDialog(true);
  };

  const handleOpenWreathCustomDialog = () => {
    if (loginUser && loginUser.member_id) {
      setOpenWreathCustomDialog(true);
    } else {
      navigate('/pet/login');
    }
  };

  const [isReload, setReload] = useState(false);
  const handleReload = () => {
    setReload(true);
    setOpenWreathCustomDialog(false);
    setReload(false);
  };

  const handleTabClick = () => {
    // 부고 탭 클릭 시 페이지를 다시 로드합니다.
    //history.push('/memorial/mypage/obituary');
    window.location.reload();
    navigate('/pet/mypage/obituary');
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser && loginUser.member_id) {
      getSearchObituaryTotalCount();
      getSearchObituarys(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">마이페이지 </h2>
            <p>
              메모리얼 캔버스는
              <br />
              새로운 장례 문화를 선도하는 사회적 기업입니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          <div className="tabs tabs-default">
            <ul>
              <li className="is-active">
                <a href={() => false}>
                  <Link className="on" to="/pet/mypage/obituary" onClick={handleTabClick}>
                    부고
                  </Link>
                </a>
              </li>
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/memorial">추모관</Link>
                </a>
              </li>
              {/*<li>*/}
              {/*  <a href={() => false}>*/}
              {/*    <Link className="on" to="/pet/mypage/wreath">*/}
              {/*      구매내역*/}
              {/*    </Link>*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/member">회원설정</Link>
                </a>
              </li>
            </ul>
          </div>

          <div className="wrap-mypage">
            {/* S : 목록 상단 검색 + 버튼 */}
            <div className="list-head">
              {/* S : 목록 검색 */}
              <div className="box-search">
                <label for="ip-search" className="offscreen">
                  검색하기
                </label>
                <input
                  type="search"
                  id="ip-search"
                  placeholder="반려동물의 이름을 입력하세요"
                  value={searchWord}
                  onChange={e => setSearchWord(e.target.value)}
                  onKeyPress={onKeyPress}
                />
                <button type="button" ref={searchRef} onClick={() => handleSearch()}>
                  <i className="ic ic-search"></i>
                  <span className="offscreen">검색하기</span>
                </button>
              </div>
              {/* E : 목록 검색 */}
              {/* S : 버튼 */}
              <a href={() => false} className="btn btn-pos" onClick={handleRegister}>
                부고 등록
              </a>
              {/* E : 버튼 */}
            </div>
            {/* E : 목록 상단 검색 + 버튼 */}

            {/* S : 작성한 부고 글 목록 - 개발서버*/}
            {/* {searchObituarys && searchObituarys.length > 0 ? (
              <div className="list-obituary">
                {searchObituarys.map(obituary => (
                  <div className="box-obituary" key={obituary.memorial_id}>
                    <div className="inner">
                      <a
                        href={() => false}
                        className="img-link"
                        onClick={() => handleObituarysId(obituary.memorial_id)}
                      >
                        <div className="img">
                          <span className="chk-size">
                            <img
                              alt=""
                              src={
                                obituary.deceased_photo ? obituary.deceased_photo : noDeceasedPhoto
                              }
                            />
                          </span>
                        </div>
                      </a>

                      <div className="content-obituary">
                        <div className="obituary-info">
                          <p className="name">
                            {`${obituary.deceased_name} ${obituary.deceased_job_title}`}
                            {obituary.display_yn === 'N' ? (
                              <span className="secret">
                                <i className="ic ic-secret2"></i>
                                <span className="offscreen">비밀글</span>
                              </span>
                            ) : (
                              ' '
                            )}
                          </p>
                          <ul className="btns">
                            <li>
                              <a
                                href={() => false}
                                onClick={() => handleModify(obituary.memorial_id)}
                              >
                                수정
                              </a>
                            </li>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleDelete(obituary.memorial_id)}
                              >
                                삭제
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div className="btn-wrap">
                          <ul className="btns">
                            <li className={`${obituary.digital_wreath_yn === 'Y' ? 'w-100' : ''}`}>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => {
                                  setCurrentObituary(obituary);
                                  setOpenSharedDialog(true);
                                }}
                              >
                                부고전송
                              </button>
                            </li>

                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleOpenThanksDialog(obituary.memorial_id)}
                              >
                                감사인사 전송
                              </button>
                            </li>
                            {obituary.digital_wreath_yn === 'Y' ? (
                              <li>
                                <button
                                  type="button"
                                  className="btn btn-sm-ln-blue"
                                  // onClick={() => handleOpenWreathCustomDialog()}
                                  onClick={() =>
                                    navigate(`/memorial/mypage/wreath/${obituary.memorial_id}`)
                                  }
                                >
                                  디지털 화환
                                </button>
                              </li>
                            ) : (
                              ''
                            )}
                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleChangeStatus(obituary.memorial_id)}
                              >
                                추모관 전환
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleObituarys(obituary.memorial_id)}
                              >
                                추모의 글
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null} */}
            {/* E : 작성한 부고 글 목록 - 개발서버*/}

            {/* S : 작성한 부고 글 목록 - 운영서버*/}
            {searchObituarys && searchObituarys.length > 0 ? (
              <div className="list-obituary">
                {searchObituarys.map(obituary => (
                  <div className="box-obituary" key={obituary.memorial_id}>
                    <div className="inner">
                      <a
                        href={() => false}
                        className="img-link"
                        onClick={() => handleObituarysId(obituary.memorial_id)}
                      >
                        <div className="img">
                          <span className="chk-size">
                            <img
                              alt=""
                              src={
                                obituary.deceased_photo ? obituary.deceased_photo : noDeceasedPhoto
                              }
                            />
                          </span>
                        </div>
                      </a>

                      <div className="content-obituary">
                        <div className="obituary-info">
                          <p className="name">
                            {`${obituary.deceased_name} ${obituary.deceased_job_title}`}
                            {obituary.display_yn === 'N' ? (
                              <span className="secret">
                                <i className="ic ic-secret2"></i>
                                <span className="offscreen">비밀글</span>
                              </span>
                            ) : (
                              ' '
                            )}
                          </p>
                          <ul className="btns">
                            <li>
                              <a
                                href={() => false}
                                onClick={() => handleModify(obituary.memorial_id)}
                              >
                                수정
                              </a>
                            </li>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleDelete(obituary.memorial_id)}
                              >
                                삭제
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div className="btn-wrap">
                          <ul className="btns">
                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => {
                                  setCurrentObituary(obituary);
                                  setOpenSharedDialog(true);
                                }}
                              >
                                부고전송
                              </button>
                            </li>

                            {/*<li>*/}
                            {/*  <button*/}
                            {/*    type="button"*/}
                            {/*    className="btn btn-sm-ln-blue"*/}
                            {/*    onClick={() => handleOpenThanksDialog(obituary.memorial_id)}*/}
                            {/*  >*/}
                            {/*    감사인사 전송*/}
                            {/*  </button>*/}
                            {/*</li>*/}
                            {/* 여기서부터는 주석풀지말기 */}
                            {/* {obituary.digital_wreath_yn === 'Y' ? (
                              <li>
                                <button
                                  type="button"
                                  className="btn btn-sm-ln-blue"
                                  // onClick={() => handleOpenWreathCustomDialog()}
                                  onClick={() =>
                                    navigate(`/memorial/mypage/wreath/${obituary.memorial_id}`)
                                  }
                                >
                                  디지털 화환
                                </button>
                              </li>
                            ) : (
                              ''
                            )} */}
                            {/* 여기까지 */}
                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleChangeStatus(obituary.memorial_id)}
                              >
                                추모관 전환
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className="btn btn-sm-ln-blue"
                                onClick={() => handleObituarys(obituary.memorial_id)}
                              >
                                추모의 글
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {/* E : 운영반영 */}

            {/* S : 페이징 */}
            <div className="btn-wrap">
              <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
                더보기 {page}/{totalPage}
              </button>
            </div>
            {/* E : 페이징 */}
          </div>

          <SharedDialog
            isOpen={openSharedDialog}
            onClose={() => setOpenSharedDialog(false)}
            memorial={currentObituary}
          />
          <ObituaryThanksDialog
            isOpen={openThanksDialog}
            onClose={() => setOpenThanksDialog(false)}
            memorialId={selectedMemorialId}
          />
          <WreathCustomDialog
            isOpen={openWreathCustomDialog}
            onClose={() => setOpenWreathCustomDialog(false)}
            obituary={currentObituary}
            handleReload={handleReload}
          />
        </div>
      </div>

      {/* E : CONTENT */}
    </section>
  );
}

export default MypageObituaryPage;

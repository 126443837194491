import { useEffect, useState } from 'react';

import Memorial from './components/memorial';
import Premium from './components/premium';
import Author from './components/author';
import { useLocation } from 'react-router-dom';
import useLogin from '../../../stores/login/hook';

function ServicePage() {
  const { state } = useLocation();

  const [mode, setMode] = useState('memorial');
  const [loginCheck, setLoginCheck] = useState(false);

  const loginUser = useLogin();

  useEffect(() => {
    if (state && state.viewTab) {
      setMode(state.viewTab);
    }
  }, []);

  useEffect(() => {
    //alert('확인하려구 ' + JSON.stringify(loginUser));
    if (loginUser && loginUser.member_id) {
      setLoginCheck(true);
    } else {
      setLoginCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <>
      {mode === 'memorial' ? (
        <Memorial setMode={setMode} />
      ) : mode === 'premium' ? (
        <Premium setMode={setMode} loginCheck={loginCheck} />
      ) : (
        <Author setMode={setMode} />
      )}
    </>
  );
}

export default ServicePage;

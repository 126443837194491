import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';

import useDialog from 'stores/dialog/hook';
import useLayout from '../../../../../stores/layout/hook';
import KakaoMap from '../../../../../components/KakaoMap';
import copy from 'copy-to-clipboard';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

function FuneralViewDialog({ isOpen, onClose, hall, funeralAddr }) {
  const { setGnbType } = useLayout();

  const { showAlert, hideAlert } = useDialog();
  const tMap = (addr, lng, lat) => {
    if (isMobile) {
      if (isAndroid || isIOS) {
        window.location.href =
          'https://apis.openapi.sk.com/tmap/app/routes?appKey=ZZcxvuTEPg3ONafLLiwh22rXg1x1cmQjtzctWtk0&name=' +
          addr +
          '&lon=' +
          lng +
          '&lat=' +
          lat;
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };

  const kakaoMap = (addr, lng, lat) => {
    window.open('https://map.kakao.com/link/to/' + addr + ',' + lat + ',' + lng, '_blank');
  };

  const NaverMap = (addr, lng, lat) => {
    // window.open(
    //   'http://m.map.naver.com/route.nhn?menu=route&ename=' + addr + '&ex=' + lat + '&ey=' + lng,
    //   '_blank',
    // );
    if (isMobile) {
      if (isAndroid || isIOS) {
        window.open('nmap://place?lat=' + lat + '&lng=' + lng + '&name==' + addr, '_blank');
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };
  const shreadAddr = async () => {
    try {
      copy(hall.hall_addr);
      showAlert('알림', '주소복사가 완료되었습니다.', () => hideAlert());
    } catch (error) {
      console.log('error > ', error);
      showAlert('알림', '주소복사에 실패하였습니다.', () => hideAlert());
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
        {hall ? (
          <div className="wrap is-new" id="memorialCanvas">
            <div className="modal is-map" id="modal-map" aria-hidden="true">
              <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
                <div
                  className="modal__container"
                  role="dialog"
                  data-size="small"
                  aria-modal="true"
                  aria-labelledby="modal-agreement-title"
                >
                  <div className="modal__header">
                    <h2 className="modal__title">오시는 길</h2>
                    <button
                      className="modal__close js-modal-close"
                      aria-label="Close modal"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      <i className="ic ic-close"></i>
                    </button>
                  </div>
                  <div className="modal__content">
                    <div className="wrap-map">
                      <div className="box-map">
                        {/* S : 지도 노출 영역 */}
                        {/*<div style="width:100%;height:100%;background:red;opacity: .1;"></div>*/}
                        {/*<div style={{ width: '100%', height: '100%' }}>*/}
                        <KakaoMap location={hall.hall_addr} />
                        {/*</div>*/}
                        {/* E : 지도 노출 영역 */}
                      </div>
                      <ul className="btn-go-map">
                        <li>
                          <button
                            type="button"
                            onClick={() => NaverMap(hall.hall_addr, hall.logt, hall.lat)}
                          >
                            <i className="ic ic-navermap"></i> 네이버 지도
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => kakaoMap(hall.hall_addr, hall.logt, hall.lat)}
                          >
                            <i className="ic ic-kakaomap"></i> 카카오 맵
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => tMap(hall.hall_addr, hall.logt, hall.lat)}
                          >
                            <i className="ic ic-tmap"></i>티맵
                          </button>
                        </li>
                      </ul>
                      <div className="map-info">
                        <p className="title">{hall.hall_name}</p>
                        <p className="address">{hall.hall_addr}</p>
                        <div className="btn-map-info">
                          <button type="button" className="btn-sm" onClick={() => shreadAddr()}>
                            <i className="ic ic-text-copy-black"></i> 주소 복사
                          </button>
                          {/*<a href="tel:042-220-9870" className="btn-sm">*/}
                          <a href={`tel:${hall.hall_contact}`} className="btn-sm">
                            <i className="ic ic-copy-tel"></i> {hall.hall_contact}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no_data">등록된 장례정보가 없습니다.</div>
        )}
      </Dialog>
    </>
  );
}
export default FuneralViewDialog;

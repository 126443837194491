import { useNavigate } from 'react-router-dom';
import { saveConsultBoardApi } from 'apis/consultBoard';
import React, { useState, useEffect, useRef } from 'react';

import imgIntro01 from 'css/static/media/img_about_runeral_01.png';
import imgIntro02 from 'css/static/media/img_service_funeral_01.png';
import imgIntro03 from 'css/static/media/img_service_funeral_02.png';
import imgIntro04 from 'css/static/media/img_service_funeral_03.png';
import imgIntro05 from 'css/static/media/img_service_funeral_04.png';
import imgIntro06 from 'css/static/media/img_service_funeral_05.png';
import imgIntro07 from 'css/static/media/img_service_funeral_06.png';

import useDialog from '../../../../stores/dialog/hook';
import useLogin from 'stores/login/hook';
import { getMemberApi } from '../../../../apis/member';

function ServicePremiumPage({ setMode, loginCheck }) {
  const navigate = useNavigate();
  const loginUser = useLogin();

  const { showAlert, hideAlert } = useDialog();

  const [mobile, setMobile] = useState(null);

  const handleRegister = () => {
    if (loginUser.loginUser && loginUser.loginUser.member_id) {
      // console.log(JSON.stringify(loginUser.loginUser));

      //alert(mobile);
      saveConsultBoard();
    } else {
      showAlert('알림', '로그인 후 이용 가능합니다.', () => login());
      //console.log(JSON.stringify(loginUser.loginUser));
      //window.open('https://happytalk.io/l/Hukgmn');
    }

    // alert(loginCheck);
    // if (loginCheck === true) {
    //   alert('로그인 됐어');
    // } else {
    //   alert(';로그인해');
    // }
    // window.open('https://happytalk.io/l/Hukgmn');
  };

  const login = () => {
    navigate('/pet/login');
    hideAlert();
  };

  const getMember = async params => {
    const result = await getMemberApi(params);
    setMobile(result.data.mobile);
  };

  // 다시 확인해서 하기
  const saveConsultBoard = async () => {
    let params = {};
    params.req_name = loginUser.loginUser.member_name;
    params.req_phone = mobile;
    params.req_email = loginUser.loginUser.email;
    params.deceased_name = '　';
    params.relation_desc = '　';
    params.basic_contents = '상담신청';
    params.type = '003';
    params.item_type = '004';
    const result = await saveConsultBoardApi(params);
    // alert('들어왔나?');
    if (result.code === '0000') {
      //alert(JSON.stringify(result.data));
      window.open('https://21gramhelp.channel.io/');
    } else {
      showAlert('알림', '마이페이지 → 회원설정에서 전화번호를 입력해주세요.', () => hideAlert());
    }
    //showAlert('알림', result.message, () => hideAlert());
  };

  useEffect(() => {
    getMember(loginUser.loginUser.member_id);
  }, []);

  return (
    <section className="container is-about" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        <div className="contents-width">
          <h3>펫 메모리얼 캔버스 소개</h3>

          <div className="tabs tabs-about">
            <ul>
              <li>
                <a href={() => false} onClick={() => setMode('memorial')}>
                  펫 메모리얼 캔버스란?
                </a>
              </li>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('premium')}>
                  장례 안내
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => setMode('author')}>
                  작가 안내
                </a>
              </li>
            </ul>
          </div>

          <div className="box-title">
            <h4 className="title">장례 서비스는</h4>
            <p className="title sm">
              장례 서비스는 <em>‘21그램’ 과 함께합니다.</em>
            </p>
            <p className="desc">슬픔의 처음부터 끝까지 함께하겠습니다.</p>
          </div>

          <div className="wrap-service">
            <div className="box-service-info">
              <div className="box-img">
                <img src={imgIntro01} />
              </div>
              <div className="box-text">
                <p className="title">
                  아이와의 이별을 <br />
                  생각해보신 적 있으신가요?
                </p>
                <p className="desc">
                  우리는 모두 언젠가 삶이라는 여행을 끝내고
                  <br />
                  곁을 떠나게 된다는 사실을 알고 있습니다.
                  <br />
                  하지만, 막상 그 상황에 놓이면 <br className="d-none d-md-block" />
                  당황하고 슬퍼하느라 정신이 없습니다.
                </p>
                <p className="desc">
                  그렇기에 우리는 아이들과의 이별을 <br className="d-none d-md-block" />
                  미리 준비해야 합니다.
                  <br />
                  한번뿐인 이별의 순간에 후회가 남지 않도록, <br className="d-none d-md-block" />
                  이별에도 준비가 필요합니다.
                </p>
                <div className="btn-wrap">
                  <a
                    href={() => false}
                    className="btn btn-pos"
                    // onClick={() => navigate('/pet/service/funeral/register')}
                    onClick={handleRegister}
                  >
                    상담 바로가기
                  </a>
                </div>
              </div>
            </div>

            <div className="wrap-process">
              <h5>상담 절차</h5>

              <div className="contents-process">
                <ol className="box-process-detail">
                  <li>
                    <div className="box-img">
                      <img src={imgIntro02} />
                    </div>
                    <div className="box-text">
                      <p className="title">1. 장례 상담</p>
                      <p>
                        아이가 무지개 다리를 건너기 전 위의 상담하기 버튼을 클릭하여 상담을
                        진행합니다.
                        <br />
                        상담은 카카오톡 상담하기 서비스를 활용하여 진행되므로 카카오톡 로그인 후
                        문의사항을 남기면 빠른 시간내에 상담사가 답변드립니다.
                      </p>
                    </div>
                    <div className="box-desc">
                      <p>
                        특별한 외상이 없는 경우 1-2일 정도 자택에서 함께 시간을 보낸 뒤 장례를
                        진행하셔도 괜찮습니다.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box-img">
                      <img src={imgIntro03} />
                    </div>
                    <div className="box-text">
                      <p className="title">2. 장례식장 예약 및 방문</p>
                      <p>
                        아이가 무지개 다리를 건너면 대표번호(1688-1240) 또는 온라인 예약을 통해
                        원하는 일시에 장례를 예약하고,
                        <br />
                        예약 일시에 아이를 담요로 감싸거나 상자에 안치하여 장례식장으로 방문합니다.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box-img">
                      <img src={imgIntro04} />
                    </div>
                    <div className="box-text">
                      <p className="title">3. 담당 장례지도사 상담</p>
                      <p>
                        장례부서 이후 안치과정까지, 보호자님께서 원하시는 방향에 맞게 장례를
                        진행하실 수 있도록
                        <br />
                        담당 장례지도사가 차분하게 상담을 도와드립니다.
                      </p>
                    </div>
                    <div className="box-desc">
                      <p>장례용품 추가를 원하실 경우, 상담 시 직접 보고 선택하실 수 있습니다.</p>
                    </div>
                  </li>
                  <li>
                    <div className="box-img">
                      <img src={imgIntro05} />
                    </div>
                    <div className="box-text">
                      <p className="title">4. 염습</p>
                      <p>
                        아이의 생전과 가장 비슷한 모습으로 인사를 나누실 수 있도록 최소한의 손길로
                        깨끗하고 단정하게 정리합니다
                      </p>
                    </div>
                    <div className="box-desc">
                      <p>장례용품을 추가로 선택하신 경우, 입복 및 입관을 진행합니다.</p>
                    </div>
                  </li>
                  <li>
                    <div className="box-img">
                      <img src={imgIntro06} />
                    </div>
                    <div className="box-text">
                      <p className="title">5. 추모예식</p>
                      <p>아이의 안녕을 빌고 인사를 나누는 시간을 갖습니다.</p>
                    </div>
                    <div className="box-desc list">
                      <ul>
                        <li>단독 추모실에서 추모시간 제한없이 인사를 나누실 수 있습니다.</li>
                        <li>아이 사진을 미리 전달해주시면 슬라이드 영상으로 상영해드립니다.</li>
                        <li>
                          보호자님께서 편하게 이용하실 수 있도록 단독 추모실 내에 털 간직 키트,
                          발도장 찍기(잉크), 편지쓰기(엽서), 인연의 끈(붉은 실)이 준비되어 있습니다.
                        </li>
                        <li>종교 예식을 진행하실 경우 간단한 종교 물품을 준비해드립니다.</li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="box-img">
                      <img src={imgIntro07} />
                    </div>
                    <div className="box-text">
                      <p className="title">6. 화장 및 수·분골</p>
                      <p>
                        아이의 화장이 진행되는 동안 단독 참관실에서 함께하실 수 있습니다.
                        <br />
                        화장이 끝난 유골은 수습 후 장례지도사가 수작업으로 분골을 진행합니다.
                      </p>
                    </div>
                    <div className="box-desc">
                      <p>21그램은 개별화장을 원칙으로, 어떠한 경우에도 합동화장을 하지 않습니다.</p>
                    </div>
                  </li>
                </ol>
              </div>
            </div>

            <div className="wrap-service-info has-margin">
              <dl>
                <dt>회사명</dt>
                <dd>21그램</dd>
                <dt>빠른 상담</dt>
                <dd>1688-1240</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default ServicePremiumPage;

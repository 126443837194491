import { useState, useEffect, useRef, Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// import { getCanvasApi } from 'apis/canvas';

import {
  getCanvasBltsSubApi,
  getCanvasBltCommentApi,
  saveCanvasBltCommentApi,
  deleteCanvasBltCommentApi,
  modifyCanvasBltApi,
} from 'apis/canvasBlt';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import MyLife from './components/MyLife';
import MyHistory from './components/MyHistory';
import MyQna from './components/MyQna';
import MyBucketlist from './components/MyBucketlist';

import MyProfile from './components/MyProfile';
import ModifyMyProfile from './components/ModifyMyProfile';

import Subscribe from './components/Subscribe';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import MyQnaDialog from './components/MyQnaDialog';

function MyCanvas() {
  const scrollRef = useRef();
  const { loginUser } = useLogin();

  const schema = Yup.object({
    com_contents: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_id: Yup.string().required('* 필수 입력 항목입니다.'),
  }).required();

  const defaultValues = {
    com_contents: '',
    blt_id: '',
  };

  const { register, setValue, getValues, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const location = useLocation();

  const [isEdit, setEdit] = useState(false);

  const navigate = useNavigate();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();

  const [canvasBlts, setCanvasBlts] = useState();
  const [searchBltType, setSearchBltType] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [listSize] = useState(4);
  const [totalPage, setTotalPage] = useState(0);

  const [canvasBltStatus, setCanvasBltStatus] = useState();
  const [canvasBltStatusCount, setCanvasBltStatusCount] = useState(0);

  const [selectedComment, setSelectedComment] = useState();
  const [openMyQnaDialog, setOpenMyQnaDialog] = useState(false);
  const [canvasId, setCanvasId] = useState();

  const [comments, setComments] = useState();
  const [isActive, setActive] = useState(false);

  const getCanvasBltsSub = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    //params.goalstatus = 'N';

    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    if (loginUser?.canvas_id) params.canvas_id = loginUser.canvas_id;
    if (searchBltType) params.blt_type = searchBltType;

    const result = await getCanvasBltsSubApi(params);

    if (result.code === '0000') {
      setCanvasBlts(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };
  const getCanvasBltComment = async () => {
    const params = {};
    params.blt_id = canvasBlts.blt_id;
    params.orderBy = { direction: 'desc', field: 'comment_id' };

    const result = await getCanvasBltCommentApi(params);

    if (result.code === '0000') {
      setComments(result.data);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const saveCanvasBltComment = async data => {
    let params = {};

    params = data;

    params.blt_id = canvasBlts.blt_id;
    params.member_id = loginUser.member_id;
    params.member_name = loginUser.member_name;

    if (selectedComment) {
      params.up_blt_id = selectedComment.comment_id;
      params.comment_type = '2';
    }

    const result = await saveCanvasBltCommentApi(params);

    if (result.code === '0000') {
      setValue('com_contents', '');
      setSelectedComment();
      getCanvasBltComment();
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const getCanvasBltsSubMore = async pageNum => {
    let params = {};
    params.orderBy = { direction: 'desc', field: 'blt_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    //params.goalstatus = 'N';

    if (loginUser?.member_id) params.member_id = loginUser.member_id;
    if (loginUser?.canvas_id) params.canvas_id = loginUser.canvas_id;

    if (searchBltType) params.blt_type = searchBltType;

    const result = await getCanvasBltsSubApi(params);

    if (result.code === '0000') {
      setCanvasBlts([...canvasBlts, ...result.data]);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };
  const getCanvasBltsStatusSub = async () => {
    const params = {};
    params.canvas_id = loginUser.canvas_id;
    params.blt_type = '3';
    params.goalstatus = 'Y';
    //params.member_id = loginUser.member_id;
    //console.log(JSON.stringify(params));
    const result = await getCanvasBltsSubApi(params);
    if (result.code === '0000') {
      setCanvasBltStatus(result.data);
      setCanvasBltStatusCount(result.data_cnt);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };
  const deleteCanvasBltComment = async commentId => {
    const result = await deleteCanvasBltCommentApi(commentId);

    if (result.code === '0000') {
      getCanvasBltComment();
      // showLifeAlert('알림', result.message, () => hideLifeAlert());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };
  // const getCanvas = async () => {
  //   let params = {};
  //   params.canvas_id = loginUser.canvas_id;
  //   if (loginUser?.member_id) params.member_id = loginUser.member_id;

  //   const result = await getCanvasApi(params);

  //   if (result.code === '0000') {
  //     setCanvas(result.data);
  //   } else {
  //     showLifeAlert('알림', result.message, () => hideLifeAlert());
  //   }
  // };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showLifeAlert('알림', '마지막 페이지 입니다.', () => hideLifeAlert());
    } else {
      setPage(nextPage);
      getCanvasBltsSubMore(nextPage);
    }
  };

  const moveRegister = () => {
    let url = '/life/everyone/bucketlist/register';
    if (tabIndex === 1) url = '/life/everyone/bucketlist/register';
    if (tabIndex === 2) url = '/life/everyone/faq/register';
    if (tabIndex === 3) url = '/life/everyone/daily/register';
    if (tabIndex === 4) url = '/life/everyone/history/register';

    navigate(url);
  };

  const moveRegisterMenu = register => {
    setActive(false);
    if (register === 1) {
      navigate('/life/everyone/daily/register');
    } else if (register === 2) {
      navigate('/life/everyone/bucketlist/register');
    } else if (register === 3) {
      navigate('/life/everyone/faq/register');
    } else if (register === 4) {
      navigate('/life/everyone/history/register');
    }
    //else {
    //   navigate('/life/everyone/bucketlist/register');
    // }
  };

  const deletedCanvasBlt = id => {
    setCanvasBlts([...canvasBlts.filter(canvasBlt => canvasBlt.blt_id !== id)]);
    init();
  };

  const handleCommentDelete = commentId => {
    showLifeConfirm(
      '삭제 확인',
      '정말 삭제하시겠습니까?',
      () => confirmCommentDelete(commentId),
      () => hideLifeConfirm(),
    );
  };

  const confirmCommentDelete = commentId => {
    hideLifeConfirm();
    deleteCanvasBltComment(commentId);
  };

  const handleDelete = id => {
    showLifeConfirm(
      '삭제 확인',
      '정말 삭제하시겠습니까?',
      () => confirmDelete(id),
      () => hideLifeConfirm(),
    );
  };

  const confirmDelete = id => {
    hideLifeConfirm();
    //alert(id);
    showLifeAlert('알림', '삭제가 완료되었습니다.', () => hideLifeAlert());
    modifyCanvasBlt(id);
  };

  const modifyCanvasBlt = async id => {
    let params = {};
    params.blt_id = id;
    params.use_yn = 'N';

    const result = await modifyCanvasBltApi(params);

    if (result.code === '0000') {
      deletedCanvasBlt(id);
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const handleModify = () => {
    navigate(`/life/everyone/faq/modify/${getValues('blt_id')}`);
  };

  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const reflash = () => {
    // 팝업 닫고 리스트 다시 불러오고
    setOpenMyQnaDialog(false);
    init();
  };

  const init = async () => {
    // await getCanvas();

    if (tabIndex < 5) await getCanvasBltsSub(1);
    getCanvasBltsStatusSub();
  };

  useEffect(() => {
    if (tabIndex === 0) {
      setSearchBltType();
    } else if (tabIndex === 1) {
      setSearchBltType('4');
    } else if (tabIndex === 2) {
      setSearchBltType('3');
    } else if (tabIndex === 3) {
      setSearchBltType('1');
    } else if (tabIndex === 4) {
      setSearchBltType('2');
    } else if (tabIndex === 5) {
      // alert('구독 기능 작업중..');
    } else if (tabIndex === 6) {
      alert('연결 기능 작업중..');
    }
  }, [tabIndex]);

  useEffect(() => {
    if (location?.state?.searchBltType) {
      if (location.state.searchBltType === '4') setTabIndex(1);
      if (location.state.searchBltType === '3') setTabIndex(2);
      if (location.state.searchBltType === '1') setTabIndex(3);
      if (location.state.searchBltType === '2') setTabIndex(4);
      if (location.state.searchBltType === '0') setTabIndex(0);

      if (location.state.searchBltType !== '0')
        setTimeout(() => scrollRef.current.scrollIntoView({ behavior: 'smooth' }), 1000);
    }
    // else {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  }, [location]);

  useEffect(() => {
    if (loginUser?.canvas_id) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser, searchBltType]);

  return (
    <section className="container" id="containerWrap">
      <div className={`${isActive ? 'bg-overlay' : ''}`}></div>
      {/* S : CONTENT */}
      <div className="content">
        <div className="contents-width is-mycanvas">
          {/* 마이 캔버스 페이지일 경우 is-mycanvas */}

          {/* S : 프로필 */}
          {isEdit ? <ModifyMyProfile setEdit={setEdit} /> : <MyProfile setEdit={setEdit} />}
          {/* E : 프로필 */}
          <div ref={scrollRef} style={{ position: 'absolute', marginTop: '-100px' }}></div>

          {/* S : 탭 */}
          <div className="tabs tabs-scroll">
            <ul>
              <li className={tabIndex === 0 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(0)}>
                  전체
                </a>
              </li>
              <li className={tabIndex === 1 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(1)}>
                  버킷
                </a>
              </li>
              <li className={tabIndex === 2 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(2)}>
                  문답
                </a>
              </li>
              <li className={tabIndex === 3 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(3)}>
                  일상
                </a>
              </li>
              <li className={tabIndex === 4 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(4)}>
                  자서전
                </a>
              </li>
              <li className={tabIndex === 5 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(5)}>
                  구독
                </a>
              </li>
              {/* <li className={tabIndex === 6 ? 'is-active' : ''}>
                <a href={() => false} onClick={() => setTabIndex(6)}>
                  연결
                </a>
              </li> */}
            </ul>
          </div>
          {/* E : 탭 */}
          {tabIndex === 0 || tabIndex === 2 ? (
            <div className="canvas-post">
              <div className="canvas-inner">
                <div className="canvas-head">
                  {/* S : 분류 */}
                  <div className="category">{canvasBltStatusCount}개의 새 질문</div>
                  {/* E : 분류 */}
                </div>
                {/* E : 글 작성자 정보 */}
                {/* S : 글 내용 */}

                <div className="canvas-content">
                  {/* S : 글 */}
                  {canvasBltStatus && canvasBltStatus.length > 0
                    ? canvasBltStatus.map(canvasBltStatus => (
                        <div key={canvasBltStatus.blt_id} className="qna-content qna-leave">
                          {JSON.parse(
                            canvasBltStatus.blt_contents.replace(/(\n|\r\n)/g, '<br />'),
                          ) &&
                            JSON.parse(canvasBltStatus.blt_contents.replace(/(\n|\r\n)/g, '<br />'))
                              .length > 0 &&
                            JSON.parse(
                              canvasBltStatus.blt_contents.replace(/(\n|\r\n)/g, '<br />'),
                            ).map(qna => <p className="qna-q">{qna.questions}</p>)}
                          <div className="qna-util">
                            <div className="qna-questioner">
                              <span className="date">{canvasBltStatus.createdtime}</span>
                              <span className="name">
                                {canvasBltStatus.goalcomment
                                  ? canvasBltStatus.goalcomment + ' / '
                                  : ''}{' '}
                                {canvasBltStatus.blt_anonymous_yn === 'N'
                                  ? canvasBltStatus.request_title
                                  : '익명'}
                              </span>
                            </div>
                            <div className="btn-wrap">
                              <button
                                type="button"
                                className="btn btn-xs btn-green"
                                data-micromodal-trigger="modal-qna-reply"
                                onClick={() => {
                                  setOpenMyQnaDialog(true);
                                  setCanvasId(canvasBltStatus.blt_id);
                                }}
                              >
                                답변하기
                              </button>
                              <button
                                type="button"
                                className="btn btn-xs btn-lngray02"
                                data-micromodal-trigger="modal-sample-02"
                                onClick={() => handleDelete(canvasBltStatus.blt_id)}
                              >
                                삭제
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    : ''}
                  {/* E : 글 */}
                </div>

                {/* E : 글 내용 */}
              </div>
            </div>
          ) : (
            ''
          )}

          {/* S : 작성한 글 : 버킷리스트 */}
          {tabIndex < 5 &&
            (canvasBlts && canvasBlts.length > 0 ? (
              canvasBlts.map(canvasBlt => (
                <Fragment key={canvasBlt.blt_id}>
                  {canvasBlt.blt_type === '1' && (
                    <MyLife canvasBlt={canvasBlt} deletedCanvasBlt={deletedCanvasBlt} />
                  )}
                  {canvasBlt.blt_type === '2' && (
                    <MyHistory canvasBlt={canvasBlt} deletedCanvasBlt={deletedCanvasBlt} />
                  )}
                  {canvasBlt.blt_type === '3' && canvasBlt.goalstatus === 'N' ? (
                    <MyQna
                      canvasBlt={canvasBlt}
                      deletedCanvasBlt={deletedCanvasBlt}
                      canvasBltStatus={canvasBltStatus}
                      canvasBltStatusCount={canvasBltStatusCount}
                    />
                  ) : (
                    ''
                  )}
                  {canvasBlt.blt_type === '4' && (
                    <MyBucketlist canvasBlt={canvasBlt} deletedCanvasBlt={deletedCanvasBlt} />
                  )}
                </Fragment>
              ))
            ) : (
              <div className="canvas-post no-canvas">
                <div className="canvas-inner">
                  <a href={() => false} onClick={() => moveRegister()}>
                    <span className="circle-gray">
                      <i className="ic ic-circle-plus"></i>
                    </span>
                    {loginUser?.member_name} 님의 첫번째 이야기를 작성해 주세요
                  </a>
                </div>
              </div>
            ))}
          {tabIndex === 5 && <Subscribe canvasMemberId={loginUser.member_id} />}
        </div>
        {/* S : 더보기 버튼 */}
        {tabIndex < 5 && canvasBlts && canvasBlts.length > 0 ? (
          <div className="btn-wrap">
            <button type="button" className="btn btn-more" onClick={() => handleMore(page)}>
              더보기 {page}/{totalPage}
            </button>
          </div>
        ) : (
          ' '
        )}
        {/* E : 더보기 버튼 */}
        {/* S : (고정) 기록 버튼 */}
        <div className="quick-btn-wrap">
          <button
            className={`btn btn-fixed-list ${isActive ? 'is-active' : ''}`}
            onClick={() => setActive(!isActive)}
          >
            <span>기록</span>
          </button>
          <ul className={`quick-write-list ${isActive ? 'is-active' : ''}`}>
            <li>
              <a href={() => false} onClick={() => moveRegisterMenu(1)}>
                <i className="ic ic-write-life"></i> 일상
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => moveRegisterMenu(2)}>
                <i className="ic ic-write-bucket"></i> 버킷리스트
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => moveRegisterMenu(3)}>
                <i className="ic ic-write-faq"></i> 문답
              </a>
            </li>
            <li>
              <a href={() => false} onClick={() => moveRegisterMenu(4)}>
                <i className="ic ic-write-history"></i> 자서전
              </a>
            </li>
          </ul>
          {/* S : 22-12-14 모바일 top 버튼 추가 */}
          <button type="button" className="btn-go-top" onClick={() => scrollTo()}>
            <span className="offscreen">위로가기</span>
            <i className="ic ic-arrow-up"></i>
          </button>
          {/* E : 22-12-14 모바일 top 버튼 추가 */}
        </div>
        {/* <a href={() => false} className="btn btn-fixed-list" onClick={() => moveRegister()}>
            기록
          </a> */}
        {/* E : (고정) 기록 버튼 */}
      </div>
      {openMyQnaDialog && (
        <MyQnaDialog
          canvasId={canvasId}
          isOpen={openMyQnaDialog}
          onClose={() => setOpenMyQnaDialog(false)}
          reflash={reflash}
        />
      )}
      {/* E : CONTENT */}
    </section>
  );
}

export default MyCanvas;

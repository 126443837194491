import { call, put, takeLatest } from 'redux-saga/effects';

import { emailLogin, kakaoLogin, naverLogin } from 'apis/member';
import { notiAuthSendApi, notiAuthVerifyApi } from 'apis/openapi';
import {
  emailLoginRequest,
  emailLoginSuccess,
  kakaoLoginFailure,
  kakaoLoginRequest,
  kakaoLoginSuccess,
  naverLoginFailure,
  naverLoginRequest,
  naverLoginSuccess,
  emailLoginFailure,
  notiAuthSendRequest,
  notiAuthSendSuccess,
  notiAuthSendFailure,
  notiAuthVerifyRequest,
  notiAuthVerifySuccess,
  notiAuthVerifyFailure,
} from './reducer';

/**
 * Redux-Saga 실행함수 생성
 *
 * @param {ReturnType<typeof gitUserProfileRequest>} action
 * @return {*}  {Generator<any, void, IGithubProfile>}
 */
function* getLoginSaga(action) {
  try {
    const loginUser = yield call(emailLogin, action.payload);
    yield put(emailLoginSuccess(loginUser));
  } catch (e) {
    yield put(emailLoginFailure(e));
  }
}

function* getKakaoLoginSaga(action) {
  try {
    const loginUser = yield call(kakaoLogin, action.payload);
    yield put(kakaoLoginSuccess(loginUser));
  } catch (e) {
    yield put(kakaoLoginFailure(e));
  }
}

function* getNaverLoginSaga(action) {
  try {
    const loginUser = yield call(naverLogin, action.payload);
    yield put(naverLoginSuccess(loginUser));
  } catch (e) {
    yield put(naverLoginFailure(e));
  }
}

function* notiAuthSendSaga(action) {
  try {
    const loginUser = yield call(notiAuthSendApi, action.payload);
    yield put(notiAuthSendSuccess(loginUser));
  } catch (e) {
    yield put(notiAuthSendFailure(e));
  }
}

function* notiAuthVerifySaga(action) {
  try {
    const loginUser = yield call(notiAuthVerifyApi, action.payload);
    yield put(notiAuthVerifySuccess(loginUser));
  } catch (e) {
    yield put(notiAuthVerifyFailure(e));
  }
}

/**
 * Redux Action Connect Redux Saga
 * Redux 액션과 Redux Saga 연결
 *
 * @export
 * @return {*}  {Generator<any, void, unknown>}
 */
export function* loginSaga() {
  yield takeLatest(emailLoginRequest.type, getLoginSaga);
  yield takeLatest(kakaoLoginRequest.type, getKakaoLoginSaga);
  yield takeLatest(naverLoginRequest.type, getNaverLoginSaga);

  yield takeLatest(notiAuthSendRequest.type, notiAuthSendSaga);
  yield takeLatest(notiAuthVerifyRequest.type, notiAuthVerifySaga);
}

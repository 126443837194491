import { useEffect, useState, useRef } from 'react';

import { getMembersApi } from 'apis/member';

import { Dialog } from '@mui/material';
import Pagination from 'react-js-pagination';
import useLogin from 'stores/login/hook';

function SearchMemberDialog({ isOpen, onClose, selectedMemberId }) {
  const searchRef = useRef();
  const { loginUser } = useLogin();

  const [members, setMembers] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchMembers, setSearchMembers] = useState([]);

  const [page, setPage] = useState(1);
  const [listSize] = useState(3);
  const [totalCount, setTotalCount] = useState(0);

  const getMembers = async pageNum => {
    // let params = {};
    // params.orderBy = { direction: 'desc', field: 'memorial_id' };
    // params.page = pageNum;
    // params.itemInPage = listSize;
    // if (searchValue && searchValue !== '') params.searchWord = searchValue;

    const result = await getMembersApi({});
    //console.log(JSON.stringify(result));
    setMembers(result.data);
    setTotalCount(result.data_cnt);
  };

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getMembers(selectedPage);
  };

  const handleSearchMembers = () => {
    getMembers(1);
  };

  useEffect(() => {
    setSearchMembers(
      members && members.length > 0
        ? members.filter(member => member.member_name.indexOf(searchValue) > -1)
        : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    getMembers();
  }, []);

  // useEffect(() => {
  //   if (loginUser) getMembers(1);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loginUser]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="modal" id="modal-sample-02" aria-hidden="true">
        <div className="modal__overlay" tabindex="-1" data-micromodal-close>
          <div
            className="modal__container"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-1-title"
          >
            <header className="modal__header">
              <h2 className="modal__title" id="modal-2-title">
                회원 검색
              </h2>
              <button
                className="modal__close"
                aria-label="닫기"
                data-micromodal-close
                onClick={onClose}
              ></button>
            </header>
            <div className="modal__content">
              {/* S : 목록 검색 박스 */}
              <div className="content-box has-bdr-top">
                <table className="tbl-search">
                  <caption></caption>
                  <colgroup>
                    <col />
                    <col width="135px" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td className="input">
                        <div className="ip-item sel-ip-box">
                          <label for="selCategory02" className="offscreen">
                            검색입력
                          </label>
                          <select id="selCategory02">
                            <option>전체</option>
                            {/* <option>부고 대상</option>
                            <option>소유자</option> */}
                          </select>
                          <input
                            type="text"
                            id="ipSearchText2"
                            placeholder="회원을 검색하세요"
                            // value={searchValue}
                            onChange={changeSearchValue}
                            onKeyPress={onKeyPress}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn-search"
                          ref={searchRef}
                          onClick={handleSearchMembers}
                        >
                          검색
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* E : 목록 검색 박스 */}

              {/* S : 회원 목록 */}
              <div className="list-user">
                {/* S : 검색 결과 없을 때 */}
                {/*
					<div className="no-data">
						검색결과가 없습니다.
					</div>
					*/}
                {/* E : 검색 결과 없을 때 */}
                {/* S : 회원 정보 반복 시작 */}
                {searchMembers && searchMembers.length > 0 ? (
                  searchMembers.slice(0, 5).map(member => (
                    <div className="box-user" key={member.member_id}>
                      <div className="info-user">
                        <p className="name">{member.member_name}</p>
                        <p className="info">
                          <span>{member.email}</span>
                          <span>{member.mobile}</span>
                        </p>
                      </div>

                      <div className="btn-wrap">
                        <button
                          type="button"
                          className="btn btn-sm"
                          onClick={() => selectedMemberId(member.member_id)}
                        >
                          소유자 변경
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data">검색결과가 없습니다.</div>
                )}
                {/* E : 회원 정보 반복 끝 */}
              </div>
              {/* E : 회원 목록 */}

              {/* S : 페이징 */}
              {/* <Pagination
                activePage={page}
                itemsCountPerPage={listSize}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                firstPageText={''}
                prevPageText={''}
                nextPageText={''}
                lastPageText={''}
                onChange={handlePageChange}
                itemClassFirst={'ico-page-first'}
                itemClassPrev={'ico-page-prev'}
                itemClassNext={'ico-page-next'}
                itemClassLast={'ico-page-end'}
              /> */}
              {/* E : 페이징 */}
            </div>
            <footer className="modal__footer"></footer>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SearchMemberDialog;

import { useEffect, useState } from 'react';

import Story from './components/StoryContent';
import Donation from './components/DonationContent';
import { useLocation } from 'react-router-dom';

function PetCommunity() {
  const { state } = useLocation();

  const [mode, setMode] = useState('story');

  useEffect(() => {
    if (state && state.viewTab) {
      setMode(state.viewTab);
      //tabElement.current?.scrollIntoView({ block: 'center' });
      console.log('scroll into ...');
    }
  }, []);

  return <>{mode === 'story' ? <Story setMode={setMode} /> : <Donation setMode={setMode} />}</>;
}
export default PetCommunity;

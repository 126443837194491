import { useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import parse from 'html-react-parser';
import useCode from 'stores/code/hook';

function Faq({ faqs, code }) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { myfaqBltTypeCodes } = useCode();

  useEffect(() => {
    setSelectedIndex(-1);
  }, [faqs]);

  return (
    <>
      <div className="faq-list">
        {faqs &&
          faqs.map((faq, index) => (
            <ul>
              <li key={index} className={` ${selectedIndex === index ? 'is-active' : ''} `}>
                <div className="question" onClick={() => setSelectedIndex(index)}>
                  <p className="title">{faq.title}</p>
                  {myfaqBltTypeCodes &&
                    myfaqBltTypeCodes.length > 0 &&
                    myfaqBltTypeCodes.map(code =>
                      faq.blt_type === code.code ? (
                        <span className="category">{code.value}</span>
                      ) : (
                        ''
                      ),
                    )}
                </div>
                <Collapse in={selectedIndex === index}>
                  <div className="answer">
                    {parse(faq.contents.replace(/(\n|\r\n)/g, '<br />'))}
                  </div>
                </Collapse>
              </li>
            </ul>
          ))}
      </div>
    </>
  );
}

export default Faq;

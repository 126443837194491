import { useEffect } from 'react';

import useLayout from 'stores/layout/hook';

import imgIntro01 from 'assets/memorial/images/img_service_01.png';
import imgIntro02 from 'assets/memorial/images/img_service_02.png';
import imgIntro03 from 'assets/memorial/images/img_service_03.png';
import imgIntro04 from 'assets/memorial/images/img_service_04.png';

function ServiceMemorialPage({ setMode }) {
  const { setGnbType } = useLayout();

  useEffect(() => {
    setGnbType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container is-about" id="containerWrap">
      {' '}
      {/* 220926 className 수정 : 서비스 소개일 경우 className="is-about" 추가 */}
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        {/* S : 22-11-02 서비스 소개 페이지 전체 수정 */}
        <div className="contents-width">
          <h3>서비스 소개</h3>

          <div className="tabs tabs-about">
            <ul>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('memorial')}>
                  메모리얼 캔버스란?
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => setMode('premuim')}>
                  조문보 프리미엄
                </a>
              </li>
            </ul>
          </div>

          <div className="box-title">
            <h4 className="title">메모리얼 캔버스는</h4>
            <p className="desc">
              고인의 삶을 제대로 추모하는 고인 중심의 장례 문화를 만들어 가기 위해 시작되었습니다.{' '}
              <br className="d-block d-md-none" />
              고인이 인생을 담은 글인 조문보가 고인의 장례와 함께하며 유족에게는 위로를,
              조문객에게는 감동을 전합니다.
              <br />
              고인에 대한 그리움이 담긴 기록들은 온라인 추모관에서 늘 우리 곁에 함께합니다.
            </p>
          </div>

          <div className="wrap-service">
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro01} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">01.Memories forever</span>
                <h5>
                  사랑하는 사람과의
                  <br />
                  추억을 영원히
                </h5>
                <p>
                  메모리얼캔버스를 통해 소중한 추억들을 영원히 간직하며,{' '}
                  <br className="d-block d-md-none" />
                  고인을 추모하는 전통 가족 문화를 소중히 지킵니다.
                </p>
              </div>
            </div>
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro02} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">02.condolence diary</span>
                <h5>
                  고인의 일대기를
                  <br />
                  담은 조문보
                </h5>
                <p>
                  사회 활동,업적, 어록 등을 기록한 조문보를 제작하여{' '}
                  <br className="d-block d-md-none" />
                  고인의 일대기를 기립니다.
                </p>
              </div>
            </div>
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro03} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">03.digital wreath service</span>
                <h5>
                  온라인 조문과 <br className="d-none d-md-block" />
                  디지털화환
                </h5>
                <p>
                  마이라이프캔버스에서는 온라인으로 조문이 가능하며{' '}
                  <br className="d-block d-md-none" />
                  디지털 화환 서비스를 제공합니다.
                </p>
              </div>
            </div>
            <div className="box-service">
              <div className="img-service">
                <img src={imgIntro04} alt="" />
              </div>
              <div className="text-service">
                <span className="desc">04.online memorial hall</span>
                <h5>온라인 추모관</h5>
                <p>
                  고인을 기리는 추모글과 고인의 삶이 주는 지혜와 메시지,{' '}
                  <br className="d-block d-md-none" />
                  살아온 이야기, 사진이 담긴 조문보 및 동영상 등을 언제든지 볼 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* E : 22-11-02 서비스 소개 페이지 전체 수정 */}
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default ServiceMemorialPage;

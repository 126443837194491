import imgService1 from 'assets/images/img_service1.png';

function AboutBlt() {
  return (
    <section>
      <div className="visual_area">
        <div className="visual style1"></div>
        <h2 className="title">
          01
          <br />
          조문보는
        </h2>
      </div>
      <div className="service_content">
        <p className="text">
          조문보는 부고와 함께 고인의 삶과 정신적 유산을 조문객에게 알리며 추모하는 글을 담은 문서로
          유족과 지인들에게 기억 되는 고인의 삶에 관한 큰 줄기가 압축적으로 정리된 형식의 글입니다.
          고인의 삶의 상징어, 의미, 약력, 유언, 어록, 추모 글과 유족 인사말 등이 담깁니다.
        </p>
        <p className="text">
          장례 이후에는 고인의 삶을 간직하고 추억하며 남기신 지혜의 메시지를 통해 가족구성원들이
          지속적으로 서로 연결될 수 있는 소중한 공간이 될 수 있습니다.{' '}
        </p>
        <span className="img_area">
          <img alt="" src={imgService1} />
        </span>
      </div>
    </section>
  );
}

export default AboutBlt;

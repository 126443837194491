import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useLayout from 'stores/layout/hook';
import useCode from 'stores/code/hook';

import { getHallApi } from 'apis/hall';
import { uploadApi } from 'apis/openapi';
import { saveMemorialApi } from 'apis/memorial';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDate } from 'utils/date';

import ChudosaSampleDialog from 'pages/memorial-canvas/obituary/components/ChudosaTemplateDialog';
import SearchHallDialog from 'pages/memorial-canvas/obituary/components/SearchHallDailog';
import Relations from 'pages/memorial-canvas/obituary/components/Relations';
import Condolatorys from 'pages/memorial-canvas/obituary/components/Condolatorys';
import Blts from 'pages/memorial-canvas/obituary/components/Blts';
import PreviewDialog from 'pages/memorial-canvas/obituary/components/PreviewDialog';
import MediaUploader from 'pages/memorial-canvas/obituary/components/MediaUploader';
import Chudosas from '../../../memorial-canvas/obituary/components/Chudosas';

function AdminObituaryRegisterPage() {
  const navigate = useNavigate();

  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();
  const { relationDetailCodes } = useCode();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const schema = Yup.object({
    //chief_mourner_desc: Yup.string().required('* 고인과의 관계를 선택하세요.'),
    // chief_mourner: Yup.string()
    //   .required('* 상주명을 입력하세요.')
    //   .max(50, '* 최대 입력은 20자까지 가능합니다.'),
    // chief_mourner_phone: Yup.string()
    //   .required('* 휴대폰 번호를 입력하세요.')
    //   .matches(
    //     '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
    //     '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
    //   ),
    deceased_name: Yup.string()
      .required('* 이름을 입력하세요.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    // deceased_birthday: Yup.string().required('* 생년월일을 입력하세요.'),
    // deceased_date: Yup.string().required('* 임종일자를 입력하세요.'),
    // deceased_hour: Yup.string().required('* 임종시간을 입력하세요.'),
    // birth_date: Yup.string().required('* 발인일자를 입력하세요.'),
    // birth_hour: Yup.string().required('* 발인시간을 입력하세요.'),
    // funeral_place: Yup.string().required('* 장지주소를 입력하세요.'),
    funeral_addr: Yup.string()
      .required('* 등록된 장례식장 정보가 없습니다.')
      .max(100, '* 최대 입력은 100자까지 가능합니다.'),
    // relations_datas: Yup.array().of(
    //   Yup.object().shape({
    //     relations_name: Yup.string().required('* 상주명을 입력하세요.'),
    //     relations_detail: Yup.string().required('* 고인관계를 선택하세요.'),
    //   }),
    // ),
    condolatory_datas: Yup.array().of(
      Yup.object().shape({
        applicant_name: Yup.string().required('* 이름을 입력하세요.'),
        bank_name: Yup.string().required('* 은행을 선택하세요.'),
        account_number: Yup.string().required('* 계좌번호를 입력하세요.'),
      }),
    ),
    blt_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 조문보 주제를 등록 하세요.')
          .max(20, '* 조문보 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string()
          // .test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 3000000)
          .required('* 조문보를 입력하세요.'),
      }),
    ),
    //deceased_introduction: Yup.string().required('* 안내글을 입력하세요.'),
    chudosa: Yup.string().required('* 추도사를 입력하세요.'),
  }).required();

  const defaultValues = {
    chief_mourner: '',
    chief_mourner_desc: '',
    chief_mourner_phone: '',
    digital_wreath_yn: 'Y',
    chief_funeral_yn: 'N',
    birth_yn: 'N',
    funeral_yn: 'N',
    funeral_addr_yn: 'N',
    deceased_video_yn: 'N',
    delivery_yn: 'Y',
    condolatory_yn: 'Y',
    chief_funeral_nm: '',
    deceased_name: '',
    // deceased_birthday: getDate(-30000),
    deceased_birthday: '',
    deceased_job_title: '',
    // deceased_date: getDate(0),
    // deceased_hour: '00:00',
    // birth_date: getDate(3),
    // birth_hour: '00:00',
    deceased_date: '',
    deceased_hour: '',
    birth_date: '',
    birth_hour: '',
    funeral_addr: '',
    funeral_room: '',
    funeral_place: '',
    deceased_photo: '',
    deceased_introduction: '',
    chudosa: '',
    own_member_id: '',
    create_member_id: '',
    display_yn: 'Y',
    mylty_yn: 'N',
    donation_yn: 'N',
    status: '1',
    relations_datas: [
      { relations_type: 'S', relations_detail: '', relations_name: '', mobile: '' },
    ],
    condolatory_datas: [{ applicant_name: '', bank_name: '', account_number: '' }],
    chudosa_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    blt_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    medias: [],
    deceased_video_link: '',
  };

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [openSearchHallDialog, setOpenSearchHallDialog] = useState(false);
  const [openChudosaSampleDialog, setOpenChudosaSampleDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [deceasedPhoto, setDeceasedPhoto] = useState(null);
  const [displayYn, setDisplayYn] = useState(true);
  const [birthYn, setBirthYn] = useState(false);
  const [funeralYn, setFuneralYn] = useState(false);
  const [funeralAddrYn, setFuneralAddrYn] = useState(false);
  const [videoYn, setVideoYn] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [deliveryYn, setDeliveryYn] = useState(false);
  const [chiefFuneralYn, setChiefFuneralYn] = useState(false);
  const [condolatoryYn, setCondolatoryYn] = useState(false);

  const [multyYn, setMultyYn] = useState(false);

  const [hall, setHall] = useState(null);

  const [medias, setMedias] = useState([]);
  const [visual, setVisual] = useState(false);

  const getHall = async hallId => {
    const result = await getHallApi(hallId);
    setHall(result.data);
  };

  const saveMemorial = async params => {
    params.path = 'memorial';
    const result = await saveMemorialApi(params);
    if (result.type === 'success') {
      showAlert('알림', result.message, () => successSaveMemorial(result.id));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);

    setDeceasedPhoto(result.uploadUrl);
    setValue('deceased_photo', result.uploadUrl);
  };

  const selectedHall = hallId => {
    setValue('funeral_addr', hallId);
    getHall(hallId);
    setOpenSearchHallDialog(false);
  };

  const handleChangeDisplayYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('display_yn', isChecked);
    //setDisplayYn(value);
  };

  const handleChangeMultyYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('multy_yn', isChecked);
    //setDisplayYn(value);
  };

  const handleBirthYn = value => {
    setValue('birth_yn', value ? 'Y' : 'N');
    setBirthYn(value);
  };

  const handleFuneralAddrYn = value => {
    setValue('funeral_addr_yn', value ? 'Y' : 'N');
    setFuneralAddrYn(value);
  };

  const handleFuneralYn = value => {
    setValue('funeral_yn', value ? 'Y' : 'N');
    setFuneralYn(value);
  };

  const handleVideoYn = value => {
    setValue('deceased_video_yn', value ? 'Y' : 'N');
    setVideoYn(value);
  };

  const handleDeliveryYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('delivery_yn', isChecked);
    //setDeliveryYn(value);
  };

  const handleChiefFuneralYn = value => {
    setValue('chief_funeral_yn', value ? 'Y' : 'N');
    setChiefFuneralYn(value);
  };

  const handleCondolatoryYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('condolatory_yn', isChecked);
    // setCondolatoryYn(value);
  };

  const changeImageFile = event => {
    const { files } = event.target;
    uploadImageFile(files[0]);
  };

  const removeImageFile = () => {
    setDeceasedPhoto(null);
    setValue('deceased_photo', '');
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
    //console.log(media);
    //console.log(media.media_link);
    setValue('deceased_video_link', media.media_link);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  const handlePreview = () => {
    setOpenPreviewDialog(true);
  };

  const handleClose = () => {
    navigate('/admin/obituary');
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleDigitalWreathYn = event => {
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('digital_wreath_yn', isChecked);
  };

  const onSubmit = data => {
    saveMemorial(data);
  };

  const imageDelete = () => {
    showConfirm(
      '삭제 확인',
      '첨부한 파일을 삭제하시겠습니까?',
      () => removeImageFile(),
      () => hideConfirm(),
    );
  };

  const onError = error => {
    showAlert('알림', '잘못된 입력된 정보가 존재합니다. 입력값을 확인하세요.', () => hideAlert());
    console.log('error > ', error);
  };

  const successSaveMemorial = memorialId => {
    hideAlert();
    // navigate(`/memorial/obituary/view/${memorialId}`);
    window.location.href = `/memorial/obituary/view/${memorialId}`;
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id && loginUser.role !== 'ADMIN') {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      window.location.href = '/';
    }
    if (loginUser && loginUser.member_id) {
      setValue('own_member_id', loginUser.member_id);
      setValue('create_member_id', loginUser.member_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="container" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          {/* S : 서브 비주얼 + 타이틀 */}
          <div className="visual-wrap">
            <div className="contents-width">
              <h2 className="title">부고 등록</h2>
              <p>
                사랑하는 사람의 부음을 문자 및 단체 알림, SNS 메시지로
                <br className="d-md-block d-none" /> 발송 대행하고
                <br className="d-block d-md-none" />
                장례 의식 공지 등의 서비스를 제공합니다.
              </p>
            </div>
          </div>
          {/* E : 서브 비주얼 + 타이틀 */}
          <div className="contents-width">
            {/* S : 부고 등록 */}
            <div className="wrap-form">
              {/* S : 상주정보 */}
              {/* S : 대표 상주정보 */}
              <div className="section-form">
                <h3 className="label">
                  대표 상주 정보
                  {/* <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em> */}
                </h3>
                {/* S : 상주정보 */}
                <div className="ip-wrap ip-group ip-chief-mourner">
                  <div className="ip-item is-error">
                    <label for="ip-chief-mourner-relation" className="offscreen">
                      고인과의 관계
                    </label>
                    <select
                      id="ip-chief-mourner-relation"
                      name={'chief_mourner_desc'}
                      // aria-invalid={errors.chief_mourner_desc ? 'true' : 'false'}
                      onChange={handleChange}
                    >
                      {relationDetailCodes && relationDetailCodes.length > 0
                        ? relationDetailCodes.map(code => (
                            <option value={code.code} key={code.code}>
                              {code.value}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="ip-item is-error">
                    <label for="ip-chief-mourner-name" className="offscreen">
                      상주 이름
                    </label>
                    <input
                      {...register('chief_mourner')}
                      // aria-invalid={errors.chief_mourner ? 'true' : 'false'}
                      type="text"
                      id="ip-chief-mourner-name"
                      placeholder="상주명을 입력하세요"
                    />
                    {/* {errors.chief_mourner && (
                      <p className="message">{errors.chief_mourner.message}</p>
                    )} */}
                  </div>

                  <div className="ip-item is-error">
                    <label for="ip-chief-mourner-mobile" className="offscreen"></label>
                    <input
                      {...register('chief_mourner_phone')}
                      // aria-invalid={errors.chief_mourner_phone ? 'true' : 'false'}
                      type="number"
                      id="ip-chief-mourner-mobile"
                      placeholder="휴대폰번호* ex. 01011111111"
                    />
                    {/* {errors.chief_mourner_phone && (
                      <p className="message">{errors.chief_mourner_phone.message}</p>
                    )} */}
                  </div>
                </div>
                {/* E : 상주정보 */}
                {/* S : 상주 추가 버튼 */}
                {visual === false ? (
                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-md-white"
                      onClick={() => setVisual(true)}
                    >
                      상주 추가
                    </button>
                  </div>
                ) : (
                  ''
                )}
                {/* E : 상주 추가 버튼 */}
                {/* S : 디지털화환/부고전송 신청/사용 여부 */}
                <ul className="rc-list">
                  <li>
                    <div className="rc-item">
                      <input
                        type="checkbox"
                        id="chk-sample-1"
                        defaultChecked={true}
                        onChange={handleDigitalWreathYn}
                      />
                      <label for="chk-sample-1">디지털화환을 신청합니다.</label>
                    </div>
                  </li>
                  <li>
                    <div className="rc-item">
                      <input
                        type="checkbox"
                        id="chk-sample-2"
                        defaultChecked={true}
                        onChange={handleDeliveryYn}
                      />
                      <label for="chk-sample-2">부고 전송을 사용합니다.</label>
                    </div>
                  </li>
                </ul>
                {/* E : 디지털화환/부고전송 신청/사용 여부 */}
              </div>
              {/* E : 대표 상주정보 */}

              {/* S : 추가 상주정보 */}
              {visual === true ? (
                <Relations
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  setVisual={setVisual}
                  errors={errors}
                />
              ) : (
                ''
              )}
              {/* E : 추가 상주정보 */}
              {/* E : 상주정보 */}

              {/* S : 고인 정보 */}
              <div className="section-form">
                <h3 className="label">
                  고인 정보입력
                  <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em>
                </h3>

                {/* S : 고인 이름/직위 */}
                <div className="ip-wrap ip-group">
                  <div className="ip-item is-error">
                    <label for="ip-deceased-name" className="offscreen">
                      고인 이름 입력
                    </label>
                    <input
                      type="text"
                      id="ip-deceased-name"
                      placeholder="이름"
                      aria-invalid={errors.deceased_name ? 'true' : 'false'}
                      {...register('deceased_name')}
                    />
                    {errors.deceased_name && (
                      <p className="message">{errors.deceased_name.message}</p>
                    )}
                  </div>
                  <div className="ip-item p is-error">
                    <label for="ip-deceased-pos" className="offscreen">
                      고인 직위 입력
                    </label>
                    <input
                      type="text"
                      id="ip-deceased-pos"
                      placeholder="직위"
                      {...register('deceased_job_title')}
                    />
                  </div>
                </div>
                {/* E : 고인 이름/직위  */}

                {/* S : 생년월일 */}
                <div className="ip-item is-error">
                  <label for="ip-deceased-birth" className="label sm">
                    생년월일
                    {/* <em className="import">
                      <span className="offscreen">필수입력</span>
                    </em> */}
                  </label>
                  <input
                    type="date"
                    id="ip-deceased-birth"
                    // aria-invalid={errors.deceased_birthday ? 'true' : 'false'}
                    {...register('deceased_birthday')}
                  />
                  {/* {errors.deceased_birthday && (
                    <p className="message">{errors.deceased_birthday.message}</p>
                  )} */}
                </div>
                {/* E : 생년월일 -*/}

                {/* S : 임종 일시 */}
                <div className="ip-wrap ip-group">
                  <h4 className="label sm">
                    임종일자
                    {/* <em className="import">
                      <span className="offscreen">필수입력</span>
                    </em> */}
                  </h4>
                  <div className="ip-item is-error">
                    <label for="ip-deceased-dying-day" className="offscreen">
                      임종일자 입력
                    </label>
                    <input
                      type="date"
                      id="ip-deceased-dying-day"
                      // aria-invalid={errors.deceased_date ? 'true' : 'false'}
                      {...register('deceased_date')}
                    />

                    {/* {errors.deceased_date && (
                      <p className="message">{errors.deceased_date.message}</p>
                    )} */}
                  </div>
                  <div className="ip-item">
                    <label for="ip-deceased-dying-time" className="offscreen">
                      임종일자 시간
                    </label>
                    <input
                      type="time"
                      id="ip-deceased-dying-time"
                      // aria-invalid={errors.deceased_hour ? 'true' : 'false'}
                      {...register('deceased_hour')}
                    />
                  </div>
                </div>
                {/* E : 임종 일시 */}

                {/* S : 발인 일시 */}
                <div className="ip-wrap ip-group">
                  <h4 className="label sm">
                    발인일자
                    {/* <em className="import">
                      <span className="offscreen">필수입력</span>
                    </em> */}
                  </h4>
                  <div className="ip-item">
                    <label for="ip-deceased-death-day" className="offscreen">
                      발인일자
                    </label>
                    <input
                      type="date"
                      id="ip-deceased-death-day"
                      // aria-invalid={errors.deceased_hour ? 'true' : 'false'}
                      {...register('birth_date')}
                    />
                  </div>
                  <div className="ip-item">
                    <label for="ip-deceased-death-time" className="offscreen">
                      발인시간
                    </label>
                    <input
                      type="time"
                      id="ip-deceased-death-time"
                      // aria-invalid={errors.birth_hour ? 'true' : 'false'}
                      {...register('birth_hour')}
                    />
                  </div>

                  {/* S : 공개여부 */}
                  <div className="rc-item rc-toggle">
                    <input type="checkbox" id="ip-public10" />
                    <label for="ip-public10" onClick={() => handleBirthYn(birthYn ? false : true)}>
                      <span className="toggle"></span> 공개여부
                    </label>
                  </div>
                  {/* E : 공개여부 */}
                </div>
                {/* E : 발인 일시 */}

                {/* S : 고인 사진 */}
                {/* S : 고인사진은 기존 마크업에 className 이름만 추가되었습니다. */}
                <div className="ip-wrap ip-filebox">
                  <span className="label">고인사진</span>
                  <label className="file" for="imageFile">
                    <span
                      className="offscreen"
                      onClick={() => document.getElementById('imageFile').click()}
                    >
                      파일선택
                    </span>
                  </label>
                  <input
                    type="file"
                    className="input-hidden"
                    id="imageFile"
                    onChange={changeImageFile}
                  />

                  {/* 첨부 이미지 등록 전 */}
                  {/*<div className="file_output default"></div>*/}

                  {/* 첨부 이미지 등록 후 */}
                  {deceasedPhoto ? (
                    <div className="file-output" onChange={changeImageFile}>
                      <span className="img-area">
                        <img
                          alt=""
                          src={deceasedPhoto}
                          //src="https://d3lzegcz38cz56.cloudfront.net/photo/41092f61-c8a8-4b57-8a39-e505942cb9953d9a47a286411ddbb2060b7d2ecef49e4bef53c9.jpg"
                        />
                      </span>
                      <button className="file-del" onClick={() => imageDelete()}>
                        <span className="offscreen">사진 삭제</span>
                      </button>
                    </div>
                  ) : (
                    <div className="file_output default" onChange={changeImageFile}></div>
                  )}
                </div>
                {/* E : 고인 사진 */}
              </div>
              {/* E : 고인 정보 */}

              {/* S : 고인 일대기(영상) */}
              {/* S : 고인 일대기 영상은 기존 마크업에 className 이름만 추가되었습니다. */}
              <div className="section-form">
                <div className="pos-r">
                  <span className="label">고인 일대기(영상)</span>

                  <MediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
                  <div className="rc-item rc-toggle">
                    <input type="checkbox" id="ip-public01" />
                    <label for="ip-public01" onClick={() => handleVideoYn(videoYn ? false : true)}>
                      <span className="toggle"></span> 공개여부
                    </label>
                  </div>
                </div>
              </div>
              {/* E : 고인 일대기(영상) */}

              {/* S : 안내글, 추도사 */}
              <div className="section-form">
                {/* S : 안내글 입력 */}
                <div className="ip-item is-error">
                  <label for="ip-text1" className="label">
                    안내글
                    {/* <em className="import">
                      <span className="offscreen">필수입력</span>
                    </em> */}
                  </label>
                  <input
                    type="text"
                    id="ip-text1"
                    placeholder="예) 저희 상가는 조의금을 정중히 사양합니다."
                    {...register('deceased_introduction')}
                    // aria-invalid={errors.deceased_introduction ? 'true' : 'false'}
                  />
                  {/* {errors.deceased_introduction && (
                    <p className="message">{errors.deceased_introduction.message}</p>
                  )} */}
                </div>
                {/* E : 안내글 입력 */}
                {/* S : 추도사 입력 */}
                <div className="ip-wrap ">
                  <div className="ip-item is-error">
                    <label for="text-tribute" className="label">
                      추도사
                      <em className="import">
                        <span className="offscreen">필수입력</span>
                      </em>
                    </label>
                    <textarea
                      {...register('chudosa')}
                      id="text-tribute"
                      placeholder="예) 삼가 영전(靈前)에 깊은 애도(哀悼)의 뜻을 표합니다."
                    ></textarea>
                    {errors.chudosa && <p className="message">{errors.chudosa.message}</p>}
                  </div>
                  <button
                    type="button"
                    className="btn-underline"
                    onClick={() => setOpenChudosaSampleDialog(true)}
                  >
                    예시보기
                  </button>
                </div>
                {/* E : 추도사 입력 */}
              </div>
              {/* E : 안내글, 추도사 */}

              {/* S : 장례식장 정보 */}
              <div className="section-form">
                {/* S : 장례식장 입력 */}
                <div className="ip-wrap ip-group ip-funeral">
                  <h4 className="label">
                    장례식장{' '}
                    <em className="import">
                      <span className="offscreen">필수입력</span>
                    </em>
                  </h4>
                  <div className="ip-item has-btn is-error">
                    <label for="ip-deceased-name" className="offscreen">
                      장례식장 이름
                    </label>
                    <input
                      type="text"
                      id="ip-deceased-name"
                      placeholder="장례식장을 검색해주세요"
                      value={hall ? hall.hall_name : ''}
                      onClick={() => setOpenSearchHallDialog(true)}
                      aria-invalid={errors.funeral_addr ? 'true' : 'false'}
                    />
                    <button
                      type="button"
                      className="btn-search-funeral"
                      onClick={() => setOpenSearchHallDialog(true)}
                    >
                      검색
                    </button>

                    {errors.funeral_addr && (
                      <p className="message">{errors.funeral_addr.message}</p>
                    )}
                  </div>
                  <div className="ip-item">
                    <label for="ip-deceased-pos" className="offscreen">
                      장례식장 호실 입력
                    </label>
                    <input
                      type="text"
                      {...register('funeral_room')}
                      name="funeral_room"
                      id="ip-deceased-pos"
                      placeholder="호실"
                    />
                  </div>

                  <div className="rc-item rc-toggle">
                    <input type="checkbox" id="ip-public03" />
                    <label
                      for="ip-public03"
                      onClick={() => handleFuneralAddrYn(funeralAddrYn ? false : true)}
                    >
                      <span className="toggle"></span> 공개여부
                    </label>
                  </div>
                </div>
                {/* E : 장례식장 입력 */}

                {/* S : 장지 입력 */}
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label for="ip-commissioner" className="label">
                      장지
                    </label>
                    <input
                      type="text"
                      {...register('funeral_place')}
                      id="ip-commissioner"
                      placeholder="장지주소를 입력해주세요"
                    />
                    {errors.funeral_place && (
                      <p className="message">{errors.funeral_place.message}</p>
                    )}
                  </div>
                  <div className="rc-item rc-toggle">
                    <input type="checkbox" id="ip-public04" />
                    <label
                      for="ip-public04"
                      onClick={() => handleFuneralYn(funeralYn ? false : true)}
                    >
                      <span className="toggle"></span> 공개여부
                    </label>
                  </div>
                </div>
                {/* E : 장지 입력 */}

                {/* S : 장례위원장 입력 */}
                <div className="ip-wrap">
                  <div className="ip-item">
                    <label for="ip-commissioner" className="label">
                      장례위원장
                    </label>
                    <input
                      {...register('chief_funeral_nm')}
                      type="text"
                      id="ip-commissioner"
                      placeholder="장례위원장명을 입력하세요"
                    />
                  </div>
                  <div className="rc-item rc-toggle">
                    <input type="checkbox" id="ip-public05" />
                    <label
                      for="ip-public05"
                      onClick={() => handleChiefFuneralYn(chiefFuneralYn ? false : true)}
                    >
                      <span className="toggle"></span> 공개여부
                    </label>
                  </div>
                </div>
                {/* E : 장례위원장 입력 */}
              </div>
              {/* E : 장례식장 정보 */}

              {/* S : 조의금 계좌 입력 */}
              <div className="section-form">
                <h3 className="label">
                  조의금 계좌 입력
                  <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em>
                </h3>
                <Condolatorys
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                />
                {/* E : 조의금 계좌 입력 */}
                {/* S : 조의금 계좌 신청/사용 여부 */}
                <ul className="rc-list">
                  <li>
                    <div className="rc-item">
                      <input
                        type="checkbox"
                        id="chk-sample-10"
                        defaultChecked={true}
                        onChange={handleCondolatoryYn}
                      />
                      <label for="chk-sample-10">조의금을 받을 수 있는 버튼을 사용합니다.</label>
                    </div>
                  </li>
                </ul>
                {/* E : 조의금 계좌 신청/사용 여부 */}
              </div>
              <div className="section-form">
                <Chudosas
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                />
              </div>
              <div className="section-form">
                <Blts control={control} setValue={setValue} getValues={getValues} errors={errors} />
              </div>
              {/* S : 부고 공개 여부 확인 */}
              <ul className="rc-list">
                <li>
                  <div className="rc-item">
                    <input
                      type="checkbox"
                      id="chk-sample-3"
                      defaultChecked={true}
                      onChange={handleChangeDisplayYn}
                    />
                    <label for="chk-sample-3">입력한 부고를 공개하시겠습니까?</label>
                  </div>
                </li>
                <li>
                  {/* E : 부고 공개 여부 확인 */}
                  <div className="rc-item">
                    <input
                      type="checkbox"
                      id="chk-sample-4"
                      defaultChecked={false}
                      onChange={handleChangeMultyYn}
                    />
                    <label for="chk-sample-4">입력한 추모관을 공개하시겠습니까?</label>
                  </div>
                </li>
              </ul>

              {/* S : 버튼 */}
              <div className="btn-wrap">
                <button
                  type="button"
                  className="btn-form btn-ln-blue"
                  onClick={() => handlePreview()}
                >
                  미리보기
                </button>
                <button
                  type="button"
                  className="btn-form btn-navy"
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  등록완료
                </button>
                <button type="button" className="btn-form btn-ln-gray " onClick={handleClose}>
                  취소
                </button>
              </div>
              {/* E : 버튼 */}
            </div>
            {/* E : 부고 등록 */}
          </div>
        </div>
        <ChudosaSampleDialog
          isOpen={openChudosaSampleDialog}
          onClose={() => setOpenChudosaSampleDialog(false)}
        />
        <SearchHallDialog
          isOpen={openSearchHallDialog}
          onClose={() => setOpenSearchHallDialog(false)}
          selectedHall={selectedHall}
        />
        {openPreviewDialog && (
          <PreviewDialog
            isOpen={openPreviewDialog}
            onClose={() => setOpenPreviewDialog(false)}
            getValues={getValues}
          />
        )}
      </section>
    </>
  );
}

export default AdminObituaryRegisterPage;

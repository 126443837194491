import { useState, useEffect } from 'react';
import { getCanvasBltApi } from 'apis/canvasBlt';
import useCode from 'stores/code/hook';
import useDialog from 'stores/dialog/hook';

import { useFieldArray, Controller } from 'react-hook-form';

import LifeCanvasQuillEditor from 'components/editor/LifeCanvasQuillEditor';

import BltSampleDialog from 'pages/memorial-canvas/obituary/components/BltSampleDialog';
import OpenHistoryDialog from '../OpenHistoryDialog';
import OpenJoinHistoryDialog from '../../OpenJoinHistoryDialog';

function Blts({ control, setValue, getValues, errors, canvasId, setResponseId, setMode }) {
  const { showAlert, hideAlert } = useDialog();
  const { bltTitleCodes } = useCode();
  const [contents, setContents] = useState('');
  const [openBltSampleDialog, setOpenBltSampleDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openJoinHistoryDialog, setOpenJoinHistoryDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [bltId, setBltId] = useState();
  const [bltContent, setBltContent] = useState();
  const [checked, setChecked] = useState(true);
  const [checkedInputs, setCheckedInputs] = useState([]);

  const { showLifeAlert, hideLifeAlert } = useDialog();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'blt_datas',
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const handleAppend = () => {
    if (fields.length > 4) {
      showAlert('알림', '조문보는 최대 5개까지 등록 가능합니다.', () => hideAlert());
      return;
    }
    append({ blt_title: '', blt_contents: '', display_yn: 'Y' });
  };

  const handleRemove = index => {
    remove(index);
  };

  const handleOrderUp = index => {
    const targetIndex = index - 1;
    if (targetIndex > -1) move(index, index - 1);
  };

  const handleOrderDown = index => {
    const targetIndex = index + 1;
    if (fields.length > targetIndex) move(index, targetIndex);
  };

  const handleChangeDisplayYn = (name, value) => {
    setValue(name, value);
    move(0, 0);
  };

  const getSelectedValue = value => {
    setOpenHistoryDialog(false);
    setOpenJoinHistoryDialog(false);
    getCanvasBlt();
    setValue(`blt_datas.${selectedIndex}.blt_title`, value);
    setValue(`blt_datas.${selectedIndex}.blt_contents`, bltContent);
    //setBltContent(null);
    // setValue('blt_title', value); //조문보 내용 입력하는 걸로 보내야함
  };

  const handleOpenTemplate = index => {
    setSelectedIndex(index);
    setOpenHistoryDialog(true);
  };

  const handleOpenLinkedTemplate = index => {
    setSelectedIndex(index);
    setOpenJoinHistoryDialog(true);
  };

  const getCanvasBlt = async () => {
    let params = {};
    params.blt_id = bltId;

    const result = await getCanvasBltApi(params);

    if (result.code === '0000') {
      Object.entries(result.data).forEach(([key, value]) => {
        //setValue(key, value);
        if (key === 'blt_contents') setBltContent(value);
      });
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  useEffect(() => {
    getCanvasBlt();
  }, [bltId]);

  useEffect(() => {
    if (fields && fields.length === 0) handleAppend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <div className="condolence-wrap">
      <div className="condolence-header">
        <h3>조문보 등록</h3>

        <div className="btn-wrap">
          <button type="button" className="btn-sample" onClick={() => setOpenBltSampleDialog(true)}>
            <i className="ic ic-sample"></i>
            샘플보기
          </button>
          <button type="button" className="btn btn-md-text" onClick={handleAppend}>
            + 추가
          </button>
        </div>
      </div>
      {/* S : 추가(복사)되는 영역 */}
      {fields &&
        fields.length > 0 &&
        fields.map((item, index) => (
          <div className="condolence-content" key={item.id}>
            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-sort btn-sort-up"
                onClick={() => handleOrderUp(index)}
              >
                <i className="ic ic-up"></i>
                <span className="offscreen">위로정렬</span>
              </button>
              <button
                type="button"
                className="btn btn-sort btn-sort-down"
                onClick={() => handleOrderDown(index)}
              >
                <i className="ic ic-down"></i>
                <span className="offscreen">아래로정렬</span>
              </button>
              <button
                type="button"
                className="btn btn-sort btn-sort-delete"
                onClick={() => handleRemove(index)}
              >
                <i className="ic ic-minus"></i>
                <span className="offscreen">삭제</span>
              </button>
            </div>
            <div className="condolence-option">
              {setMode === '2' ? (
                <button
                  type="button"
                  className="btn btn-xs btn-lnblue"
                  data-micromodal-trigger="modal-sample-02"
                  onClick={() => handleOpenTemplate(index)}
                >
                  내 자서전에서 불러오기
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-xs btn-lnblue"
                  data-micromodal-trigger="modal-sample-02"
                  onClick={() => handleOpenLinkedTemplate(index)}
                >
                  연결 자서전에서 불러오기
                </button>
              )}
              <div className="rc-item rc-toggle">
                <input
                  type="checkbox"
                  id="ip-public01"
                  name={`blt_datas.${index}.display_yn`}
                  // onChange={()=>}
                  checked={getValues(`blt_datas.${index}.display_yn`) === 'Y' ? true : false}
                />
                <label
                  for="ip-public01"
                  onClick={() =>
                    handleChangeDisplayYn(
                      `blt_datas.${index}.display_yn`,
                      getValues(`blt_datas.${index}.display_yn`) === 'Y' ? 'N' : 'Y',
                    )
                  }
                >
                  공개여부
                  <span className="toggle"></span>
                </label>
              </div>
            </div>

            <div className="condolence-option column">
              <div className="sel-item">
                <label for="sel-category1" className="offscreen"></label>
                <select
                  id="sel-category1"
                  className="select"
                  name={`blt_datas.${index}.blt_title`}
                  onChange={handleChange}
                >
                  {bltTitleCodes && bltTitleCodes.length > 0
                    ? bltTitleCodes.map(code => (
                        <option value={code.code} key={code.value}>
                          {code.value}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="ip-item is-error">
                <label for="ip-subject1" className="offscreen">
                  제목 입력
                </label>
                <Controller
                  control={control}
                  name={`blt_datas.${index}.blt_title`}
                  render={({ field }) => (
                    <input
                      {...field}
                      aria-invalid={errors.blt_datas?.[index]?.blt_title ? 'true' : 'false'}
                      type="text"
                      id="ip-subject1"
                      placeholder="최대 20글자로 입력해주세요."
                      maxLength="20"
                    />
                  )}
                />
                {errors.blt_datas?.[index]?.blt_title && (
                  <p class="message">{errors.blt_datas?.[index]?.blt_title.message}</p>
                )}
              </div>
            </div>
            <div className="write-form">
              <div className="box-editor has-editor">
                {/* 에디터 입력 경우일 때만 has-editor 추가(내부 여백값 삭제) */}
                <Controller
                  control={control}
                  name={`blt_datas.${index}.blt_contents`}
                  render={({ field }) => (
                    <div>
                      <LifeCanvasQuillEditor
                        {...field}
                        type="text"
                        id="contents"
                        className="input"
                        aria-invalid={errors.blt_contents?.[index]?.blt_contents ? 'true' : 'false'}
                        //value={contents}
                        //onChange={changeContents}
                        style={{ minHeight: '50vh' }}
                      />
                    </div>
                  )}
                />
                {/* // <div style={{ height: '250px', background: 'rgba(249,99,99,.3)' }}>
              //   EDITOR(세로값 100% 가 되게 설정해주세요)
              // </div> */}
              </div>
              <div className="ip-item is-error">
                {errors.blt_datas?.[index]?.blt_contents && (
                  <p class="message">{errors.blt_datas?.[index]?.blt_contents.message}</p>
                )}
              </div>
            </div>
          </div>
        ))}

      <BltSampleDialog isOpen={openBltSampleDialog} onClose={() => setOpenBltSampleDialog(false)} />
      {openHistoryDialog && (
        <OpenHistoryDialog
          isOpen={openHistoryDialog}
          getSelectedValue={getSelectedValue}
          setBltId={setBltId}
          onClose={() => setOpenHistoryDialog(false)}
        />
      )}
      {openJoinHistoryDialog && (
        <OpenJoinHistoryDialog
          isOpen={openJoinHistoryDialog}
          getSelectedValue={getSelectedValue}
          setBltId={setBltId}
          canvasId={canvasId}
          setResponseId={setResponseId}
          onClose={() => setOpenJoinHistoryDialog(false)}
        />
      )}
    </div>
  );
}

export default Blts;

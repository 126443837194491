import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDialog from 'stores/dialog/hook';
import { getWreathApi, getWreathsApi, postCalculationApi } from 'apis/wreathCalculation';

//excel download
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function WreathCalculate() {
  const navigate = useNavigate();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { memorialId } = useParams();
  const [wreath, setWreath] = useState([]);
  const [wreathList, setWreathList] = useState([]);
  const [showCommission, setShowCommission] = useState(false);
  const [guideList, setGuideList] = useState([false, false, false, false]);
  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);

  const getWreath = async () => {
    let params = {};
    params.memorial_id = memorialId;
    // api 요청
    const result = await getWreathApi(params);
    if (result.code === '0000') {
      setWreath(result.data[0]);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getWreathList = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = listSize;

    // api 요청
    const result = await getWreathsApi(params);

    if (result.code === '0000') {
      setWreathList(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getWreateListMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = listSize;

    // api 요청
    const result = await getWreathsApi(params);
    if (result.code === '0000') {
      setWreathList([...wreathList, ...result.data]);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getWreateListExcel = async () => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = 1;
    params.itemInPage = 9999;

    // api 요청
    const result = await getWreathsApi(params);
    if (result.code === '0000') {
      excelDownload(result.data);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handleClickMoreWreate = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getWreateListMore(nextPage);
    }
  };

  const handleClickCommission = () => {
    setShowCommission(() => !showCommission);
  };

  const handleShowGuide = index => {
    let temp = [...guideList];
    temp[index] = true;
    setGuideList(temp);
  };

  const handleDownloadExcel = () => {
    getWreateListExcel();
  };

  const handleHideGuide = () => {
    setGuideList([false, false, false, false]);
  };

  // 콤마 안찍혀서 날아오면 사용할 것
  const numberFormatter = num => {
    return Number(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // excel
  const excelFileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const excelFileExtension = '.xlsx';
  /**
   * excel download
   */
  const excelDownload = excelData => {
    const ws = XLSX.utils.aoa_to_sheet([
      ['날짜', '보낸분 성함', '회사명/모임명', '화환 종류', '금액'],
    ]);
    excelData.map(data => {
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            data.reg_date,
            data.send_name,
            data.send_group,
            data.flower_type_name,
            data.flower_price,
          ],
        ],
        {
          origin: -1,
        },
      );
      ws['!cols'] = [
        //<-- 행 사이즈
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
      ];
      return false;
    });
    const wb = { Sheets: { sheet: ws }, SheetNames: ['sheet'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, '디지털 화환 상세 내역' + excelFileExtension);
  };

  useEffect(() => {
    if (memorialId) {
      // 디지털 화환 내역
      getWreath();
      // 디지털 화환 상세 내역(리스트)
      getWreathList(1);
    }
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">디지털 화환 </h2>
            <p>
              디지털 화환은 상주께 전해지며 허례의식을 줄이고
              <br />
              유가족 위로와 기부문화에 이바지합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          {/* S : 디지털 화한 정산 내역 */}
          <div className="wrap-wreath-calculate">
            {/* S : 타이틀 */}
            <div className="box-title">
              <h3 className="title">디지털 화환 내역</h3>
              <p className="total-price">
                총 정산금액
                <em>{numberFormatter(wreath.flower_price_final)}원</em>
              </p>
            </div>
            {/* E : 타이틀 */}
            {/* S : 이름 */}
            <div className="box-chief-mourner">
              <p className="deceased">{wreath.deceased_name}</p>
              <p className="name">{wreath.chief_mourner_desc + ' ' + wreath.chief_mourner}</p>
            </div>
            {/* E : 이름 */}
            {/* S : 세부 내역 */}
            <div className="wrap-collection">
              <div className="total-collection">
                <p className="title">디지털 화환 총 모금액</p>
                <p className="price">{numberFormatter(wreath.flower_price)}원</p>
              </div>

              {/*
							***** DESC *****
							수수료 텍스트가 포함된 button을 클릭 했을 때, className="wrap-commission"에 className="is-active"가 추가되게 해주세요.
						*/}
              <div className={showCommission ? 'wrap-commission is-active' : 'wrap-commission'}>
                <div className="box-commission">
                  <p className="title">
                    <button type="button" onClick={handleClickCommission}>
                      수수료
                    </button>
                  </p>
                  <p className="price">{numberFormatter(wreath.flower_fee)}원</p>
                </div>
                <div className="list-commission">
                  <dl>
                    {/*
										***** DESC *****
										className="btn-popup-guide" 버튼을 클릭(혹은 마우스 오버) 했을 때, <dt>에 className="is-active"가 부여되게 해주세요.
									*/}
                    <dt className={guideList[0] ? 'is-active' : ''}>
                      부가세
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(0)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">부가세는 총 모금액-(총 모금액/1.1) 입니다.</div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_surtax)}원</dd>
                    <dt className={guideList[1] ? 'is-active' : ''}>
                      PG 수수료
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(1)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">
                        PG 수수료는 신용카드인 경우 (총 모금액 + 부가세) * 2.3% 이고, 무통장입금인
                        경우 (총 모금액 + 부가세) * 1.2% 입니다.
                      </div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_pg_fee)}원</dd>
                    <dt className={guideList[2] ? 'is-active' : ''}>
                      법인세
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(2)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">
                        법인세는 (총 모금액+부가세+PG수수료)*0.2 입니다.
                      </div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_corporate_tax)}원</dd>
                    <dt className={guideList[3] ? 'is-active' : ''}>
                      이용 수수료
                      {/* S : 도움말 보기 */}
                      <button
                        className="btn-popup-guide"
                        onMouseOver={() => handleShowGuide(3)}
                        onMouseOut={handleHideGuide}
                      >
                        <span className="offscreen">도움말 보기</span>
                      </button>
                      {/* S : 도움말 툴팁 */}
                      <div className="box-tooltip">이용 수수료는 총 모금액 * 0.11 입니다.</div>
                      {/* E : 도움말 툴팁 */}
                      {/* E : 도움말 보기 */}
                    </dt>
                    <dd>{numberFormatter(wreath.flower_use_fee)}원</dd>
                  </dl>
                </div>
              </div>
              <div className="calculate-collection">
                <p className="title">
                  <span>정산금액</span>
                </p>
                <p className="price">
                  <em>{numberFormatter(wreath.flower_price_final)}원</em>
                </p>
              </div>
            </div>
            {/* E : 세부 내역 */}
            {/* S : 목록 */}
            <div className="box-title between">
              <h4 className="title sm">상세 내역 리스트</h4>
              <a onClick={() => handleDownloadExcel()} className="btn-download">
                <i className="ic ic-download"></i>엑셀 다운로드
              </a>
            </div>
            <div className="list-wreath-calculate">
              {wreathList
                ? wreathList.map((wreath, index) => {
                    return (
                      <div className="box-wreath-calculate" key={wreath.seq}>
                        <div className="box-content">
                          <p className="title">{wreath.flower_type_name}</p>
                          <p className="price">{numberFormatter(wreath.flower_price)}원</p>
                        </div>
                        <div className="info">
                          <span className="group">{wreath.send_group}</span>
                          <span className="name">{wreath.send_name}</span>
                          <span className="date">{wreath.reg_date.split(' ')[0]}</span>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            {/* E : 목록  */}
            {/* S : 페이징 */}
            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-more"
                onClick={() => handleClickMoreWreate(page)}
              >
                더보기 {page}/{totalPage}
              </button>
            </div>
          </div>
          {/* E : 페이징 */}
          {/* E : 디지털 화한 정산 내역 */}

          {/* S : 페이징 */}
          <div className="btn-wrap">
            {/* S : 정산 완료 되었을 대 버튼 이름명 변경 및 비활성화 상태 */}
            {wreath.calculate_yn === 'N' ? (
              <a
                href={() => false}
                className="btn btn-pos"
                onClick={() => navigate(`/pet/mypage/wreath/result/${memorialId}`)}
              >
                디지털 화환 정산
              </a>
            ) : (
              <a href={() => false} className="btn btn-pos" onClick={() => navigate(-1)} disabled>
                디지털 화환 정산 완료
              </a>
            )}
            {/* E : 정산 완료 되었을 대 버튼 이름명 변경 및 비활성화 상태 */}
          </div>
          {/* E : 페이징 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default WreathCalculate;

import { useState, useEffect } from 'react';
import useDialog from 'stores/dialog/hook';
import { useNavigate } from 'react-router-dom';

import { saveMemberApi } from 'apis/member';
import { notiAuthSendApi, notiAuthVerifyApi } from 'apis/openapi';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ServiceDialog from 'layouts/memorial-canvas/Login/ServiceDialog';
import PrivacyDialog from 'layouts/memorial-canvas/Login/PrivacyDialog';

function RegisterDefaultPage({ onChange, closeDialog }) {
  const { showAlert, hideAlert } = useDialog();

  const navigate = useNavigate();

  const [minutes, setMinutes] = useState(parseInt(0));
  const [seconds, setSeconds] = useState(parseInt(0));

  const [openPrivacyDialog, setOpenPrivacyDialog] = useState(false);
  const [openServiceDialog, setOpenServiceDialog] = useState(false);

  // 인증 전 : 0 인증 후 : 1
  const [authStatus, setAuthStatus] = useState(0);
  const [authCode, setAuthCode] = useState(0);
  const [birth, setBirth] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const schema = Yup.object({
    email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    // member_name: Yup.string()
    //   .required('* 필수 입력 항목입니다.')
    //   .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    passwd: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)-_=+]).{8,16}$',
        '* 패스워드는 최소 8자 이상 16자 이하 영문, 숫자, 특수기호를 포함해야합니다.',
      ),
    passwd_confirm: Yup.string().oneOf([Yup.ref('passwd'), null], '비밀번호가 일치하지 않습니다'),
    // mobile: Yup.string()
    //   .required('* 필수 입력 항목입니다.')
    //   .matches(
    //     '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
    //     '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
    //   ),
    // code: Yup.string()
    //   .required('* 필수입력 항목입니다.')
    //   .min(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.')
    //   .max(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.'),
    agreement_privacy: Yup.boolean().oneOf([true], '* 필수 체크 항목입니다.'),
    agreement_service: Yup.boolean().oneOf([true], '* 필수 체크 항목입니다.'),
  }).required();

  const defaultValues = {
    email: '',
    member_name: '',
    mobile: '',
    passwd: '',
    passwd_confirm: '',
    login_type: 'E',
    id: 0,
    code: '',
    verified: 'N',
    agreement_privacy: false,
    agreement_service: false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    // if (getValues('verified') !== 'Y') {
    //   showAlert('알림', '인증코드를 확인하세요.', () => hideAlert());
    // } else {
    //   saveMember(data);
    // }
    if (authStatus === 0) {
      showAlert('알림', '본인 인증이 완료되지 않았습니다.', () => hideAlert());
    } else {
      saveMember(data);
    }
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleNotiAuthSend = () => {
    // if (!getValues('mobile') || getValues('mobile') === '' || errors.mobile) {
    //   showAlert('알림', '휴대폰 번호를 확인하세요.', () => hideAlert());
    // } else {
    //   notiAuthSend();
    // }
    window.open(
      'https://mylifecanvas.co.kr/apiserver/authVerify',
      'auth_popup',
      'width=430,height=640,scrollbars=yes',
    );
  };

  const notiAuthSend = async () => {
    const params = { send_type: 'S', phone_num: getValues('mobile') };
    const result = await notiAuthSendApi(params);

    setValue('id', result.id);
    showAlert('알림', '문자로 발송된 인증번호를 입력하세요.', () => hideAlert());
  };

  const handleNotiAuthVerify = () => {
    if (!getValues('code') || getValues('code') === '' || errors.code) {
      showAlert('알림', '인증번호를 확인하세요.', () => hideAlert());
    } else {
      notiAuthVerify();
    }
  };

  const notiAuthVerify = async () => {
    const params = { id: getValues('id'), code: getValues('code') };
    const result = await notiAuthVerifyApi(params);
    if (result.code === '0000') setValue('verified', 'Y');
    showAlert('알림', result.message, () => hideAlert());
  };

  const saveMember = async parmas => {
    parmas.birth = birth;
    parmas.tx_seq_no = authCode;
    parmas.member_name = name;
    parmas.mobile = phone;

    const result = await saveMemberApi(parmas);

    if (result.code === '0000') {
      showAlert('알림', result.message, () => successSaveMember());
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const successSaveMember = () => {
    hideAlert();
    navigate(-1);
  };
  const resetTimer = () => {
    setMinutes(3);
    setSeconds(0);
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(seconds) > 0) {
        setSeconds(parseInt(seconds) - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(minutes) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  useEffect(() => {
    // 본인인증 서비스
    window.addEventListener('message', function (e) {
      const result = e.data;
      // RSLT_CD: "B000"면 성공
      if (result.RSLT_CD === 'B000') {
        setAuthStatus(1);
        setBirth(result.RSLT_BIRTHDAY);
        setAuthCode(result.TX_SEQ_NO);
        setName(result.RSLT_NAME);
        setPhone(result.TEL_NO);
      }
      console.log(result);
    });

    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('message', function (e) {
        const result = e.data;
        // RSLT_CD: "B000"면 성공

        console.log(result);
      });
    };
  }, []);

  return (
    <>
      <section class="container" id="containerWrap">
        {/* S : CONTENT */}
        <div class="content">
          <div className="visual-wrap">
            <div className="contents-width">
              <h2 className="title">회원가입</h2>
            </div>
          </div>
          <div class="contents-width">
            {/* S : 회원가입 */}
            <div class="member-form">
              <h2>JOIN US</h2>
              <form>
                <fieldset>
                  <legend></legend>
                  {/* S : 아이디,비밀번호 */}
                  {/* <div class="ip-wrap"> */}
                  <div class="ip-item is-error  is-overlap">
                    <label for="user-id" class="offscreen">
                      이메일주소 입력
                    </label>
                    <input
                      {...register('email')}
                      aria-invalid={errors.email ? 'true' : 'false'}
                      type="email"
                      id="user-id"
                      placeholder="이메일주소"
                    />
                    {errors.email && <p class="message">{errors.email.message}</p>}
                  </div>
                  <div class="ip-item is-error">
                    <label for="user-pw" class="offscreen">
                      비밀번호 입력
                    </label>
                    <input
                      {...register('passwd')}
                      aria-invalid={errors.passwd ? 'true' : 'false'}
                      type="password"
                      id="user-pw"
                      placeholder="비밀번호"
                    />
                    {errors.passwd && <p class="message">{errors.passwd.message}</p>}
                  </div>
                  <div class="ip-item is-error">
                    <label for="user-pw2" class="offscreen">
                      비밀번호 입력
                    </label>
                    <input
                      {...register('passwd_confirm')}
                      aria-invalid={errors.passwd_confirm ? 'true' : 'false'}
                      type="password"
                      id="user-pw2"
                      placeholder="비밀번호 확인"
                    />
                    {errors.passwd_confirm && (
                      <p class="message">{errors.passwd_confirm.message}</p>
                    )}
                  </div>
                  {/* </div> */}
                  {/* E : 아이디,비밀번호 */}

                  {/* S : 인증 전 */}
                  {/* <div class="ip-wrap"> */}
                  {/*<div class="ip-item is-error">*/}
                  {/*  <label for="user-name" class="offscreen">*/}
                  {/*    이름 입력*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    {...register('member_name')}*/}
                  {/*    aria-invalid={errors.member_name ? 'true' : 'false'}*/}
                  {/*    type="text"*/}
                  {/*    id="user-name"*/}
                  {/*    placeholder="이름"*/}
                  {/*  />*/}
                  {/*  {errors.member_name && <p class="message">{errors.member_name.message}</p>}*/}
                  {/*</div>*/}
                  {/*<div class="ip-wrap has-btn">*/}
                  {/*  <div class="ip-item">*/}
                  {/*    <label for="user-mobile" class="offscreen">*/}
                  {/*      휴대폰 번호 입력*/}
                  {/*    </label>*/}
                  {/*    <input*/}
                  {/*      {...register('mobile')}*/}
                  {/*      aria-invalid={errors.mobile ? 'true' : 'false'}*/}
                  {/*      type="number"*/}
                  {/*      id="user-mobile"*/}
                  {/*      placeholder="휴대폰 번호"*/}
                  {/*    />*/}
                  {/*    <button type="button" class="btn btn-sm-gray" onClick={handleNotiAuthSend}>*/}
                  {/*      인증번호 전송*/}
                  {/*    </button>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/* </div> */}
                  {/* <div class="btn-wrap">
                  <button type="button" class="btn btn-blue" onClick={handleNotiAuthSend}>
                    인증번호 전송
                  </button>
                </div> */}
                  {/* E : 인증 전 */}

                  {/* S : 인증 후 */}
                  {/* <div class="btn-wrap">
                  <button type="button" class="btn btn-blue" disabled>
                    인증번호 전송
                  </button>
                </div> */}
                  {/*<div class="ip-wrap ip-certify has-btn">*/}
                  {/*  <div class="ip-wrap">*/}
                  {/*    <div class="ip-item is-error">*/}
                  {/*      <label for="user-certify" class="offscreen">*/}
                  {/*        인증번호 입력*/}
                  {/*      </label>*/}
                  {/*      <input*/}
                  {/*        {...register('code')}*/}
                  {/*        aria-invalid={errors.code ? 'true' : 'false'}*/}
                  {/*        type="text"*/}
                  {/*        id="user-certify"*/}
                  {/*        placeholder="인증번호"*/}
                  {/*      />*/}
                  {/*      <span class="timer">*/}
                  {/*        남은시간 : {minutes}분 {seconds < 10 ? `0${seconds}` : seconds}초*/}
                  {/*      </span>*/}
                  {/*      <button*/}
                  {/*        type="button"*/}
                  {/*        class="btn btn-sm-blue"*/}
                  {/*        onClick={handleNotiAuthVerify}*/}
                  {/*      >*/}
                  {/*        인증코드 확인*/}
                  {/*      </button>*/}
                  {/*      {errors.code && <p class="message">{errors.code.message}</p>}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/* <div class="btn-wrap">
                  <button type="button" class="btn btn-blue" onClick={handleNotiAuthVerify}>
                    확인
                  </button>
                </div> */}
                  {/* E : 인증 후 */}

                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={handleNotiAuthSend}
                      disabled={authStatus === 1 ? true : false}
                    >
                      본인 인증
                    </button>
                  </div>

                  {/* S : 약관동의 */}
                  <div class="rc-item is-error">
                    <input
                      {...register('agreement_privacy')}
                      aria-invalid={errors.agreement_privacy ? 'true' : 'false'}
                      type="checkbox"
                      id="chk-agree-term1"
                    />
                    <label for="chk-agree-term1">개인정보처리방침</label>
                    <a href={() => false} onClick={() => setOpenPrivacyDialog(true)}>
                      보기
                    </a>
                    {errors.agreement_privacy && (
                      <p class="message">{errors.agreement_privacy.message}</p>
                    )}
                  </div>
                  <div class="rc-item is-error">
                    <input
                      {...register('agreement_service')}
                      aria-invalid={errors.agreement_service ? 'true' : 'false'}
                      type="checkbox"
                      id="chk-agree-term2"
                    />
                    <label for="chk-agree-term2">이용약관</label>
                    <a href={() => false} onClick={() => setOpenServiceDialog(true)}>
                      보기
                    </a>
                    {errors.agreement_service && (
                      <p class="message">{errors.agreement_service.message}</p>
                    )}
                  </div>
                  {/* E : 약관동의 */}

                  <div class="btn-wrap">
                    <a
                      href={() => false}
                      class="btn btn-white"
                      onClick={() => navigate('/memorial/login')}
                    >
                      취소
                    </a>
                    <button
                      type="button"
                      class="btn btn-blue"
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      가입하기
                    </button>
                    {/* <a class="btn btn-white" onClick={() => navigate('/memorial/login/register')}>
                      취소
                    </a> */}
                  </div>
                </fieldset>
              </form>
            </div>
            {/* E : 회원가입 */}
          </div>
        </div>
        {/* E : CONTENT */}
        {openPrivacyDialog && (
          <PrivacyDialog isOpen={openPrivacyDialog} onClose={() => setOpenPrivacyDialog(false)} />
        )}
        {openServiceDialog && (
          <ServiceDialog isOpen={openServiceDialog} onClose={() => setOpenServiceDialog(false)} />
        )}
      </section>
    </>
  );
}

export default RegisterDefaultPage;

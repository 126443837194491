import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { getMemorialsApi } from 'apis/memorial';

import useLayout from 'stores/layout/hook';
import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

import ContentLoader from 'react-content-loader';
import Pagination from 'react-js-pagination';

import { useInView } from 'react-intersection-observer';

import noDeceasedPhoto from 'css/static/media/user_no_portrait_obituary.png';
import noDeceasedPhoto1 from 'css/static/media/user_no_portrait_rememberance.png';

import 'css/pagination.css';
import loading_test from '../../../css/static/media/loading.gif';

function MemorialPage() {
  const navigate = useNavigate();
  const searchRef = useRef();

  const { showAlert, hideAlert } = useDialog();
  const { setGnbType } = useLayout();
  const { loginUser, setOpenLogin } = useLogin();

  const [loading, setLoading] = useState(false);

  const [searchMemorials, setSearchMemorials] = useState(null);

  const [page, setPage] = useState(1);
  const [listSize] = useState(8);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  //페이징 처리
  const [ref, inView] = useInView();

  const changeSearchValue = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      searchRef.current.click();
    }
  };

  const handleSearchMemorials = () => {
    getSearchMemorials(1);
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getSearchMemorials(selectedPage);
  };

  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      if (page === 1) {
        return;
      }
      // showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setPage(nextPage);
      getSearchMemorialsMore(nextPage);
    }
  };

  const getSearchMemorials = async pageNum => {
    let params = {};
    params.status = 2;
    params.multy_type = 'Y';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'createdtime' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    // setLoading(true);
    const result = await getMemorialsApi(params);
    if (result.code === '0000') {
      setSearchMemorials(result.data);
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert);
    }
    // setLoading(false);
  };

  const getSearchMemorialsMore = async pageNum => {
    let params = {};
    params.status = 2;
    params.multy_type = 'Y';
    params.display_yn = 'Y';
    params.orderBy = { direction: 'desc', field: 'createdtime' };
    params.page = pageNum;
    params.itemInPage = listSize;
    if (searchValue && searchValue !== '') params.searchWord = searchValue;

    setLoading(true);
    const result = await getMemorialsApi(params);
    if (result.code === '0000') {
      setSearchMemorials([...searchMemorials, ...result.data]);
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert);
    }
    setLoading(false);
  };

  const handleRegister = () => {
    if (loginUser && loginUser.member_id) {
      navigate('/memorial/memorial/register');
    } else {
      navigate('/memorial/login');
    }
  };

  useEffect(() => {
    setGnbType('sub_gnb');
    getSearchMemorials(1);
    //setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inView && !loading) {
      handleMore(page);
    }
  }, [inView, loading]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">추모관 </h2>
            <p>
              고인을 기리는 추모글과 고인의 저서, 어록, 활동 자료, 사진 및 동영상 등을{' '}
              <br className="d-block d-md-none" />
              온라인 상으로 만날 수 있습니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          {/* S : 23-04-21 상단 검색영역 수정 */}
          {/* S : 목록 상단 */}
          <div className="list-head is-between">
            {/* S : 글 갯수 */}
            <div className="list-head-left">
              메모리얼 캔버스에서
              <br />
              {totalCount}명의 삶을 기억하고 추모합니다.
            </div>
            {/* E : 글 갯수 */}
            {/* S : 검색 */}
            <div className="list-head-right">
              {/* S : 목록 검색 */}
              <div className="box-search">
                <label for="ip-search" className="offscreen">
                  검색하기
                </label>
                <input
                  type="search"
                  id="ip-search"
                  placeholder="고인명을 입력하세요"
                  value={searchValue}
                  onChange={changeSearchValue}
                  onKeyPress={onKeyPress}
                />
                <button
                  type="button"
                  style={{ cursor: 'pointer' }}
                  ref={searchRef}
                  onClick={handleSearchMemorials}
                >
                  <i className="ic ic-search"></i>
                  <span className="offscreen">검색하기</span>
                </button>
              </div>
              {/* E : 목록 검색 */}
              {/* S : 버튼 */}
              <a href={() => false} className="btn btn-pos" onClick={handleRegister}>
                추모관 등록
              </a>
              {/* E : 버튼 */}
            </div>
            {/* E : 검색 */}
          </div>
          {/* E : 목록 상단 */}
          {/* E : 23-04-21 상단 검색영역 수정 */}
          {/* E : 목록 상단 */}

          {/* S : 추모 목록 */}
          <div className="wrap-memorial">
            <div className="list-memorial">
              {/* S : 추모글 반복 시작 */}
              {searchMemorials &&
                searchMemorials.length > 0 &&
                searchMemorials.map(memorial => (
                  <div className="box-memorial" key={memorial.memorial_id}>
                    {/*운영 반영 할때 M00000001026로 바꾸기*/}
                    {/*<Link*/}
                    {/*  to={*/}
                    {/*    memorial.memorial_id === 'M00000001026'*/}
                    {/*      ? '/memorial/memorial/views/M00000001026'*/}
                    {/*      : `/memorial/memorial/view/${memorial.memorial_id}`*/}
                    {/*  }*/}
                    {/*>*/}
                    <Link to={`/memorial/memorial/view/${memorial.memorial_id}`}>
                      <div className="img">
                        <span className="chk-size">
                          <img
                            alt=""
                            src={
                              memorial.deceased_photo
                                ? memorial.deceased_photo
                                : memorial.memorial_id.substring(
                                    memorial.memorial_id.length - 1,
                                    memorial.memorial_id.length,
                                  ) %
                                    2 ===
                                  0
                                ? noDeceasedPhoto
                                : noDeceasedPhoto1
                            }
                          />
                        </span>
                      </div>
                      <div className="info">
                        <p className="name">{`${memorial.deceased_name} ${memorial.deceased_job_title}`}</p>
                        {memorial.deceased_birthday || memorial.deceased_date ? (
                          <span className="date">{`${memorial.deceased_birthday} ~ ${memorial.deceased_date}`}</span>
                        ) : (
                          <br />
                        )}
                      </div>
                      <div className="btn-wrap">
                        <a href={() => false} className="btn btn-blue">
                          추모
                        </a>
                      </div>
                    </Link>
                  </div>
                ))}
              {/* E : 추모글 반복 종료 */}
            </div>
            <div className="btn-wrap" ref={ref}>
              {/*<button type="button" class="btn btn-more" onClick={() => handleMore(page)}>*/}
              {/*  더보기 {page}/{totalPage}*/}
              {/*</button>*/}
              {loading === true ? <img src={loading_test} alt="" /> : ''}
            </div>
          </div>
          {/* E : 추모글 목록 */}

          {/* S : 페이징 */}

          {/* E : 페이징 */}
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default MemorialPage;

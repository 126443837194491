import Dialog from '@mui/material/Dialog';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'css/popup.css';
import { useState, useEffect } from 'react';

function MediaAlbumDialog({ isOpen, onClose, medias, initialSlide }) {
  SwiperCore.use([Navigation]);

  const [isPlay, setIsPlay] = useState(false);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="mg" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal is-preview" id="modal-remembrance-01" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추모의 글</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                {medias && medias.length > 0 ? (
                  <Swiper
                    navigation={true}
                    className="swiper preview-img"
                    initialSlide={initialSlide}
                    onSlideChange={e => {
                      setIsPlay(false);
                    }}
                    style={{ height: '100%' }}
                    //loop={true}
                  >
                    {medias.map((media, index) => (
                      <SwiperSlide className="swiper-slide" key={index}>
                        {media.media_type === 'V' ? (
                          <ReactPlayer
                            className="react-player"
                            url={media.media_link} // 플레이어 url
                            width="100%" // 플레이어 크기 (가로)
                            height="100%" // 플레이어 크기 (세로)
                            playing={isPlay} // 자동 재생 on
                            muted={false} // 자동 재생 on
                            controls // 플레이어 컨트롤 노출 여부
                            light={false} // 플레이어 모드
                            pip={true} // pip 모드 설정 여부
                            onPlay={() => setIsPlay(true)}
                          />
                        ) : (
                          <img alt="" src={media.media_link} className="swiper-slide-name" />
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : null}
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  data-micromodal-close
                  onClick={onClose}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default MediaAlbumDialog;

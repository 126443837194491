import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
function MypageWreathPage() {
  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">디지털 화환</h2>
            <p>
              디지털 화환은 상주께 전해지며 허례의식을 줄이고
              <br />
              유가족 위로와 기부문화에 이바지합니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          <div className="tabs tabs-default">
            <ul>
              {/* S : 22-10-03 메뉴 순서 변경 */}
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/obituary">부고</Link>
                </a>
              </li>
              <li>
                <a href={() => false}>
                  <Link className="on" to="/pet/mypage/memorial">
                    추모관
                  </Link>
                </a>
              </li>
              {/*<li className="is-active">*/}
              {/*  <a href={() => false}>*/}
              {/*    {' '}*/}
              {/*    <Link className="on" to="/pet/mypage/wreath">*/}
              {/*      구매내역*/}
              {/*    </Link>*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/member">회원설정</Link>
                </a>
              </li>
              {/* E : 22-10-03 메뉴 순서 변경 */}
            </ul>
          </div>

          <div className="wrap-mypage">
            {/* S : 디지털 화한 구매 내역 */}
            <div className="wrap-wreath-calculate">
              {/* S : 타이틀 */}
              <div className="box-title">
                <h3 className="title">디지털 화환 구매 내역(준비중)</h3>
              </div>
              {/* E : 타이틀 */}

              {/* S : 기간 검색 */}
              <div className="wrap-search-date">
                <div className="ip-wrap rc-double">
                  <ul className="rc-list row">
                    <li>
                      <div className="rc-item square">
                        <input type="radio" id="rc-date1" name="rc-date" checked="" />
                        <label htmlFor="rc-date1">1개월</label>
                      </div>
                    </li>
                    <li>
                      <div className="rc-item square">
                        <input type="radio" id="rc-date2" name="rc-date" />
                        <label htmlFor="rc-date2">3개월</label>
                      </div>
                    </li>
                    <li>
                      <div className="rc-item square">
                        <input type="radio" id="rc-date3" name="rc-date" />
                        <label htmlFor="rc-date3">6개월</label>
                      </div>
                    </li>
                    <li>
                      <div className="rc-item square">
                        <input type="radio" id="rc-date4" name="rc-date" />
                        <label htmlFor="rc-date4">전체</label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ip-group">
                  <div className="ip-wrap">
                    <div className="ip-item">
                      <label htmlFor="ip-search-date1" className="offscreen">
                        검색 시작일
                      </label>
                      <input
                        type="date"
                        id="ip-search-date1"
                        placeholder="검색 시작일"
                        value="2022-06-20"
                      />
                    </div>
                    <div className="gap">~</div>
                    <div className="ip-item">
                      <label htmlFor="ip-search-date2" className="offscreen">
                        검색 종료일
                      </label>
                      <input
                        type="date"
                        id="ip-search-date2"
                        placeholder="검색 종료일"
                        value="2022-06-30"
                      />
                    </div>
                  </div>
                  <button type="button" className="btn-search">
                    조회
                  </button>
                </div>
              </div>
              {/* E : 기간 검색 */}

              {/* S : 목록 */}
              <div className="list-wreath-calculate">
                {/* S : 상세 내역 반복 시작 */}
                <div className="box-wreath-calculate">
                  <div className="box-content">
                    <p className="title">근조화환</p>
                    <p className="price">100,000원</p>
                  </div>
                  <div className="info">
                    <span className="group">산악동호회모임</span>
                    <span className="name">김미나</span>
                    <span className="date">2022.03.01</span>
                    <a href={() => false} className="btn-detail">
                      주문상세
                    </a>
                  </div>
                </div>
                {/* E : 상세 내역 반복 시작 */}
                {/* S : 상세 내역 반복 시작 */}
                <div className="box-wreath-calculate">
                  <div className="box-content">
                    <p className="title">근조화환</p>
                    <p className="price">100,000원</p>
                  </div>
                  <div className="info">
                    <span className="group">산악동호회모임</span>
                    <span className="name">김미나</span>
                    <span className="date">2022.03.01</span>
                    <a href={() => false} className="btn-detail">
                      주문상세
                    </a>
                  </div>
                </div>
                {/* E : 상세 내역 반복 시작 */}
                {/* S : 상세 내역 반복 시작 */}
                <div className="box-wreath-calculate">
                  <div className="box-content">
                    <p className="title">근조화환</p>
                    <p className="price">100,000원</p>
                  </div>
                  <div className="info">
                    <span className="group">산악동호회모임</span>
                    <span className="name">김미나</span>
                    <span className="date">2022.03.01</span>
                    <a href={() => false} className="btn-detail">
                      주문상세
                    </a>
                  </div>
                </div>
                {/* E : 상세 내역 반복 시작 */}
              </div>
              {/* E : 목록  */}
              {/* S : 페이징 */}
              <div className="btn-wrap">
                <button type="button" className="btn btn-more">
                  더보기
                </button>
              </div>
              {/* E : 페이징 */}
            </div>
            {/* E : 디지털 화한 정산 내역 */}
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default MypageWreathPage;

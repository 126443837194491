import React, { useState, useEffect, useRef } from 'react';

import useCodes from 'stores/code/hook';
import useLogin from 'stores/login/hook';

import { useInView } from 'react-intersection-observer';

import configs from '@configs/configs';

import {
  getCommentApi,
  getAllCommentApi,
  getCommentsApi,
  saveCommentApi,
  deleteCommentApi,
  deleteCommentVerifyApi,
  getCommentCategoryApi,
  modifyCommentApi,
  getCommentsExceldownloadApi,
} from 'apis/comment';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Pagination from 'react-js-pagination';

import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import VerifyPasswordDialog from 'pages/memorial-canvas/obituary/components/VerifyPasswordDialog';
import MediaViewDialog from 'pages/memorial-canvas/obituary/components/MediaViewDialog';
import MediaViewSwiperDialog from 'pages/memorial-canvas/obituary/components/MediaViewSwiperDialog';
import ContentViewDialog from 'pages/memorial-canvas/obituary/components/ContentViewDialog';

import MediaUploader from 'pages/memorial-canvas/obituary/components/MediaUploader';

import CommentGroupDialog from '../CommentGroupDialog';
import SaveCommentCategoryDialog from 'pages/memorial-canvas/obituary/components/CommentCategoryDialog';
import ModifyCommentCategoryDialog from 'pages/memorial-canvas/obituary/components/CommentCategoryDialog/modify';
import DeleteCommentCategoryDialog from 'pages/memorial-canvas/obituary/components/CommentCategoryDialog/delete';
import useDialog from 'stores/dialog/hook';
import LoginDialog from 'pages/memorial-canvas/obituary/components/LoginDialog';
import VerifyCommentCategoryPasswordDialog from 'pages/memorial-canvas/obituary/components/VerifyCommentCategoryPassword';
import MediaAlbumDialog from 'pages/memorial-canvas/obituary/components/MediaAlbumDialog';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

//excel download
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DisabledByDefault } from '@mui/icons-material';

//로딩 이미지
import loading_test from 'css/static/media/loading.gif';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { uploadImageApi, uploadVideoApi } from '../../../../../apis/openapi';
import { useDropzone } from 'react-dropzone';
import Progress from '../../../../../components/mui/Progress';
import CommentSharedDialog from '../CommentSharedDialog';
import { getMemorialApi } from '../../../../../apis/memorial';
import commentTemplateDialog from '../CommentTemplateDialog';
import CommentTemplateDialog from '../CommentTemplateDialog';

let imageCount = 0;
let videoCount = 0;

function TabCondolencesGroupContent({ currentLastUrl, tabState }) {
  const location = useLocation();
  const { memorialId } = useParams();

  // const memorialId = location.state.memorialId;
  // const ownMemberId = location.state.ownMemberId;
  // const deceasedName = location.state.deceasedName;
  // const index = location.state.tabIndex;

  const navigate = useNavigate();

  const { commentTemplateCodes } = useCodes();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();
  const { loginUser } = useLogin();
  // const userInfo = JSON.parse(localStorage.getItem('loginUser'));
  const { pageEnd } = useRef(null);
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(0);
  const [ref, inView] = useInView();
  const [tabIndex, setTabIndex] = useState('');
  const [url, setUrl] = useState('');

  const schema = Yup.object({
    writer_name: Yup.string()
      .max(30, '* 작성자명은 최대 30글자까지 입력이 가능합니다.')
      .required('* 작성자명을 입력하세요.'),
    blt_password: Yup.string()
      .max(30, '* 비밀번호는 최대 30글자까지 입력이 가능합니다.')
      .required('* 비밀번호를 입력하세요.'),
    blt_contents: Yup.string()
      .max(3000, '* 추모글은 최대 3000글자까지 입력이 가능합니다.')
      .required('* 추모글을 입력하세요.'),
  }).required();

  const defaultValues = {
    memorial_id: memorialId,
    member_id: loginUser && loginUser.member_id ? loginUser.member_id : 'guest',
    writer_name: loginUser && loginUser.member_id ? loginUser.member_name : '',
    blt_password: loginUser && loginUser.member_id ? loginUser.member_id : '',
    blt_contents: '',
    display_yn: 'Y',
    medias: [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [page, setPage] = useState(1);
  const [listSize] = useState(5);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [openVerifyPasswordDialog, setOpenVerifyPasswordDialog] = useState(false);
  const [openModPasswordDialog, setOpenModPasswordDialog] = useState(false);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);
  const [media, setMedia] = useState(null);
  const [openMediaAlbumDialog, setOpenMediaAlbumDialog] = useState(false);
  const [openCommentTemplateDialog, setOpenCommentTemplateDialog] = useState(false);
  const [displayYn, setDisplayYn] = useState(true);

  //페이지 state 1:글목록 0:글쓰기
  const [pageName, setPageName] = useState(1);
  //수정할 commentId
  const [modCommentId, setModCommentId] = useState(0);
  const [modCommentIdTemp, setModCommentIdTemp] = useState(0);
  //수정할 글
  const [contentMod, setContentMod] = useState(null);
  const [contentModTemp, setContentModTemp] = useState(null);
  const [contentPassword, setContentPassword] = useState('');
  //답글 commentId
  const [repCommentId, setRepCommentId] = useState('');
  //검색 타입 작성자:writer 내용:content
  const [searchType, setSearchType] = useState('writer');
  //검색 타입 작성자:writer 내용:content
  const [searchText, setSearchText] = useState('');
  //엑셀 다운로드 버튼 체크
  const [excelChecked, setExcelChecked] = useState(false);
  //선택다운로드 체크박스
  const [checkedList, setCheckedList] = useState([]);
  //전체선택 체크박스
  const [allChecked, setAllChecked] = useState(false);
  const [itemIndex, setitemIndex] = useState(0);

  const [content, setContent] = useState(null);
  const [openContentViewDialog, setOpenContentViewDialog] = useState(false);

  const [contentValChk, setContentValChk] = useState('');

  const handleMediaView = (media, index) => {
    setMedia(media);
    setitemIndex(index);
    setOpenMediaViewDialog(true);
  };

  const handleViewCommentInfo = commentId => {
    getComment(commentId);
    setOpenContentViewDialog(true);
  };

  const onSubmit = data => {
    // alert(JSON.stringify(data));
    saveComment(data);
  };

  const saveComment = async params => {
    console.log('카테' + selectedCommentCategory.category_id);
    if (pageName === 0) {
      params.parent_comment_id = '0';
      params.category_id =
        selectedCommentCategory.category_id === '' ? ' ' : selectedCommentCategory.category_id;
    } else {
      params.category_id =
        selectedCommentCategory.category_id === '' ? ' ' : selectedCommentCategory.category_id;
    }
    params.comment_yn = 'Y';
    const result = await saveCommentApi(params);
    if (result.code === '0000') {
      showAlert('알림', '추모글 등록이 완료되었습니다', () => handleClose());
    } else {
      showAlert('알림', result.message, () => handleClose());
    }

    categoryCnt(1);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const [comments, setComments] = useState(null);
  const [commentsExcel, setCommentsExcel] = useState(null);
  const [medias, setMedias] = useState([]);
  const [Commentmedias, setCommentMedias] = useState([]);

  // 부고 정보 저장
  const [deceasedName, setDeceasedName] = useState(null);
  const [ownMemberId, setOwnMemberId] = useState(null);

  const getObituary = async params => {
    setOpenLoadingDialog(true);
    const result = await getMemorialApi(params);
    setOpenLoadingDialog(false);
    setDeceasedName(result.data.deceased_name);
    setOwnMemberId(result.data.own_member_id);
  };

  const getComments = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.comment_yn = 'Y';
    //params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.search_type = searchType;
    params.search_text = searchText;
    params.category_id = selectedCommentCategory.category_id;
    // console.log('확인 데이터 ' + selectedCommentCategory.category_id);
    // console.log('params확인' + JSON.stringify(params));
    setOpenLoadingDialog(true);
    const result = await getCommentsApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setComments(result.data);
      Object.entries(result.data).forEach(([key, value]) => {
        setValue(key, value);
        if (key === 'medias') setMedias(value);
      });
      setPage(1);
      setTotalPage(parseInt(Math.ceil(result.data_cnt / listSize)));
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getCommentsExcel = async () => {
    if (checkedList.length === 0) {
      showAlert('알림', '다운로드할 글을 선택해 주세요.', () => hideAlert());
    } else {
      let params = {};
      params.memorial_id = memorialId;
      params.category_id = selectedCommentCategory.category_id;
      params.comment_ids = checkedList;
      params.search_type = searchType;
      params.search_text = searchText;

      setOpenLoadingDialog(true);
      await getCommentsExceldownloadApi(params, selectedCommentCategoryName);
      setOpenLoadingDialog(false);
    }
  };

  const getAllCommentsExcel = async () => {
    if (comments.length === 0) {
      showAlert('알림', '다운로드할 게시글이 없습니다.', () => hideAlert());
    } else {
      let params = {};
      params.memorial_id = memorialId;
      params.category_id = selectedCommentCategory.category_id;
      params.search_type = searchType;
      params.search_text = searchText;

      setOpenLoadingDialog(true);
      await getCommentsExceldownloadApi(params, selectedCommentCategoryName);
      setOpenLoadingDialog(false);
    }
  };

  const getCommentsMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.comment_yn = 'Y';
    //params.orderBy = { direction: 'desc', field: 'comment_id' };
    params.page = pageNum;
    params.itemInPage = listSize;
    params.search_type = searchType;
    params.search_text = searchText;
    params.category_id = selectedCommentCategory.category_id;

    setLoading(true);
    const result = await getCommentsApi(params);

    if (result.code === '0000') {
      // setOpenLoadingDialog(true);
      setComments([...comments, ...result.data]);
      // setOpenLoadingDialog(false);
      //setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
    setLoading(false);
  };

  const getComment = async comment_id => {
    let params = {};
    // 필수
    params.memorial_id = memorialId;
    params.comment_id = comment_id + '';
    params.category_id = selectedCommentCategory.category_id;
    // 검색
    params.search_type = searchType;
    params.search_text = searchText;

    const result = await getCommentApi(params);
    // showAlert('알림', result.message, () => hideAlert());
    setCommentInfo(result.data);
  };

  // 추모의 글 상세
  const [commentInfo, setCommentInfo] = useState({});

  // 추모 카테고리 state 홍순규
  const [commentCategory, setCommentCategory] = useState([]);
  const [catPage, setCatPage] = useState(1);
  const [catListSize] = useState(5);
  const [catTotalPage, setCatTotalPage] = useState(0);
  const [catTotalCount, setCatTotalCount] = useState(0);
  const [selectedCommentCategory, setSelectedCommentCategory] = useState({ category_id: '' });
  const [showModify, setShowModify] = useState(false);
  const [CategorySearchText, setCategorySearchText] = useState('');
  const [CategorySearchType, setCategorySearchType] = useState('category_name');
  const [currCategory, setCurrCategory] = useState({});

  // 추모 모임 팝업(저장,수정,삭제) + 로그인 체크 팝업 + 비밀방 비밀번호 체크 팝업
  const [selectedCommentCategoryName, setSelectedCommentCategoryName] = useState('추모의 글');
  const [selectedCommentCategoryDesc, setSelectedCommentCategoryDesc] = useState('');
  const [openCommentGroupDialog, setOpenCommentGroupDialog] = useState(false);
  const [openSaveCommentCategoryDialog, setOpenSaveCommentCategoryDialog] = useState(false);
  const [openModifyCommentCategoryDialog, setOpenModifyCommentCategoryDialog] = useState(false);
  const [openDeleteCommentCategoryDialog, setOpenDeleteCommentCategoryDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openVerifyCommentCategoryDialog, setOpenVerifyCommentCategoryDialog] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [currentMedia, setCurrentMedia] = useState({});
  // 추모 모임 공유하기
  const [openCommentSharedDialog, setOpenCommentSharedDialog] = useState(false);

  // 추모 방 공유시
  const getCommentCategoryShare = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;

    const result = await getCommentCategoryApi(params);
    if (result.code === '0000') {
      //setCommentCategory(result.data);

      result.data.map(category => {
        if (category.category_id === currentLastUrl) {
          //alert(JSON.stringify(category));
          handleChangeSelectedCategory(category);
        }
      });
      // setCatPage(1);
      // setCatTotalPage(parseInt(Math.ceil((result.data_cnt + 1) / catListSize)));
      //
      // setCatTotalCount(result.data_cnt);
    }
  };

  // 추모카테고리
  const getCommentCategory = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.search_text = CategorySearchText;
    params.search_type = CategorySearchType;

    if (isMobile) {
      // 모바일은 전체 로드ㄱ
      params.itemInPage = 1000;
    } else {
      // 변경해야함 0503
      params.itemInPage = catListSize;
    }

    if (loginUser.member_id) {
      params.create_member_id = loginUser.member_id;
    }
    params.member_id = loginUser.member_id;

    setOpenLoadingDialog(true);
    const result = await getCommentCategoryApi(params);
    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setCommentCategory(result.data);
      //console.log(JSON.stringify(result.data));
      setCatPage(1);
      setCatTotalPage(parseInt(Math.ceil((result.data_cnt + 1) / catListSize)));

      setCatTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getCommentCategoryMore = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = catListSize;
    params.search_text = CategorySearchText;
    params.search_type = CategorySearchType;
    params.member_id = loginUser.member_id;

    setOpenLoadingDialog(true);
    const result = await getCommentCategoryApi(params);

    setOpenLoadingDialog(false);
    if (result.code === '0000') {
      setCommentCategory([...commentCategory, ...result.data]);
      setCatTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };
  const handleCommentLogin = () => {
    if (!loginUser.member_id) {
      handleOpenLoginDialog();
      return;
    }
  };
  const handleOpenSaveCommentCategory = () => {
    // 로그인 체크
    if (!loginUser.member_id) {
      handleOpenLoginDialog();
      return;
    }
    setOpenSaveCommentCategoryDialog(true);
  };

  const handleOpenModifyCommentCategory = () => {
    setOpenModifyCommentCategoryDialog(true);
  };

  const handleOpenDeleteCommentCategory = () => {
    setOpenDeleteCommentCategoryDialog(true);
  };

  const handleChangeSelectedCategory = category => {
    //alert('확인확인' + JSON.stringify(category));

    setSearchText('');

    if (category.category_name === '추모의 글') {
      //alert('추모의 글 클릭');
      setSelectedCommentCategory({ category_id: '' });
    }
    // 로그인 체크
    // if (
    //   category.category_id != '' &&
    //   !loginUser.member_id &&
    //   category.category_name !== '추모의 글'
    // ) {
    //   handleOpenLoginDialog();
    //   return;
    // }
    //console.log(category);

    // setSelectedCommentCategory(category);
    //비밀방 체크
    if (category.display_yn === 'N' && !(loginUser.member_id === ownMemberId)) {
      //alert('안들어왔나..?');
      setCurrCategory(() => category);
      setOpenVerifyCommentCategoryDialog(true);
    } else {
      setSelectedCommentCategoryName(category.category_name);
      setSelectedCommentCategoryDesc(category.category_desc);
      // active 처리
      setSelectedCommentCategory(category);
    }
  };

  const handleShowModify = () => {
    setShowModify(!showModify);
  };

  const handleMoreCommentCategory = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > catTotalPage) {
      //showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
    } else {
      setCatPage(nextPage);
      getCommentCategoryMore(nextPage);
    }
  };

  const handleOpenLoginDialog = () => {
    setOpenLoginDialog(true);
  };

  const handleChangeCategorySearchText = event => {
    setCategorySearchText(event.target.value);
  };

  const handleCategorySearch = () => {
    getCommentCategory(1);
  };

  const handleKeyPressCategorySearch = event => {
    if (event.key === 'Enter') {
      getCommentCategory(1);
    }
  };

  const handleOpenAlbumDialog = (media, index) => {
    setCurrentMedia(media);
    setInitialSlide(index);
    setOpenMediaAlbumDialog(true);
  };

  useEffect(() => {
    setShowModify(() => false);
    // 댓글 호출 api
    setSearchText('');
    getComments(1);

    setValue('category_id', selectedCommentCategory.category_id);
    setSelectedCommentCategoryName(selectedCommentCategory.category_name);
    setSelectedCommentCategoryDesc(selectedCommentCategory.category_desc);

    setAllChecked(false);
    setFinish(0);
  }, [selectedCommentCategory]);

  useEffect(() => {
    setCheckedList([]);
  }, [allChecked]);
  // end 홍순규
  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getComments(selectedPage);
  };

  const handleClose = () => {
    reset();
    setMedias([]);
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    setValue('blt_password', loginUser && loginUser.member_id ? loginUser.member_id : '');
    setDisplayYn(getValues('display_yn') === 'Y' ? true : false);
    getComments(1);

    hideAlert();

    //추모글 리스트 페이지로 이동
    movePage(1);
    //추모글 리스트 페이지로 수정 textarea 숨김
    setModCommentId(0);
    //추모글 답글 input 숨김
    setRepCommentId('');
  };

  const [selectedCommentId, setSelectedCommentId] = useState(null);

  const handleDelete = (commentId, depth, categoryId) => {
    if (depth === 1) {
      if (categoryId !== null && categoryId !== '') {
        showConfirm(
          '삭제 확인',
          '해당 추모의 글의 답글이 모두 삭제 됩니다. \n 삭제 하시겠습니까?',
          () => handleConfirm(commentId),
          () => hideConfirm(),
        );
      } else {
        showConfirm(
          '삭제 확인',
          '해당 추모의 글을 삭제 하시겠습니까?',
          () => handleConfirm(commentId),
          () => hideConfirm(),
        );
      }
    } else {
      showConfirm(
        '삭제 확인',
        '정말 삭제 하시겠습니까?',
        () => handleConfirm(commentId),
        () => hideConfirm(),
      );
    }
  };

  // const handleChangeDiaplayYn_ = () => {
  //   setDisplayYn(!displayYn);
  // };

  const handleChangeDiaplayYn = event => {
    // const isChecked = event.target.checked ? 'Y' : 'N';
    //alert(event.target.checked);
    setDisplayYn(event.target.checked);
  };

  const handleChangeContentMod = event => {
    setContentMod(event.target.value);
    if (event.target.value === '' || event.target.value === null) {
      setContentValChk('* 추모글을 입력하세요.');
    } else {
      setContentValChk('');
    }
  };

  /**
   * 글수정 이벤트
   */
  const handleModifySubmit = () => {
    if (contentMod === '' || contentMod === null) {
      setContentValChk('* 추모글을 입력하세요.');
    } else {
      let data = {};
      data.comment_id = modCommentId;
      data.blt_contents = contentMod;
      modComment(data);
      setContentValChk('');
    }
  };

  /**
   * 글수정
   * @param {입력 param} params
   */
  const modComment = async params => {
    params.medias = medias;
    const result = await modifyCommentApi(params);
    showAlert('알림', result.message, () => handleClose());
  };

  /**
   * 답글 노출
   * @param {글번호} commentId
   */
  const handleReply = commentId => {
    if (!loginUser.member_id) {
      handleOpenLoginDialog();
      return;
    }
    if (repCommentId === commentId) {
      setRepCommentId('');
    } else {
      setRepCommentId(commentId);
    }
    setModCommentId(0);
  };

  const onCheckedElement = (checked, item) => {
    if (checked) {
      setCheckedList([...checkedList, item]);
    } else if (!checked) {
      setCheckedList(checkedList.filter(el => el !== item));
    }
  };

  const isChecked = commentId => {
    return checkedList.includes(commentId);
  };

  const onAllChecked = async checked => {
    setAllChecked(() => !allChecked);

    if (checked) {
      // 전체 추모의 글 요청
      let params = {};
      params.memorial_id = memorialId;
      params.category_id = selectedCommentCategory.category_id;
      params.search_type = searchType;
      params.search_text = searchText;

      setOpenLoadingDialog(true);
      const result = await getAllCommentApi(params);

      setOpenLoadingDialog(false);
      if (result.code === '0000') {
        setCheckedList(
          // 삭제된 글 제외
          // result.data.filter(comment => comment.use_yn === 'Y').map(comment => comment.comment_id)

          // 삭제된 글 포함
          result.comment_ids,
        );
      }

      // 전체체크
    } else {
      // 전체체크 해제
      setCheckedList(() => []);
    }
  };

  const handleConfirm = commentId => {
    hideConfirm();
    deleteComment(commentId);
  };

  const handleVerifyDelete = commentId => {
    setSelectedCommentId(commentId);
    setOpenVerifyPasswordDialog(true);
  };

  const deleteCommentVerify = async params => {
    setOpenVerifyPasswordDialog(false);
    const result = await deleteCommentVerifyApi({
      comment_id: selectedCommentId,
      blt_password: params.password,
    });

    getComments(1);
    showAlert('알림', result.message, () => hideAlert());

    categoryCnt(-1);
  };

  const deleteComment = async params => {
    const result = await deleteCommentApi(params);
    getComments(1);
    showAlert('알림', result.message, () => hideAlert());

    categoryCnt(-1);
  };

  /**
   * 글 추가/삭제 시 category 표기 숫자 변경
   * @param {계산 숫자} arg
   */
  const categoryCnt = arg => {
    let selectedCateIndex = commentCategory.findIndex(
      cate => cate.category_id === selectedCommentCategory.category_id,
    );
    commentCategory[selectedCateIndex].cnt = commentCategory[selectedCateIndex].cnt + arg;
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    //alert('미디어확인을 한다!' + medias);
    setValue('medias', [...medias]);
    setMedias([...medias]);
  };

  const removeCommentMedia = (index, medias) => {
    // alert(JSON.stringify(medias));
    // comments.medias.splice(index, 1);
    //
    // //setValue('medias', [...medias]);
    // setMedias([...comments.medias]);
    //alert(index);
    //alert('미디어확인' + JSON.stringify(medias));
    medias.splice(index, 1);
    setValue('medias', [...medias]);
    setMedias([...medias]);
    //alert(JSON.stringify(medias));
  };

  // const handleCommentTemplate = event => {
  //   const commentTemplate = event.target.value;
  //   setValue('blt_contents', commentTemplate);
  // };

  const handleCommentTemplate = value => {
    setValue('blt_contents', value);
  };

  const handleSearchType = event => {
    setSearchType(event.target.value);
  };

  const handleSearchText = event => {
    setSearchText(event.target.value);
  };

  const handleSearch = e => {
    if (e.key === 'Enter') getComments(1);
  };

  //페이징 처리 추가 0502
  const handleMore = pageNum => {
    const nextPage = pageNum + 1;
    if (nextPage > totalPage) {
      if (page === 1) {
        return;
      }
      //showAlert('알림', '마지막 페이지 입니다.', () => hideAlert());
      setFinish(1);
    } else {
      setPage(nextPage);
      getCommentsMore(nextPage);
    }
  };

  /**
   * 글쓰기/목록 페이지 이동
   * @param {0:글쓰기 1:글목록} pageName
   */
  const movePage = pageName => {
    setPageName(pageName);
  };

  /**
   * 수정 버튼 이벤트
   */
  const handleModify = (commentId, contents, password, memberId) => {
    if (memberId) {
      setModCommentId(commentId);
      setContentMod(contents);
    } else {
      setOpenModPasswordDialog(true);
      setModCommentIdTemp(commentId);
      setContentModTemp(contents);
      setContentPassword(password);
      setRepCommentId('');
    }
  };
  const handleModifyView = params => {
    setOpenModPasswordDialog(false);
    if (contentPassword === params.password) {
      setModCommentId(modCommentIdTemp);
      setContentMod(contentMod);
    } else {
      showAlert('알림', '비밀번호가 일치하지않습니다. \n비밀번호를 확인해주시기 바랍니다.', () =>
        hideAlert(),
      );
    }
  };
  const handleModifyClose = () => {
    setModCommentIdTemp(0);
    setContentModTemp(null);
    setContentPassword('');
    setModCommentId(0);
    setContentMod(null);
    setRepCommentId('');
    setContentValChk('');
  };

  const handleChangeCategorySearchType = e => {
    setCategorySearchType(e.target.value);
  };

  const excelFileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const excelFileExtension = '.xlsx';
  /**
   * excel download
   */
  const excelDownload = excelData => {
    const ws = XLSX.utils.aoa_to_sheet([[selectedCommentCategoryName], [], ['작성자', '내용']]);
    excelData.map(data => {
      XLSX.utils.sheet_add_aoa(ws, [[data.writer_name, data.blt_contents]], {
        origin: -1,
      });
      ws['!cols'] = [
        //<-- 행 사이즈
        { wpx: 100 },
        { wpx: 500 },
      ];
      return false;
    });
    const wb = { Sheets: { sheet: ws }, SheetNames: ['sheet'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, selectedCommentCategoryName + excelFileExtension);
  };

  const moveUrl = url => {
    if (url === 1) {
      // 일대기
      navigate(`/memorial/obituary/view/${memorialId}`, {
        state: { index: 1 },
      });
      // navigate(`/memorial/obituary/view/${memorialId}`);
    } else if (url === 2) {
      // 추도사
      navigate(`/memorial/obituary/view/${memorialId}`, {
        state: { index: 2 },
      });
    } else if (url === 3) {
      // 추억앨범
      navigate(`/memorial/obituary/view/${memorialId}`, {
        state: { index: 3 },
      });
    }
  };

  //미디어 업로드
  // const handleMediaView = media => {
  //   setMedia(media);`
  //   setOpenMediaViewDialog(true);
  // };

  const imageMaxCount = 5;
  const videoMaxCount = 1;

  const imageMaxSize = 5 * 1024 * 1024;
  //  const videoMaxSize = 50 * 1024 * 1024;
  const videoMaxSize = 1 * 1024 * 1024 * 1024;

  const imageTypeCode = 'I';
  const videoTypeCode = 'V';

  const [displayProgressBar, setdisplayProgressBar] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);

  const uploadPercentage = value => {
    console.log('uploadPercentage > ', value);
    setProgressBarPercentage(value);
  };

  const uploadImageFile = async file => {
    setdisplayProgressBar(true);
    const result = await uploadImageApi(file, uploadPercentage);
    //('사진확인' + JSON.stringify(result));
    console.log('result > ', result);

    if (result.code === '0000') {
      addMedia({
        media_type: imageTypeCode,
        thumbnail_link: result.thumnailUrl,
        media_link: result.uploadUrl,
      });

      imageCount = imageCount + 1;
    } else {
      showAlert('알림', '파일 업로드 중 오류가 발생하였습니다.', () => hideAlert());
    }
    setProgressBarPercentage(0);
    setdisplayProgressBar(false);
  };

  const uploadVideoFile = async file => {
    setdisplayProgressBar(true);

    const result = await uploadVideoApi(file, uploadPercentage);
    console.log('video result > ', result);
    if (result.code === '0000') {
      addMedia({
        media_type: videoTypeCode,
        thumbnail_link: result.thumnailUrl,
        media_link: result.uploadUrl,
      });

      videoCount = videoCount + 1;
    } else {
      showAlert('알림', '파일 업로드 중 오류가 발생하였습니다.', () => hideAlert());
    }
    setProgressBarPercentage(0);
    setdisplayProgressBar(false);
  };

  const uploadMediaFile = async file => {
    if (file.type.indexOf('image/') > -1) {
      if (file.size > imageMaxSize) {
        showAlert('알림', '사진 최대 용량은 5MB를 초과할 수 없습니다.', () => hideAlert());

        return;
      } else if (imageCount >= imageMaxCount) {
        showAlert('알림', '사진은 최대 5개까지만 등록 가능합니다.', () => hideAlert());

        return;
      }
      await uploadImageFile(file);
      return;
    }

    if (file.type.indexOf('video/') > -1) {
      if (file.size > videoMaxSize) {
        showAlert('알림', '동영상 최대 용량은 50MB를 초과할 수 없습니다.', () => hideAlert());

        return;
      } else if (videoCount >= videoMaxCount) {
        showAlert('알림', '동영상은 최대 1개까지만 등록 가능합니다.', () => hideAlert());

        return;
      }
      await uploadVideoFile(file);
    }
  };

  const handleRemoveMedia = (index, mediaType) => {
    //alert('지우기 전에 확인 ' + JSON.stringify(mediaType));
    //setValue('medias', mediaType);
    imageCount--;
    removeMedia(index);
  };

  const onDrop = async acceptedFiles => {
    for (const file of acceptedFiles) {
      await uploadMediaFile(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/*', 'video/*'],
  });

  const xy = useRef(null);

  useEffect(() => {
    if (medias.length === 0) {
      imageCount = 0;
      videoCount = 0;
    }
  }, [medias]);

  useEffect(() => {
    setValue('display_yn', displayYn ? 'Y' : 'N');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayYn]);

  useEffect(() => {
    setValue('medias', medias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medias]);

  useEffect(() => {
    setValue('parent_comment_id', repCommentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repCommentId]);

  useEffect(() => {
    reset();
    setValue('member_id', loginUser && loginUser.member_id ? loginUser.member_id : 'guest');
    setValue('writer_name', loginUser && loginUser.member_id ? loginUser.member_name : '');
    setValue('blt_password', loginUser && loginUser.member_id ? loginUser.member_id : '');
    setDisplayYn(getValues('display_yn') === 'Y' ? true : false);
    setValue(repCommentId);
    setValue('category_id', selectedCommentCategory.category_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    // if (memorialId) {
    //   getObituary(memorialId);
    //   getComments(1);
    //   getCommentCategory(1);
    //   setFinish(0);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
  }, [pageName]);

  //페이지 로딩 구현 0503
  useEffect(() => {
    if (inView && !loading && totalPage !== 1 && finish !== 1) {
      handleMore(page);
    }
  }, [inView, loading, page]);

  useEffect(() => {
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    handleChangeSelectedCategory({ category_name: '추모의 글', category_id: '' });
    if (memorialId) {
      getObituary(memorialId);
      getComments(1);
      getCommentCategory(1);
      setFinish(0);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    handleChangeSelectedCategory({ category_name: '추모의 글', category_id: '' });
  }, [tabState]);

  //추모의 방 공유
  useEffect(() => {
    //alert(currentLastUrl);
    getCommentCategoryShare();
  }, [currentLastUrl]);

  return (
    <div className="wrap-message" ref={xy}>
      <div className="contents-width">
        <div className="box-title has-crown">
          <h4 className="title">추모의 글</h4>
        </div>

        {/* S : 23-08-23 추모 모임 목록 노출 */}
        <div className="wrap-gathering">
          <ul className="list-gathering">
            {commentCategory && commentCategory.length > 0
              ? commentCategory.map((category, idx) => (
                  <li
                    className={
                      selectedCommentCategoryName === category.category_name ? 'is-active' : ''
                    }
                  >
                    <a
                      href={() => false}
                      onClick={() => handleChangeSelectedCategory(category)}
                      className="btn"
                    >
                      {category.category_name}
                    </a>
                  </li>
                ))
              : ''}
            {/* 선택된 모임의 li에 is-active class 추가 */}
          </ul>
        </div>
        {/* E : 23-08-23 추모 모임 목록 노출 */}

        {/* S : 추모의 글 입력 */}
        <div className="wrap-write">
          {/* S : 글 입력 상단 - 타이틀, 버튼 */}

          {selectedCommentCategoryName === '추모의 글' ||
          selectedCommentCategory.category_id === '' ? (
            <div className="list-head">
              <div className="list-head-left">
                <h5>추모글 남기기</h5>
              </div>
              <div className="list-head-right">
                <button
                  type="button"
                  className="btn btn-sm-ln-blue"
                  onClick={() => setOpenCommentGroupDialog(true)}
                >
                  <i className="ic ic-flower"></i> 모임 등록
                </button>
              </div>
            </div>
          ) : (
            <div className="list-head">
              <div className="list-head-left">
                <h5>{selectedCommentCategoryName}</h5>
                <button
                  type="button"
                  className="btn-share"
                  onClick={() => setOpenCommentSharedDialog(true)}
                >
                  <i className="ic ic-share"></i>
                  <span className="offscreen">공유하기</span>
                </button>
              </div>
              <div className="list-head-right">
                <button
                  type="button"
                  className="btn btn-sm-ln-blue"
                  onClick={() => setOpenCommentGroupDialog(true)}
                >
                  <i className="ic ic-flower"></i> 모임 등록
                </button>
              </div>
            </div>
          )}
          {/* E : 글 입력 상단 - 추모모임 이름, 버튼 */}
          {/* S : 추모글 입력 폼*/}
          <div className="wrap-form">
            {/* S : 추모글 작성 */}
            <div className="contents-form">
              {/* S : 비회원 : 이름, 비밀번호 */}
              {/* S : 회원 : 이름 */}
              {loginUser && loginUser.member_id ? (
                <p className="from-name">
                  from. <span>{loginUser.member_name}</span>
                </p>
              ) : null}
              {/* E : 회원 : 이름 */}

              <div className="ip-wrap">
                {loginUser && !loginUser.member_id ? (
                  <>
                    <div className="ip-item is-error">
                      <label htmlFor="ip-name" className="offscreen">
                        이름 입력
                      </label>
                      <input
                        type="text"
                        id="ip-name"
                        placeholder="작성자명"
                        aria-invalid={errors.writer_name ? 'true' : 'false'}
                        {...register('writer_name')}
                      />
                      {errors.writer_name && (
                        <p className="message">{errors.writer_name.message}</p>
                      )}
                    </div>
                    <div className="ip-item is-error">
                      <label htmlFor="ip-password" className="offscreen">
                        비밀번호 입력
                      </label>
                      <input
                        type="password"
                        id="ip-password"
                        placeholder="비밀번호(삭제 시 필요)"
                        aria-invalid={errors.blt_password ? 'true' : 'false'}
                        {...register('blt_password')}
                      />
                      {errors.blt_password && (
                        <p className="message">{errors.blt_password.message}</p>
                      )}
                    </div>
                  </>
                ) : null}
              </div>

              {/* E : 비회원 : 이름, 비밀번호 */}
              {/* S : TEXTAREA */}
              <div className="ip-wrap">
                <div className="ip-item is-error">
                  <label htmlFor="ip-textarea" className="offscreen">
                    내용 입력
                  </label>
                  <textarea
                    {...register('blt_contents')}
                    id="ip-textarea"
                    cols="30"
                    rows="10"
                    id="ip-textarea"
                    placeholder="미처 하지 못했던 말들, 전하고 싶은 말을 추모의 글로 남겨주세요."
                  ></textarea>
                  {errors.blt_contents && <p className="message">{errors.blt_contents.message}</p>}
                </div>
              </div>
              {/* E : TEXTAREA */}
            </div>
            {/* E : 추모글 작성 */}

            {/* S : 사진/영상 등록, 추도사 예시, 공개 설정 버튼 */}
            <div className="box-form">
              {/* S : 파일첨부 영역 */}
              <div className="ip-wrap">
                <div className="box-form-head">
                  <div className="box-form-head-left">
                    <div className="ip-item">
                      <label className="btn btn-sm-blue" htmlFor="imageFile2">
                        사진/동영상 등록
                      </label>
                      <input
                        type="file"
                        className="offscreen"
                        id="imageFile2"
                        {...getRootProps()}
                        {...getInputProps()}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-sm-blue"
                      onClick={() => setOpenCommentTemplateDialog(true)}
                    >
                      추모글 예시
                    </button>
                  </div>
                  <div className="box-form-head-right">
                    <div className="rc-item rc-switch">
                      <input
                        type="checkbox"
                        id="switch7"
                        switch="none"
                        defaultChecked={true}
                        onChange={handleChangeDiaplayYn}
                      />
                      <label htmlFor="switch7" data-on-label="공개" data-off-label="비공개"></label>
                    </div>
                  </div>
                </div>
                {/* S : LOADING BAR */}
                {/*<div className="MuiBox-root css-1afp10f">*/}
                {/*  <div className="MuiBox-root css-70qvj9">*/}
                {/*    <div className="MuiBox-root css-1okj7g6">*/}
                {/*      <span*/}
                {/*        className="MuiLinearProgress-root MuiLinearProgress-colorSecondary MuiLinearProgress-determinate css-18bwlys-MuiLinearProgress-root"*/}
                {/*        role="progressbar"*/}
                {/*        aria-valuenow="0"*/}
                {/*        aria-valuemin="0"*/}
                {/*        aria-valuemax="100"*/}
                {/*      >*/}
                {/*        <span*/}
                {/*          className="MuiLinearProgress-bar MuiLinearProgress-barColorSecondary MuiLinearProgress-bar1Determinate css-wsl6a8-MuiLinearProgress-bar1"*/}
                {/*          style={{ transform: 'translateX(-100%)' }}*/}
                {/*        ></span>*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*    <div className="MuiBox-root css-pazuet">*/}
                {/*      <p className="MuiTypography-root MuiTypography-body2 css-1yu4snl-MuiTypography-root">*/}
                {/*        0%*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {displayProgressBar ? <Progress value={progressBarPercentage} /> : null}
                {/* E : LOADING BAR */}
              </div>
              {/* E : 파일첨부 영역 */}
              {/* *****
                    추억액범과 구조 동일(ip-wrap에 ip-file-wrap class만 삭제된 상태)
                ***** */}
              {/* S : 첨부 이미지 등록 후 */}
              {medias && medias.length > 0 ? (
                <div className="file-output-wrap">
                  {/* S : 등록된 파일 loop */}
                  {medias.map((media, index) => (
                    <div className="file-output">
                      <div
                        className="file-area"
                        key={media.thumbnail_link}
                        style={{ cursor: 'pointer' }}
                      >
                        {media.media_type === 'I' ? (
                          <img alt="" src={media.media_link} />
                        ) : (
                          <video src={media.media_link} controls></video>
                        )}
                      </div>
                      <button
                        className="file-del"
                        // onClick={() => removeCommentMedia(index)}
                        // onClick={() => handleRemoveMedia(index, medias)}
                        onClick={() => handleRemoveMedia(index, media.media_type)}
                        //onClick={() => alert(medias)}
                      >
                        <span className="offscreen">파일 삭제</span>
                      </button>
                    </div>
                  ))}
                  {/* E : 등록된 파일 loop */}
                </div>
              ) : null}
              {/* E : 첨부 이미지 등록 후 */}
            </div>
            {/* E : 사진/영상, 추도사 예시, 공개 설정 버튼 */}

            {/* S : 등록 버튼 */}
            <div className="btn-wrap">
              <button
                type="button"
                className="btn btn-pos"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록
              </button>
            </div>
            {/* E : 등록 버튼 */}
          </div>
          {/* E : 추모글 입력 폼 */}
        </div>
        {/* E : 추모의 글 입력 */}
        {/* S : 추모의 글 목록 */}
        {/* S : 목록 상단 글 갯수, 검색 */}
        <div className="list-head">
          <div className="list-head-left">
            <p>
              <em>{totalCount}</em> 개의 추모의 글이 등록되었습니다.
            </p>
          </div>
          <div className="list-head-right">
            {/* S : 검색(PC만 노출) */}
            <div className="box-search">
              <label htmlFor="ip-search1" className="offscreen">
                검색어 입력
              </label>
              <select id="ip-search1" onChange={handleSearchType}>
                <option value="writer">작성자</option>
                <option value="contents">내용</option>
              </select>
              <label htmlFor="ip-search2" className="offscreen">
                검색어 입력
              </label>
              <input
                type="search"
                id="ip-search2"
                placeholder="검색어를 입력해주세요"
                autocomplete="off"
                onChange={handleSearchText}
                onKeyPress={handleSearch}
              />
              <button type="button">
                <i className="ic ic-search" onClick={() => getComments(1)}></i>
                <span className="offscreen" onClick={() => getComments(1)}>
                  검색하기
                </span>
              </button>
            </div>
            {/* E : 검색(PC만 노출) */}
          </div>
        </div>
        {/* S : 목록 상단 글 갯수, 검색 */}
        {/* S : 접속자가 상주일 때 */}
        {/* S : 다운로드 버튼 클릭 전 */}
        {loginUser && loginUser.member_id === ownMemberId ? (
          <>
            <div className="list-util">
              {/* S : 전체 선택 */}
              <div className="util-item"></div>
              {/* E : 전체 선택 */}
              {/* S : 다운로드 */}
              {excelChecked === false && (
                <div className="util-item">
                  <button
                    type="button"
                    className="btn-download"
                    onClick={() => setExcelChecked(true)}
                  >
                    <i className="ic ic-download"></i>다운로드
                  </button>
                </div>
              )}
              {/* E : 다운로드 */}
            </div>
            {/* E : 다운로드 버튼 클릭 전 */}
            {/* S : 다운로드 버튼 클릭 후 */}
            {excelChecked === true && (
              <div className="list-util">
                {/* S : 전체 선택 */}
                <div className="util-item">
                  <div className="rc-item">
                    <input
                      type="checkbox"
                      id="rc-item0"
                      // name="rc-item"
                      onChange={e => {
                        {
                          onAllChecked(e.target.checked, e.target.value);
                        }
                      }}
                      checked={allChecked}
                    />
                    <label htmlFor="rc-item0">전체선택</label>
                  </div>
                </div>
                {/* E : 전체 선택 */}
                {/* S : 다운로드 */}
                <div className="util-item">
                  <p>선택한 글이 엑셀 파일로 다운로드 됩니다.</p>
                  <button type="button" className="btn-download" onClick={() => getCommentsExcel()}>
                    <i className="ic ic-download"></i>다운로드
                  </button>
                </div>
                {/* E : 다운로드 */}
              </div>
            )}
          </>
        ) : (
          ''
        )}

        {/* E : 다운로드 버튼 클릭  후 */}
        {/* E : 접속자가 상주일 때 */}
        {/* S : 등록된 게시물이 있을 때 */}
        {comments && comments.length > 0 ? (
          <div className="wrap-list is-message">
            {/* S : 추모의 글 - 글 + 사진 + 답글 작성하기 */}
            {comments.map(comment => (
              <div className="box-list" key={comment.comment_id}>
                {/* S : 선택 체크박스*/}

                {excelChecked === true && (
                  <div className="rc-item">
                    <input
                      type="checkbox"
                      id={'rc-item' + comment.comment_id}
                      value={comment.comment_id}
                      checked={isChecked(comment.comment_id)}
                      onClick={e => onCheckedElement(e.target.checked, comment.comment_id)}
                    />
                    <label htmlFor={'rc-item' + comment.comment_id}>
                      <span className="offscreen">선택</span>
                    </label>
                  </div>
                )}
                {/* E : 선택 체크박스 */}
                {comment.comment_id === modCommentId ? (
                  <div className="box-inner">
                    <div className="box-modify">
                      {/* S : 이름, 날짜, 취소/등록버튼 */}
                      <div className="box-util">
                        <div className="box-info">
                          <span className="name">{comment.writer_name}</span>
                          <span className="date">{comment.createdtime}</span>
                        </div>
                      </div>
                      {/* E : 이름, 날짜, 취소/등록버튼 */}
                      {/* S : 글 내용 입력/수정 */}
                      <div className="ip-item is-error">
                        <label htmlFor="ip-textarea2" className="offscreen">
                          내용 입력
                        </label>
                        <textarea
                          name=""
                          id="ip-textarea2"
                          cols="30"
                          rows="10"
                          placeholder="미처 하지 못했던 말들, 전하고 싶은 말을 추모의 글로 남겨주세요."
                          onChange={handleChangeContentMod}
                        >
                          {comment.blt_contents}
                        </textarea>
                        {contentValChk && <p className="message">{contentValChk}</p>}
                      </div>
                      {/* E : 글 내용 입력/수정 */}
                      {/* S : 첨부 이미지 */}
                      {comment.medias && comment.medias.length > 0 ? (
                        <div className="file-output-wrap">
                          {comment.medias.map((media, index) => (
                            <div className="file-output">
                              <div
                                className="file-area"
                                key={media.thumbnail_link}
                                style={{ cursor: 'pointer' }}
                              >
                                {media.media_type === 'I' ? (
                                  <img alt="" src={media.media_link} />
                                ) : (
                                  <video src={media.media_link} controls></video>
                                )}
                              </div>
                              {/*다시 작업 0430*/}
                              {/*<button*/}
                              {/*  className="file-del"*/}
                              {/*  onClick={() => removeCommentMedia(index, comment.medias)}*/}
                              {/*  //onClick={() => handleRemoveMedia(index, medias)}*/}
                              {/*  //onClick={() => alert(JSON.stringify(comment.medias))}*/}
                              {/*>*/}
                              {/*  <span className="offscreen">파일 삭제</span>*/}
                              {/*</button>*/}
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {/* E : 첨부 이미지 */}
                      {/* S : 버튼 */}
                      <ul className="box-btn">
                        <li>
                          <button type="button" onClick={() => handleModifyClose()}>
                            취소
                          </button>
                        </li>
                        <li>
                          <button type="button" onClick={() => handleModifySubmit()}>
                            등록
                          </button>
                        </li>
                      </ul>
                      {/* E : 버튼 */}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="box-inner">
                      {/* S : 이름, 날짜 */}
                      <div className="box-util">
                        <div className="box-info">
                          {/* S : 답글 아이콘 */}
                          {comment.depth > 1 ? (
                            <span className="icon">
                              <i className="ic ic-reply"></i>
                            </span>
                          ) : (
                            ''
                          )}
                          {/* E : 답글 아이콘 */}
                          <span className="name">{comment.writer_name}</span>
                          <span className="date">{comment.createdtime}</span>
                          {comment.display_yn === 'N' ? (
                            <span className="icon">
                              <i className="ic ic-secret"></i>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      {/* E : 이름, 날짜 */}

                      {/* S : 추모의 글 내용, 이미지 */}
                      {comment.display_yn === 'Y' ||
                      (loginUser.member_id && loginUser.member_id === comment.member_id) ||
                      (loginUser.member_id && loginUser.member_id === ownMemberId) ? (
                        <div className="box-content">
                          <p>{comment.blt_contents}</p>

                          {comment.medias && comment.medias.length > 0 ? (
                            <div className="file-output-wrap">
                              {comment.medias.map((media, index) => (
                                <div className="file-output">
                                  <div
                                    className="file-area"
                                    key={media.media_id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleOpenAlbumDialog(comment.medias, index)}
                                  >
                                    <img alt="" src={media.thumbnail_link} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="box-content">
                          <p>비공개 게시글입니다.</p>
                        </div>
                      )}
                      {/* E : 추모의 글 내용, 이미지  */}

                      {/* S : 버튼 */}
                      {comment.use_yn === 'Y' &&
                        (loginUser && loginUser.member_id ? (
                          loginUser.member_id === comment.member_id ? (
                            <ul className="box-btn">
                              <li>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleModify(
                                      comment.comment_id,
                                      comment.blt_contents,
                                      '',
                                      loginUser.member_id,
                                    )
                                  }
                                >
                                  수정
                                </button>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDelete(
                                      comment.comment_id,
                                      comment.depth,
                                      selectedCommentCategory.category_id,
                                    )
                                  }
                                >
                                  삭제
                                </button>
                              </li>
                            </ul>
                          ) : (
                            loginUser.member_id === ownMemberId && (
                              <ul className="box-btn">
                                <li>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleDelete(
                                        comment.comment_id,
                                        comment.depth,
                                        selectedCommentCategory.category_id,
                                      )
                                    }
                                  >
                                    삭제
                                  </button>
                                </li>
                              </ul>
                            )
                          )
                        ) : !loginUser.member_id && comment.member_id === 'guest' ? (
                          <ul className="box-btn">
                            <li>
                              <button
                                type="button"
                                onClick={() =>
                                  handleModify(
                                    comment.comment_id,
                                    comment.blt_contents,
                                    comment.blt_password,
                                    '',
                                  )
                                }
                              >
                                수정
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                onClick={() => handleVerifyDelete(comment.comment_id)}
                              >
                                삭제
                              </button>
                            </li>
                          </ul>
                        ) : null)}

                      {/* E : 버튼 */}

                      {/* S : 게시글 수정 */}
                      {comment.comment_id === modCommentId ? (
                        <div className="box-modify">
                          {/* S : 이름, 날짜, 취소/등록버튼 */}
                          <div className="box-util">
                            <div className="box-info">
                              <span className="name">{comment.writer_name}</span>
                              <span className="date">{comment.createdtime}</span>
                            </div>
                          </div>
                          {/* E : 이름, 날짜, 취소/등록버튼 */}
                          {/* S : 글 내용 입력/수정 */}
                          <div className="ip-item">
                            <label htmlFor="ip-textarea2" className="offscreen">
                              내용 입력
                            </label>
                            <textarea
                              name=""
                              id="ip-textarea2"
                              cols="30"
                              rows="10"
                              placeholder="미처 하지 못했던 말들, 전하고 싶은 말을 추모의 글로 남겨주세요."
                              onChange={handleChangeContentMod}
                            >
                              {comment.blt_contents}
                            </textarea>
                            {contentValChk && <p className="message">{contentValChk}</p>}
                          </div>
                          {/* E : 글 내용 입력/수정 */}
                          {/* S : 첨부 이미지 */}
                          {comment.medias && comment.medias.length > 0 ? (
                            <div className="file-output-wrap">
                              {comment.medias.map((media, index) => (
                                <div className="file-output">
                                  <div
                                    className="file-area"
                                    key={media.thumbnail_link}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {media.media_type === 'I' ? (
                                      <img alt="" src={media.media_link} />
                                    ) : (
                                      <video src={media.media_link} controls></video>
                                    )}
                                  </div>
                                  {/*다시 작업 0430*/}
                                  <button
                                    className="file-del"
                                    onClick={() => handleRemoveMedia(index, media.media_type)}
                                  >
                                    <span className="offscreen">파일 삭제</span>
                                  </button>
                                </div>
                              ))}
                            </div>
                          ) : null}
                          {/* E : 첨부 이미지 */}
                          {/* S : 버튼 */}
                          <ul className="box-btn">
                            <li>
                              <button type="button" onClick={() => handleModifyClose()}>
                                취소
                              </button>
                            </li>
                            <li>
                              <button type="button" onClick={() => handleModifySubmit()}>
                                등록
                              </button>
                            </li>
                          </ul>
                          {/* E : 버튼 */}
                        </div>
                      ) : (
                        ''
                      )}
                      {/* E : 게시글 수정 */}

                      {/* S : 답글달기 */}

                      <div className="box-reply">
                        {selectedCommentCategory.category_id != '' && comment.use_yn === 'Y' ? (
                          <button type="button" onClick={() => handleReply(comment.comment_id)}>
                            답글달기
                          </button>
                        ) : (
                          ''
                        )}
                        {comment.comment_id === repCommentId &&
                        selectedCommentCategory.category_id != '' ? (
                          <div className="ip-item is-error has-btn">
                            <label htmlFor="ip-reply" className="offscreen">
                              내용 입력
                            </label>
                            <input type="text" id="ip-reply" {...register('blt_contents')} />
                            <button
                              type="button"
                              className="btn-search"
                              onClick={handleSubmit(onSubmit, onError)}
                            >
                              등록
                            </button>
                            {errors.blt_contents && (
                              <p className="message">{errors.blt_contents.message}</p>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          // E : 등록된 게시물이 있을 때 */}
          // S : 등록된 게시물이 없을 때
          <div className="wrap-list is-message">
            <div className="no-data">
              <div className="box-text">
                <p className="title">등록된 추모글이 없습니다.</p>
                <p className="desc"> 전하고 싶은 말을 추모의 글로 남겨주세요.</p>
              </div>
            </div>
          </div>
          //E : 등록된 게시물이 없을 때
        )}
        {/* E : 추모의 글 목록 */}
        {/*  S : 페이징 */}
        <div className="btn-wrap" ref={ref}>
          {/*<button type="button" className="btn btn-more" onClick={() => handleMore(page)}>*/}
          {/*  더보기 {page}/{totalPage}*/}
          {/*</button>*/}
          {loading === true ? <img src={loading_test} alt="" /> : ''}
        </div>
      </div>
      <VerifyPasswordDialog
        isOpen={openVerifyPasswordDialog}
        onClose={() => setOpenVerifyPasswordDialog(false)}
        callBack={deleteCommentVerify}
      />
      <VerifyPasswordDialog
        isOpen={openModPasswordDialog}
        onClose={() => setOpenModPasswordDialog(false)}
        callBack={handleModifyView}
      />
      <MediaViewDialog
        isOpen={openMediaViewDialog}
        onClose={() => setOpenMediaViewDialog(false)}
        media={media}
      />
      {/* <MediaViewSwiperDialog
        isOpen={openMediaViewDialog}
        onClose={() => setOpenMediaViewDialog(false)}
        media={media}
        itemIndex={itemIndex}
      /> */}
      <ContentViewDialog
        isOpen={openContentViewDialog}
        onClose={() => setOpenContentViewDialog(false)}
        commentInfo={commentInfo}
        searchType={searchType}
        searchText={searchText}
        getComment={getComment}
        deleteComment={deleteComment}
        setCommentInfo={setCommentInfo}
      />
      <CommentGroupDialog
        isOpen={openCommentGroupDialog}
        onClose={() => setOpenCommentGroupDialog(false)}
        memorialId={memorialId}
        ownMemberId={ownMemberId}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
      />

      <SaveCommentCategoryDialog
        isOpen={openSaveCommentCategoryDialog}
        onClose={() => setOpenSaveCommentCategoryDialog(false)}
        memorialId={memorialId}
        memberId={getValues('member_id')}
        callBack={getCommentCategory}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
      />

      <ModifyCommentCategoryDialog
        isOpen={openModifyCommentCategoryDialog}
        onClose={() => setOpenModifyCommentCategoryDialog(false)}
        memorialId={memorialId}
        memberId={getValues('member_id')}
        callBack={getCommentCategory}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
        category={selectedCommentCategory}
        commentCategory={commentCategory}
        setCommentCategory={setCommentCategory}
      />

      <DeleteCommentCategoryDialog
        isOpen={openDeleteCommentCategoryDialog}
        onClose={() => setOpenDeleteCommentCategoryDialog(false)}
        handleChangeSelectedCategory={handleChangeSelectedCategory}
        callBack={getCommentCategory}
        categoryId={selectedCommentCategory.category_id}
      />

      <VerifyCommentCategoryPasswordDialog
        isOpen={openVerifyCommentCategoryDialog}
        onClose={() => setOpenVerifyCommentCategoryDialog(false)}
        callBack={setSelectedCommentCategory}
        category={selectedCommentCategory}
        currCategory={currCategory}
      />

      <LoginDialog isOpen={openLoginDialog} onClose={() => setOpenLoginDialog(false)} />

      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={openLoadingDialog}
        //onClick={() => setOpenLoadingDialog(false)}
        backdrop="static"
      >
        <Stack gap={1} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography>Loading...</Typography>
        </Stack>
      </Backdrop>

      <CommentSharedDialog
        isOpen={openCommentSharedDialog}
        onClose={() => setOpenCommentSharedDialog(false)}
        memorialId={memorialId}
        categoryId={selectedCommentCategory.category_id}
        category={selectedCommentCategory}
      />

      <MediaAlbumDialog
        isOpen={openMediaAlbumDialog}
        onClose={() => setOpenMediaAlbumDialog(false)}
        medias={currentMedia}
        initialSlide={initialSlide}
      />
      <CommentTemplateDialog
        isOpen={openCommentTemplateDialog}
        onClose={() => setOpenCommentTemplateDialog(false)}
        value={handleCommentTemplate}
        // selectedTemplate={template => selectedTemplate(template)}
      />
    </div>
  );
}

export default TabCondolencesGroupContent;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import 'css/popup.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AlertDialog({ isOpen, title, message, callback }) {
  return (
    <Dialog
      open={isOpen}
      onClose={callback}
      maxWidth="sm"
      fullWidth
      //TransitionComponent={Transition}
    >
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal is-notice" id="modal-obituary-05" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">{title}</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={callback}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <p>{message}</p>
              </div>
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={callback}
                  data-micromodal-close
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AlertDialog;

function ServiceLifePage({ setMode }) {
  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content about-service is-green">
        {/* 서비스 소개 페이지일 경우(라이프:is-green/메모리얼:is-blue) */}

        <h2 className="contents-width">서비스 소개</h2>
        {/* 22-07-21 타이틀 텍스트 수정 */}
        <div className="contents-width">
          {/* S : 22-11-02 탭 추가 */}
          <div className="tabs tabs-about">
            <ul>
              <li className="is-active">
                <a href={() => false} onClick={() => setMode('life')}>
                  라이프 캔버스란?
                </a>
              </li>
              <li>
                <a href={() => false} onClick={() => setMode('premuim')}>
                  자서전 프리미엄
                </a>
              </li>
            </ul>
          </div>
          {/* E : 22-11-02 탭 추가 */}
          <div className="box-tit">
            <h3>
              캔버스 위의
              <br />
              자유로운 기록
            </h3>
            <p className="desc">
              라이프 캔버스가 당신의 인생을 단 하나뿐인 작품으로 만들어 줍니다.
            </p>
          </div>
          {/* 22-07-21 hr 삭제 */}
          <p className="title">모두의 삶은 저마다 극적인 스토리와 가치관이 있습니다.</p>
          <p className="desc">
            이를 기록하기 시작하면 본인만의 특별한 스토리가 만들어 지고 그때야 비로소 삶은 하나의
            작품으로 남게 됩니다.
            <br />
            자서전을 작성하는 과정에서는 자기 자신을 마주하며 자신의 삶을 다시 한번 성찰하게 하고,
            앞으로의 삶을 계획할 수 있는 기회를 만들어 줍니다.
            <br />
            완성된 자서전은 자산으로 남아 사랑하는 이들에게 널리 전해집니다.
          </p>
        </div>
        <div className="has-bg">
          <div className="contents-width">
            <h3>LIFE CANVAS</h3>
            <ul>
              <li>
                <span className="number">01</span>
                <span className="keyword">
                  기록 <em>RECORD</em>
                </span>
                <p>언제든지 나에 대한 모든것을 쉽게 기록할 수 있습니다.</p>
              </li>
              <li>
                <span className="number">02</span>
                <span className="keyword">
                  주제 <em>TOPIC</em>
                </span>
                <p>삶에 관해 깊게 생각해 볼 수 있는 주제가 주어집니다.</p>
              </li>
              <li>
                <span className="number">03</span>
                <span className="keyword">
                  공유 <em>SHARE</em>
                </span>
                <p>나의 이야기들을 책자 처럼 공유할 수 있습니다.</p>
              </li>
              <li>
                <span className="number">04</span>
                <span className="keyword">
                  자서전 형식 <em>SAVE</em>
                </span>
                <p>기록한 내용들은 멋진 자서전 형식으로 저장 됩니다.</p>
              </li>
              <li>
                <span className="number">05</span>
                <span className="keyword">
                  기억 <em>MEMORY</em>
                </span>
                <p>남긴 기록은, 기억되고 싶은 나의 모습으로 기억될 수 있게끔 도와줍니다.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default ServiceLifePage;

import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination, Lazy } from 'swiper';

import 'swiper/css/pagination';

function MediaViewSwiperDialog({ isOpen, onClose, media, itemIndex }) {
  const [currentMedia, setCurrentMedia] = useState('');

  // const onSlideChangeHandler = e => {
  //   setCurrentMedia(e);
  //   console.log(media.length);
  // };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <div className="wrap" id="lifeCanvas">
        <div className="modal is-image" id="modal-sample-04" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title offscreen">이미지 레이어(슬라이드) 팝업</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="swiper preview-swiper">
                  {media && media.length > 0 ? (
                    <div className="swiper-wrapper">
                      {/* S : LOOP */}
                      <Swiper
                        modules={[Navigation, Pagination, Lazy]}
                        initialSlide={itemIndex}
                        slidesPerView={1}
                        spaceBetween={23}
                        centeredSlides={false}
                        //loop={true}
                        // autoHeight={true}
                        //calculateHeight={true}
                        simulateTouch={true}
                        //autoplay={{ delay: 2000 }}
                        //onSlideChange={onSlideChangeHandler}
                        onActiveIndexChange={swiper => {
                          setCurrentMedia(swiper.activeIndex);
                          // console.log(swiper.activeIndex);
                        }}
                        navigation={{
                          nextEl: '.box-swiper-arrow .swiper-button-next',
                          prevEl: '.box-swiper-arrow .swiper-button-prev',
                        }}
                        //navigation={true}
                        pagination={{ el: '.swiper-pagination', clickable: true, type: 'fraction' }}
                        breakpoints={{
                          0: {
                            autoHeight: false,
                          },
                          992: {
                            autoHeight: false,
                          },
                        }}
                        // lazy={true}
                        preloadImages={false}
                        lazyLoading={true}
                        lazyLoadingInPrevNext={true}
                        watchSlidesProgress={true}
                        watchSlidesVisibility={true}
                      >
                        {/* <div className="swiper-wrapper"> */}
                        {media.map(mediaItem => (
                          <SwiperSlide className="swiper-slide" key={mediaItem.media_id}>
                            {/* <div className="swiper-slide chk-size"> */}
                            {mediaItem.media_type === 'I' ? (
                              <img
                                src={mediaItem.media_link}
                                alt=""
                                // className="swiper-lazy swiper-slide-name"
                              />
                            ) : (
                              <video
                                preload="auto"
                                autoplay="autoplay"
                                controls=""
                                style={{ width: '100%', height: '100%;' }}
                              >
                                <source src={mediaItem.media_link} />
                              </video>
                            )}
                            {/* <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div> */}
                            {/* </div> */}
                          </SwiperSlide>
                        ))}
                        {/* </div> */}
                      </Swiper>
                    </div>
                  ) : (
                    ' '
                  )}

                  <div className="swiper-pagination swiper-pagination-fraction swiper-pagination-horizontal">
                    <span className="swiper-pagination-current">{currentMedia} </span>
                    <span className="swiper-pagination-total">{media.length} </span>
                  </div>
                </div>
              </div>
              <div className="modal__footer">
                {/*<button type="button" className="btn btn-blue" data-micromodal-close><i className="ic ic-close"><span className="offscreen">닫기</span></i></button>*/}
              </div>
            </div>

            <div className="box-swiper-arrow">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
export default MediaViewSwiperDialog;

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import Canvas from './canvas';
import Withdrawal from './withdrawal';

import { getMemberApi, modifyMemberApi } from 'apis/member';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function Member() {
  const navigate = useNavigate();

  const { showLifeAlert, hideLifeAlert } = useDialog();
  const { loginUser, logout } = useLogin();
  const { setGnbType } = useLayout();
  const { loginTypeCodes } = useCode();

  const schema = Yup.object({
    member_name: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    mobile: Yup.string()
      .required('필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
  }).required();

  const defaultValues = {
    member_id: '',
    member_name: '',
    member_type: 'N',
    email: '',
    mobile: '',
    addr: '',
    use_yn: 'Y',
    login_type: 'E',
    nation_number: 82,
    nation_name: '대한민국',
    del_yn: 'N',
    status: 1,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [mode, setMode] = useState('setting');
  const [member, setMember] = useState(null);
  const [loginTypeValue, setLoginTypeValue] = useState('');

  // const getMember = async params => {
  //   const result = await getMemberApi(params);
  //   if (result.code === '0000') {
  //     setMember(result.data);
  //   } else {
  //     showAlert('알림', result.message, () => hideLifeAlert());
  //   }
  // };

  const getMember = async params => {
    const result = await getMemberApi(params);
    setMember(result.data);
  };

  const modifyMember = async parmas => {
    const result = await modifyMemberApi(parmas);
    const user = JSON.parse(localStorage.getItem('loginUser'));

    if (user.member_id === parmas.member_id) {
      const modifyUser = { ...user, member_name: parmas.member_name };
      localStorage.setItem('loginUser', JSON.stringify(modifyUser));
      showLifeAlert('알림', result.message, () => hideLifeAlert());
      if (user.member_name !== parmas.member_name) {
        showLifeAlert('알림', '이름이 변경되었습니다. \n 다시 로그인 해주세요.', () => logout());
      }
    }
  };

  const onSubmit = data => {
    modifyMember(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleLogout = () => {
    logout();
    navigate('/life');
  };

  useEffect(() => {
    if (member) {
      Object.entries(member).forEach(([key, value]) => {
        setValue(key, value);
      });

      setLoginTypeValue(
        loginTypeCodes.filter(loginTypeCode => loginTypeCode.code === member.login_type)[0].value,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      showLifeAlert('알림', '올바른 접근이 아닙니다.', () => hideLifeAlert());
      navigate('/');
    }

    if (loginUser) getMember(loginUser.member_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {mode === 'setting' ? (
        <section className="container" id="containerWrap">
          {/* S : CONTENT */}
          <div className="content">
            <div className="contents-width is-setting">
              <div className="tabs tabs-setting">
                <ul>
                  <li className="is-active">
                    <a href={() => false} onClick={() => setMode('setting')}>
                      회원설정
                    </a>
                  </li>
                  <li>
                    <a href={() => false} onClick={() => setMode('canvas')}>
                      캔버스 관리
                    </a>
                  </li>
                </ul>
              </div>
              {member ? (
                <>
                  <h2 className="offscreen">회원설정</h2>
                  <h3>기본정보</h3>

                  <div className="setting-form">
                    <div className="ip-item is-error">
                      <label for="user-id">이메일</label>
                      <input
                        {...register('email')}
                        aria-invalid={errors.email ? 'true' : 'false'}
                        type="email"
                        id="user-id"
                        className="input type"
                        readOnly
                      />
                      <p class="message">{errors.email && errors.email.message}</p>
                    </div>
                    {member && member.login_type === 'E' && (
                      <div className="btn-wrap">
                        <a
                          href={() => false}
                          onClick={() => navigate('/life/settings/password')}
                          className="btn btn-blue"
                        >
                          비밀번호 변경
                        </a>
                      </div>
                    )}
                    <div className="ip-item is-error">
                      <label for="user-name">이름</label>
                      <input
                        {...register('member_name')}
                        aria-invalid={errors.member_name ? 'true' : 'false'}
                        type="text"
                        id="user-name"
                        className="input"
                      />
                      <p class="message">{errors.member_name && errors.member_name.message}</p>
                    </div>
                    {/* <div className="ip-item">
              <label for="user-birth">생일</label>
              <input
                type="date"
                id="user-birth"
                placeholder="생일"
                value="1971-05-15"
              />
            </div> */}
                    <div className="ip-item is-error">
                      <label for="user-mobile">휴대폰 번호</label>
                      <input
                        {...register('mobile')}
                        aria-invalid={errors.mobile ? 'true' : 'false'}
                        type="number"
                        id="user-mobile"
                        className="input"
                      />
                      <p class="message">{errors.mobile && errors.mobile.message}</p>
                    </div>
                    <div className="ip-item is-error">
                      <label for="user-address">주소</label>
                      <input
                        {...register('addr')}
                        aria-invalid={errors.addr ? 'true' : 'false'}
                        type="text"
                        id="address"
                        className="input"
                      />
                      <p class="message">{errors.addr && errors.addr.message}</p>
                    </div>
                    <div className="ip-item">
                      <label for="user-login">로그인 타입</label>
                      <input
                        type="text"
                        id="login_type"
                        className="input type"
                        value={loginTypeValue}
                        readOnly
                      />
                    </div>
                    <div className="btn-wrap column">
                      <a
                        href={() => false}
                        onClick={handleSubmit(onSubmit, onError)}
                        //onClick={() => alert(JSON.stringify(loginUser.member_type))}
                        className="btn btn-blue"
                      >
                        저장
                      </a>
                      <a href={() => false} onClick={handleLogout} className="btn btn-md-text">
                        로그아웃
                      </a>
                    </div>
                    <div className="btn-wrap right">
                      <a
                        href={() => false}
                        onClick={() => setMode('withdrawal')}
                        className="btn btn-sm-arrow"
                      >
                        탈퇴하기
                      </a>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {/* E : CONTENT */}
        </section>
      ) : mode === 'canvas' ? (
        <Canvas setMode={setMode} />
      ) : mode === 'withdrawal' ? (
        <Withdrawal setMode={setMode} />
      ) : (
        ''
      )}
    </>
  );
}

export default Member;

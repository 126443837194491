import { Outlet } from 'react-router-dom';

import Gnb from 'layouts/memorial-canvas/Gnb';
import RightBanner from 'layouts/memorial-canvas/RightBanner';
import Footer from 'layouts/memorial-canvas/Footer';

function MemorialCanvasLayout() {
  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="wrap is-new is-board" id="memorialCanvas">
        {/* S: HEADER */}
        {/* <Gnb /> */}
        {/* E: HEADER */}

        {/* S: CONTENTS */}
        <Outlet />
        {/* E: CONTENTS */}

        {/* S: FOOTER */}
        {/* <Footer /> */}
        {/* E: FOOTER */}
        {/* <RightBanner /> */}
        <div className="wrap-quick-btn">
          {/* S : 22-12-14 모바일 top 버튼 추가 */}
          <button type="button" className="btn-go-top" onClick={() => scrollTo()}>
            <span className="offscreen">위로가기</span>
            <i className="ic ic-arrow-up"></i>
          </button>
          {/* E : 22-12-14 모바일 top 버튼 추가 */}
        </div>
      </div>
    </>
  );
}

export default MemorialCanvasLayout;

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { saveConsultBoardApi } from 'apis/consultBoard';
import useDialog from 'stores/dialog/hook';

function PremiumRegisterPage() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { showAlert, hideAlert } = useDialog();

  const [premiumStatus, setPremiumStatus] = useState('');

  const schema = Yup.object({
    req_name: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    req_phone: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    req_email: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
        '* 이메일 형식이 올바르지 않습니다. 예) user@mcircle.biz',
      ),
    deceased_name: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    relation_desc: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    basic_contents: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(1000, '최대 입력은 1000자까지 가능합니다.'),
    //agreement: Yup.boolean().oneOf([true], '* 필수 체크 항목입니다.'),
  }).required();

  const defaultValues = {
    req_name: '',
    req_phone: '',
    req_email: '',
    deceased_name: '',
    relation_desc: '',
    basic_contents: '',
    type: '001',
    item_type: state.service,
    agreement: false,
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = data => {
    saveConsultBoard(data);
    navigate('/pet');
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const saveConsultBoard = async params => {
    const result = await saveConsultBoardApi(params);
    console.log(JSON.stringify(result));
    result.code === '0000' && reset();
    // showAlert('알림', result.message, () => hideAlert());
    showAlert('알림', '상담신청이 완료되었습니다.', () => hideAlert());
  };

  useEffect(() => {
    setPremiumStatus(state.service);
    setValue('item_type', state.service);
    setValue('type', '001');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container is-premium" id="containerWrap">
      {/* 조문보 프리미엄 서비스일 때 className="is-premium" */}
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2>
              Memorial
              <br />
              Canvas
            </h2>
          </div>
        </div>
        {/* E : 서브 비주얼 */}

        <div className="contents-width">
          <h3>신청하기</h3>

          <div className="wrap-premium-form">
            {premiumStatus === '001' ? (
              <div className="box-premium is-basic">
                <span className="desc">Premium</span>
                <p className="title">프리미엄</p>
                <p className="price">60만원</p>
                <div className="detail">
                  <dl>
                    <dt>작가</dt>
                    <dd>내부 작가</dd>
                  </dl>
                  <dl>
                    <dt>최대 분량</dt>
                    <dd>10 Page</dd>
                  </dl>
                  <dl>
                    <dt>작가 인터뷰</dt>
                    <dd>2시간(비대면, 1회)</dd>
                  </dl>
                  <dl>
                    <dt>원고 수정 / 제작 기간</dt>
                    <dd>2회 / 2달</dd>
                  </dl>
                  <dl>
                    <dt>동영상</dt>
                    <dd>
                      *3분 앨범 슬라이드형 영상
                      <br />
                      *5분 동영상 제작의뢰 가능하며 <br className="d-block d-lg-none" />
                      별도 비용 청구
                    </dd>
                  </dl>
                </div>
              </div>
            ) : (
              ''
            )}
            {premiumStatus === '002' ? (
              <div className="box-premium is-gold">
                <span className="desc">Premium Gold</span>
                <p className="title">프리미엄 골드</p>
                <p className="price">200만원</p>
                <div className="detail">
                  <dl>
                    <dt>작가</dt>
                    <dd>외부 전문 작가</dd>
                  </dl>
                  <dl>
                    <dt>최대 분량</dt>
                    <dd>20 Page</dd>
                  </dl>
                  <dl>
                    <dt>작가 인터뷰</dt>
                    <dd>2시간(비대면, 1회)</dd>
                  </dl>
                  <dl>
                    <dt>원고 수정 / 제작 기간</dt>
                    <dd>2회 / 2달</dd>
                  </dl>
                  <dl>
                    <dt>동영상</dt>
                    <dd>
                      *3분 앨범 슬라이드형 영상
                      <br />
                      *5분 동영상 제작의뢰 가능하며
                      <br />
                      별도 비용 청구
                    </dd>
                  </dl>
                </div>
              </div>
            ) : (
              ''
            )}
            {premiumStatus === '003' ? (
              <div className="box-premium is-deluxe">
                <span className="desc">Premium Deluxe</span>
                <p className="title">프리미엄 디럭스</p>
                <p className="price">500만원 이상</p>
                <div className="detail">
                  <dl>
                    <dt>작가</dt>
                    <dd>외부 전문 작가</dd>
                  </dl>
                  <dl>
                    <dt>최대 분량</dt>
                    <dd>50 Page</dd>
                  </dl>
                  <dl>
                    <dt>작가 인터뷰</dt>
                    <dd>8시간(대면, 2~4회)</dd>
                  </dl>
                  <dl>
                    <dt>원고 수정 / 제작 기간</dt>
                    <dd>4회 / 3달</dd>
                  </dl>
                  <dl>
                    <dt>동영상</dt>
                    <dd>
                      *3분 앨범 슬라이드형 영상
                      <br />
                      *5분 동영상 제작의뢰 가능하며
                      <br />
                      별도 비용 청구
                    </dd>
                  </dl>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="premium-form">
              <div className="premium-form-item">
                <div className="ip-item is-error">
                  <label for="ip-name">신청자 이름</label>
                  <input
                    type="text"
                    id="ip-name"
                    {...register('req_name')}
                    aria-invalid={errors.req_name ? 'true' : 'false'}
                    placeholder="이름을 입력하세요"
                  />
                  {errors.req_name && <p className="message">{errors.req_name.message}</p>}
                </div>
                <div className="ip-item is-error">
                  <label for="ip-mobile">전화번호</label>
                  <input
                    type="text"
                    id="ip-mobile"
                    {...register('req_phone')}
                    aria-invalid={errors.req_phone ? 'true' : 'false'}
                    placeholder="휴대폰번호* ex. 01011111111"
                  />
                  {errors.req_phone && <p className="message">{errors.req_phone.message}</p>}
                </div>
              </div>

              <div className="premium-form-item">
                <div className="ip-item is-error">
                  <label for="ip-email">이메일</label>
                  <input
                    type="text"
                    id="ip-email"
                    {...register('req_email')}
                    aria-invalid={errors.req_email ? 'true' : 'false'}
                    placeholder="sample@naver.com"
                  />
                  {errors.req_email && <p className="message">{errors.req_email.message}</p>}
                </div>
                <div className="ip-item is-error">
                  <label for="ip-chief-mourner-relation">반려동물 이름</label>
                  <input
                    type="text"
                    id="ip-chief-mourner-relation"
                    {...register('deceased_name')}
                    aria-invalid={errors.deceased_name ? 'true' : 'false'}
                    placeholder="반려동물 이름을 입력하세요"
                  />
                  {errors.deceased_name && (
                    <p className="message">{errors.deceased_name.message}</p>
                  )}
                </div>
              </div>

              <div className="premium-form-item">
                <div className="ip-item is-error">
                  <label for="ip-name2">반려동물과의 관계</label>
                  <input
                    type="text"
                    id="ip-name2"
                    {...register('relation_desc')}
                    aria-invalid={errors.relation_desc ? 'true' : 'false'}
                    placeholder="반려동물과의 관계를 입력하세요"
                  />
                  {errors.relation_desc && (
                    <p className="message">{errors.relation_desc.message}</p>
                  )}
                </div>
              </div>

              <div className="premium-form-item">
                <div className="ip-item is-error">
                  <label for="textarea-01">상담내용</label>
                  <textarea
                    id="textarea-01"
                    rows="5"
                    cols="3"
                    {...register('basic_contents')}
                    placeholder="상담 받고싶은 내용을 입력하세요"
                  ></textarea>
                  {errors.basic_contents && (
                    <p className="message">{errors.basic_contents.message}</p>
                  )}
                </div>
              </div>

              {/* S : 약관동의 */}
              <div className="premium-form-rc-item">
                <div className="rc-item">
                  <input type="checkbox" id="chk-agree-term1" />
                  <label for="chk-agree-term1">
                    <span>(필수)</span> 이용약관 동의
                  </label>
                  <a href={() => false} className="">
                    보기
                  </a>
                </div>
                <div className="rc-item">
                  <input type="checkbox" id="chk-agree-term2" />
                  <label for="chk-agree-term2">
                    <span>(필수)</span> 개인정보 수집 및 이용동의
                  </label>
                  <a href={() => false}>보기</a>
                </div>
              </div>
              {/* E : 약관동의 */}

              <div className="btn-wrap">
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit, onError)}
                  className="btn btn-pos"
                >
                  상담 신청
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}
export default PremiumRegisterPage;

import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useLogin from 'stores/login/hook';

function LoginNaver() {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const { naverLogin } = useLogin();

  const loginNaver = async token => {
    const result = await naverLogin(token);
    console.log('result > ', result);
    navigate('/');
  };

  const getNaverToken = () => {
    if (!hash) return;
    const token = hash.split('=')[1].split('&')[0];
    loginNaver(token);
  };

  useEffect(() => {
    getNaverToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}

export default LoginNaver;

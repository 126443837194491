import api from '@apis/api';
import configs from '@configs/configs';

import axios from 'axios';

export async function getCommentsApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mComment`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function getAllCommentApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mComment/mCommentIds`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function getCommentApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mComment/detail`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function saveCommentApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mComment/ins`, params);
    if (response.data.code === '0000')
      return { type: 'success', message: response.data.message, code: '0000' };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function modifyCommentApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.put(`${configs.API_BASEURL}/mComment`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// export async function deleteCommentApi(commentId) {
//   try {
//     const response = await api.delete(`${configs.API_BASEURL}/mComment?comment_id=${commentId}`);
//     if (response.data.code === '0000') return { type: 'success', message: response.data.message };
//     else return { type: 'failure', message: response.data.message };
//   } catch (error) {
//     console.log(error);
//     return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
//   }
// }

export async function deleteCommentApi(commentId) {
  try {
    const response = await api.delete(
      `${configs.API_BASEURL}/mComment/dels?comment_ids=${commentId}&path=${configs.PATH}`,
    );
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// 비 로그인 사용자 추모글 삭제 (비밀번호 검증)
export async function deleteCommentVerifyApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mComment/verify`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// 미디어 컨텐츠 리스트 (memorial_id 기준)
export async function getMediasApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mComment/mediaComments`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// 추모 카테고리 조회
export async function getCommentCategoryApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mCommentCategory`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// 추모 카테고리 등록
export async function saveCommentCategoryApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.post(`${configs.API_BASEURL}/mCommentCategory/ins`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// 추모 카테고리 수정
export async function modifyCommentCategoryApi(params) {
  params.path = configs.PATH;
  try {
    const response = await api.put(`${configs.API_BASEURL}/mCommentCategory`, params);
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

// 추모 카테고리 삭제
export async function deleteCommentCategoryApi(categoryId) {
  try {
    const response = await api.delete(
      `${configs.API_BASEURL}/mCommentCategory/dels?category_id=${categoryId}&path=${configs.PATH}`,
    );
    if (response.data.code === '0000') return response.data;
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function getCommentsExceldownloadApi(params, selectedCommentCategoryName) {
  let config = {
    responseType: 'arraybuffer',
  };
  params.path = configs.PATH;
  axios.post(`${configs.API_BASEURL}/downloadCommentExcel`, params, config).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedCommentCategoryName}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

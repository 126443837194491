import { Dialog } from '@mui/material';

function ServiceDialog({ isOpen, onClose }) {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <div className="wrap" id="lifeCanvas">
        <div class="modal is-terms" id="modal-terms-02" aria-hidden="true">
          <div class="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              class="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div class="modal__header">
                <h2 class="modal__title">이용약관</h2>
                <button
                  class="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i class="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="terms-inner">
                  <h3>제 1장 총칙</h3>
                  <h4>제 1조 목적</h4>

                  <p>주식회사 MCircle(이하 ‘회사’)가 제공하는 서비스를 이용해 주셔서 감사합니다.</p>
                  <p>
                    본 약관은 서비스 이용자가 회사가 제공하는 온라인상의 인터넷 서비스(이하
                    “서비스”라고 하며, 접속 가능한 유∙무선 단말기의 종류와는 상관없이 이용 가능한
                    “회사”가 제공하는 모든 “서비스”를 의미합니다. 이하 같습니다)에 회원으로 가입하고
                    이를 이용함에 있어 회사와 회원(본 약관에 동의하고 회원등록을 완료한 서비스
                    이용자를 말합니다. 이하 “회원”이라고 합니다)의 권리•의무 및 책임사항, 기타
                    필요한 사항을 규정함을 목적으로 합니다.
                  </p>
                  <p>
                    여러분은 본 약관에 동의함으로써 통합서비스에 가입하여 통합서비스를 이용할 수
                    있습니다. 단, 여러분은 회사가 아닌 계열사를 포함한 제3자가 제공하는 서비스에
                    가입되지는 않으며, 회사가 제공하는 유료서비스 또는 멤버십 서비스의 경우 여러분이
                    별도의 유료이용약관 또는 멤버십 이용약관에 대한 동의한 때에 회사와 여러분 간의
                    이용계약이 성립합니다.
                  </p>

                  <h4>제 2조 (약관의 효력 및 개정)</h4>
                  <p>이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.</p>
                  <ol>
                    <li>
                      1) 회사는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면에
                      게시합니다.
                    </li>
                    <li>2) 이 약관은 서비스를 통하여 이를 공지함으로써 효력이 발생됩니다.</li>
                    <li>
                      3) 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할
                      수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 15일 전부터
                      여러분에게 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 피치
                      못하게 여러분에게 불리한 내용으로 변경할 경우에는 그 시행일자 30일 전부터
                      여러분이 등록한 이메일 주소로 이메일(이메일주소가 없는 경우 서비스 내 전자쪽지
                      발송, 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단) 발송 또는
                      여러분이 등록한 휴대폰번호로 카카오톡 메시지 또는 문자메시지 발송하는 방법
                      등으로 개별적으로 통지합니다.
                    </li>
                    <li>
                      4) 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며,
                      회사가 전 항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관
                      시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을
                      명확하게 고지하였음에도 여러분의 의사표시가 없는 경우에는 변경된 약관을 승인한
                      것으로 봅니다.
                    </li>
                    <li>
                      5) 회사는 "약관의 규제에 관한 법률", "정보 통신망 이용 촉진 및 정보 보호에
                      관한 법률(이하 "정보 통신망 법") 등 관련법을 위배하지 않는 범위에서 이 약관을
                      개정할 수 있습니다.
                    </li>
                  </ol>

                  <h4>제 3조 (약관 외 준칙)</h4>
                  <p>
                    본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 통합서비스를 구성하는
                    개별 서비스의 이용약관 및 운영정책, 개별 서비스 내 세부 하위서비스의 이용약관,
                    MCircle 규칙 등(이하 총칭하여 ‘세부지침’)의 규정에 따릅니다. 또한 본 약관과
                    세부지침의 내용이 충돌할 경우 세부지침에 따릅니다.
                  </p>
                  <p>
                    본 약관에 명시되지 않은 사항에 대해서는 “별도의 이용약관 및 정책”, 약관의 규제에
                    관한 법률, 정보통신망법 및 관계법령 또는 상관례에 따릅니다.
                  </p>

                  <h3>제 2장 통합서비스 이용 계약</h3>
                  <h4>제 4조 (이용계약의 성립)</h4>
                  <ol>
                    <li>
                      1) 이용계약은 회원이 되고자 하는 자가 약관의 내용에 대하여 동의를 한 다음
                      회원가입신청을 하고 회사가 요구하는 항목들을 사실대로 기재하고 약관에 동의를
                      하면 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
                    </li>
                    <li>
                      2) 이용계약은 서비스 이용 희망자의 이용약관 동의 후 이용 신청에 대하여 회사가
                      승낙함으로써 성립합니다.
                    </li>
                  </ol>

                  <h4>제 5조 (이용 신청)</h4>
                  <p>
                    회원으로 가입하여 서비스를 이용하기를 희망하는 자는 회사가 정한 회원가입양식에
                    본인의 성명 및 아이디 등 필수사항을 입력하고 휴대전화 본인인증 후, ‘등록하기’
                    또는 ‘확인’버튼을 누름으로써 서비스 이용신청을 할 수 있습니다. 다만, 회사가
                    필요하다고 인정하는 경우 이용신청자에게 별도의 서류 제출을 요구할 수 있습니다.
                  </p>

                  <h4>제 6조 (이용 신청의 승낙 및 제한)</h4>
                  <ol>
                    <li>
                      1) 서비스 이용계약은 이용회원이 되고자 하는 자(이하 "가입신청자")가 회사가
                      요구하는 항목들을 사실대로 기재하고 약관에 동의 버튼을 선택하여 동의 의사를
                      회사에 전자적 방식으로 전송하게 되면, 본 약관에 동의하고 회사에 서비스 이용을
                      신청한 것으로 간주됩니다.
                    </li>
                    <li>
                      2) 서비스 이용계약은 제2조 제1항의 약관 동의를 포함하는 서비스 이용신청에
                      대하여 회사가 다음 각 호의 제반 요건을 충족한 가입신청자에게 서비스 이용을
                      승낙함으로써 성립합니다.
                      <ol>
                        <li>(1) 가입신청자는 반드시 신청자 본인의 명의로 가입하여야 합니다.</li>
                        <li>
                          (2) 가입신청자는 본인 명의의 결제수단(금융기관의 계좌, 카드 등)을
                          소유하여야 하며, 결제수단의 명의는 가입신청자의 명의자와 일치하여야
                          합니다.
                        </li>
                        <li>
                          (3) 회사는 본인확인기관 등을 통한 “휴대전화 본인인증”을 요청합니다.
                          본인확인의 경우 개인정보보호 법령에 따라 본인확인기관 등이 제공하는 별도의
                          이용약관 동의에 의거하여 본인확인 절차를 진행하며, 회사는 본인확인 절차를
                          취할 수 없는 이용신청에 대해서는 해당 신청자에게 추가적으로 증빙자료를
                          요청할 수 있습니다.
                        </li>
                        <li>
                          (4) 회사는 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수
                          있고, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                          <ol>
                            <li>(a) 서비스 설비에 여유가 없는 경우</li>
                            <li>(b) 기술상 문제가 있다고 판단되는 경우</li>
                            <li>(c) 기타 회사의 사정상 필요하다고 인정되는 경우</li>
                          </ol>
                        </li>
                        <li>
                          (5) 회사는 다음 각 호에 해당하는 사항을 인지하는 경우 이용신청을 승낙하지
                          않을 수 있습니다.
                          <ol>
                            <li>(a) 본인의 실명이 아니거나, 타인의 명의를 사용하여 신청한 경우</li>
                            <li>(b) 허위 정보를 기재하여 신청한 경우</li>
                            <li>(c) 신청양식의 기재사항을 누락하거나 오기하여 신청하는 경우</li>
                            <li>
                              (d) 14세 미만 아동이 개인정보제공에 대하여 법정대리인의 동의를 얻지
                              아니한 경우
                            </li>
                            <li>
                              (e) 사회질서 및 미풍양속을 저해하거나 저해할 목적으로 신청한 경우
                            </li>
                            <li>
                              (f) 회원의 귀책사유에 의하여 회원자격을 상실한 적이 있는 경우. 단,
                              회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함
                            </li>
                            <li>(g) 기타 회사가 정한 이용요건에 충족되지 않은 경우</li>
                          </ol>
                        </li>
                        <li>
                          (6) 제2항과 제3항에 따라 이용신청의 승낙을 유보하거나 아니한 경우, 회사는
                          원칙적으로 이를 이용신청자에게 알리도록 합니다. 다만, 회사의 귀책사유 없이
                          이용신청자에게 통지할 수 없는 경우는 예외로 합니다.
                        </li>
                        <li>
                          (7) 회사는 회원에 대하여 회원 종류 및 등급별로 구분하여 이용시간,
                          이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
                        </li>
                        <li>
                          (8) 회사는 회원에 대하여 영화 및 비디오물의 진흥에 관한 법률 및
                          청소년보호법 등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을
                          할 수 있습니다.
                        </li>
                      </ol>
                    </li>

                    <li></li>
                  </ol>

                  <h4>제 7조 (회원정보의 변경)</h4>
                  <ol>
                    <li>
                      1) 회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할
                      수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디 등은 수정이 불가능합니다.
                    </li>
                    <li>
                      2) 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을
                      하거나 전자우편 기타 방법으로 회사에 그 변경사항을 알려야 합니다.
                    </li>
                    <li>
                      3) 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는
                      책임지지 않습니다.
                    </li>
                  </ol>

                  <h4>제 8조 (개인정보의 보호)</h4>
                  <ol>
                    <li>1) 회사는 회원의 개인정보를 보호하고 존중합니다.</li>
                    <li>
                      2) 회사는 이용 신청 시 회원이 제공하는 정보, 커뮤니티 활동, 각종 이벤트 참가를
                      위하여 회원이 제공하는 정보, 기타 서비스 이용 과정에서 수집되는 정보 등을
                      통하여 회원에 관한 정보를 수집하며, 회원의 개인정보는 본 이용계약의 이행과 본
                      이용계약상의 서비스 제공을 위한 목적으로만 사용됩니다.
                    </li>
                    <li>
                      3) 회사는 서비스 제공과 관련하여 취득한 회원의 신상정보를 본인의 승낙 없이
                      제3자에게 누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수 없습니다. 다만,
                      다음의 각 호에 해당하는 경우에는 그러하지 아니합니다.
                      <ol>
                        <li>(1) 정보통신서비스의 제공에 따른 요금 정산을 위하여 필요한 경우</li>
                        <li>
                          (2) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을
                          알아볼 수 없는 형태로 가공하여 제공하는 경우
                        </li>
                        <li>
                          (3) 관계 법령에 의하여 수사상 목적으로 정해진 절차와 방법에 따라
                          관계기관의 요구가 있는 경우
                        </li>
                        <li>(4) 다른 법률에 특별한 규정이 있는 경우</li>
                        <li>(5) 정보통신윤리위원회가 관계법령에 의하여 요청 경우</li>
                      </ol>
                    </li>
                    <li>
                      4) 회사는 수집된 개인정보의 취급 및 관리 등의 업무(이하 업무)를 스스로
                      수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 회사가 선정한
                      회사에 위탁할 수 있습니다. 위탁에 대한 세부내용은 별도의 개인정보보호지침을
                      통해 공지합니다.
                    </li>
                  </ol>

                  <h4>제 9조 (개인정보의 이용)</h4>
                  <ol>
                    <li>
                      1) 회사가 수집하는 개인정보는 서비스의 제공에 필요한 최소한으로 하되, 필요한
                      경우 보다 더 자세한 정보를 요구할 수 있습니다.
                    </li>
                    <li>
                      2) 회사가 외부업체(이하 위탁 받은 업체)에 특정서비스의 제공을 위탁하는 경우
                      서비스 제공에 필요한 회원의 개인정보를 회원의 동의를 받아 위탁 받는 업체에
                      제공할 수 있으며 서비스 위탁 사실을 명시 합니다.
                    </li>
                    <li>
                      3) 회원은 원하는 경우 언제든 회사에 제공한 개인정보의 수집과 이용에 대한
                      동의를 철회할 수 있으며, 동의의 철회는 해지 신청을 하는 것으로 이루어집니다.
                    </li>
                  </ol>

                  <h4>제 10조 (계약 사항의 변경)</h4>
                  <ol>
                    <li>
                      1) 회원은 이용 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 해야
                      하며 회원정보를 변경하지 아니하여 발생되는 문제의 책임은 회원에게 있습니다.
                    </li>
                    <li>
                      2) 회원이 원하는 경우 이용동의를 철회할 수 있으며, 이용동의를 철회한 경우
                      ‘서비스’ 이용에 제약이 따를 수 있습니다. 이용동의의 철회는 탈퇴 신청을 하는
                      것으로 이루어집니다.
                    </li>
                  </ol>

                  <h3>제 3장 계약 당사자의 의무</h3>
                  <h4>제 11조 (회사의 의무)</h4>
                  <ol>
                    <li>
                      1) 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을
                      위하여 지속적으로 노력하며, 서비스에 장애가 생기거나 멸실 된 때에는 지체 없이
                      이를 수리 복구하여야 합니다. 다만, 천재지변, 비상사태 또는 그 밖에 부득이한
                      경우에는 그 서비스를 중단하거나 중지할 수 있습니다
                    </li>
                    <li>
                      2) 회사는 회원의 프라이버시 보호와 관련하여 제 9 조에 제시된 내용을 지킵니다
                    </li>
                    <li>
                      3) 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용고객과의 계약 관련
                      절차 및 내용 등에 있어 이용고객에게 편의를 제공하도록 노력합니다.
                    </li>
                    <li>
                      4) 회사는 전화, 전자우편 또는 서비스 화면의 상담창구 등을 통하여 이용고객의
                      불만사항을 접수, 처리 하여야 합니다.
                    </li>
                    <li>
                      5) 회사는 이용자로부터 제기되는 의견이나 불만을 정당하다고 인정할 경우에는
                      즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 이용고객에게 그 사유를
                      전자우편, 전화 등으로 통보하여야 합니다.
                    </li>
                    <li>
                      6) 회사는 서비스의 제공을 중지한 경우 해당 회원에게 서비스가 중지되는 사유,
                      이의제기 절차 등을 서면(전자문서포함), 모사전송, 전화 또는 이와 유사한 방법 중
                      어느 하나의 방법을 이용하여 통지합니다.
                    </li>
                    <li>
                      7) 회사는 사업의 폐지 또는 휴지 예정일 30일 전까지 특별한 사정이 없는 한
                      이용자 에게 그 사실을 통보 하여야 합니다.
                    </li>
                  </ol>

                  <h4>제 12조 (회원의 의무)</h4>
                  <ol>
                    <li>
                      1) 회원은 서비스를 이용할 때 다음 각 호의 행위를 하여서는 안됩니다
                      <ol>
                        <li>
                          (1) 본 약관 및 전자금융거래법 등 서비스이용에 관련된 관계법령을 위반한
                          행위
                        </li>
                        <li>
                          (2) 서비스를 제공받기 위해 이용 신청 또는 변경 시 허위 사실을 기재하거나
                          기타 서비스 운영을 고의로 방해하는 일체의 행위
                        </li>
                        <li>
                          (3) 서비스를 제공받기 위해 이용 신청 또는 변경 시 허위 사실을 기재하거나,
                          다른 회원의 정보를 수집 저장 공개 및 부정하게 사용하는 행위
                        </li>
                        <li>
                          (4) 회사의 지식재산권, 제3자의 지식재산권 등 기타 권리를 침해하거나 회사의
                          동의 없이 회원 또는 는 상업적인 목적을 위하여 본 서비스 자체, 서비스
                          내용의 일부 또는 전부를 복사, 복제, 판매, 재판매 또는 양수도하는 행위
                        </li>
                        <li>
                          (5) 기타 범죄 또는 법령이 금지하는 행위와 관련되었다고 추정되는 일체의
                          행위
                        </li>
                        <li>
                          (6) 서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는
                          컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
                        </li>
                        <li>(7) 회사의 서비스를 해킹하거나 해킹에 이용하는 일체의 행위</li>
                        <li>(8) 기타 불법적이거나 부당한 행위</li>
                        <li>
                          (9) 회사는 회원이 게시하거나 등록하는 서비스내의 내용물 등이 다음 각 호에
                          해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있습니다.
                        </li>
                        <li>
                          (10) 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는
                          내용인 경우
                        </li>
                        <li>(11) 공공질서 및 미풍양속에 위반되는 내용인 경우</li>
                        <li>(12) 범죄적 행위에 결부된다고 인정되는 내용일 경우</li>
                        <li>
                          (13) 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
                        </li>
                        <li>(14) 회사에서 규정한 게시기간이나 용량을 초과한 경우</li>
                        <li>
                          (15) 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트를
                          링크하는 경우
                        </li>
                        <li>(16) 게시판의 성격에 부합하지 않는 게시물의 경우</li>
                        <li>(17) 기타 관계법령에 위반된다고 판단되는 경우</li>
                      </ol>
                    </li>
                    <li>
                      2) 회원은 관련 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항,
                      회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를
                      하여서는 아니 됩니다.
                    </li>
                    <li>
                      3) 회원은 반드시 본인이 직접 서비스를 이용해야 하며, 이용계약상 지위를
                      타인에게 양도, 증여하거나 본인 이외의 타인이 서비스를 이용하여 발생하는 문제에
                      대한 책임은 회원 본인에게 있습니다.
                    </li>
                  </ol>

                  <h4>제 13조 (회원에 대한 통지)</h4>
                  <ol>
                    <li>
                      1) 회사는 회원의 서비스 이용과 관련한 권리 및 의무 등에 관한 사항을 회원이
                      지정한 이메일 주소, SMS 등으로 통지할 수 있습니다.
                    </li>
                    <li>
                      2) 회사는 회원 전체에 대한 통지의 경우 서비스에 내용을 게시함으로써 제1항의
                      통지에 갈음할 수 있습니다.
                    </li>
                  </ol>

                  <h3>제 4장 서비스 이용 범위</h3>
                  <h4>제 14조 (서비스 이용 범위)</h4>
                  <ol>
                    <li>
                      1) 회사는 회원에게 아래의 서비스를 제공합니다.
                      <ol>
                        <li>a) 부고 및 추모관 알림 서비스</li>
                        <li>b) 조문 메시지 전송 서비스</li>
                        <li>c) 조의금 송금 서비스</li>
                        <li>d) 디지털화환 서비스</li>
                        <li>e) 조문보 프리미엄 제작 서비스</li>
                        <li>f) 감사인사 서비스</li>
                        <li>g) 기일알림 서비스</li>
                        <li>
                          h) 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게
                          제공하는 일체의 서비스
                        </li>
                      </ol>
                    </li>
                    <li>2) 회원은 회사를 통한 가입 시 서비스를 이용할 수 있습니다.</li>
                    <li>
                      3) 회사의 서비스는 회사 동의 없이 상업적으로 이용할 수 없으며, 이를 위반 시
                      사전 통보 없이 서비스를 차단 할 수 있습니다.
                    </li>
                    <li>
                      4) 상업적 재판매 등의 이용 범위는 회사 와 별도의 협의 및 계약으로 진행 하시는
                      경우에는 서비스 가능합니다.
                    </li>
                  </ol>

                  <h4>제 15조 (서비스의 제공 및 변경)</h4>
                  <ol>
                    <li>
                      1) 회사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보를
                      공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다.
                    </li>
                    <li>
                      2) 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는
                      전부 또는 일부 서비스를 변경할 수 있습니다. 서비스의 내용, 이용방법,
                      이용시간에 대하여 변경이 있는 경우에는 특별한 사정이 없는 한 변경사유, 변경될
                      서비스의 내용 및 제공일자 등은 그 변경 전 7일 이상 해당 서비스 초기화면에
                      게시하여야 합니다.
                    </li>
                    <li>
                      3) 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의
                      필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는
                      한 회원에게 별도의 보상을 하지 않습니다.
                    </li>
                  </ol>

                  <h4>제 16조 (회원의 게시물)</h4>
                  <ol>
                    <li>
                      1) 회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위배되는 내용을
                      포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및
                      삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 합니다.
                    </li>
                    <li>
                      2) 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한
                      사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당
                      게시물에 대해 임시조치(게시물에 대한 접근을 임시적으로 차단하는 조치를
                      말합니다) 등을 취할 수 있습니다.
                    </li>
                    <li>
                      3) 회사는 개별 서비스별로 게시물과 관련된 세부이용지침을 별도로 정하여 시행할
                      수 있으며, 회원은 그 지침에 따라 게시물(회원간 전달 포함)을 게재하여야 합니다.
                    </li>
                  </ol>

                  <h4>제 17조 (게시물의 저작권)</h4>
                  <p>서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.</p>
                  <ol>
                    <li>
                      1) 게시물에 대한 권리와 책임은 게시자에게 있으며 회사는 게시자의 동의 없이는
                      이를 서비스 내 게재 이외에 영리적 목적으로 사용할 수 없습니다. 단, 비영리적인
                      경우에는 그러하지 아니하며 또한 회사는 서비스 내의 게재권을 갖습니다.
                    </li>
                    <li>
                      2) 회원의 게시물이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을
                      포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및
                      삭제 등을 요청할 수 있으며, 회사와 회원은 관련법에 따라 조치를 취하여야
                      합니다.
                    </li>
                    <li>
                      3) 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한
                      사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당
                      게시물에 대해 임시조치 등을 취할 수 있습니다.
                    </li>
                    <li>
                      4) 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 단, 회원의 게시물
                      및 제휴계약에 따라 제공된 저작물 등은 제외합니다. 회사는 서비스와 관련하여
                      회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 콘텐츠 등을 이용할 수 있는
                      이용권만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수
                      없습니다.
                    </li>
                    <li>
                      5) 게시물은 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당
                      노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.
                      이 경우, 회사는 저작권법 규정을 준수하며, 회원은 언제든지 고객센터 또는 각
                      서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의
                      조치를 취할 수 있습니다.
                    </li>
                  </ol>

                  <h4>제 18조 (서비스 이용시간)</h4>
                  <p>
                    서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일
                    24시간 가능함을 원칙으로 합니다. 다만, 정기 점검 등의 필요로 회사가 정한 날이나
                    시간은 그러하지 않습니다.
                  </p>

                  <h4>제 19조 (서비스 제공의 중지 등)</h4>
                  <p>회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.</p>
                  <ol>
                    <li>1) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                    <li>
                      2) 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
                    </li>
                    <li>3) 기타 불가항력적 사유가 있는 경우</li>
                    <li>
                      4) 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주
                      등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를
                      제한하거나 중지할 수 있습니다.
                    </li>
                    <li>
                      5) 회사는 제 1 항 및 2 항의 규정에 의하여 서비스의 이용을 제한하거나 중지한
                      때에는 그 사유 및 제한기간 등을 지체 없이 회원에게 알려야 합니다.
                    </li>
                  </ol>

                  <h3>제 5장 계약 해지 및 이용 제한</h3>
                  <h4>제 20조 (계약 해지 및 이용 제한)</h4>
                  <ol>
                    <li>
                      1) 회원이 이용 계약을 해지하고자 하는 경우에는 회원 본인이 회원탈퇴 기능을
                      통하여 회원탈퇴를 회사에 신청하여야 합니다.
                    </li>
                    <li>
                      2) 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이
                      이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.
                      <ol>
                        <li>
                          (1) 타인의 개인정보, 아이디 및 비밀번호를 도용한 경우타인의 개인정보,
                          아이디 및 비밀번호를 도용한 경우
                        </li>
                        <li>(2) 가입한 이름이 실명이 아닌 경우</li>
                        <li>(3) 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
                        <li>(4) 회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우</li>
                        <li>(5) 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</li>
                        <li>
                          (6) 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는
                          실행하는 경우
                        </li>
                        <li>(7) 서비스 운영을 고의로 방해한 경우</li>
                        <li>
                          (8) 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성
                          정보를 전송하는 경우
                        </li>
                        <li>
                          (9) 정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스
                          프로그램 등을 유포하는 경우
                        </li>
                        <li>
                          (10) 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법 선거운동과
                          관련하여 선거관리위원회의 유권해석을 받은 경우
                        </li>
                        <li>
                          (11) 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는
                          유통시키거나 상업적으로 이용하는 경우
                        </li>
                        <li>
                          (12) 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란 사이트
                          링크하는 경우
                        </li>
                        <li>(13) 회사의 승인 없이 서비스를 상업적으로 이용하는 경우</li>
                        <li>
                          (14) 결제기관을 속여 부정한 방법으로 결제하거나 지불거절(Chargebeck)을
                          악용하여 정당한 이유 없이 유료서비스를 구매하거나 환불하는 경우
                        </li>
                        <li>
                          (15) 회사는 회원이 1년 이상 로그인하지 않는 경우, 회원정보의 보호 및
                          운영의 효율성을 위해 이용을 제한할 수 있습니다.
                        </li>
                      </ol>
                    </li>
                    <li>
                      3) 회사는 전항, 12조(회원의 의무) 에도 불구하고 주민등록법을 위반한 명의도용
                      및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을
                      위반한 스팸메세지 및 불법통신, 해킹, 악성프로그램의 배포, 접속권한 초과행위
                      등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에
                      따른 서비스 이용정지 시 서비스 내의 금액, 포인트, 혜택 및 권리 등도 모두
                      소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.
                    </li>
                  </ol>

                  <h3>제 6장 기타</h3>
                  <h4>제 21조 (면책조항 Ⅰ)</h4>
                  <ol>
                    <li>
                      1) 본 서비스는 회원의 편의를 위하여 제공하는 서비스로, 본 서비스와 관련된
                      부과금액 등 가상 계좌에 입금 된 금액이 없는 동안에는 서비스 이용과 관련하여
                      발생한 어떠한 손해에 대하여도 회사가 책임을 지지 않습니다.
                    </li>
                    <li>
                      2) 회사의 가상 계좌 및 카드결제 제공과 관련하여 회사의 귀책사유로 인하여
                      회원이 서비스 이용에 손해를 입은 경우, 회원은 합당하고 적절한 보상을 받을 수
                      있습니다.
                    </li>
                  </ol>

                  <h4>제 22조 (면책조항 Ⅱ)</h4>
                  <ol>
                    <li>
                      1) 회사는 관련 법령상 허용되는 한도 내에서 통합서비스와 관련하여 본 약관에
                      명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다. 또한,
                      회사는 CP(Contents Provider)가 제공하거나 여러분이 작성하는 등의 방법으로
                      통합서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을
                      하지 않으며, 회사의 과실 없이 발생된 여러분의 손해에 대하여는 책임을 부담하지
                      아니합니다.
                    </li>
                    <li>
                      2) 회사는 회사의 과실로 인하여 여러분이 손해를 입게 될 경우 본 약관 및 관련
                      법령에 따라 여러분의 손해를 배상하겠습니다. 다만 회사는 회사의 과실 없이
                      발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 회사는
                      법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및
                      징벌적 손해에 대한 책임을 부담하지 않습니다.
                      <ol>
                        <li>(1) 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해</li>
                        <li>(2) 여러분의 귀책사유로 통합서비스 이용에 장애가 발생한 경우</li>
                        <li>(3) 통합서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해</li>
                        <li>
                          (4) 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써
                          발생하는 손해
                        </li>
                        <li>
                          (5) 제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을
                          방해함으로써 발생하는 손해
                        </li>
                        <li>(6) 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해</li>
                        <li>
                          (7) 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등
                          제3자가 서비스를 이용하는 과정에서 발생된 손해
                        </li>
                        <li>(8) 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해</li>
                      </ol>
                    </li>
                    <li>
                      3) 회사는 여러분이 통합서비스를 이용하여 기대하는 수익을 상실한 것에 대하여
                      책임을 지지 않으며 그 밖에 통합서비스를 통하여 얻은 자료로 인한 손해 등에
                      대하여도 책임을 지지 않습니다.
                    </li>
                    <li>
                      4) 회사는 회사의 과실이 없는 한 여러분 상호간 또는 여러분과 제3자 상호간에
                      통합서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한
                      손해를 배상할 책임도 없습니다.
                    </li>
                  </ol>

                  <h4>제 23조 (통지 및 공지)</h4>
                  <p>
                    회사는 여러분과의 의견 교환을 소중하게 생각합니다. 여러분은 언제든지 MCircle
                    고객센터 및 개별 서비스 고객센터에 방문하여 의견을 개진할 수 있습니다. 서비스
                    이용자 전체에 대한 공지는 MCircle 고객센터 및 관련된 개별 서비스 공지사항에
                    게시함으로써 효력이 발생합니다. 여러분에게 중대한 영향을 미치는 사항의 경우에는
                    MCircle계정에 등록된 이메일 주소로 이메일(이메일주소가 없는 경우 서비스 내 알림
                    메시지를 띄우는 등의 별도의 전자적 수단) 발송 또는 여러분이 등록한 휴대폰번호로
                    카카오톡 메시지 또는 문자메시지 발송하는 방법 등으로 개별적으로 알려
                    드리겠습니다.
                  </p>

                  <h4>제 24조 (분쟁의 해결)</h4>
                  <ol>
                    <li>1) 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.</li>
                    <li>
                      2) 회사와 회원간에 발생한 분쟁에 대해서는 민사소송법상의 법원을 관할법원으로
                      합니다.
                    </li>
                  </ol>

                  <h3>부칙</h3>
                  <p>본 약관은 2022년 02월 10일부터 적용됩니다.</p>

                  <hr />

                  <h3>유료 이용약관</h3>
                  <h4>제 1조 (목적)</h4>
                  <p>
                    본 약관은 주식회사 MCircle(이하 “회사”라 합니다)가 인터넷 서비스를 통해 운영하는
                    홈페이지를 통하여 제공되는 각종 유료 서비스의 이용조건 및 절차에 관한 사항을
                    규정함을 목적으로 합니다.
                  </p>

                  <h4>제 2조 (약관의 효력 및 개정)</h4>
                  <p>이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.</p>
                  <ol>
                    <li>
                      1) 회사는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면에
                      게시합니다.
                    </li>
                    <li>2) 이 약관은 서비스를 통하여 이를 공지함으로써 효력이 발생됩니다.</li>
                    <li>
                      3) 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할
                      수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 15일 전부터
                      여러분에게 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 피치
                      못하게 여러분에게 불리한 내용으로 변경할 경우에는 그 시행일자 30일 전부터
                      등록된 이메일 주소로 이메일(이메일주소가 없는 경우 서비스 내 전자쪽지 발송,
                      서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단) 발송 또는 여러분이
                      등록한 휴대폰번호로 카카오톡 메시지 또는 문자메시지 발송하는 방법 등으로
                      개별적으로 통지합니다.
                    </li>
                    <li>
                      4) 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며,
                      회사가 전 항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관
                      시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을
                      명확하게 고지 하였음에도 여러분의 의사표시가 없는 경우에는 변경된 약관을
                      승인한 것으로 봅니다.
                    </li>
                    <li>
                      5) 회사는 "약관의 규제에 관한 법률", "정보 통신망 이용 촉진 및 정보 보호에
                      관한 법률(이하 "정보 통신망 법") 등 관련법을 위배하지 않는 범위에서 이 약관을
                      개정할 수 있습니다
                    </li>
                  </ol>

                  <h4>제 3조 (약관 외 준칙)</h4>
                  <p>
                    본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 통합서비스를 구성하는
                    개별 서비스의 이용약관 및 운영정책, 개별 서비스 내 세부 하위서비스의 이용약관,
                    MCircle 규칙 등(이하 총칭하여 ‘세부지침’)의 규정에 따릅니다. 또한 본 약관과
                    세부지침의 내용이 충돌할 경우 세부지침에 따릅니다. 본 약관에 명시되지 않은
                    사항에 대해서는 “별도의 이용약관 및 정책”, 약관의 규제에 관한 법률, 정보통신망법
                    및 관계법령 또는 상관례에 따릅니다.
                  </p>

                  <h4>제 4조 (용어의 정리)</h4>
                  <ol>
                    <li>
                      1) 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                      <ol>
                        <li>
                          (1) 유료서비스 : 디지털화환을 회원 또는 비회원이 결제 방식을 통해 구매 시,
                          결제된 금액을 부고 만든 회원(상주)에게 정산해주는 서비스 입니다.
                        </li>
                        <li>(2) 구매 : 회사가 제공하는 유료서비스를 결제하는 행위를 말합니다.</li>
                        <li>
                          (3) 환불 : 회사에 결제한 유료서비스를 현금으로 되돌려 받거나, 구매한
                          상품을 상품 구매의 취소에 의하여 현금으로 되돌려 받는 것을 말합니다.
                        </li>
                        <li>
                          (4) 환전 : 회사의 정책 및 안내에 따라 다른 이용자로부터 받은 후원(본
                          서비스에서는 조문객이 보내는 디지털 화환을 의미합니다. 이하 ‘후원’이라고
                          합니다.) 을 현금으로 전환하기 위해 신청을 하고, 플랫폼서비스 수수료 및
                          원천징수세액 등을 제외한 나머지를 현금으로 받는 행위를 말합니다.
                        </li>
                        <li>
                          (5) 수수료 : 회사가 서비스를 제공, 운영하는 데 필요한 제반 비용(서버
                          유지비, 망사용료, 저작권료 기타 사용권 취득 비용, 그 밖에 인력, 시설 유지
                          비용 등)으로 각 서비스 형태에 맞게 책정된 비율로 회사에게 귀속되는 요금을
                          말합니다.
                        </li>
                      </ol>
                    </li>
                    <li>
                      2) 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는
                      관계법령 및 기타 일반적인 상관례에 의합니다
                    </li>
                  </ol>

                  <h4>제 5조 (유료서비스의 내용 등의 게시)</h4>
                  <ol>
                    <li>
                      1) 회사는 다음 사항을 해당 유료서비스의 안내 또는 구매화면 등에 이용자가 알기
                      쉽게 표시합니다.
                      <ol>
                        <li>(1) 유료서비스의 명칭, 종류 및 내용</li>
                        <li>(2) 유료서비스의 가격과 그 지급방법 및 시기</li>
                        <li>(3) 유료서비스의 공급방법, 시기 및 제공기간</li>
                        <li>
                          (4) 이용자피해보상, 서비스에 대한 불만 및 이용자와 회사간 분쟁처리에 관한
                          사항
                        </li>
                        <li>(5) 거래에 관한 약관</li>
                        <li>
                          (6) 서비스의 가격 외에 이용자가 추가로 부담하여야 할 사항이 있는 경우, 그
                          내용 및 금액
                        </li>
                        <li>
                          (7) 이용자가 미성년자인 경우, 법정대리인의 동의를 얻지 못하면 미성년자
                          본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용
                        </li>
                      </ol>
                    </li>
                    <li>
                      2) 회사에서 제공하는 유료서비스는 유료서비스 제공기간에 따라 사용할 수
                      있습니다.
                    </li>
                  </ol>

                  <h4>제 6조 (이용 계약의 성립)</h4>
                  <p>
                    이용자가 본 약관에 동의하고, 회사가 정하는 결제수단과 방법을 통해 유료서비스를
                    구매함으로써 이용계약이 성립됩니다.
                  </p>

                  <h4>제 7조 (유료서비스의 구매)</h4>
                  <ol>
                    <li>1) 유료서비스의 구매는 회사의 [온라인추모관] 계정이 없어도 가능합니다.</li>
                    <li>
                      2) 유료서비스의 구매는 휴대폰, 신용카드 등 회사에서 제공하는 결제방식을 통해서
                      가능합니다. 다만, 각 결제수단을 운영하는 사업자가 별도로 있는 경우, 이용자는
                      해당 결제수단을 이용하기 전에 해당 결제수단의 사업자가 제시하는 절차를
                      이행하여야 합니다. 회사의 서비스 이용요금을 결제할 때에는 해당 결제수단의
                      사업자가 제시하는 절차 이행 및 이용약관에 동의한 것으로 간주합니다.
                    </li>
                  </ol>

                  <h4>제 8조 (결제에 대한 승인)</h4>
                  <ol>
                    <li>
                      1) 회사는 다음의 각 항에 해당하는 이용신청에 대해서는 승인을 하지 않거나, 추후
                      해당 승인을 취소할 수 있습니다.
                      <ol>
                        <li>(1) 이용 요금을 납부하지 않거나 납부자를 확인할 수 없는 경우</li>
                        <li>(2) 만 19세 미만의 미성년자가 법정대리인의 동의 없이 신청한 경우</li>
                        <li>(3) 이용신청자의 귀책사유로 승인이 불가능하다고 판단되는 경우</li>
                        <li>
                          (4) 기타 이용 신청을 승인하는 것이 관계 법령이 위반되거나 사회 안녕 질서
                          및 미풍양속, 회사의 관련 약관 등 회사의 업무 방침에 위반된다고 판단되는
                          경우
                        </li>
                      </ol>
                    </li>
                    <li>
                      2) 회사는 다음의 각 항에 해당하는 경우에는 이용신청에 대한 승인 제한 사유가
                      해소될 때까지 승인을 제한할 수 있습니다.
                      <ol>
                        <li>
                          (1) 서비스 설비가 부족하여 만족스러운 서비스를 제공할 수 없다고 판단될
                          경우
                        </li>
                        <li>(2) 서비스 상의 장애가 발생한 경우</li>
                        <li>
                          3) 이용자가 유료서비스 결제를 진행한 경우, 결제 취소를 신청할 수 없습니다.
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h4>제 9조 (이의신청)</h4>
                  <ol>
                    <li>
                      1) 이용자는 유료서비스 구매, 결제 등에 이의가 있을 경우에는 고객센터를 통해
                      이의신청을 할 수 있습니다.
                    </li>
                    <li>
                      2) "회사"는 전항의 이의신청 접수 후 10일 이내에 타당성 여부를 조사하고, 그
                      결과를 이용자에게 통지합니다.
                    </li>
                    <li>
                      3) 부득이한 사유로 전항의 기간 내에 이의신청결과를 통지할 수 없을 경우에는 그
                      사유와 재지정된 처리기한을 명시하여 이를 이용자에게 통지합니다.
                    </li>
                  </ol>

                  <h4>제 10조 (계약 해지·해제)</h4>
                  <ol>
                    <li>
                      1) 회사 또는 이용자는 다음 각호의 어느 하나에 해당하는 경우 유료서비스 관련
                      계약을 해제 또는 해지할 수 있습니다.
                      <ol>
                        <li>(1) 이용자가 회사와 합의한 해제·해지사유가 발생한 경우</li>
                        <li>(2) 법률에서 정한 해제·해지사유가 발생한 경우</li>
                      </ol>
                    </li>
                    <li>
                      2) 회사가 유료서비스를 공급하지 않거나 불완전한 서비스를 공급한 경우에 있어서
                      이용자가 당해 서비스계약을 해제 또는 해지하기 위하여는 상당한 기간을 정하여
                      완전한 서비스를 공급할 것을 최고하여야 합니다. 다만, 이용자는 다음 각호의 어느
                      하나에 해당하는 경우 최고를 하지 않고 계약을 해제 또는 해지할 수 있습니다
                      <ol>
                        <li>
                          (1) 일정한 시일 또는 기간 내에 서비스를 공급하여야만 그 목적을 달성할 수
                          있는 계약에서 회사가 그 시기에 서비스를 공급하지 아니한 경우
                        </li>
                        <li>(2) 회사가 미리 서비스를 공급하지 않을 의사를 표시한 경우</li>
                        <li>
                          (3) 완전한 서비스의 공급이 불가능한 경우(서비스 구입목적을 달성할 수 없는
                          경우에 한함)
                        </li>
                        <li>
                          (4) 서비스계약이 계속 거래에 해당하는 경우 기타 법률에 규정되거나 회사와
                          당사자가 합의한 경우
                        </li>
                        <li>(5) 기타 법률에 규정되거나 회사와 당사자가 합의한 경우</li>
                      </ol>
                    </li>
                  </ol>

                  <h4>제 11조 (계약 해지·해제의 효과)</h4>
                  <ol>
                    <li>
                      1) 회사 또는 이용자가 서비스계약을 해제한 경우, 이용자는 회사에게 서비스를
                      반환하여야 합니다. 다만, 이용자가 서비스를 이용한 경우 그 사용에 따라 얻은
                      이익을 반환하여야 합니다. 회사 또는 이용자는 해제권의 행사와 별도로 상대방에게
                      손해배상을 청구할 수 있습니다. 다만, 상대방이 손해배상책임을 면하기 위해서는
                      자신에게 고의 또는 과실 없음을 증명하여야 합니다.
                    </li>
                    <li>
                      2) 회사 또는 이용자가 서비스계약을 해지한 경우에 당해 서비스계약은 해지한 시점
                      이후부터 효력을 잃으며, 회사 또는 이용자는 해지권의 행사와 별도로 상대방에게
                      손해배상을 청구할 수 있습니다. 다만, 상대방이 손해배상책임을 면하기 위해서는
                      자신에게 고의 또는 과실 없음을 증명하여야 합니다.
                    </li>
                  </ol>

                  <h4>제 12조 (양도금지)</h4>
                  <p>
                    이용자의 약관 상의 권리는 이를 양도하거나 증여할 수 없으며, 질권의 목적으로
                    사용할 수 없습니다.
                  </p>

                  <h4>제 13조 (환전) *디지털화환 -{'>'} 현금</h4>
                  <p>회사는 회원(상주)가 받은 후원을 현금으로 환전하여 지불합니다.</p>
                  <ol>
                    <li>
                      1) 환전이 가능한 유료서비스는 이용자가 회사의 정책 안내에 따라 신청하여야
                      하며, 회사는 세금과 수수료 등을 공제한 금액을 이용자에게 지급합니다.
                      <ol>
                        <li>(1) 후원 받은 화환의 유효기간은 받은 시점으로부터 1년간 입니다.</li>
                        <li>
                          (2) 비 실명 계정은 환전을 할 수 없으며, 실명 인증 후 환전신청이
                          가능합니다.
                        </li>
                        <li>
                          (3) 회원(상주)은 관련 세금 및 수수료를 공제한 후 화환 금액의 90%를
                          받습니다. 신용카드 수수료 등을 포함한 모든 거래비용은 회사에서 부담합니다.
                        </li>
                      </ol>
                    </li>
                    <li>
                      2) 회사는 이용자가 부정, 위법한 목적, 약관 기타 서비스 취지에 반하는 방법으로
                      유료서비스를 구매, 이용한 경우에는 결제 승인을 취소하거나 그 환전, 환불 등을
                      거절, 정지할 수 있으며 해당 유료서비스를 반환, 회수할 수 있습니다.
                    </li>
                    <li>
                      3) 환전의 제한은 정상적인 이용이라는 점이 밝혀지거나 결제 대금이 정상 납부될
                      경우에는 해제될 수 있습니다.
                    </li>
                  </ol>

                  <h4>제 14조 (환불)</h4>
                  <ol>
                    <li>
                      1) 이용자가 아닌 회원 또는 비회원이 결제한 유료 서비스에 대해 환불을 할 수
                      없습니다.
                    </li>
                  </ol>

                  <h4>제 15조 (요금 등의 반환)</h4>
                  <ol>
                    <li>
                      1) "회사"는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금
                      전액을 환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를
                      사전에 고지하고, 이용자가 선택한 방법으로 환급합니다.
                    </li>
                    <li>
                      2) 전항에 따라 회사가 잔여액을 반환할 경우 회사는 반환의무가 있음이 확정된
                      날로부터 3영업일 이내에 과오금을 환불하는 것으로 합니다. 다만, 회사는 이용자의
                      귀책사유에 의하여 반환의무가 발생하는 경우에 한하여 결제 수수료 등 입금에 따라
                      발생한 비용을 공제하고 반환할 수 있습니다.
                    </li>
                    <li>
                      3) 이용약관 제19조 제2항 등의 경우 이와 관련된 요금 등은 반환하지 않습니다.
                    </li>
                  </ol>

                  <h4>제 16조 (서비스하자 등에 의한 회원피해보상)</h4>
                  <ol>
                    <li>
                      1) 회사의 자체 서비스의 중대한 하자에 의하여 유료로 구입한 서비스가 손상 또는
                      훼손되거나 삭제된 경우, 이용자는 그에 상응하는 적절한 수단을 통해 보상 받을 수
                      있습니다.
                    </li>
                    <li>
                      2) 사업자가 서비스의 중지·장애에 대하여 사전 고지한 경우에 있어서 이용자의
                      피해구제 등은 다음 각호에 의합니다. 다만, 서비스 개선을 목적으로 한 설비 점검
                      및 보수 시 1개월을 기준으로 최대 24시간은 중지·장애 시간에 포함하지 않습니다.
                      <ol>
                        <li>
                          (1) 1개월을 기준으로 서비스 중지·장애시간이 10시간을 초과하는 경우 :
                          10시간과 이를 초과한 시간의 2배의 시간만큼 이용기간을 무료로 연장
                        </li>
                        <li>
                          (2) 1개월을 기준으로 서비스 중지·장애시간이 10시간을 초과하지 않은 경우 :
                          중지·장애 시간에 해당하는 시간을 무료로 연장
                        </li>
                      </ol>
                    </li>
                    <li>
                      3) 제2항 및 제3항을 적용함에 있어서 사전고지는 서비스 중지, 장애 시점을
                      기준으로 24시간 이전에 고지된 것에 한합니다.
                    </li>
                  </ol>

                  <h4>제 17조 (면책조항)</h4>
                  <ol>
                    <li>
                      1) "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "유료서비스"를
                      제공할 수 없는 경우에는 "유료서비스" 제공에 관한 책임이 면제됩니다.
                    </li>
                    <li>
                      2) "회사"는 이용자의 귀책사유로 인한 유료서비스 이용의 장애에 대하여는 회사의
                      고의 또는 중과실이 없는 한 책임을 지지 않습니다
                    </li>
                    <li>
                      3) "회사"는 이용자가 "유료서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
                      정확성 등의 내용에 관하여는 회사의 고의 또는 중과실이 없는 한 책임을 지지
                      않습니다.
                    </li>
                    <li>
                      4) "회사"는 이용자 상호간 또는 이용자와 제3자 간에 "유료서비스"를 매개로 하여
                      발생한 분쟁 등에 대하여 회사의 고의 또는 중과실이 없는 한 책임을 지지
                      않습니다.
                    </li>
                    <li>
                      5) "회사”는 이용자가 유료서비스를 이용하여 기대하는 수익을 상실한 것에 대하여
                      회사의 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
                    </li>
                  </ol>

                  <h4>제 18조 (관할법원)</h4>
                  <ol>
                    <li>
                      1) 서비스 이용에 관하여 회사와 이용자 간에 이견 또는 분쟁이 있는 경우, 양
                      당사자간의 합의에 의해 원만히 해결해야 합니다.
                    </li>
                    <li>
                      2) 전항으로 해결되지 아니할 경우 소송의 관할은 민사소송법에 따라 정합니다.
                    </li>
                    <li>3) 본 약관의 해석 및 적용은 대한민국 법령을 기준으로 합니다.</li>
                  </ol>

                  <h3>부칙</h3>
                  <p>본 약관은 2022년 02월 10일부터 적용됩니다.</p>
                </div>
              </div>
              <div class="modal__footer">
                <button type="button" class="btn btn-blue" onClick={onClose} data-micromodal-close>
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ServiceDialog;

import { useState } from 'react';

import useDialog from 'stores/dialog/hook';

import { useNavigate } from 'react-router-dom';
import { findEmailApi } from 'apis/member';
import { notiAuthSendApi, notiAuthVerifyApi } from 'apis/openapi';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

function FindIdPage({ onChange, closeDialog }) {
  const { showAlert, hideAlert } = useDialog();
  const navigate = useNavigate();
  const schema = Yup.object({
    member_name: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    mobile: Yup.string()
      .required('* 필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '* 핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
    code: Yup.string()
      .required('* 필수입력 항목입니다.')
      .min(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.')
      .max(6, '* 핸드폰으로 전송받은 6자리 인증코드를 입력하세요.'),
  }).required();

  const defaultValues = {
    member_name: '',
    mobile: '',
    id: 0,
    code: '',
    verified: 'N',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [findId, setFindId] = useState(null);

  const onSubmit = data => {
    if (getValues('verified') !== 'Y') {
      showAlert('알림', '인증코드를 확인하세요.', () => hideAlert());
    } else {
      findEmail(data);
    }
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleNotiAuthSend = () => {
    if (!getValues('mobile') || getValues('mobile') === '' || errors.mobile) {
      showAlert('알림', '휴대폰 번호를 확인하세요.', () => hideAlert());
    } else {
      notiAuthSend();
    }
  };

  const notiAuthSend = async () => {
    const params = { send_type: 'S', phone_num: getValues('mobile') };
    const result = await notiAuthSendApi(params);
    setValue('id', result.id);
    showAlert('알림', '문자로 발송된 인증코드를 입력하세요.', () => hideAlert());
  };

  const handleNotiAuthVerify = () => {
    if (!getValues('code') || getValues('code') === '' || errors.code) {
      showAlert('알림', '인증번호를 확인하세요.', () => hideAlert());
    } else {
      notiAuthVerify();
    }
  };

  const notiAuthVerify = async () => {
    const params = { id: getValues('id'), code: getValues('code') };
    const result = await notiAuthVerifyApi(params);
    if (result.code === '0000') setValue('verified', 'Y');
    showAlert('알림', result.message, () => hideAlert());
  };

  const findEmail = async () => {
    const params = { member_name: getValues('member_name'), mobile: getValues('mobile') };
    const result = await findEmailApi(params);
    if (result.code === '0000') {
      setFindId(result.data);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  return (
    <>
      <section className="container" id="containerWrap">
        {/* S : CONTENT */}
        <div className="content">
          <div className="visual-wrap">
            <div className="contents-width">
              <h2 className="title">아이디 찾기 </h2>
            </div>
          </div>
          <div className="contents-width">
            {/* S : 회원가입 */}
            <div className="member-form">
              <h2 className="offscreen">FIND ID</h2>
              <h3 className="title">아이디 찾기</h3>
              <form>
                <fieldset>
                  <legend>아이디 찾기</legend>
                  {/* S : 인증 전 */}
                  <div className="ip-item is-error">
                    <label for="user-name" className="offscreen">
                      이메일 입력
                    </label>
                    <input
                      {...register('member_name')}
                      type="text"
                      id="user-name"
                      placeholder="이름"
                      aria-invalid={errors.email ? 'true' : 'false'}
                    />
                    {errors.member_name && <p className="message">{errors.member_name.message}</p>}
                  </div>
                  <div className="ip-wrap ip-certify-number has-btn">
                    <div className="ip-item is-error">
                      <label for="user-mobile" className="offscreen">
                        휴대폰 번호 입력
                      </label>
                      <input
                        {...register('mobile')}
                        type="number"
                        id="user-mobile"
                        placeholder="휴대폰 번호"
                        aria-invalid={errors.mobile ? 'true' : 'false'}
                      />
                      <button
                        type="button"
                        className="btn btn-sm-gray"
                        onClick={handleNotiAuthSend}
                      >
                        인증번호 전송
                      </button>
                      {errors.mobile && <p className="message">{errors.mobile.message}</p>}
                    </div>
                  </div>
                  {/* E : 인증 전 */}

                  {/* S : 인증 후 */}
                  <div className="ip-wrap ip-certify-number has-btn">
                    <div className="ip-wrap">
                      <div className="ip-item  is-error">
                        {/* 에러 메세지 출력시 class="is-error" 추가 */}
                        <label for="user-certify" className="offscreen">
                          인증번호 입력
                        </label>
                        <input
                          {...register('code')}
                          type="text"
                          id="user-certify"
                          placeholder="인증번호"
                          aria-invalid={errors.code ? 'true' : 'false'}
                        />
                        {/* <span className="timer">남은시간 : 2분 32초</span> */}
                        <button
                          type="button"
                          className="btn btn-sm-blue"
                          onClick={handleNotiAuthVerify}
                        >
                          인증코드 확인
                        </button>
                        {errors.code && <p className="message">{errors.code.message}</p>}
                      </div>
                    </div>
                  </div>
                  {/* E : 인증 후 */}

                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-blue"
                      data-micromodal-trigger="modal-sample-02"
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      아이디 찾기
                    </button>
                    {/* 필수값 생략 등일 때는 disabled 추가 */}
                  </div>
                  {findId && findId.length > 0
                    ? findId.map(id => <div key={id}>회원님의 아이디는 [{id}]입니다.</div>)
                    : null}
                  <ul className="login-util">
                    <li>
                      <a href={() => false} onClick={() => navigate('/pet/login')}>
                        로그인
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => navigate('/pet/login/findid')}>
                        아이디 찾기
                      </a>
                    </li>
                    <li>
                      <a href={() => false} onClick={() => navigate('/pet/login/findpw')}>
                        비밀번호 초기화
                      </a>
                    </li>
                  </ul>
                </fieldset>
              </form>
            </div>
            {/* E : 회원가입 */}
          </div>
        </div>
        {/* E : CONTENT */}
      </section>
    </>
  );
}

export default FindIdPage;

import { useState, useRef, useEffect, forwardRef } from 'react';

import { useFieldArray, Controller } from 'react-hook-form';

import useLayout from 'stores/layout/hook';

import useDialog from 'stores/dialog/hook';

import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { saveCanvasBltApi } from 'apis/canvasBlt';

import useLogin from 'stores/login/hook';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

function UserQnaDialog({ isOpen, onClose, canvas, reflash }) {
  const navigate = useNavigate();

  const submitRef = useRef();
  const { showLifeAlert, hideLifeAlert, showLifeConfirm, hideLifeConfirm } = useDialog();

  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
  const { setGnbType } = useLayout();

  const { loginUser } = useLogin();

  const schema = Yup.object({
    canvas_id: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_type: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_title: Yup.string().required('* 필수 입력 항목입니다.'),
    display_yn: Yup.string().required('* 필수 입력 항목입니다.'),
    blt_contents: Yup.string().required('* 필수 입력 항목입니다.'),
    member_id: Yup.string().required('* 필수 입력 항목입니다.'),
    faqs: Yup.array().of(
      Yup.object().shape({
        questions: Yup.string().required('* 질문을 입력하세요.'),
        // answer: Yup.string().required('* 답변을 입력하세요.'),
      }),
    ),
  }).required();

  const defaultValues = {
    canvas_id: '',
    blt_type: '3',
    blt_title: '문답',
    display_yn: 'Y',
    goalstatus: 'Y',
    goalcomment: '',
    blt_contents: '',
    blt_anonymous_yn: 'N',
    member_id: '',
    member_name: '',
  };

  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [anonymousYn, setAnonymousYn] = useState(false);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'faqs',
  });

  const handleAppend = () => {
    append({ questions: '', answer: '' });
  };

  const handleChangeAnonymousYn = value => {
    setValue('blt_anonymous_yn', value ? 'Y' : 'N');
    setAnonymousYn(value);
  };

  const checkSubmit = () => {
    setValue('blt_contents', getValues('faqs') ? JSON.stringify(getValues('faqs')) : '');
    setValue('');
    submitRef.current.click();
  };

  const onSubmit = data => {
    saveCanvasBlt(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const confirmClose = () => {
    // onClose();

    // callback 받을 함수
    hideLifeAlert();
    reflash();
  };

  const moveLogin = () => {
    hideLifeAlert();
    navigate('/life/login');
  };

  const saveCanvasBlt = async params => {
    const result = await saveCanvasBltApi(params);

    if (result.code === '0000') {
      showLifeAlert('알림', '질문 등록이 완료되었습니다.', () => confirmClose());
    } else {
      showLifeAlert('알림', result.message, () => hideLifeAlert());
    }
  };

  const init = async () => {
    if (loginUser && !loginUser.member_id)
      showLifeAlert('알림', '로그인 후 이용가능합니다.', () => moveLogin());
    if (loginUser?.member_id) setValue('member_id', loginUser.member_id);
    if (loginUser?.member_name) setValue('member_name', loginUser.member_name);
    setValue('canvas_id', canvas.canvas_id);
    //console.log(loginUser.canvas_id);
    //console.log(JSON.stringify(canvas));
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fields && fields.length === 0) handleAppend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      <div className="wrap" id="lifeCanvas">
        <div className="modal" id="modal-qna" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">질문 남기기</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  data-micromodal-close
                  onClick={onClose}
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              {fields &&
                fields.length > 0 &&
                fields.map((content, index) => (
                  <div className="modal__content" key={index}>
                    {canvas.anonymous_yn === 'Y' ? (
                      <div className="rc-item rc-toggle">
                        <input type="checkbox" id="ip-public11" />
                        <label
                          for="ip-public11"
                          onClick={() => handleChangeAnonymousYn(anonymousYn ? false : true)}
                        >
                          익명으로 질문하기 <span className="toggle"></span>
                        </label>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="ip-item">
                      <label for="ip-subject" className="offscreen">
                        관계명 입력
                      </label>
                      <input
                        type="text"
                        id="ip-subject"
                        placeholder="관계를 입력해주세요"
                        {...register('goalcomment')}
                      />
                    </div>

                    <div className="ip-item">
                      <label for="textarea-01" className="offscreen">
                        질문 내용 입력
                      </label>
                      <Controller
                        control={control}
                        name={`faqs.${index}.questions`}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            id="textarea-01"
                            rows="5"
                            cols="3"
                            placeholder="무엇을 질문하고 싶나요?"
                          />
                        )}
                      />
                    </div>
                  </div>
                ))}
              <div className="modal__footer">
                <button
                  type="button"
                  className="btn btn-white"
                  data-micromodal-close
                  onClick={onClose}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => checkSubmit()}
                  data-micromodal-close
                >
                  올리기
                </button>
                <button
                  ref={submitRef}
                  type="button"
                  className="btn btn-green"
                  onClick={handleSubmit(onSubmit, onError)}
                  style={{ display: 'none' }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserQnaDialog;

import { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import { getMemorialApi } from 'apis/memorial';

import parse from 'html-react-parser';

import TabBltContent from 'pages/memorial-canvas/obituary/components/TabBltContent';
import WreathContent from 'pages/memorial-canvas/obituary/components/WreathContent';
import CondolatoryDialog from 'pages/memorial-canvas/obituary/components/CondolatoryDialog';
import WreathDialog from 'pages/memorial-canvas/obituary/components/WreathDialog';
import WreathCustomDialog from 'pages/memorial-canvas/obituary/components/WreathCustomDialog';

import SharedDialog from 'pages/memorial-canvas/obituary/components/SharedDialog';

import noDeceasedPhoto from 'assets/images/no_img_obituary.jpg';

import MediaViewDialog from 'pages/memorial-canvas/obituary/components/MediaViewDialog';
import FuneralViewDialog from './components/FuneralViewDialog';
import TabChudosasContent from './components/TabChudosasContent';
import TabAlbumContent from '../memorial/components/TabAlbumContent';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import TabCondolencesGroupContent from './components/TabCondolencesGroupContent';

function ObituaryViewPage() {
  const navigate = useNavigate();

  const { memorialId } = useParams();

  const { state } = useLocation();
  const tabElement = useRef(null);

  const { setGnbType } = useLayout();
  const { showAlert, hideAlert } = useDialog();
  const { loginUser } = useLogin();

  const [loading, setLoading] = useState(true);
  const [obituary, setObituary] = useState(null);
  const [deceasedName, setDeceasedName] = useState(null);
  const [ownMemberId, setOwnMemberId] = useState(null);
  const [relations, setRelations] = useState(null);
  const [residents, setResidents] = useState(null);
  const [condolatorys, setCondolatorys] = useState(null);
  const [blts, setBlts] = useState(null);
  const [chudosas, setChudosas] = useState(null);
  const [currentLastUrl, setCurrentLastUrl] = useState(null);

  const [bltContents, setBltContents] = useState(null);
  const [chudosaContents, setChudosaContents] = useState(null);
  const [hall, setHall] = useState(null);
  const [funeralAddr, setFuneralAddr] = useState(null);
  const [url, setUrl] = useState('');

  const [tabIndex, setTabIndex] = useState(1);
  const [tabBlt, setTabBlt] = useState(false);
  const [tabState, setTabState] = useState(false);

  const [openSharedDialog, setOpenSharedDialog] = useState(false);
  const [openCondolatoryDialog, setOpenCondolatoryDialog] = useState(false);
  const [openWreathDialog, setOpenWreathDialog] = useState(false);
  const [openWreathCustomDialog, setOpenWreathCustomDialog] = useState(false);
  const [openFuneralViewDialog, setOpenFuneralViewDialog] = useState(false);
  const [openLoadingDialog, setOpenLoadingDialog] = useState(false);

  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);

  const [isActive, setActive] = useState(false);
  const [count, setCount] = useState(0);

  const getObituary = async params => {
    setOpenLoadingDialog(true);
    const result = await getMemorialApi(params);
    setOpenLoadingDialog(false);

    if (
      result.data.display_yn === 'Y' &&
      result.data.status === '2' &&
      result.data.multy_yn !== 'Y'
    ) {
      handleChange();
    }

    if (
      result.type === 'error' ||
      result.data.display_yn === 'N'
      //(result.data.status === '2' && result.data.multy_yn !== 'Y')
    ) {
      // showAlert('알림', '존재하지 않는 부고입니다.', () => handleChange());
      if (!loginUser || loginUser.member_id !== result.data.own_member_id) {
        showAlert('알림', '존재하지 않는 부고입니다.', () => handleClose());
        return null;
      }
    }

    setObituary(result.data);
    setRelations(result.relations_datas);
    setBlts(result.blt_datas);
    setChudosas(result.chudosa_datas);
    setCondolatorys(result.condolatory_datas);
    setLoading(false);
    setUrl(result.data.deceased_video_link);
  };

  const handleClose = () => {
    hideAlert();
    navigate('/');
  };

  const handleChange = () => {
    // showAlert('알림', '발인일 기준 일주일이 경과하여 부고 페이지가 추모관으로 이동되었습니다. 추모관으로 이동합니다', () => handleClose());
    navigate(`/memorial/memorial/view/${memorialId}`);
  };

  const handleModify = () => {
    navigate(`/memorial/obituary/modify/${memorialId}`);
  };

  const selectedReceiver = receiver => {
    //setOpenWreathDialog(false);
    navigate('/memorial/obituary/wreath', {
      state: { memorial_id: memorialId, receiver: receiver },
    });
  };

  const handleOpenWreathDialog = () => {
    if (loginUser && loginUser.member_id) {
      setOpenWreathDialog(true);
    } else {
      navigate('/memorial/login');
    }
  };

  const handleOpenWreathCustomDialog = () => {
    if (loginUser && loginUser.member_id) {
      setOpenWreathCustomDialog(true);
    } else {
      navigate('/memorial/login');
    }
  };

  const media = {
    media_type: 'V',
    media_link: url,
  };
  const handleMediaView = () => {
    setOpenMediaViewDialog(true);
  };

  const [isReload, setReload] = useState(false);
  const handleReload = () => {
    setReload(true);
    setOpenWreathCustomDialog(false);
    setReload(false);
  };
  const homeRef = useRef();
  const onHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const moveUrl = () => {
    navigate(`/memorial/letter/${memorialId}`, {
      state: {
        // memorialId: memorialId,
        ownMemberId: obituary.own_member_id,
        deceasedName: deceasedName,
      },
    });
  };

  const scrollTo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onClickTab = index => {
    if (tabIndex === index && index !== 1) {
      setTabState(!tabState);
      return;
    }
    if (tabBlt === true) {
      window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    }
    setTabIndex(index);
  };

  useEffect(() => {
    setResidents(
      relations && relations.length > 0
        ? relations.reduce(
            (accumulator, currentObject, index) =>
              `${
                index > 0 && currentObject.relations_name !== ''
                  ? accumulator + ', '
                  : currentObject.relations_name === ''
                  ? ''
                  : ','
              } ${currentObject.relations_detail} ${currentObject.relations_name}`,
            '',
          )
        : '',
    );
    //console.log(JSON.stringify(relations));
  }, [relations]);

  useEffect(() => {
    setBltContents(
      blts && blts.length > 0
        ? blts.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [blts]);

  useEffect(() => {
    setChudosaContents(
      chudosas && chudosas.length > 0
        ? chudosas.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [chudosas]);

  useEffect(() => {
    setHall(obituary && obituary.hall_data ? obituary.hall_data : null);
    setFuneralAddr(obituary && obituary.funeral_addr_yn ? obituary.funeral_addr_yn : null);
    setDeceasedName(obituary && obituary.deceased_name ? obituary.deceased_name : null);
    setOwnMemberId(obituary && obituary.own_member_id ? obituary.own_member_id : null);
  }, [obituary]);

  useEffect(() => {
    setGnbType('sub_gnb');
    getObituary(memorialId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state && state.viewTab) {
      setTabIndex(state.viewTab);
      tabElement.current?.scrollIntoView({ block: 'center' });
      console.log('scroll into ...');
    }
  });

  const xy = useRef(null);

  useEffect(() => {
    if (tabBlt === true) {
      window.scrollTo({ top: xy.current.offsetTop - 100, behavior: 'smooth' });
    }
  }, [tabBlt]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">{deceasedName}님 부고</h2>
          </div>
        </div>

        {/* E : 서브 비주얼 + 타이틀 */}
        {/* S : 23-04-21 메모리얼 캔버스 개편에 따른 마크업 수정 */}
        {/*
         *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
         */}
        <div className="fixed-list is-obituary" style={{ top: '0' }}>
          <ul>
            <li className={tabIndex === 1 && tabBlt === true ? 'is-active' : ''}>
              <button
                type="button"
                onClick={() => {
                  onClickTab(1);
                  setTabBlt(true);
                }}
              >
                일대기
              </button>
            </li>
            <li className={tabIndex === 2 ? 'is-active' : ''}>
              <button
                type="button"
                onClick={() => {
                  onClickTab(2);
                  setTabBlt(false);
                }}
              >
                추도사
              </button>
            </li>
            <li className={tabIndex === 3 ? 'is-active' : ''}>
              <button
                type="button"
                onClick={() => {
                  onClickTab(3);
                  setTabBlt(false);
                }}
              >
                추억앨범
              </button>
            </li>
            <li className={tabIndex === 4 ? 'is-active' : ''}>
              {/*<button type="button" onClick={() => moveUrl()}>*/}
              {/*<button type="button">*/}
              {/*  <Link to={`/memorial/letter/${memorialId}`}>추모의 글</Link>*/}
              {/*</button>*/}
              <button
                type="button"
                onClick={() => {
                  onClickTab(4);
                  setTabBlt(false);
                  setCount(count + 1);
                }}
              >
                추모의 글
              </button>
            </li>
          </ul>
        </div>

        <div className="wrap-detail">
          {/* S : 영정 사진 */}
          {/* S : 사진 없을 때 */}
          {/*
           *** Desc : 부고일 때 "is-obituary" / 추모관 일때 "is-remembrance"
           */}
          {obituary ? (
            <>
              {obituary.deceased_photo ? (
                <div className="wrap-portrait is-obituary has-portrait">
                  <div className="box-title">
                    <h3 className="title">세상에 남겨진 오직 한 사람의 이야기</h3>
                    <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                  </div>
                  <div className="box-portrait">
                    <img
                      src={obituary.deceased_photo ? obituary.deceased_photo : noDeceasedPhoto}
                      alt=""
                    />
                    {/*<img src="https://images.unsplash.com/photo-1648183185045-7a5592e66e9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1084&q=80" />*/}
                    {/*<img src="../../static/media/user_no_portrait_obituary.png" />*/}
                  </div>
                </div>
              ) : (
                <div className="wrap-portrait is-obituary">
                  <div className="box-title">
                    <h3 className="title">
                      세상에 남겨진 <br className="d-md-block d-none" /> 오직 한 사람의 이야기
                    </h3>
                    <p className="desc">언제 어디서나 마음 속 이야기를 전하세요</p>
                  </div>
                </div>
              )}
              {/* E : 사진 있을 때 */}
              {/* E : 영정 사진 */}

              {/* S : 고인 정보 */}
              <div className="wrap-deceased contents-width">
                <div className="box-name">
                  <h4 className="name">{`${obituary.deceased_name} ${obituary.deceased_job_title} `}</h4>
                  {loginUser &&
                  loginUser.member_id === obituary.own_member_id &&
                  obituary.status === '1' ? (
                    <a href={() => false} className="btn-modify" onClick={() => handleModify()}>
                      수정
                    </a>
                  ) : null}
                  {obituary.display_yn === 'N' ? (
                    <span className="icon">
                      <i className="ic ic-secret"></i>
                      <span className="offscreen">비공개 글</span>
                    </span>
                  ) : (
                    ''
                  )}
                  <div className="btn-wrap">
                    <button
                      type="button"
                      className="btn btn-sm-ln-blue"
                      onClick={() => window.open(`/memorial/obituary/view/${memorialId}`, '_blank')}
                      style={{
                        fontSize: '12px',
                        marginLeft: '10px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                    >
                      부고 바로가기
                    </button>
                  </div>
                </div>
                {obituary.deceased_birthday !== '' && obituary.deceased_date !== '' ? (
                  <p className="birth">{`${obituary.deceased_birthday} ~ ${obituary.deceased_date}`}</p>
                ) : obituary.deceased_birthday !== '' && obituary.deceased_date === '' ? (
                  <p className="birth">{`${obituary.deceased_birthday} ~ `}</p>
                ) : obituary.deceased_birthday === '' && obituary.deceased_date !== '' ? (
                  <p className="birth">{`~ ${obituary.deceased_date}`}</p>
                ) : (
                  ''
                )}

                {/* S : 버튼 */}
                <div className="btns">
                  <ul>
                    {obituary.delivery_yn === 'Y' ? (
                      <li>
                        <button type="button" onClick={() => setOpenSharedDialog(true)}>
                          <i className="ic ic-send-obituary"></i>부고보내기
                        </button>
                      </li>
                    ) : (
                      ''
                    )}
                    {/*{condolatorys &&*/}
                    {/*  condolatorys.length > 0 &&*/}
                    {/*  condolatorys.map(condolatory =>*/}
                    {/*    condolatory.account_number !== '' || condolatory.bank_name !== '' ? (*/}
                    {/*      obituary.condolatory_yn === 'Y' ? (*/}
                    {/*        <li>*/}
                    {/*          <button type="button" onClick={() => setOpenCondolatoryDialog(true)}>*/}
                    {/*            <i className="ic ic-condolence-money"></i>조의금전달*/}
                    {/*          </button>*/}
                    {/*        </li>*/}
                    {/*      ) : (*/}
                    {/*        ''*/}
                    {/*      )*/}
                    {/*    ) : (*/}
                    {/*      ''*/}
                    {/*    ),*/}
                    {/*  )}*/}
                    {obituary.condolatory_yn === 'Y' ? (
                      condolatorys &&
                      condolatorys.length > 0 &&
                      condolatorys[0].account_number !== '' &&
                      condolatorys[0].bank_name !== '' &&
                      condolatorys[0].applicant_name !== '' ? (
                        <li>
                          <button type="button" onClick={() => setOpenCondolatoryDialog(true)}>
                            <i className="ic ic-condolence-money"></i>조의금전달
                          </button>
                        </li>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                    {obituary.digital_wreath_yn === 'Y' && obituary.calculate_request_yn === 'N' && (
                      <>
                        {/* 운영반영 */}
                        <li>
                          {1 === 2 && (
                            <button type="button">
                              <i
                                className="ic ic-digital-wreath"
                                onClick={() => handleOpenWreathDialog()}
                              ></i>
                              디지털화환
                            </button>
                          )}
                          <button type="button" onClick={() => handleOpenWreathCustomDialog()}>
                            <i className="ic ic-digital-wreath"></i>디지털화환
                          </button>
                        </li>

                        {/* 개발반영 */}
                        {/*<li>*/}
                        {/*  */}
                        {/*      <button type="button">*/}
                        {/*        <i className="ic ic-digital-wreath"  onClick={() => selectedReceiver(obituary.chief_mourner)}></i>디지털화환*/}
                        {/*      </button>*/}

                        {/*</li>*/}
                      </>
                    )}
                  </ul>
                </div>
                {/* E : 버튼 */}
              </div>
              {/* E : 고인 정보 */}

              {/* S : 장례식장, 알림글 */}
              <div className="wrap-funeral-hall contents-width">
                <div className="box-funeral-hall">
                  <dl>
                    {obituary.chief_mourner || obituary.chief_mourner_desc ? (
                      <>
                        <dt>상주</dt>
                        <dd className="is-full">
                          {obituary.chief_mourner_desc ? `${obituary.chief_mourner_desc}` : ''}{' '}
                          {obituary.chief_mourner ? `${obituary.chief_mourner} ` : ''}
                          {residents}
                        </dd>
                      </>
                    ) : (
                      ''
                    )}
                    {obituary.funeral_addr_yn === 'Y' && obituary.funeral_addr !== ' ' ? (
                      <>
                        <dt>빈소</dt>
                        <dd className="is-full">
                          {hall && hall.hall_name}{' '}
                          {obituary.funeral_room ? obituary.funeral_room : ''}
                          {hall && hall.hall_name && hall.hall_addr ? (
                            <button
                              type="button"
                              className="btn btn-sm-gray"
                              onClick={() => setOpenFuneralViewDialog(true)}
                            >
                              오시는길
                            </button>
                          ) : (
                            ''
                          )}
                        </dd>
                      </>
                    ) : (
                      ''
                    )}
                    {obituary.birth_yn === 'Y' && obituary.birth_date !== '' ? (
                      <>
                        <dt>발인</dt>
                        <dd>
                          {obituary.birth_date} {obituary.birth_hour}
                        </dd>
                      </>
                    ) : (
                      ''
                    )}
                    {obituary.funeral_yn === 'Y' && obituary.funeral_place !== '' ? (
                      <>
                        <dt>장지</dt>
                        <dd>{obituary.funeral_place}</dd>
                      </>
                    ) : (
                      ''
                    )}
                    {obituary.chief_funeral_yn === 'Y' && obituary.chief_funeral_nm !== '' ? (
                      <>
                        <dt>장례위원장</dt>
                        <dd>{obituary.chief_funeral_nm}</dd>
                      </>
                    ) : (
                      ''
                    )}
                  </dl>
                </div>
                {obituary.deceased_introduction !== '' ? (
                  <>
                    <div className="box-funeral-hall">
                      <h5 className="title">알림글</h5>
                      <p>
                        {obituary.deceased_introduction &&
                          parse(obituary.deceased_introduction.replace(/(\n|\r\n)/g, '<br />'))}
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              {/* E : 장례식장, 알림글  */}

              {/* S : 추모 영상 */}
              {obituary.deceased_video_yn === 'Y' && obituary.deceased_video_link !== '' ? (
                <div className="wrap-remembrance-vod">
                  <div className="contents-width">
                    <div className="box-title">
                      <h4 className="title">추모 영상</h4>
                      <p className="desc">고인의 삶을 영상으로 담아 추억합니다.</p>
                    </div>
                    <div className="box-vod">
                      <div className="inner-item">
                        {obituary.video_thumbnail ? (
                          <video className="video" poster={obituary.video_thumbnail} />
                        ) : (
                          <video
                            className="video"
                            poster="https://plus.unsplash.com/premium_photo-1675826774817-fe983ceb0475?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                          />
                        )}
                        {/*  <source src="../../static/media/temp/sample.mp4" type="video/mp4" />*/}
                        {/*</video>*/}
                        <button
                          type="button"
                          className="btn-vod-play is-show"
                          onClick={() => handleMediaView()}
                        >
                          <span className="offscreen">추모영상 재생하기</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ' '
              )}
              {/* E : 추모 영상 */}

              {/* S : 디지털 화환 */}
              {obituary.digital_wreath_yn === 'Y' && !isReload && (
                <WreathContent memorialId={memorialId} message={state ? state.message : null} />
              )}
              {/* E : 디지털 화환 */}

              {/* S : 부고/추모관 공통 컨텐츠 */}
              {/* S : 일대기 */}
              {tabIndex === 1 && (
                <TabBltContent
                  bltContents={bltContents}
                  deceasedName={deceasedName}
                  xy={xy}
                  tabState={tabState}
                />
              )}
              {tabIndex === 2 && (
                <TabChudosasContent chudosaContents={chudosaContents} tabState={tabState} />
              )}
              {tabIndex === 3 && (
                <TabAlbumContent
                  memorialId={memorialId}
                  deceasedName={deceasedName}
                  ownMemberId={ownMemberId}
                  tabState={tabState}
                />
              )}
              {tabIndex === 4 && (
                <TabCondolencesGroupContent currentLastUrl={currentLastUrl} tabState={tabState} />
              )}
              {/* E : 일대기 */}
              <SharedDialog
                isOpen={openSharedDialog}
                onClose={() => setOpenSharedDialog(false)}
                memorial={obituary}
              />
              {condolatorys ? (
                <CondolatoryDialog
                  isOpen={openCondolatoryDialog}
                  onClose={() => setOpenCondolatoryDialog(false)}
                  condolatorys={condolatorys}
                />
              ) : null}
              <WreathDialog
                isOpen={openWreathDialog}
                onClose={() => setOpenWreathDialog(false)}
                obituary={obituary}
                relations={relations}
                selectedReceiver={selectedReceiver}
              />
              <WreathCustomDialog
                isOpen={openWreathCustomDialog}
                onClose={() => setOpenWreathCustomDialog(false)}
                obituary={obituary}
                handleReload={handleReload}
              />
              <MediaViewDialog
                isOpen={openMediaViewDialog}
                onClose={() => setOpenMediaViewDialog(false)}
                media={media}
              />
              <FuneralViewDialog
                isOpen={openFuneralViewDialog}
                onClose={() => setOpenFuneralViewDialog(false)}
                hall={hall}
                funeralAddr={funeralAddr}
              />
            </>
          ) : (
            ''
          )}
          {/* E : 부고 상세 (부고 상세만 디자인 이슈로 기존의 페이지와 컨텐츠 마크업이 다릅니다 확인해주세요) */}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={openLoadingDialog}
          //onClick={() => setOpenLoadingDialog(false)}
          backdrop="static"
        >
          <Stack gap={1} justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
            <Typography>Loading...</Typography>
          </Stack>
        </Backdrop>
      </div>

      {/* E : CONTENT */}
      <div className="wrap-quick-btn">
        {/* S : 22-12-14 모바일 top 버튼 추가 */}
        <button type="button" className="btn-go-top" onClick={() => scrollTo()}>
          <span className="offscreen">위로가기</span>
          <i className="ic ic-arrow-up"></i>
        </button>
        {/* E : 22-12-14 모바일 top 버튼 추가 */}
      </div>
    </section>
  );
}

export default ObituaryViewPage;

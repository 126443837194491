import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog } from '@mui/material';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import configs from '@configs/configs';
import useScript from 'utils/useScript';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import NotiAcountDialog from 'pages/memorial-canvas/obituary/components/NotiAcountDialog';
import useDialog from 'stores/dialog/hook';

function CondolatoryDialog({ isOpen, onClose, condolatorys }) {
  const { pathname } = useLocation();
  const currentUrl = `${configs.BASEURL}${pathname.replace('/board', '')}`;
  const { showAlert, hideAlert } = useDialog();

  const [selectedCondolatory, setSelectedCondolatory] = useState(null);
  const [openNotiAcountDialog, setOpenNotiAcountDialog] = useState(false);

  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');

  const sendCondolatoryByToss = tossLink => {
    if (isMobile) {
      if (isAndroid || isIOS) {
        window.location.href = tossLink;
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };

  const sendCondolatoryByKakaoPay = kakaoPayLink => {
    if (isMobile) {
      if (isAndroid || isIOS) {
        window.location.href = kakaoPayLink;
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };

  const sendCondolatoryByKakaoLink = contents => {
    window.Kakao.Link.sendDefault({
      objectType: 'text',
      text: contents,
      link: {
        webUrl: currentUrl,
        mobileWebUrl: currentUrl,
      },
    });
  };

  const sendCondolatoryBySms = condolatory => {
    setSelectedCondolatory(condolatory);
    setOpenNotiAcountDialog(true);
  };

  const sendCondolatoryByCopy = async contents => {
    try {
      copy(contents);
      showAlert(
        '계좌 복사가 완료되었습니다.',
        '공유하고 싶은 곳에 [붙여넣기] 하시면 \n 계좌 내용을 공유할 수 있습니다.',
        () => hideAlert(),
      );
    } catch (error) {
      console.log('error > ', error);
      showAlert('알림', '계좌 복사에 실패하였습니다', () => hideAlert());
    }
  };

  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init('c38ef9667188c5012eda6562f4403007');
      }
    }
  }, [status]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-condolence-money" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">조의금 전달</h2>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                {/* S : 조의금 계좌 정보 */}
                {condolatorys && condolatorys.length > 0 ? (
                  condolatorys.map((condolatory, index) => (
                    <div className="box-condolence-money">
                      <div className="account-info">
                        <p className="account-name">{`예금주: ${condolatory.applicant_name}`}</p>
                        <div className="btn-copy-wrap">
                          <button
                            type="button"
                            className="btn btn-sm-text"
                            onClick={() =>
                              sendCondolatoryByCopy(condolatory.message_template.contents)
                            }
                          >
                            <i className="ic ic-text-copy"></i>계좌정보 복사
                          </button>
                        </div>
                      </div>
                      <div className="bank-info">
                        <p className="bank-name">{condolatory.bank_name}</p>
                        <p className="bank-number">{condolatory.account_number}</p>
                      </div>
                      <div className="bank-btn">
                        <button
                          type="button"
                          onClick={() =>
                            sendCondolatoryByKakaoLink(condolatory.message_template.contents)
                          }
                        >
                          <i className="ic ic-share-kakao-text"></i>카카오톡 공유
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            sendCondolatoryBySms(condolatory);
                          }}
                        >
                          <i className="ic ic-share-sms-dark"></i>문자 공유
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>등록된 조의금 정보가 없습니다.</div>
                )}
                {/* E : 조의금 계좌 정보 */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedCondolatory ? (
        <NotiAcountDialog
          isOpen={openNotiAcountDialog}
          onClose={() => setOpenNotiAcountDialog(false)}
          selectedCondolatory={selectedCondolatory}
        />
      ) : null}
    </Dialog>
  );
}

export default CondolatoryDialog;

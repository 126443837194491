import { useState, useEffect } from 'react';
import configs from '@configs/configs';
import Dialog from '@mui/material/Dialog';
import useScript from 'utils/useScript';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { getMessageTemplateViewApi } from 'apis/messageTemplate';
import useDialog from 'stores/dialog/hook';

import 'css/popup.css';

function ObituaryThanksDialog({ isOpen, onClose, memorialId }) {
  const linkUrl = `${configs.BASEURL}/pet/obituary/view/${memorialId}`;
  const [messageTemplate, setMessageTemplate] = useState(null);
  const [message, setMessage] = useState(' ');
  const [selectedId, setSelectedId] = useState(1);

  const { showAlert, hideAlert } = useDialog();

  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');

  const shreadKakaoLink = () => {
    window.Kakao.Link.sendDefault({
      objectType: 'text',
      text: message,
      link: {
        webUrl: linkUrl,
        mobileWebUrl: linkUrl,
      },
    });
  };

  const shreadSms = () => {
    if (isMobile) {
      if (isAndroid) {
        window.location.href = `sms:?body=${message}`;
      } else if (isIOS) {
        window.location.href = `sms:&body=${message}`;
      } else {
        showAlert('알림', '지원하지 않는 기기입니다.', () => hideAlert());
      }
    } else {
      showAlert('알림', '모바일 기기에서만 지원하는 기능입니다.', () => hideAlert());
    }
  };

  const handleSelectedId = (id, contents) => {
    setSelectedId(id);
    setMessage(contents);
  };

  const selectedTemplate = contents => {
    setMessage(contents);
  };

  const handleChange = event => {
    const { value } = event.target;
    setMessage(value);
  };

  useEffect(() => {
    if (status === 'ready' && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init('c38ef9667188c5012eda6562f4403007');
      }
    }
  }, [status]);

  const getMessageTemplateView = async params => {
    const result = await getMessageTemplateViewApi(params);
    setMessageTemplate(result.result);
  };

  useEffect(() => {
    if (messageTemplate) setMessage(messageTemplate[0].contents);
  }, [messageTemplate]);

  useEffect(() => {
    if (memorialId) getMessageTemplateView({ memorial_id: memorialId, message_type: 'T' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorialId]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      {memorialId ? (
        <>
          <div className="wrap is-new" id="memorialCanvas">
            <div className="modal " id="modal-mypage-01" aria-hidden="true">
              <div className="modal__overlay" tabindex="-1" data-micromodal-close>
                <div
                  className="modal__container"
                  role="dialog"
                  data-size="small"
                  aria-modal="true"
                  aria-labelledby="modal-agreement-title"
                >
                  <div className="modal__header">
                    <h2 className="modal__title">감사인사 전송</h2>
                    <button
                      className="modal__close js-modal-close"
                      aria-label="Close modal"
                      onClick={onClose}
                      data-micromodal-close
                    >
                      <i className="ic ic-close"></i>
                    </button>
                  </div>
                  <div className="modal__content">
                    <p className="modal-cont-title has-underline">
                      감사인사 양식을 선택하신 후 수정하여 전송하세요.
                    </p>

                    <ul className="rc-list long-desc">
                      {messageTemplate && messageTemplate.length > 0
                        ? messageTemplate.map((messageTemplate, index) => (
                            <li>
                              <div
                                className="rc-item"
                                key={messageTemplate.message_template_id}
                                onClick={() =>
                                  handleSelectedId(
                                    messageTemplate.message_template_id,
                                    messageTemplate.contents,
                                  )
                                }
                              >
                                <input
                                  type="radio"
                                  name="rdo-mypage-1"
                                  checked={
                                    messageTemplate.message_template_id === selectedId
                                      ? true
                                      : false
                                  }
                                  //onClick={() => selectedTemplate(messageTemplate.contents)}
                                />
                                <label>{messageTemplate.contents}</label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>

                    <p className="modal-cont-title">답례 인사문을 수정 후 전송하세요</p>
                    <div className="text-count">
                      <textarea onChange={handleChange} value={message} maxLength={200} />
                      <div className="text-count">{message.length} /200자</div>
                    </div>
                    <p className="text-guide">
                      ※감사 메시지 카카오톡 전송 시 최대 200자 까지 입력할 수 있습니다.
                    </p>
                  </div>
                  <div className="modal__footer">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={() => shreadKakaoLink()}
                      data-micromodal-close
                    >
                      카카오톡
                    </button>
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={() => shreadSms()}
                      data-micromodal-close
                    >
                      문자메세지
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Dialog>
  );
}

export default ObituaryThanksDialog;

import { Dialog } from '@mui/material';

import useCodes from 'stores/code/hook';

function CommentTemplateDialog({ isOpen, onClose, value }) {
  const { commentCustomTemplateCodes } = useCodes();

  const handleTemplate = template => {
    value(template);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <div className="wrap is-new" id="memorialCanvas">
        <div className="modal" id="modal-memorial-article" aria-hidden="true">
          <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              data-size="small"
              aria-modal="true"
              aria-labelledby="modal-agreement-title"
            >
              <div className="modal__header">
                <h2 className="modal__title">추모글 예시</h2>
                <p className="desc">추모의 글을 남겨 마음을 전달하세요.</p>
                <button
                  className="modal__close js-modal-close"
                  aria-label="Close modal"
                  onClick={onClose}
                  data-micromodal-close
                >
                  <i className="ic ic-close"></i>
                </button>
              </div>
              <div className="modal__content">
                <div className="list-article no-title">
                  <ul>
                    {/* S : LOOP */}
                    {commentCustomTemplateCodes &&
                      commentCustomTemplateCodes.length > 0 &&
                      commentCustomTemplateCodes.map(commentTemplateCode => (
                        <li>
                          <div className="article-detail">
                            <p className="text">{commentTemplateCode.value}</p>
                          </div>
                          <button
                            type="button"
                            className="btn-sm-ln-navy"
                            onClick={() => handleTemplate(commentTemplateCode.value)}
                          >
                            선택
                          </button>
                        </li>
                      ))}

                    {/* E : LOOP */}
                  </ul>
                </div>
                <div className="box-loading">
                  {/* 아래 로딩 이미지는 디자인팀 통하여 gif 이미지 전달 받은 후 교체해주세요 */}
                  {/*<img src="../../static/media/ico_loading.png" />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
export default CommentTemplateDialog;

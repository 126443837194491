import { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';
import useLogin from 'stores/login/hook';

import { getMemorialApi } from 'apis/memorial';

import parse from 'html-react-parser';
import ContentLoader from 'react-content-loader';

import TabBltContent from 'pages/memorial-canvas/obituary/components/TabBltContent';
import TabCondolencesContent from 'pages/memorial-canvas/obituary/components/TabCondolencesContent';
import TabAlbumContent from 'pages/memorial-canvas/memorial/components/TabAlbumContent';
import TabVideoContent from 'pages/memorial-canvas/memorial/components/TabVideoContent';
import SharedDialog from 'pages/memorial-canvas/memorial/components/SharedDialog';
import TabCondolencesContentBoard from 'pages/memorial-canvas/obituary/components/TabCondolencesContentBoard';

import noDeceasedPhoto from 'assets/images/no_img_memorial.jpg';

import MediaViewDialog from 'pages/memorial-canvas/obituary/components/MediaViewDialog';

function MemorialViewPage() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const tabElement = useRef(null);

  const { memorialId } = useParams();

  const { setGnbType } = useLayout();
  const { showAlert, hideAlert } = useDialog();
  const { loginUser } = useLogin();

  const [loading, setLoading] = useState(true);
  const [memorial, setMemorial] = useState(null);
  const [blts, setBlts] = useState(null);

  const [bltContents, setBltContents] = useState(null);
  const [url, setUrl] = useState('');

  const [tabIndex, setTabIndex] = useState(0);
  const [openSharedDialog, setOpenSharedDialog] = useState(false);
  const [openMediaViewDialog, setOpenMediaViewDialog] = useState(false);

  const [isActive, setActive] = useState(false);

  const getMemorial = async params => {
    const result = await getMemorialApi(params);
    //console.log(JSON.stringify(result.data));
    if (
      result.type === 'error' ||
      result.data.display_yn === 'N' ||
      (result.data.status === '1' && result.data.multy_yn !== 'Y')
    ) {
      if (!loginUser || loginUser.member_id !== result.data.own_member_id) {
        showAlert('알림', '존재하지 않는 추모관입니다.', () => handleClose());
        return null;
      }
    }

    setMemorial(result.data);
    setBlts(result.blt_datas);
    setLoading(false);
    setUrl(result.data.deceased_video_link);
  };

  const handleClose = () => {
    hideAlert();
    // navigate('/');
  };

  const handleModify = () => {
    navigate(`/memorial/memorial/modify/${memorialId}`);
  };

  const media = {
    media_type: 'V',
    media_link: url,
  };
  const handleMediaView = () => {
    setOpenMediaViewDialog(true);
  };
  const homeRef = useRef();
  const onHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    setBltContents(
      blts && blts.length > 0
        ? blts.filter(blt => blt.display_yn === 'Y').sort((a, b) => a.order_num - b.order_num)
        : [],
    );
  }, [blts]);

  useEffect(() => {
    setGnbType('sub_gnb');
    getMemorial(memorialId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state && state.viewTab) {
      setTabIndex(state.viewTab);
      tabElement.current?.scrollIntoView({ block: 'center' });
      console.log('scroll into ...');
    }
  });

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        {/* <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">추모관 </h2>
            <p>
              고인을 기리는 추모글과 고인의 저서, 어록, 활동 자료, 사진 및 동영상 등을{' '}
              <br className="d-block d-md-none" />
              온라인 상으로 만날 수 있습니다.
            </p>
          </div>
        </div> */}
        {/* E : 서브 비주얼 + 타이틀 */}

        {/* S : 추모관 상세 : 미리보기 영역 (부고 상세만 디자인 이슈로 기존의 페이지와 컨텐츠 마크업이 다릅니다 확인해주세요) */}
        {memorial ? (
          <div className="wrap-deceased is-memorial-hall">
            {/* 추모관일 경우에만 className="is-memorial-hall" 추가 */}
            {/* S : 고인 정보 */}
            <div className="contents-deceased">
              <div className="contents-width">
                <h3 className="offscreen">고인 정보</h3>
                {/* S : 고인 사진 */}
                <div className="deceased-img">
                  <div className="img">
                    <span className="chk-size">
                      <img
                        src={memorial.deceased_photo ? memorial.deceased_photo : noDeceasedPhoto}
                        alt=""
                      />
                    </span>
                  </div>
                </div>
                {/* E : 고인 사진 */}
                {/* S : 고인 정보 내용 */}
                <div className="deceased-info">
                  {/* S : 이름/생년월일 */}
                  <div className="deceased-text">
                    <p className="name">
                      {memorial.deceased_name} {memorial.deceased_job_title}
                      {loginUser &&
                      loginUser.member_id === memorial.own_member_id &&
                      memorial.status === '2' ? (
                        <a
                          href={() => false}
                          // style={{ cursor: 'pointer', fontSize: '13px' }}
                          className="btn-text"
                          onClick={() => handleModify()}
                        >
                          수정
                        </a>
                      ) : null}
                      {memorial.display_yn === 'N' ? (
                        <i className="ic ic-secret">
                          <span className="offscreen">비밀글</span>
                        </i>
                      ) : (
                        ''
                      )}
                    </p>
                    <p className="birth">{`${memorial.deceased_birthday} ~ ${memorial.deceased_date}`}</p>
                  </div>
                  {/* E : 이름/생년월일 */}

                  {/* S : 안내글 */}
                  <div className="deceased-guide">
                    {memorial.memorial_introduction &&
                      parse(memorial.memorial_introduction.replace(/(\n|\r\n)/g, '<br />'))}
                  </div>
                  {/* E : 안내글 */}

                  {/* S : 버튼 */}
                  <div className="btns">
                    {memorial.deceased_video_yn === 'Y' ? (
                      <button
                        type="button"
                        className="btn btn-brown"
                        onClick={() => handleMediaView()}
                      >
                        <i className="ic ic-play"></i>고인 일대기
                      </button>
                    ) : (
                      ' '
                    )}
                  </div>
                  {/* E : 버튼 */}
                </div>
                {/* E : 고인 정보 내용 */}
                {/* S : 22-09-29 바로가기 추가 */}
                {/* S : 바로가기*/}
                <div class={`wrap-quick-btn ${isActive ? 'is-active' : ''}`}>
                  <button type="button" class="btn-quick-link" onClick={() => setActive(!isActive)}>
                    <span>
                      바로
                      <br />
                      가기
                    </span>
                  </button>
                  <ul>
                    <li>
                      <a
                        href={() => false}
                        onClick={() => {
                          setTabIndex(0);
                          setActive(!isActive);
                          onHomeClick();
                        }}
                      >
                        <span>조문보</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={() => false}
                        onClick={() => {
                          setTabIndex(3);
                          setActive(!isActive);
                          onHomeClick();
                        }}
                      >
                        <span>영상</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={() => false}
                        onClick={() => {
                          setTabIndex(2);
                          setActive(!isActive);
                          onHomeClick();
                        }}
                      >
                        <span>앨범</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href={() => false}
                        onClick={() => {
                          setTabIndex(1);
                          setActive(!isActive);
                          onHomeClick();
                        }}
                      >
                        <span>
                          추모의
                          <br />글
                        </span>
                      </a>
                    </li>
                  </ul>
                  <button type="button" className="btn-go-top">
                    <span className="offscreen">위로가기</span>
                    <i className="ic ic-arrow-up"></i>
                  </button>
                  <div class="overlay-bg"></div>
                </div>
                {/* E : 바로가기*/}
                {/* E : 22-09-29 바로가기 추가 */}
              </div>
            </div>
            {/* E : 고인 정보 */}

            {/* S : 추도사 */}
            <div className="contents-memorial">
              <div className="contents-width">
                <div className="box-title">
                  <h3 className="title">추도사</h3>
                  <p className="desc">고인의 아름다운 생애</p>
                </div>

                <div className="text-memorial">
                  <div>
                    {/* <div style={{ height: '800px'}}>
                  내용출력(세로 PC에서는 800px / 모바일에서는 450px 이상 넘어갈 시 아래 더보기 버튼
                  생성/이하일 경우 비노출) */}
                    {memorial.chudosa && parse(memorial.chudosa.replace(/(\n|\r\n)/g, '<br />'))}
                  </div>
                </div>
                <div className="btn-wrap">
                  {/* <button type="button" className="btn btn-more">
                  더보기
                </button> */}
                </div>
              </div>
            </div>
            {/* E : 추도사 */}

            {/* S : 조문보/추모의글/장례정보 */}
            <div className="contents-tabs" ref={tabElement}>
              <div className="contents-width">
                {/* S : 1차 탭 */}
                <div className="tabs tabs-line" ref={homeRef}>
                  <ul>
                    <li className={tabIndex === 0 ? 'is-active' : ''}>
                      <button type="button" onClick={() => setTabIndex(0)}>
                        조문보
                      </button>
                    </li>
                    {/* 활성화 된 탭 li에 className="is-active" */}
                    <li className={tabIndex === 3 ? 'is-active' : ''}>
                      <button type="button" onClick={() => setTabIndex(3)}>
                        영상
                      </button>
                    </li>
                    <li className={tabIndex === 2 ? 'is-active' : ''}>
                      <button type="button" onClick={() => setTabIndex(2)}>
                        앨범
                      </button>
                    </li>
                    <li className={tabIndex === 1 ? 'is-active' : ''}>
                      <button type="button" onClick={() => setTabIndex(1)}>
                        추모의 글
                      </button>
                    </li>
                  </ul>
                </div>
                {/* E : 1차 탭 */}

                {/* S : 조문보 */}
                <div className="tab-contents">
                  {tabIndex === 0 && <TabBltContent bltContents={bltContents} />}
                  {tabIndex === 1 && (
                    <TabCondolencesContentBoard
                      memorialId={memorialId}
                      ownMemberId={memorial.own_member_id}
                    />
                  )}
                  {tabIndex === 2 && <TabAlbumContent memorialId={memorialId} />}
                  {tabIndex === 3 && <TabVideoContent memorialId={memorialId} />}
                </div>
                {/* E : 조문보 */}
              </div>
            </div>
            {/* E : 조문보/추모의글/장례정보  */}
            <SharedDialog
              isOpen={openSharedDialog}
              onClose={() => setOpenSharedDialog(false)}
              memorial={memorial}
            />
            <MediaViewDialog
              isOpen={openMediaViewDialog}
              onClose={() => setOpenMediaViewDialog(false)}
              media={media}
            />
          </div>
        ) : (
          ''
        )}
        {/* E : 추모관 상세 (부고 상세만 디자인 이슈로 기존의 페이지와 컨텐츠 마크업이 다릅니다 확인해주세요) */}
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default MemorialViewPage;

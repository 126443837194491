import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { codeState, getCodesRequest } from './reducer';

export default function useCode() {
  const {
    memberTypeCodes,
    memberStatusTypeCodes,
    loginTypeCodes,
    useYnCodes,
    displayYnCodes,
    relationTypeCodes,
    memorialTypeCodes,
    bankTypeCodes,
    consultingWriteTypeCodes,
    consultingTypeCodes,
    consultingPremiumCodes,
    relationDetailCodes,
    relationPetDetailCodes,
    bltTitleCodes,
    chudosaTitleCodes,
    chudosaPetTitleCodes,
    commentTemplateCodes,
    commentPetTemplateCodes,
    commentCustomTemplateCodes,
    myfaqBltTypeCodes,
    faqBltTypeCodes,
    faqPetBltTypeCodes,
    canvasTypeCodes,
    canvasStoryTypeCodes,
    bltTypeCodes,
    displayStatusCodes,
  } = useSelector(codeState);

  const dispatch = useDispatch();

  const getCodes = useCallback(() => {
    dispatch(getCodesRequest());
  }, [dispatch]);

  return {
    getCodes,
    memberTypeCodes,
    memberStatusTypeCodes,
    loginTypeCodes,
    useYnCodes,
    displayYnCodes,
    relationTypeCodes,
    memorialTypeCodes,
    bankTypeCodes,
    consultingWriteTypeCodes,
    consultingTypeCodes,
    consultingPremiumCodes,
    relationDetailCodes,
    relationPetDetailCodes,
    bltTitleCodes,
    chudosaTitleCodes,
    chudosaPetTitleCodes,
    commentTemplateCodes,
    commentPetTemplateCodes,
    commentCustomTemplateCodes,
    myfaqBltTypeCodes,
    faqBltTypeCodes,
    faqPetBltTypeCodes,
    canvasTypeCodes,
    canvasStoryTypeCodes,
    bltTypeCodes,
    displayStatusCodes,
  };
}

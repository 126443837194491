import api from '@apis/api';
import configs from '@configs/configs';

export async function getConsultBoardsApi(params) {
  const response = await api.post(`${configs.API_BASEURL}/consultBoard`, params);
  return response.data;
}

export async function getConsultBoardApi(id) {
  const response = await api.get(`${configs.API_BASEURL}/consultBoard?id=${id}`);
  return response.data;
}

export async function saveConsultBoardApi(params) {
  params.path = configs.PATH;
  const response = await api.post(`${configs.API_BASEURL}/consultBoard/ins`, params);
  return response.data;
}

export async function modifyConsultBoardApi(params) {
  try {
    const response = await api.put(`${configs.API_BASEURL}/consultBoard`, params);
    if (response.data.code === '0000') return { type: 'success', message: response.data.message };
    else return { type: 'failure', message: response.data.message };
  } catch (error) {
    console.log(error);
    return { type: 'error', message: '일시적인 오류가 발생하였습니다.' };
  }
}

export async function deleteConsultBoardApi(id) {
  const response = await api.delete(`${configs.API_BASEURL}/consultBoard?id=${id}`);
  return response.data;
}

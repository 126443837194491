import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useLayout from 'stores/layout/hook';

import { uploadApi } from 'apis/openapi';
import { saveMemorialApi } from 'apis/memorial';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDate } from 'utils/date';

import ChudosaSampleDialog from 'pages/memorial-canvas/obituary/components/ChudosaTemplateDialog';
import Blts from 'pages/memorial-canvas/obituary/components/Blts';
import Chudosas from '../obituary/components/Chudosas';
import PreviewDialog from 'pages/memorial-canvas/memorial/components/PreviewDialog';
import MediaUploader from 'pages/memorial-canvas/obituary/components/MediaUploader';

function MemorialRegisterPage() {
  const navigate = useNavigate();

  const { setGnbType } = useLayout();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert, showConfirm, hideConfirm } = useDialog();

  const schema = Yup.object({
    deceased_name: Yup.string()
      .required('* 성함을 작성하세요.')
      .max(50, '* 최대 입력은 50자까지 가능합니다.'),
    // deceased_birthday: Yup.string().required('* 생년월일을 입력하세요.'),
    // deceased_date: Yup.string().required('* 임종일자를 입력하세요.'),
    blt_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 조문보 주제를 등록 하세요.')
          .max(20, '* 조문보 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string()
          // .test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 3000000)
          .required('* 조문보를 입력하세요.'),
      }),
    ),
    chudosa_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string()
          .required('* 추도사 주제를 등록 하세요.')
          .max(20, '* 추도사 주제 최대 입력은 20자까지 가능합니다.'),
        blt_contents: Yup.string()
          // .test('len', '* 업로드 가능한 용량은 최대 3MB입니다.', val => val.length <= 3000000)
          .required('* 추도사를 입력하세요.'),
      }),
    ),
    //symbol_comment: Yup.string().required('* 상징문구를 입력하세요.'),
    deceased_introduction: Yup.string().required('* 소개글을 작성하세요.'),
    // chudosa: Yup.string().required('* 추도사를 입력하세요.'),
  }).required();

  const defaultValues = {
    deceased_name: '',
    // deceased_birthday: getDate(-30000),
    deceased_birthday: '',
    deceased_job_title: '',
    // deceased_date: getDate(0),
    deceased_date: '',
    deceased_hour: '00:00',
    birth_date: getDate(3),
    birth_hour: '00:00',
    funeral_addr: '',
    funeral_room: '',
    funeral_place: '',
    deceased_photo: '',
    symbol_comment_yn: 'N',
    deceased_introduction: '',
    memorial_introduction: '',
    // chudosa: '',
    own_member_id: '',
    create_member_id: '',
    display_yn: 'Y',
    condolatory_yn: 'N',
    donation_yn: 'N',
    status: '2',
    chudosa_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    blt_datas: [{ blt_title: '', blt_contents: '', display_yn: 'Y' }],
    medias: [],
    deceased_video_link: '',
    deceased_video_yn: 'Y',
  };

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const [openChudosaSampleDialog, setOpenChudosaSampleDialog] = useState(false);
  const [deceasedPhoto, setDeceasedPhoto] = useState(null);
  const [videoPhoto, setVideoPhoto] = useState(null);
  const [displayYn, setDisplayYn] = useState(true);
  const [symbolYn, setSymbolYn] = useState(false);
  const [videoYn, setVideoYn] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [medias, setMedias] = useState([]);

  const [message, setMessage] = useState(' ');

  const saveMemorial = async params => {
    const result = await saveMemorialApi(params);
    if (result.type === 'success') {
      showAlert('알림', result.message, () => successSaveMemorial(result.id));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);

    setDeceasedPhoto(result.uploadUrl);
    setValue('deceased_photo', result.uploadUrl);
  };

  const handleChangeDisplayYn = event => {
    // alert(event.target.checked);
    const isChecked = event.target.checked ? 'Y' : 'N';
    setValue('display_yn', isChecked);
    //setDisplayYn(value);
  };

  const handleSymbolYn = value => {
    setValue('symbol_comment_yn', value ? 'Y' : 'N');
    setSymbolYn(value);
  };

  const handleVideoYn = value => {
    setValue('deceased_video_yn', value ? 'Y' : 'N');
    setVideoYn(value);
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
    //console.log(media);
    //console.log(media.media_link);
    setValue('deceased_video_link', media.media_link);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  const changeImageFile = event => {
    const { files } = event.target;
    uploadImageFile(files[0]);
  };

  const removeImageFile = () => {
    setDeceasedPhoto(null);
    setValue('deceased_photo', '');
  };

  const changeVideoImageFile = event => {
    const { files } = event.target;
    // 다시 작성해야 하는 부분(API전달받아서 작성)
    uploadVideoImageFile(files[0]);
  };

  const uploadVideoImageFile = async params => {
    const result = await uploadApi(params);

    setVideoPhoto(result.uploadUrl);
    setValue('video_thumbnail', result.uploadUrl);
  };

  const removeVideoImageFile = () => {
    hideConfirm();
    setVideoPhoto(null);
    // 다시 작성해야 하는 부분(API전달받아서 작성)
    setValue('video_thumbnail', '');
  };

  const handlePreview = () => {
    setOpenPreviewDialog(true);
  };

  const handleClose = () => {
    navigate('/memorial');
  };

  const handleChange = event => {
    const { value } = event.target;
    setMessage(value);
  };

  const onSubmit = data => {
    saveMemorial(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const successSaveMemorial = memorialId => {
    hideAlert();
    navigate(`/memorial/memorial/view/${memorialId}`);
  };

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser && loginUser.member_id) {
      setValue('own_member_id', loginUser.member_id);
      setValue('create_member_id', loginUser.member_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">추모관 등록</h2>
            <p>
              고인을 기리는 추모글과 고인의 저서, 어록, 활동 자료, 사진 및 동영상 등을{' '}
              <br className="d-block d-md-none" />
              온라인 상으로 만날 수 있습니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          {/* S : 추모관 등록 */}
          <div className="wrap-form">
            {/* S : 23-04-03 메모리얼 캔버스 개편에 따른 마크업 수정 */}
            {/* S : 고인정보 */}
            <div className="contents-form">
              <div className="box-title">
                <h3>고인정보</h3>
                <p className="required">* 표시는 필수값입니다.</p>
              </div>
              {/* S : 고인 성함, 직위 */}
              <div className="form-group">
                <div className="ip-wrap gap-10">
                  <div className="ip-item is-error">
                    <label htmlFor="ip-deceased-name">
                      성함{' '}
                      <span className="required">
                        *<span className="offscreen">필수입력</span>
                      </span>
                    </label>
                    <input
                      type="text"
                      id="ip-deceased-name"
                      placeholder="성함을 작성하세요"
                      aria-invalid={errors.deceased_name ? 'true' : 'false'}
                      {...register('deceased_name')}
                    />
                    {errors.deceased_name && (
                      <p className="message">{errors.deceased_name.message}</p>
                    )}
                  </div>
                  <div className="ip-item is-error">
                    <label htmlFor="ip-deceased-pos">직위</label>
                    <input
                      type="text"
                      id="ip-deceased-pos"
                      placeholder="직위를 작성하세요"
                      {...register('deceased_job_title')}
                    />
                  </div>
                </div>
              </div>
              {/* E : 고인 성함, 직위 */}
              {/* S : 생년월일 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>생년월일</h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item">
                    <label htmlFor="ip-deceased-birth" className="offscreen">
                      생년월일
                    </label>
                    <input
                      type="date"
                      id="ip-deceased-birth"
                      placeholder="년-월-일"
                      {...register('deceased_birthday')}
                    />
                  </div>
                </div>
              </div>
              {/*E : 생년월일 */}
              {/* S : 임종일자 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>임종일자</h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item">
                    <label htmlFor="ip-deceased-dying-day" className="offscreen">
                      임종일자
                    </label>
                    <input
                      type="date"
                      id="ip-deceased-dying-day"
                      placeholder="년-월-일"
                      {...register('deceased_date')}
                    />
                  </div>
                </div>
              </div>
              {/* E : 임종일자 */}
              {/* S : 영정 사진 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>영정사진</h5>
                </div>

                {/* S : 파일첨부 영역 */}
                <div className="ip-wrap ip-file-wrap">
                  <div className="ip-item">
                    <label className="file" htmlFor="imageFile">
                      {/* for 값과 관련하여 css 설정되어 있으니 변경시 반드시 공지해주세요 */}
                      <span
                        className="offscreen"
                        onClick={() => document.getElementById('imageFile').click()}
                      >
                        파일선택
                      </span>
                      여기를 클릭하여 사진을 등록할 수 있습니다.
                      <br />* 이미지 파일만 등록할 수 있습니다.
                    </label>
                    <input
                      type="file"
                      className="offscreen"
                      id="imageFile"
                      onChange={changeImageFile}
                    />
                  </div>
                  {/* S : 첨부 이미지 등록 후 */}
                  {deceasedPhoto ? (
                    <div className="file-output-wrap">
                      <div className="file-output">
                        <div className="file-area is-image">
                          <img
                            alt=""
                            src={deceasedPhoto}
                            // src="https://d3lzegcz38cz56.cloudfront.net/photo/41092f61-c8a8-4b57-8a39-e505942cb9953d9a47a286411ddbb2060b7d2ecef49e4bef53c9.jpg"
                          />
                        </div>
                        <button className="file-del" onClick={removeImageFile}>
                          <span className="offscreen">파일 삭제</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="file_output default" onChange={changeImageFile}></div>
                  )}
                  {/* E : 첨부 이미지 등록 후 */}
                </div>

                {/* E : 파일첨부 영역 */}
              </div>
              {/* E : 영정 사진 */}

              {/* S : 소개글 */}
              <div className="form-group">
                <div className="box-stitle">
                  <h5>
                    소개글{' '}
                    <span className="required">
                      *<span className="offscreen">필수입력항목</span>
                    </span>
                  </h5>
                </div>
                <div className="ip-wrap">
                  <div className="ip-item is-error">
                    <label htmlFor="text-tribute" className="offscreen">
                      소개글 입력
                    </label>
                    <textarea
                      id="text-tribute"
                      placeholder="고인을 소개할 수 있는 소개글을 작성하세요"
                      {...register('deceased_introduction')}
                      aria-invalid={errors.deceased_introduction ? 'true' : 'false'}
                      onChange={handleChange}
                      value={message}
                      maxLength={500}
                    ></textarea>
                    <div className="count-text">
                      <span>{message.length}</span> / 500자
                    </div>
                    {errors.deceased_introduction && (
                      <p className="message">{errors.deceased_introduction.message}</p>
                    )}
                  </div>
                </div>
              </div>
              {/* E : 소개글 */}
            </div>
            {/* E : 고인정보 */}
            {/* S : 추모 영상 */}

            <div className="contents-form">
              <div className="box-title">
                <h3>추모 영상</h3>
              </div>
              <div className="form-group">
                {/* S : 파일첨부 영역 */}
                <div className="ip-wrap ip-file-wrap">
                  <MediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
                  {/* E : 첨부 이미지 등록 후 */}

                  <div className="file-output-wrap">
                    <div className="ip-item is-type-btn">
                      <label className="file" htmlFor="thumbFile">
                        <span className="btn btn-sm-blue">영상 미리보기 사진 첨부</span>
                        <span
                          className="desc"
                          onClick={() => document.getElementById('imageFile').click()}
                        >
                          클릭하여 영상 미리보기 사진을 첨부할 수 있습니다.
                          <br />* 이미지 파일만 첨부 가능하며 사진을 첨부하지 않으시면 기본 사진으로
                          설정됩니다.
                        </span>
                      </label>
                      <input
                        type="file"
                        className="offscreen"
                        id="thumbFile"
                        onChange={changeVideoImageFile}
                      />
                    </div>
                  </div>

                  {/* S : 첨부 이미지 등록 후 */}
                  {videoPhoto ? (
                    <div className="file-output-wrap" onChange={changeVideoImageFile}>
                      <div className="file-output">
                        <div className="file-area is-video">
                          <img
                            alt=""
                            src={videoPhoto}
                            // src="https://d2y083oijopune.cloudfront.net/video/thumbnail/5d935eab-2d67-40ab-aaa5-0262b44f54f5thumbnail8e2849b6-835e-4a00-9879-3a208bafe155.png"
                          />
                        </div>
                        <button className="file-del" onClick={removeVideoImageFile}>
                          <span className="offscreen">파일 삭제</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="file_output default" onChange={changeVideoImageFile}></div>
                  )}
                  {/* E : 첨부 이미지 등록 후 */}
                </div>
                {/* E : 파일첨부 영역 */}
              </div>
            </div>
            {/* E : 추모 영상 */}
            {/* S : 추도사 작성*/}
            <Chudosas control={control} setValue={setValue} getValues={getValues} errors={errors} />
            {/* E : 추도사 작성 */}
            {/* S : 일대기 작성 */}
            <Blts control={control} setValue={setValue} getValues={getValues} errors={errors} />
            {/* E : 일대기 작성  */}
            {/* S : 추모관 공개 여부  */}
            <div className="contents-form">
              <div className="box-title">
                <h3>추모관을 공개로 등록하시겠습니까?</h3>
                <div className="rc-item rc-switch">
                  <input
                    type="checkbox"
                    id="switch11"
                    switch="none"
                    defaultChecked={true}
                    onChange={handleChangeDisplayYn}
                  />
                  <label htmlFor="switch11" data-on-label="공개" data-off-label="비공개"></label>
                </div>
                <p className="desc">
                  비공개로 등록하더라도 추후 공개로 수정하고 공유할 수 있습니다.
                </p>
              </div>
            </div>
            {/* E : 추모관 공개 여부  */}
            {/* S : 버튼 */}
            <div className="btn-wrap">
              <button type="button" className="btn btn-ln-gray " onClick={handleClose}>
                취소
              </button>
              <button
                type="button"
                className="btn btn-navy"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록
              </button>
              <button type="button" className="btn btn-ln-blue" onClick={() => handlePreview()}>
                미리보기
              </button>
            </div>
            {/* E : 버튼 */}
            {/* E : 23-04-03 메모리얼 캔버스 개편에 따른 마크업 수정 */}
          </div>
          {/* E : 추모관 등록 */}
        </div>
      </div>
      <ChudosaSampleDialog
        isOpen={openChudosaSampleDialog}
        onClose={() => setOpenChudosaSampleDialog(false)}
      />
      {openPreviewDialog && (
        <PreviewDialog
          isOpen={openPreviewDialog}
          onClose={() => setOpenPreviewDialog(false)}
          getValues={getValues}
        />
      )}
      {/* E : CONTENT */}
    </section>
  );
}

export default MemorialRegisterPage;

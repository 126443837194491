import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getWreathApi, getWreathsApi, saveCalculateApi } from 'apis/wreathCalculation';

import Button from '@mui/material/Button';
import Pagination from 'react-js-pagination';

function AdminMemberRegisterPage() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { memorialId } = useParams();
  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();
  const { memberTypeCodes, loginTypeCodes } = useCode();
  const [page, setPage] = useState(1);
  const [listSize] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [wreathList, setWreathList] = useState([]);
  const [guideList, setGuideList] = useState([false, false, false, false]);
  const [totalCount, setTotalCount] = useState(0);
  const [calculateYn, setCalculateYn] = useState('Y');

  const [wreath, setWreath] = useState([]);

  const defaultValues = {
    memorial_ids: [memorialId],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues,
  });

  const getWreath = async () => {
    let params = {};
    params.memorial_id = memorialId;
    //console.log(params);
    // api 요청
    const result = await getWreathApi(params);
    console.log(JSON.stringify(result));
    if (result.code === '0000') {
      //console.log(result.data[0]);
      setWreath(result.data[0]);
      setCalculateYn('N');
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const getWreathList = async pageNum => {
    let params = {};
    params.memorial_id = memorialId;
    params.page = pageNum;
    params.itemInPage = listSize;

    // api 요청
    const result = await getWreathsApi(params);

    if (result.code === '0000') {
      console.log(result);
      setWreathList(result.data);
      setTotalCount(result.data_cnt);
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const handleShowGuide = index => {
    let temp = [...guideList];
    temp[index] = true;
    setGuideList(temp);
  };

  const handleHideGuide = () => {
    setGuideList([false, false, false, false]);
  };

  // 콤마 안찍혀서 날아오면 사용할 것
  const numberFormatter = num => {
    return Number(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
    getWreathList(selectedPage);
  };

  const saveCalculate = async params => {
    //console.log(params);
    const result = await saveCalculateApi(params);

    showAlert('알림', result.message, () => hideAlert());
    getWreath();
  };

  const onSubmit = data => {
    if (data.deposit_yn === 'N') {
      showAlert('알림', '구분 값을 변경해 주세요.', () => hideAlert());
    } else {
      saveCalculate(data);
    }
  };

  const onSubmit2 = data => {
    data.deposit_yn = 'Y';
    saveCalculate(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleClose = () => {
    hideAlert();
    navigate('/admin/calculate');
  };
  const handleChangeCalculate = event => {
    setCalculateYn(event.target.value);
  };

  useEffect(() => {
    if (loginUser && (!loginUser.member_id || loginUser.member_type !== 'A')) {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) {
      setValue('create_member_id', loginUser.member_id);
      getWreath();
      getWreathList(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setValue('deposit_yn', calculateYn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateYn]);

  return (
    <div className="container" id="containerWrap">
      <div className="box-title">
        <h2>정산 관리</h2>
      </div>

      <div className="content-box wrap-detail">
        <h3>정산정보</h3>
        {/* 정산정보 일 때만 타이틀이 content-box 아래에 위치 */}
        {/* S : 정산정보 입력 */}
        <div className="content-detail">
          <div className="tbl-wrap">
            <table className="tbl-row">
              <caption></caption>
              <colgroup>
                <col width="25%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">정산신청 일시</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample1" value={wreath.cal_request} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">대표상주명</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample1" value={wreath.chief_mourner} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">이메일</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input
                        type="text"
                        id="ipSample1"
                        value={wreath.create_member_email}
                        disabled
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">고인 이름</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample1" value={wreath.deceased_name} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">은행명</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample1" value={wreath.account_bank} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">계좌명</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input type="text" id="ipSample1" value={wreath.account_num} disabled />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="ipSample1">정산금액</label>
                  </th>
                  <td className="input">
                    <div className="ip-item">
                      <input
                        type="text"
                        id="ipSample1"
                        value={
                          wreath.flower_price_final === undefined
                            ? ''
                            : wreath.flower_price_final
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        disabled
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* E : 정산정보 입력 */}

        {/* S : 디지털 화환 총 모금액 */}
        <div className="content-detail">
          {/* S : 세부 내역 */}
          {/* S : 23-02-21 내용 수정 */}
          {/* S : 정산 금액 상세 내역 */}
          <div className="wrap-calculate">
            <div className="total-calculate">
              <p className="title">정산 금액</p>
              <p className="price">
                {wreath.flower_price === undefined
                  ? ''
                  : wreath.flower_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                원
              </p>
            </div>
            <div className="box-calculate">
              <dl>
                <dt>디지털 화환 총 모금액</dt>
                <dd>
                  {' '}
                  {wreath.flower_price_final === undefined
                    ? ''
                    : wreath.flower_price_final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </dd>
                <dt>수수료(5%)</dt>
                <dd>-36,000원</dd>
              </dl>
            </div>
          </div>
          {/* E : 정산 금액 상세 내역 */}

          {/* S : 상태 */}
          <div className="ip-item sel-ip-box w-100">
            <select>
              <option selected="" value="상태">
                상태
              </option>
              <option value="담당자 확인">담당자 확인</option>
              <option value="입금 대기">입금 대기</option>
              <option value="입금 완료">입금 완료</option>
            </select>
            <button type="button" className="btn btn-pos">
              기록
            </button>
          </div>
          {/* E : 상태 */}

          {/* S : 기록 */}
          <div className="list-calculate">
            <p>정산기록</p>
            <div className="tbl-wrap">
              <table className="tbl-col tbl-sm">
                <caption className="offscreen">정산금액 상세 내역</caption>
                <colgroup>
                  <col width="30%" />
                  <col width="35%" />
                  <col width="35%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">상태</th>
                    <th scope="col">일자</th>
                    <th scope="col">담당자(관리자)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>정산 신청</td>
                    <td>2022.03.03 13:05</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>담당자 확인</td>
                    <td>2022.03.03 13:05</td>
                    <td>김일지</td>
                  </tr>
                  <tr>
                    <td>입금 대기</td>
                    <td>2022.03.03 13:05</td>
                    <td>김일지</td>
                  </tr>
                  <tr>
                    <td>입금 완료</td>
                    <td>2022.03.03 13:05</td>
                    <td>김일지</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* E : 기록 */}
          {/* E : 23-02-21 내용 수정 */}
          {/* E : 세부 내역 */}
        </div>
        {/* E : 디지털 화환 총 모금액 */}
      </div>

      {/* S : 테이블 상단 게시물 갯수+버튼 */}
      <div className="box-tbl-head">
        <h3>정산금액 상세 내역</h3>
        <div className="btn-wrap">
          <button type="button" className="btn btn-download">
            엑셀 다운로드
          </button>
        </div>
      </div>
      {/* E : 테이블 상단 게시물 갯수+버튼 */}

      {/* S : 목록 */}
      <div className="content-box">
        <div className="tbl-wrap">
          {/* S : 23-02-21 내용 수정 */}
          <table className="tbl-col">
            <caption className="offscreen">정산금액 상세 내역</caption>
            <colgroup>
              <col width="15%" />
              <col width="15%" />
              <col width="20%" />
              <col width="15%" />
              <col width="20%" />
              <col width="15%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">결제번호 / 승인번호</th>
                <th scope="col">결제일</th>
                <th scope="col">화환 종류</th>
                <th scope="col">금액</th>
                <th scope="col">회사/모임명</th>
                <th scope="col">상태</th>
              </tr>
            </thead>
            <tbody>
              {wreathList && wreathList.length > 0
                ? wreathList.map(row => (
                    <tr key={row.seq}>
                      <td>
                        Y0296645578
                        <br />
                        3005001
                      </td>
                      <td>{row.reg_date}</td>
                      <td>
                        {row.flower_type_name}{' '}
                        <button type="button" className="text-link">
                          상세 보기
                        </button>
                      </td>
                      <td>
                        {' '}
                        {row.flower_price === undefined
                          ? ''
                          : row.flower_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td>{row.send_name}</td>
                      {row.caculateYN === 'Y' ? (
                        <td>정산완료</td>
                      ) : (
                        <td>
                          <div className="btn-wrap">
                            <a href={() => false} className="btn">
                              결제 취소
                            </a>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                : null}
              {/* <tr>
                <td>
                  Y0296645578
                  <br />
                  3005001
                </td>
                <td>2022.03.02 17: 51</td>
                <td>
                  근조화환{' '}
                  <button type="button" className="text-link">
                    상세 보기
                  </button>
                </td>
                <td>50,000원</td>
                <td>김미나</td>
                <td>정산 완료</td>
              </tr>
              <tr>
                <td>
                  Y0296645578
                  <br />
                  3005001
                </td>
                <td>2022.03.02 17: 51</td>
                <td>
                  근조화환{' '}
                  <button type="button" className="text-link">
                    상세 보기
                  </button>
                </td>
                <td>50,000원</td>
                <td>김미나</td>
                <td>
                  <span className="tc-red">결제 취소 완료</span>
                </td>
              </tr> */}
            </tbody>
          </table>
          {/* E : 23-02-21 내용 수정 */}
        </div>
      </div>
      {/* E : 목록 */}

      {/* S : 페이징 */}
      <Pagination
        activePage={page}
        itemsCountPerPage={listSize}
        totalItemsCount={totalCount}
        pageRangeDisplayed={10}
        firstPageText={''}
        prevPageText={''}
        nextPageText={''}
        lastPageText={''}
        onChange={handlePageChange}
        itemClassFirst={'ico-page-first'}
        itemClassPrev={'ico-page-prev'}
        itemClassNext={'ico-page-next'}
        itemClassLast={'ico-page-end'}
      />
      {/* E : 페이징 */}

      {/* S : 버튼 */}
      <div className="btn-wrap center">
        {wreath.calculate_yn !== 'Y' ? (
          <button
            type="button"
            className="btn btn-lg btn-pos"
            onClick={handleSubmit(onSubmit2, onError)}
          >
            정산처리
          </button>
        ) : (
          ''
        )}
        <button type="button" className="btn btn-lg btn-white" onClick={handleClose}>
          취소
        </button>
      </div>
      {/* E : 버튼 */}
    </div>
  );
}

export default AdminMemberRegisterPage;

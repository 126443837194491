import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useDialog from 'stores/dialog/hook';

import { uploadApi } from 'apis/openapi';
import { getMemorialApi, modifyMemorialApi } from 'apis/memorial';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDate } from 'utils/date';

import ContentLoader from 'react-content-loader';
import ChudosaSampleDialog from 'pages/memorial-canvas/obituary/components/ChudosaTemplateDialog';
import Blts from 'pages/memorial-canvas/obituary/components/Blts';
import PreviewDialog from 'pages/memorial-canvas/memorial/components/PreviewDialog';
import MediaUploader from 'pages/memorial-canvas/obituary/components/MediaUploader';

function AdminMemorialRegisterPage() {
  const navigate = useNavigate();
  const { memorialId } = useParams();

  const { loginUser } = useLogin();
  const { showAlert, hideAlert } = useDialog();

  const schema = Yup.object({
    deceased_name: Yup.string()
      .required('* 이름을 입력하세요.')
      .max(50, '최대 입력은 50자까지 가능합니다.'),
    deceased_birthday: Yup.string().required('* 생년월일을 입력하세요.'),
    deceased_date: Yup.string().required('* 임종일자, 시간을 입력하세요.'),
    blt_datas: Yup.array().of(
      Yup.object().shape({
        blt_title: Yup.string().required('필수 입력 항목입니다.'),
      }),
    ),
    // symbol_comment: Yup.string().required('* 상징문구를 입력하세요.'),
    memorial_introduction: Yup.string().required('* 안내글을 입력하세요.'),
    chudosa: Yup.string().required('* 필수입력항목입니다.'),
  }).required();

  const defaultValues = {
    deceased_name: '',
    deceased_birthday: getDate(-30000),
    deceased_job_title: '',
    deceased_date: getDate(0),
    deceased_hour: '00:00',
    birth_date: getDate(3),
    birth_hour: '00:00',
    funeral_addr: '',
    funeral_room: '',
    funeral_place: '',
    deceased_photo: '',
    symbol_comment_yn: 'N',
    deceased_introduction: '',
    memorial_introduction: '',
    chudosa: '',
    own_member_id: '',
    create_member_id: '',
    display_yn: 'Y',
    mylty_yn: 'N',
    condolatory_yn: 'N',
    donation_yn: 'N',
    status: '2',
    blt_datas: [],
    medias: [],
    deceased_video_link: '',
  };

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const [loading, setLoading] = useState(true);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openChudosaSampleDialog, setOpenChudosaSampleDialog] = useState(false);
  const [deceasedPhoto, setDeceasedPhoto] = useState(null);
  const [displayYn, setDisplayYn] = useState(true);
  const [symbolYn, setSymbolYn] = useState(false);
  const [multyYn, setMultyYn] = useState(false);
  const [memorial, setMemorial] = useState(null);
  const [blts, setBlts] = useState(null);
  const [videoYn, setVideoYn] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [medias, setMedias] = useState([]);

  const getMemorial = async memorialId => {
    const result = await getMemorialApi(memorialId);
    setMemorial(result.data);
    setBlts(result.blt_datas);
    setLoading(false);
  };
  const modifyMemorial = async params => {
    const result = await modifyMemorialApi(params);
    if (result.type === 'success') {
      showAlert('알림', result.message, () => successModifyMemorial(memorialId));
    } else {
      showAlert('알림', result.message, () => hideAlert());
    }
  };

  const uploadImageFile = async params => {
    const result = await uploadApi(params);

    setDeceasedPhoto(result.uploadUrl);
    setValue('deceased_photo', result.uploadUrl);
  };

  const handleChangeDisplayYn = value => {
    setValue('display_yn', value ? 'Y' : 'N');
    setDisplayYn(value);
  };

  const handleChangeMultyYn = value => {
    setValue('multy_yn', value ? 'Y' : 'N');
    setMultyYn(value);
  };

  const handleSymbolYn = value => {
    setValue('symbol_comment_yn', value ? 'Y' : 'N');
    setSymbolYn(value);
  };

  const handleVideoYn = value => {
    setValue('deceased_video_yn', value ? 'Y' : 'N');
    setVideoYn(value);
  };

  const addMedia = media => {
    setMedias(prevState => [...prevState, media]);
    //console.log(media);
    //console.log(media.media_link);
    setValue('deceased_video_link', media.media_link);
  };

  const removeMedia = index => {
    medias.splice(index, 1);
    setMedias([...medias]);
  };

  const changeImageFile = event => {
    const { files } = event.target;
    uploadImageFile(files[0]);
  };

  const removeImageFile = () => {
    setDeceasedPhoto(null);
    setValue('deceased_photo', '');
  };
  const handlePreview = () => {
    setOpenPreviewDialog(true);
  };
  const handleClose = () => {
    navigate('/admin/condolence');
  };

  const onSubmit = data => {
    modifyMemorial(data);
  };

  const onError = error => {
    console.log('error > ', error.deceased_name);
  };

  const successModifyMemorial = memorialId => {
    hideAlert();

    navigate(`/memorial/memorial/view/${memorialId}`);
  };

  useEffect(() => {
    if (blts) setValue('blt_datas', blts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blts]);

  useEffect(() => {
    if (memorial) {
      Object.entries(memorial).forEach(([key, value]) => {
        setValue(key, value);
      });
      setDeceasedPhoto(memorial.deceased_photo);
      setDisplayYn(memorial.display_yn === 'Y' ? true : false);
      setMultyYn(memorial.multy_yn === 'Y' ? true : false);
      setSymbolYn(memorial.symbol_comment_yn === 'Y' ? true : false);
      setVideoLink(memorial.deceased_video_link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memorial]);

  useEffect(() => {
    if (loginUser && !loginUser.member_id && loginUser.role !== 'ADMIN') {
      showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }
    if (loginUser) {
      getMemorial(memorialId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">추모관 </h2>
            <p>
              고인을 기리는 추모글과 고인의 저서, 어록, 활동 자료, 사진 및 동영상 등을{' '}
              <br className="d-block d-md-none" />
              온라인 상으로 만날 수 있습니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}
        <div className="contents-width">
          {/* S : 추모관 등록 */}
          <div className="wrap-form">
            {/* S : 고인 정보 */}
            <div className="section-form">
              <h3 className="label">고인 정보입력</h3>

              {/* S : 고인 이름/직위 */}
              <div className="ip-wrap ip-group">
                <div className="ip-item is-error">
                  <label for="ip-deceased-name" className="offscreen">
                    고인 이름 입력
                  </label>
                  <input
                    type="text"
                    id="ip-deceased-name"
                    placeholder="이름"
                    aria-invalid={errors.deceased_name ? 'true' : 'false'}
                    {...register('deceased_name')}
                  />
                  {errors.deceased_name && (
                    <p className="message">{errors.deceased_name.message}</p>
                  )}
                </div>
                <div className="ip-item p is-error">
                  <label for="ip-deceased-pos" className="offscreen">
                    고인 직위 입력
                  </label>
                  <input
                    type="text"
                    id="ip-deceased-pos"
                    placeholder="직위"
                    {...register('deceased_job_title')}
                  />
                </div>
              </div>
              {/* E : 고인 이름/직위  */}

              {/* S : 생년월일 */}
              <div className="ip-item is-error">
                <label for="ip-deceased-birth" className="label sm">
                  생년월일
                  <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em>
                </label>
                <input
                  type="date"
                  id="ip-deceased-birth"
                  aria-invalid={errors.deceased_birthday ? 'true' : 'false'}
                  {...register('deceased_birthday')}
                />
                {errors.deceased_birthday && (
                  <p className="message">{errors.deceased_birthday.message}</p>
                )}
              </div>
              {/* E : 생년월일 -*/}

              {/* S : 임종 일시 */}
              <div className="ip-item is-error">
                <h4 className="label sm">
                  임종일자
                  <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em>
                </h4>
                <div className="ip-item is-error">
                  <label for="ip-deceased-dying-day" className="offscreen">
                    임종일자 입력
                  </label>
                  <input
                    type="date"
                    id="ip-deceased-dying-day"
                    aria-invalid={errors.deceased_date ? 'true' : 'false'}
                    {...register('deceased_date')}
                  />

                  {errors.deceased_date && (
                    <p className="message">{errors.deceased_date.message}</p>
                  )}
                </div>
                {/* <div className="ip-item">
                <label for="ip-deceased-dying-time" className="offscreen">
                  임종일자 시간
                </label>
                <input
                  type="time"
                  id="ip-deceased-dying-time"
                  aria-invalid={errors.deceased_hour ? 'true' : 'false'}
                  {...register('deceased_hour')}
                />
              </div> */}
              </div>
              {/* E : 임종 일시 */}

              {/* S : 고인 사진 */}
              {/* S : 고인사진은 기존 마크업에 className 이름만 추가되었습니다. */}
              <div className="ip-wrap ip-filebox">
                <span className="label">고인사진</span>
                <label className="file" for="imageFile">
                  <span
                    className="offscreen"
                    onClick={() => document.getElementById('imageFile').click()}
                  >
                    파일선택
                  </span>
                </label>
                <input
                  type="file"
                  className="input-hidden"
                  id="imageFile"
                  onChange={changeImageFile}
                />

                {/* 첨부 이미지 등록 전 */}
                {/*<div className="file_output default"></div>*/}

                {/* 첨부 이미지 등록 후 */}
                {deceasedPhoto ? (
                  <div className="file-output" onChange={changeImageFile}>
                    <span className="img-area">
                      <img
                        alt=""
                        src={deceasedPhoto}
                        //src="https://d3lzegcz38cz56.cloudfront.net/photo/41092f61-c8a8-4b57-8a39-e505942cb9953d9a47a286411ddbb2060b7d2ecef49e4bef53c9.jpg"
                      />
                    </span>
                    <button className="file-del" onClick={removeImageFile}>
                      <span className="offscreen">사진 삭제</span>
                    </button>
                  </div>
                ) : (
                  <div className="file_output default" onChange={changeImageFile}></div>
                )}
              </div>
              {/* E : 고인 사진 */}
            </div>
            {/* E : 고인 정보 */}

            {/* S : 고인 일대기(영상) */}
            {/* S : 고인 일대기 영상은 기존 마크업에 className 이름만 추가되었습니다. */}
            <div className="section-form">
              <div className="pos-r">
                <span className="label">고인 일대기(영상)</span>

                <MediaUploader medias={medias} addMedia={addMedia} removeMedia={removeMedia} />
                {videoLink ? '동영상 링크 : ' + `${videoLink}` : ' '}
                <div className="rc-item rc-toggle">
                  <input type="checkbox" id="ip-public01" checked={videoYn} onChange={!videoYn} />
                  <label for="ip-public01" onClick={() => handleVideoYn(videoYn ? false : true)}>
                    <span className="toggle"></span> 공개여부
                  </label>
                </div>
              </div>
            </div>
            {/* E : 고인 일대기(영상) */}

            {/* S : 안내글, 추도사 */}
            <div className="section-form">
              {/* S : 안내글 입력 */}
              <div className="ip-item is-error">
                <label for="ip-text1" className="label">
                  안내글
                  <em className="import">
                    <span className="offscreen">필수입력</span>
                  </em>
                </label>
                <input
                  type="text"
                  id="ip-text1"
                  placeholder="예) 저희 상가는 조의금을 정중히 사양합니다."
                  {...register('memorial_introduction')}
                  aria-invalid={errors.memorial_introduction ? 'true' : 'false'}
                />

                {errors.memorial_introduction && (
                  <p className="message">{errors.memorial_introduction.message}</p>
                )}
              </div>
              {/* E : 안내글 입력 */}
              {/* S : 추도사 입력 */}
              <div className="ip-wrap">
                <div className="ip-item">
                  <label for="text-tribute" className="label">
                    추도사
                    <em className="import">
                      <span className="offscreen">필수입력</span>
                    </em>
                  </label>
                  <textarea
                    {...register('chudosa')}
                    id="text-tribute"
                    placeholder="예) 삼가 영전(靈前)에 깊은 애도(哀悼)의 뜻을 표합니다."
                  ></textarea>
                  {errors.chudosa && <p className="message">{errors.chudosa.message}</p>}
                </div>
                <button
                  type="button"
                  className="btn-underline"
                  onClick={() => setOpenChudosaSampleDialog(true)}
                >
                  예시보기
                </button>
              </div>
              {/* E : 추도사 입력 */}
            </div>
            {/* E : 안내글, 추도사 */}

            <Blts control={control} setValue={setValue} getValues={getValues} errors={errors} />

            {/* S : 부고 공개 여부 확인 */}
            <ul className="rc-list">
              <li>
                <div className="rc-item">
                  <input
                    type="checkbox"
                    id="chk-sample-3"
                    checked={displayYn}
                    onChange={!displayYn}
                  />
                  <label
                    for="chk-sample-3"
                    onClick={() => handleChangeDisplayYn(displayYn ? false : true)}
                  >
                    입력한 추모정보를 공개하시겠습니까?
                  </label>
                </div>
              </li>
              <li>
                <div className="rc-item">
                  <input type="checkbox" id="chk-sample-4" checked={multyYn} onChange={!multyYn} />
                  <label
                    for="chk-sample-4"
                    onClick={() => handleChangeMultyYn(multyYn ? false : true)}
                  >
                    입력한 부고정보를 공개하시겠습니까?
                  </label>
                </div>
              </li>
            </ul>
            {/* E : 부고 공개 여부 확인 */}

            {/* S : 버튼 */}
            <div className="btn-wrap">
              <button
                type="button"
                className="btn-form btn-ln-blue"
                onClick={() => handlePreview()}
              >
                미리보기
              </button>
              <button
                type="button"
                className="btn-form btn-navy"
                onClick={handleSubmit(onSubmit, onError)}
              >
                등록완료
              </button>
              <button type="button" className="btn-form btn-ln-gray " onClick={handleClose}>
                취소
              </button>
            </div>
            {/* E : 버튼 */}
          </div>
          {/* E : 추모관 등록 */}
        </div>
      </div>
      <ChudosaSampleDialog
        isOpen={openChudosaSampleDialog}
        onClose={() => setOpenChudosaSampleDialog(false)}
      />
      {openPreviewDialog && (
        <PreviewDialog
          isOpen={openPreviewDialog}
          onClose={() => setOpenPreviewDialog(false)}
          getValues={getValues}
        />
      )}
      {/* E : CONTENT */}
    </section>
  );
}

export default AdminMemorialRegisterPage;

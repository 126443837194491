import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useLogin from 'stores/login/hook';
import useLayout from 'stores/layout/hook';
import useDialog from 'stores/dialog/hook';
import useCode from 'stores/code/hook';

import { getMemberApi, modifyMemberApi } from 'apis/member';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ChangePasswordDialog from 'pages/pet-memorial-canvas/mypage/components/ChangePasswordDialog';
import ChangePhoneDialog from 'pages/pet-memorial-canvas/mypage/components/ChangePhoneDialog';
import WithdrawalDialog from 'pages/pet-memorial-canvas/mypage/components/WithdrawalDialog';

function MypageMemberPage() {
  const navigate = useNavigate();

  const { loginUser, logout } = useLogin();
  const { setGnbType } = useLayout();
  const { showAlert, hideAlert } = useDialog();
  const { memberTypeCodes, loginTypeCodes } = useCode();

  const schema = Yup.object({
    member_name: Yup.string()
      .required('필수 입력 항목입니다.')
      .max(15, '최대 입력은 15자까지 가능합니다.'),
    mobile: Yup.string()
      .required('필수 입력 항목입니다.')
      .matches(
        '^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$',
        '핸드폰번호 형식이 올바르지 않습니다. 예) 01012345678',
      ),
  }).required();

  const defaultValues = {
    member_id: '',
    member_name: '',
    member_type: 'N',
    email: '',
    mobile: '',
    addr: '',
    use_yn: 'Y',
    login_type: 'E',
    nation_number: 82,
    nation_name: '대한민국',
    del_yn: 'N',
    status: 1,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [member, setMember] = useState(null);
  const [memberTypeValue, setMemberTypeValue] = useState('');
  const [loginTypeValue, setLoginTypeValue] = useState('');
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [openChangePhoneDialog, setOpenChangePhoneDialog] = useState(false);
  const [openWithDialog, setOpenWithDialog] = useState(false);
  const [phone, setPhone] = useState('');

  const [authCode, setAuthCode] = useState(0);
  // 인증 전 : 0 인증 후 : 1
  const [authStatus, setAuthStatus] = useState(0);
  const getMember = async params => {
    const result = await getMemberApi(params);
    setMember(result.data);
    console.log(JSON.stringify(result.data));
  };

  const modifyMember = async params => {
    params.tx_seq_no = authCode;
    const result = await modifyMemberApi(params);
    const user = JSON.parse(localStorage.getItem('loginUser'));

    if (user.member_id === params.member_id) {
      const modifyUser = { ...user, member_name: params.member_name };
      localStorage.setItem('loginUser', JSON.stringify(modifyUser));
      showAlert('알림', result.message, () => hideAlert());
      if (user.member_name !== params.member_name) {
        showAlert('알림', '이름이 변경되었습니다. \n다시 로그인 해주세요.', () => nameLogout());
      }
    }
  };

  const nameLogout = () => {
    logout();
    navigate('/pet');
    hideAlert();
  };

  const onSubmit = data => {
    modifyMember(data);
  };

  const onError = error => {
    console.log('error > ', error);
  };

  const handleLogout = () => {
    logout();
    navigate('/pet');
  };

  const handleNotiAuthSend = () => {
    // if (!getValues('mobile') || getValues('mobile') === '' || errors.mobile) {
    //   showAlert('알림', '휴대폰 번호를 확인하세요.', () => hideAlert());
    // } else {
    //   notiAuthSend();
    // }
    window.open(
      'https://mylifecanvas.co.kr/apiserver/authVerify',
      'auth_popup',
      'width=430,height=640,scrollbars=yes',
    );
  };

  useEffect(() => {
    console.log(phone);
    setValue('mobile', phone);
  }, [phone]);

  useEffect(() => {
    // if (window.innerWidth <= 1024) {
    //   setisMobile(true);
    // }

    // 본인인증 서비스
    window.addEventListener('message', function (e) {
      const result = e.data;
      // RSLT_CD: "B000"면 성공
      if (result.RSLT_CD === 'B000') {
        setAuthStatus(1);
        // setBirth(result.RSLT_BIRTHDAY);
        setAuthCode(result.TX_SEQ_NO);
        // setName(result.RSLT_NAME);
      }
      console.log(result);
    });
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('message', function (e) {
        const result = e.data;
        // RSLT_CD: "B000"면 성공

        console.log(result);
      });
    };
  }, []);

  useEffect(() => {
    if (member) {
      Object.entries(member).forEach(([key, value]) => {
        setValue(key, value);
      });

      setMemberTypeValue(
        memberTypeCodes.filter(memberTypeCode => memberTypeCode.code === member.member_type)[0]
          .value,
      );
      setLoginTypeValue(
        loginTypeCodes.filter(loginTypeCode => loginTypeCode.code === member.login_type)[0].value,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  useEffect(() => {
    if (loginUser && !loginUser.member_id) {
      //showAlert('알림', '올바른 접근이 아닙니다.', () => hideAlert());
      navigate('/');
    }

    if (loginUser) getMember(loginUser.member_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser]);

  useEffect(() => {
    setGnbType('sub_gnb');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container" id="containerWrap">
      {/* S : CONTENT */}
      <div className="content">
        {/* S : 서브 비주얼 + 타이틀 */}
        <div className="visual-wrap">
          <div className="contents-width">
            <h2 className="title">마이페이지 </h2>
            <p>
              메모리얼 캔버스는
              <br />
              새로운 장례 문화를 선도하는 사회적 기업입니다.
            </p>
          </div>
        </div>
        {/* E : 서브 비주얼 + 타이틀 */}

        <div className="contents-width">
          <div className="tabs tabs-default">
            <ul>
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/obituary">부고</Link>
                </a>
              </li>
              <li>
                <a href={() => false}>
                  <Link to="/pet/mypage/memorial">추모관</Link>
                </a>
              </li>
              {/*<li>*/}
              {/*  <a href={() => false}>*/}
              {/*    <Link className="on" to="/pet/mypage/wreath">*/}
              {/*      구매내역*/}
              {/*    </Link>*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li className="is-active">
                <a href={() => false}>
                  <Link className="on" to="/pet/mypage/member">
                    회원설정
                  </Link>
                </a>
              </li>
            </ul>
          </div>

          <div className="wrap-mypage">
            {/* S : 회원 설정 */}
            {member ? (
              <>
                <div className="wrap-info">
                  {/* S : 기본정보 */}
                  <div className="wrap-form">
                    <div className="section-form">
                      <h3 className="label">기본정보</h3>
                      {/* S : 이메일 */}
                      <div className="ip-item has-btn is-error">
                        <label for="ip-email" className="label sm">
                          이메일
                        </label>
                        <input
                          type="text"
                          id="ip-email"
                          {...register('email')}
                          aria-invalid={errors.email ? 'true' : 'false'}
                          disabled
                        />
                        {member.tx_seq_no === '' ? (
                          <button
                            type="button"
                            className="btn-search-default"
                            onClick={handleNotiAuthSend}
                            disabled={authStatus === 1 ? true : false}
                          >
                            본인인증
                          </button>
                        ) : (
                          <button type="button" className="btn-search-default" disabled>
                            본인인증 완료
                          </button>
                        )}
                        {errors.email && <p className="message">{errors.email.message}</p>}
                      </div>
                      {/* E : 이메일 */}
                      {/* S : 비밀번호 */}
                      {/* <div className="ip-item has-pos-btn">
                        <p className="label sm">비밀번호</p>
                      
                        {member && member.login_type === 'E' && (
                          <button
                            type="button"
                            class="btn btn-sm-blue"
                            onClick={() => setOpenChangePasswordDialog(true)}
                          >
                            비밀번호 변경
                          </button>
                        )}
                      </div> */}

                      <div className="ip-item has-pos-btn">
                        {/*<p className="label sm">비밀번호</p>*/}
                        {member && member.login_type === 'E' && (
                          <button
                            type="button"
                            className="btn btn-sm-blue"
                            onClick={() => setOpenChangePasswordDialog(true)}
                          >
                            비밀번호 변경
                          </button>
                        )}
                      </div>
                      {/* E : 비밀번호 */}
                      {/* S : 이름 */}
                      <div className="ip-item is-error">
                        <label for="ip-name" className="label sm">
                          이름
                        </label>
                        <input
                          type="text"
                          id="ip-name"
                          {...register('member_name')}
                          aria-invalid={errors.member_name ? 'true' : 'false'}
                        />

                        {errors.member_name && (
                          <p className="message">{errors.member_name.message}</p>
                        )}
                      </div>
                      {/* E : 이름 */}
                      {/* S : 핸드폰 번호 */}
                      <div className="ip-item  inner-btn is-error">
                        <label for="ip-phone" className="label sm">
                          전화번호
                        </label>
                        <input
                          type="text"
                          id="ip-phone"
                          {...register('mobile')}
                          aria-invalid={errors.mobile ? 'true' : 'false'}
                          disabled
                        />
                        <button
                          type="button"
                          className="btn btn-sm-blue"
                          onClick={() => setOpenChangePhoneDialog(true)}
                        >
                          전화번호 변경
                        </button>
                        {errors.mobile && <p className="message">{errors.mobile.message}</p>}
                      </div>
                      {/* E : 핸드폰 번호 */}
                      <div className="ip-item is-error">
                        <label htmlFor="ip-birth" className="label sm">
                          생년월일
                        </label>
                        <input
                          type="text"
                          id="ip-address"
                          {...register('birth')}
                          aria-invalid={errors.birth ? 'true' : 'false'}
                          disabled
                        />
                        {errors.birth && <p className="message">{errors.birth.message}</p>}
                      </div>
                      <div className="ip-item">
                        <label htmlFor="ip-logintype" className="label sm">
                          로그인 타입
                        </label>
                        <input type="text" id="ip-logintype" value={loginTypeValue} disabled />
                      </div>
                    </div>
                  </div>
                  {/* E : 기본정보 */}
                  {/* S : 추가정보 */}
                  {/*<div className="wrap-form">*/}
                  {/*<div className="section-form">*/}
                  {/*  <h3 className="label">추가정보</h3>*/}
                  {/* S : 이메일 */}
                  {/*<div className="ip-item is-error">*/}
                  {/*  <label for="ip-address" className="label sm">*/}
                  {/*    주소*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    id="ip-address"*/}
                  {/*    {...register('addr')}*/}
                  {/*    aria-invalid={errors.addr ? 'true' : 'false'}*/}
                  {/*  />*/}
                  {/*  {errors.addr && <p className="message">{errors.addr.message}</p>}*/}
                  {/*</div>*/}
                  {/* E : 이메일 */}
                  {/* S : 이메일 */}
                  {/*<div className="ip-item">*/}
                  {/*  <label for="ip-membertype" className="label sm">*/}
                  {/*    사용자 타입*/}
                  {/*  </label>*/}
                  {/*  <input type="text" id="ip-membertype" value={memberTypeValue} disabled />*/}
                  {/*</div>*/}
                  {/* E : 이메일 */}
                  {/* S : 이메일 */}

                  {/* E : 이메일 */}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/* S : 추가정보 */}
                </div>
                {/* E : 회원 설정 */}

                {/* S : 버튼 */}
                <div className="btn-wrap">
                  <button
                    type="button"
                    className="btn btn-pos"
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    저장
                  </button>
                  <a href={() => false} onClick={handleLogout} className="btn btn-md-text">
                    로그아웃
                  </a>
                  <a
                    href={() => false}
                    className="btn btn-sm-arrow"
                    onClick={() => setOpenWithDialog(true)}
                  >
                    탈퇴하기
                  </a>
                </div>
                <ChangePasswordDialog
                  isOpen={openChangePasswordDialog}
                  onClose={() => setOpenChangePasswordDialog(false)}
                />
                <ChangePhoneDialog
                  isOpen={openChangePhoneDialog}
                  onClose={() => setOpenChangePhoneDialog(false)}
                  setPhone={setPhone}
                />
                <WithdrawalDialog
                  isOpen={openWithDialog}
                  onClose={() => setOpenWithDialog(false)}
                />
              </>
            ) : null}
            {/* E : 버튼 */}
          </div>
        </div>
      </div>
      {/* E : CONTENT */}
    </section>
  );
}

export default MypageMemberPage;
